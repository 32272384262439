<table mat-table [dataSource]="connectedAgentDetails" class="mat-elevation-z8 table mb-5">
  <ng-container matColumnDef="agent_name">
    <th mat-header-cell *matHeaderCellDef>Agent Name</th>
    <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
  </ng-container>
  <ng-container matColumnDef="plan_type">
    <th mat-header-cell *matHeaderCellDef>Plan Type</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.priceDetails.interval == null && element.priceDetails.product == 'Unlimited'">
        Unlimited Plan
      </ng-container>
      <ng-container *ngIf="element.priceDetails.interval == null && element.priceDetails.product != 'Unlimited'">
        Pay per report</ng-container
      >
      <ng-container *ngIf="element.priceDetails.interval == 'month' && element.priceDetails.product != 'Unlimited'">
        Pay per month</ng-container
      >
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let element">{{ element?.status | titlecase }}</td>
  </ng-container>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">{{ element.date[0] ? (element?.date | date: 'dd/MM/yyyy') : '' }}</td>
  </ng-container>
  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef>Details</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.priceDetails.interval == null && element.priceDetails.product == 'Unlimited'">
        Unlimited Plan
      </ng-container>
      <ng-container *ngIf="element.priceDetails.interval == null && element.priceDetails.product != 'Unlimited'">
        You Pay ${{
          element?.discounts?.discountedPrice ? element.discounts.discountedPrice : element.priceDetails.amount
        }}
        per report</ng-container
      >
      <ng-container *ngIf="element.priceDetails.interval == 'month' && element.priceDetails.product != 'Unlimited'">
        You Pay ${{
          element?.discounts?.discountedPrice ? element.discounts.discountedPrice : element.priceDetails.amount
        }}
        per month</ng-container
      >
    </td>
  </ng-container>
  <ng-container matColumnDef="payment_status">
    <th mat-header-cell *matHeaderCellDef>Payment Status</th>
    <td mat-cell *matCellDef="let element" [ngClass]="element.loPaymentStatus[0] ? 'error-message' : ''">
      {{
        element.loPaymentStatus[0] && element.priceDetails.product != 'Unlimited'
          ? element.loPaymentStatus[0].raw.message
          : (element?.status == 'Active' || element?.status == 'active' || element?.status == 'ACTIVE') &&
            element.priceDetails.product != 'Unlimited'
          ? 'Success'
          : ''
      }}
    </td></ng-container
  >

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber',
})
export class ShortNumberPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null || value === undefined || isNaN(value)) {
      return '';
    }

    const suffixes = ['', 'k', 'M', 'B', 'T'];
    const num = Math.floor(Math.log10(Math.abs(value)) / 3);
    const shortValue = parseFloat((value / Math.pow(1000, num)).toFixed(2));
    return shortValue + suffixes[num];
  }
}

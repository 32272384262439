import { Injectable } from '@angular/core';
import { WorkspaceMode } from '../interface/workspace.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceConfig {
  defaultMode: WorkspaceMode = {
    modeName: 'Default',
    isDefault: true,
    isFilterExclusive: true,
    mapView: true,
    listView: false,
    zoomLevels: [
      {
        minZoom: 20,
        maxZoom: 19,
        api: 'getPropertiesInBoundingBox',
        filteredMarker: 'label',
        nonFilteredMarker: 'label',
      },
      {
        minZoom: 18,
        maxZoom: 17,
        api: 'getPropertiesInBoundingBox',
        filteredMarker: 'label',
        nonFilteredMarker: null,
      },
      {
        minZoom: 16,
        maxZoom: 10,
        api: 'getPropertiesInBoundingBoxWithFilters',
        filteredMarker: 'label',
        nonFilteredMarker: null,
      },
    ],
    defaultFilters: {
      listingEnabled: true,
      minDaysOnMarket: 0,
      onMarketStatus: ['active', 'active under contract'],
    },
    defaultPropertySort: {
      sortFields: 'Lst_ListingContractDate',
      sortOrder: 'DESC',
    },
    markerColor: {
      distressed: '#eb5e5e',
      hud: '#b554e2',
      listing: '#34bd7f',
      sold: '#dd9361',
      filtered: '#2e73c2',
      nonFiltered: '#cacaca',
      clustered: '#a0d8f4',
    },
  };

  /**
   * Public Record / Search APIs will be called based on this configuration
   */
  publicRecordApiConfig = [
    {
      // view: ['map'],
      defaultMode: true,
      minZoom: 19,
      maxZoom: 19,
      category: ['listing', 'nonListing'],
      updatedState: ['map', 'list'],
      // fields: ['bed', 'bath', 'lotSize', 'livingArea', 'fullAddress', 'latitude', 'longitude'],
      // filters: { PropertyType: 'residential' },
      api: 'allPropertiesInBoundingBox',
    },
    {
      // view: ['map'],
      defaultMode: true,
      minZoom: 15,
      maxZoom: 18,
      category: ['listing'],
      updatedState: ['map', 'list'],
      // fields: ['bed', 'bath', 'lotSize', 'livingArea', 'fullAddress', 'latitude', 'longitude'],
      api: 'listingInBoundingBox',
    },
    {
      // view: ['map'],
      defaultMode: true,
      minZoom: 15,
      maxZoom: 18,
      category: ['nonListing'],
      updatedState: ['map', 'list'],
      // fields: ['bed', 'bath', 'lotSize', 'livingArea', 'fullAddress', 'latitude', 'longitude'],
      api: 'propertiesInBoundingBox',
    },
    {
      // view: ['map'],
      defaultMode: true,
      minZoom: 9,
      maxZoom: 14,
      category: ['listing', 'nonListing'],
      updatedState: ['map'],
      // fields: ['bed', 'bath', 'lotSize', 'livingArea', 'fullAddress', 'latitude', 'longitude'],
      api: 'clustersInBoundingBox',
    },
    {
      // view: ['map'],
      defaultMode: true,
      minZoom: 9,
      maxZoom: 14,
      category: ['listing'],
      updatedState: ['list'],
      // fields: ['bed', 'bath', 'lotSize', 'livingArea', 'fullAddress', 'latitude', 'longitude'],
      api: 'listingInBoundingBox',
      paginatedResult: true,
    },
    {
      // view: ['map'],
      defaultMode: true,
      minZoom: 9,
      maxZoom: 14,
      category: ['nonListing'],
      updatedState: ['list'],
      // fields: ['bed', 'bath', 'lotSize', 'livingArea', 'fullAddress', 'latitude', 'longitude'],
      api: 'propertiesInBoundingBox',
      paginatedResult: true,
    },
    {
      // view: ['map'],
      defaultMode: false,
      minZoom: 15,
      maxZoom: 19,
      category: ['listing', 'nonListing'],
      updatedState: ['map', 'list'],
      // fields: ['bed', 'bath', 'lotSize', 'livingArea', 'fullAddress', 'latitude', 'longitude'],
      api: 'propertiesInBoundingBox',
    },
    {
      // view: ['map'],
      defaultMode: false,
      minZoom: 9,
      maxZoom: 14,
      category: ['listing', 'nonListing'],
      updatedState: ['map'],
      // fields: ['bed', 'bath', 'lotSize', 'livingArea', 'fullAddress', 'latitude', 'longitude'],
      api: 'clustersInBoundingBox',
    },
    {
      // view: ['map'],
      defaultMode: false,
      minZoom: 9,
      maxZoom: 14,
      category: ['listing', 'nonListing'],
      updatedState: ['list'],
      // fields: ['bed', 'bath', 'lotSize', 'livingArea', 'fullAddress', 'latitude', 'longitude'],
      api: 'propertiesInBoundingBox',
      paginatedResult: true,
    },
    // {
    //   view: ['list'],
    //   defaultMode: false,
    //   minZoom: null,
    //   maxZoom: null,
    //   category: ['listing', 'nonListing'],
    //   updatedState: ['list'],
    //   fields: ['bed', 'bath', 'lotSize', 'livingArea', 'fullAddress', 'latitude', 'longitude'],
    //   api: 'propertiesByGeo',
    //   paginatedResult: true,
    // },
  ];
}

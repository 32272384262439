<div class="prpty_visit">
  <button class="close" mat-icon-button (click)="close()" autofocus="false">
    <mat-icon>close</mat-icon>
  </button>
  <!-- <div class="pull-right" ><mat-icon>close</mat-icon></div> -->
  <div>
    <h4 class="font-12">Schedule a visit to the property</h4>
    <p class="text-secondary">Agent will contact you with a time-slot for visit</p>
  </div>
  <div class="wrap_body mt-4">
    <form [formGroup]="propertyVisitScheduleForm">
      <ng-container>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input matInput formControlName="firstName" autocomplete="off" />
              <mat-error
                *ngIf="
                  propertyVisitScheduleFormControls.firstName.errors &&
                  propertyVisitScheduleFormControls.firstName.errors.required
                "
                class="invalid-feedback"
              >
                First Name is required</mat-error
              >
              <mat-error
                *ngIf="
                  propertyVisitScheduleFormControls.firstName.errors &&
                  propertyVisitScheduleFormControls.firstName.errors.pattern
                "
                class="invalid-feedback"
              >
                Enter valid names</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="lastName" autocomplete="off" />
              <mat-error
                *ngIf="
                  propertyVisitScheduleFormControls.lastName.errors &&
                  propertyVisitScheduleFormControls.lastName.errors.required
                "
                class="invalid-feedback"
              >
                Last Name is required</mat-error
              >
              <mat-error
                *ngIf="
                  propertyVisitScheduleFormControls.lastName.errors &&
                  propertyVisitScheduleFormControls.lastName.errors.pattern
                "
                class="invalid-feedback"
              >
                Enter valid names</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" autocomplete="off" />
              <mat-error
                *ngIf="
                  propertyVisitScheduleFormControls.email.errors &&
                  propertyVisitScheduleFormControls.email.errors.required
                "
                class="invalid-feedback"
              >
                Email is required</mat-error
              >
              <mat-error
                *ngIf="
                  propertyVisitScheduleFormControls.email.errors &&
                  propertyVisitScheduleFormControls.email.errors.pattern
                "
                class="invalid-feedback"
              >
                Invalid email format</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <mat-label>Phone</mat-label>
              <input matInput formControlName="phone" autocomplete="off" />
              <mat-error
                *ngIf="
                  propertyVisitScheduleFormControls.phone.errors &&
                  propertyVisitScheduleFormControls.phone.errors.required
                "
                class="invalid-feedback"
              >
                Contact number is required</mat-error
              >
              <mat-error
                *ngIf="
                  propertyVisitScheduleFormControls.phone.errors &&
                  propertyVisitScheduleFormControls.phone.errors.pattern
                "
                class="invalid-feedback"
              >
                Enter valid contact number</mat-error
              >
            </mat-form-field>
          </div>
        </div>
      </ng-container>
      <div class="row mt-2">
        <div class="col">
          <mat-form-field (click)="preferredDate?.open()">
            <mat-label>Preferred Date</mat-label>
            <input
              (click)="preferredDate?.open()"
              matInput
              [matDatepicker]="preferredDate"
              formControlName="preferredDate"
              [min]="currentDate"
              autocomplete="off"
            />
            <mat-datepicker-toggle matSuffix [for]="preferredDate"></mat-datepicker-toggle>
            <mat-datepicker #preferredDate></mat-datepicker>
            <mat-error
              *ngIf="
                propertyVisitScheduleFormControls.preferredDate.errors &&
                propertyVisitScheduleFormControls.preferredDate.errors.required
              "
              class="invalid-feedback"
            >
              Date is required</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>Preferred Time</mat-label>
            <mat-select formControlName="preferredTime">
              <mat-option *ngFor="let timeSlot of preferredTimeSlots" [value]="timeSlot">
                {{ timeSlot }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                propertyVisitScheduleFormControls.preferredTime.errors &&
                propertyVisitScheduleFormControls.preferredTime.errors.required
              "
              class="invalid-feedback"
            >
              Time is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <mat-form-field>
            <mat-label>Notes(optional)</mat-label>
            <input matInput formControlName="notes" autocomplete="off" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-2">
        <div *ngIf="checkBox" class="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="enableSubmit"
              (change)="enableSubmit = !enableSubmit"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" style="font-style: italic" for="flexCheckDefault">
              I agree to the below terms.
            </label>
          </div>
        </div>
        <div class="col text-right">
          <button
            mat-flat-button
            [pmx-progress-button]="schedulePropertyVisitInprogress"
            [disabled]="!enableSubmit"
            color="primary"
            (click)="sendPropertyVisitRequest()"
          >
            Request Visit</button
          ><br />
        </div>
      </div>
      <div #ctaPolicy class="policyDetails" [termsPolicy]></div>
    </form>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

import { AppApi } from 'src/app/app.api';
import { AppState } from 'src/app/app.state';
import { AuthToken } from 'src/app/shared/interface/auth.interface';
import { CookieStorageService } from 'src/app/shared/service/cookie-storage.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializer {
  constructor(
    private _router: Router,
    private appState: AppState,
    private appApi: AppApi,
    private cookieStorageService: CookieStorageService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) { }

  initializeApp(): Observable<any> {
    this.appState.setAppLoadingState('getCompany', true);

    return this.appApi.getCompany().pipe(
      map((res) => {
        this.appState.companyInfoValue = {
          ...res.data,
          isTokenAuthEnabled: res && res.isTokenAuthEnabled ? true : false,
        };

        const urlParams = new URLSearchParams(window.location.search);

        let queryParams = {};
        for (const [key, value] of urlParams) {
          if (['idToken', 'accessToken', 'refreshToken', 'login'].indexOf(key) < 0) {
            queryParams[key] = value;
          }
        }
        if (Object.keys(queryParams).length != 0) {
          localStorage.setItem('appQueryParams', JSON.stringify(queryParams));
        }

        const authCode = urlParams.get('code');
        const urlIdToken = urlParams.get('idToken');
        const urlAccessToken = urlParams.get('accessToken');
        const urlRefreshToken = urlParams.get('refreshToken');

        if (authCode) {
          this._router.navigate([], {
            queryParams: {},
            replaceUrl: true,
            state: { skipUpdate: true },
          });
          this.authService.signInWithSocialMedia(authCode);
        } else {
          if (urlIdToken) this.cookieStorageService.setCookie('idToken', urlIdToken, 7);
          if (urlAccessToken) this.cookieStorageService.setCookie('accessToken', urlAccessToken, 7);
          if (urlRefreshToken) this.cookieStorageService.setCookie('refreshToken', urlRefreshToken, 7);
        }

        /**
         * Get the auth tokens from cookie and set it to appState.
         */
        const authToken: Partial<AuthToken> = {};
        const localIdToken = this.cookieStorageService.getCookieValue('idToken');
        const localAccessToken = this.cookieStorageService.getCookieValue('accessToken');
        const localRefreshToken = this.cookieStorageService.getCookieValue('refreshToken');

        if (localIdToken) authToken.idToken = localIdToken;
        if (localAccessToken) authToken.accessToken = localAccessToken;
        if (localRefreshToken) authToken.refreshToken = localRefreshToken;
        if (Object.keys(authToken).length) this.appState.authTokenValue = authToken;

        this.appState.setAppLoadingState('getCompany', false);

        if (res?.data?.alias == environment.defaultCompany) {
          this._router.navigate(['corporate-login']);
        }
      }),
      catchError(() => {
        this.appState.setAppLoadingState('getCompany', false);
        this._router.navigate(['/not-found']);
        return of();
      }),
      finalize(() => {
        this.appState.setAppLoadingState('getCompany', false);
      })
    );
  }
}

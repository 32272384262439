import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FavoriteGroup } from '../interface/favorite.interface';

@Injectable({
  providedIn: 'root',
})
export class FavoriteState {
  propertyToFavoriteList: any = {
    PMXPropertyIdList: [],
    stateList: [],
  };

  // To store the PMXPropertyId of all the favorite properties
  private favoritePropertyIdListSub$ = new BehaviorSubject<Array<any>>([]);
  favoritePropertyIdList$ = this.favoritePropertyIdListSub$.asObservable();
  get favoritePropertyIdListValue(): Array<any> {
    return this.favoritePropertyIdListSub$.getValue();
  }
  set favoritePropertyIdListValue(favoriteList: Array<any>) {
    this.favoritePropertyIdListSub$.next(favoriteList);
  }

  private followGroupListSub$ = new BehaviorSubject<Array<FavoriteGroup>>([]);
  followGroupList$ = this.followGroupListSub$.asObservable();
  get followGroupListValue(): Array<FavoriteGroup> {
    return this.followGroupListSub$.getValue();
  }
  set followGroupListValue(groups: Array<FavoriteGroup>) {
    this.followGroupListSub$.next(groups);
  }

  favoriteActionCompletedSub$ = new BehaviorSubject<boolean>(false);
  favoriteActionCompleted$ = this.favoriteActionCompletedSub$.asObservable();
  get favoriteActionCompletedValue(): boolean {
    return this.favoriteActionCompletedSub$.value;
  }
  set favoriteActionCompletedValue(status: boolean) {
    this.favoriteActionCompletedSub$.next(status);
  }
}

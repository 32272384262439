import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-upload-background-image',
  templateUrl: './upload-background-image.component.html',
  styleUrls: ['./upload-background-image.component.scss'],
})
export class UploadBackgroundImageComponent implements OnInit {
  openPortfolioBannerImageSheetValue: boolean;
  imageList: any;
  onDestroyNotifier$ = new Subject();
  imageUrl: any;
  selectedImage: string;
  // isLoading: boolean = false;
  aerialImageArray = [];
  aerialImageCategory: string;
  exteriorImageArray = [];
  exteriorImageCategory: string;
  defaultImageArray: [];
  defaultImageCategory: string;
  isDataLoading: boolean = false;
  imageArray: any[];
  userBannerImage: string;
  defaultImageLength: string;
  exteriorImageLength: string;
  aerialImageLength: string;
  disableButton: boolean = true;
  hideTab: boolean = false;

  @Output() selectedImageUrl = new EventEmitter<object>();
  @Output() uploadedFile = new EventEmitter<string>();
  @Output() closeSheet = new EventEmitter<boolean>();

  @Input() openSheet: boolean = true;
  @Input() imageUrlList: any;
  @Input() userImage: any;
  @Input() imageRatio: any;
  @Input() imageType: any;

  height: any;
  width: any;
  percentageWidth: any;
  percentageHeight: any;
  aspectRatio: any;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    if (!this.imageUrlList.length) {
      this.hideTab = false;
    } else {
      this.hideTab = true;
    }
    const [widthStr, heightStr] = this.imageRatio.split(':');
    this.width = Number(widthStr);
    this.height = Number(heightStr);
    this.aspectRatio = (this.height / this.width) * 100;
    this.percentageWidth = 100;
    this.percentageHeight = 100 / this.aspectRatio;
  }

  selectImage(image) {
    this.selectedImage = image;
    this.imageUrl = image;
    if (this.imageUrl && this.imageUrl !== this.userImage) {
      this.disableButton = false;
    } else if (this.imageUrl == this.userImage) {
      this.disableButton = true;
    }
  }

  setImage() {
    let image = {
      selectedImage: this.selectedImage,
      type: this.imageType,
    };
    this.selectedImageUrl.emit(image);
    this.isDataLoading = true;
    this.openSheet = false;
  }

  uploadFile(file) {
    this.uploadedFile.emit(file);
  }

  onClose() {
    this.closeSheet.emit(false);
  }

  ngOnDestroy() {
    this.imageList = [];
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}

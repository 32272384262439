import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNumber',
})
export class ToNumberPipe implements PipeTransform {
  transform(value: any): number {
    if (typeof value == 'number') return value;
    if (typeof value == 'boolean') return value ? 1 : 0;
    if (typeof value == 'string') {
      const rawNumValue = value.replace(/[^.\d]/g, '');
      let index = 0;
      const numValue = rawNumValue.replace(/\./g, (item) => (!index++ ? item : ''));
      return parseFloat(numValue);
    }
    return 0;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire-widget.component.html',
  styleUrls: ['./questionnaire-widget.component.scss'],
})
export class QuestionnaireWidgetComponent implements OnInit {
  optionKeyList: Array<any> = [];
  qnaForm: FormGroup;
  inProgress = false;
  selectedAnswer;
  questionObj;
  isBackEnabled;
  message: null;
  subType: null;
  status: null;
  @Input() set questionnaire(value: {
    id;
    question;
    options: Array<string>;
    subType;
    totalStage;
    activeStage;
    response?;
    message;
    status;
  }) {
    this.message = value && value.message ? value.message : null;
    this.status = value && value.status ? value.status : false;
    this.subType = value && value.subType ? value.subType : null;

    this.questionObj = value;
    this.optionKeyList = Object.keys(this.questionObj.options);
    this.inProgress = false;
    if (value.response) {
      let responseKey =
        value.options && value.response
          ? Object.keys(value.options).find((key) => value.options[key] === value.response)
          : '';
      this.selectedAnswer = { id: value.id, response: responseKey };
    } else {
      this.selectedAnswer = null;
    }
  }
  @Output() answerEvent = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.qnaForm = this.formBuilder.group({
      investDuration: ['', Validators.required],
    });
  }

  goToPreviousState() {
    let inputObj = { ...this.selectedAnswer, ...{ getPrevious: true } };
    this.answerEvent.emit(inputObj);
  }
  close() {
    let inputObj = { ...this.selectedAnswer, ...{ close: true } };
    this.answerEvent.emit(inputObj);
  }
  onAnswerSubmit(questionObj, option) {
    this.selectedAnswer = { id: questionObj.id, response: option };

    if (this.inProgress || !this.selectedAnswer) return;
    this.inProgress = true;
    this.answerEvent.emit(this.selectedAnswer);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PublicRecordApi {
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;
  private getPropertiesByGeoApi = 'getPropertiesByGeo';
  private getPropertyCountInBoundingBoxApi = 'getPropertyCountInBoundingBox';
  private getPropertyLocationsApi = 'getPropertyLocations';
  private getPropertiesInBoundingBoxWithFiltersApi = 'getPropertiesInBoundingBoxWithFilters';
  private getClustersInBoundingBoxWithFiltersApi = 'getClustersInBoundingBoxWithFilters';
  private getListingsInBoundingBoxWithFiltersApi = 'getListingsInBoundingBoxWithFilters';
  private getListingDataApi = 'getListingData';
  private getListingsByMlsNumberApi = 'getListingsByMlsNumber';

  constructor(private http: HttpClient) {}

  getListingData(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getListingDataApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next({});
          }
        );
    });
  }

  getPropertyCountInBoundingBox(params): Observable<any> {
    let inputParams = Object.assign(params, { PropertyType: 'Residential' });

    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getPropertyCountInBoundingBoxApi}`, inputParams)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }

  getPropertyLocations(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getPropertyLocationsApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }

  getClustersInBoundingBoxWithFilters(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getClustersInBoundingBoxWithFiltersApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next(err.error);
          }
        );
    });
  }

  getListingsInBoundingBoxWithFilters(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getListingsInBoundingBoxWithFiltersApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next(err.error);
          }
        );
    });
  }

  getPropertiesInBoundingBoxWithFilters(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getPropertiesInBoundingBoxWithFiltersApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.next(err.error);
          }
        );
    });
  }

  getPropertiesByGeo(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getPropertiesByGeoApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }

  getPropertiesByMlsNumber(mlsNumber): Observable<any> {
    return new Observable((observer) => {
      let url = `${this.prospektrBackendBaseURL}${this.getListingsByMlsNumberApi}` + '?mlsNumber=' + mlsNumber;
      this.http
        .get(url)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }
}

import { Injectable } from '@angular/core';
import {
  FieldWithLimit,
  FieldWithRefernce,
  FieldWithValidation,
  FilterFields,
  Mortgages,
  PropertyCharacteristicsFilterFields,
  SubTypeFilterFields,
} from '../../shared/interface/filter.interface';
@Injectable({
  providedIn: 'root',
})
export class FiltersConfig {
  defaultValidationPattern = '^([0-9]+([.][0-9]{1,2})?|([.]+[0-9]{1,2}))$';
  rangeFilterOne: Array<FieldWithLimit> = [
    {
      minVal: null,
      maxVal: null,
      key: 'custom',
      label: 'Custom Value',
      status: false,
    },
  ];

  rangeFilterTwo: Array<FieldWithLimit | FieldWithValidation> = [
    {
      minVal: null,
      maxVal: null,
      key: 'custom',
      label: 'Custom Value',
      status: false,
      pattern: '^[0-4]$',
      message: 'Accepts numbers in the range (0 - 4)',
    },
  ];

  rangeFilterThree: Array<FieldWithLimit | FieldWithValidation> = [
    {
      minVal: null,
      maxVal: null,
      key: 'custom',
      label: 'Custom Value',
      status: false,
      pattern: '^([0-9]*.)?[0-9]+$',
      message: 'Accepts decimal values only',
    },
  ];

  rangeFilterFour: Array<FieldWithLimit | FieldWithValidation> = [
    {
      minVal: null,
      maxVal: null,
      key: 'custom',
      label: 'Custom Value',
      status: false,
      pattern: '^(([1-9](?:[0-9]+){0,})?|[0])$',
      message: 'Accepts whole numbers <1000, 1000-1500, 1500-2000, 2000-2500, 2500-3000, 3000-4000, 4000+',
    },
  ];

  rangeFilterFive: Array<FieldWithLimit | FieldWithValidation> = [
    {
      minVal: null,
      maxVal: null,
      key: 'custom',
      label: 'Custom Value',
      status: false,
      pattern: '^([1-9][0-9]?$|100|[0])$',
      message: 'Accepts numbers in the range (0 - 100) <20%, 20-40%, 40-50%, 50-60%, 60-70%, 70-80%, 80-90%, 90%+',
    },
  ];

  rangeFilterFourSix: Array<FieldWithLimit | FieldWithValidation> = [
    {
      minVal: null,
      maxVal: null,
      key: 'custom',
      label: 'Custom Value',
      status: false,
      pattern: '^(100([.][0]{1,2})?|([0-9]|[1-9][0-9])([.][0-9]{1,2})?)$',
      message: 'Accepts Decimal Range (0 - 100)',
    },
  ];

  rangeFilterFourSeven: Array<FieldWithLimit | FieldWithValidation> = [
    {
      minVal: null,
      maxVal: null,
      key: 'custom',
      label: 'Custom Value',
      status: false,
      pattern: '^[1-9][0-9]*$',
      message: 'Accepts whole numbers, excluding zero',
    },
  ];

  rangeFilterEight: Array<FieldWithLimit | FieldWithValidation> = [
    {
      minVal: null,
      maxVal: null,
      key: 'custom',
      label: 'Custom Value',
      status: false,
      pattern: '^(([1-9](?:[0-9]+){0,})?|[0])$',
      message: 'Accepts whole numbers',
    },
  ];
  rangeFilterNine: Array<FieldWithRefernce> = [
    {
      label: 'Adjustable',
      key: 'Adjustable',
      keyReferance: ['Adjustable', 'Adjustable Rate', 'Step Interest Rate', 'Variable'],
      status: false,
    },
    {
      label: 'Fixed',
      key: 'Fixed',
      keyReferance: ['Fixed', 'Fixed Rate', 'Step Interest Rate', 'Variable'],
      status: false,
    },
    {
      label: 'Multiple',
      key: 'Multiple',
      keyReferance: ['Multiple', 'Multiple Loan Amounts'],
      status: false,
    },
    {
      label: 'Other',
      key: 'Other',
      status: false,
    },
  ];

  rangeFilterTen: Array<FieldWithLimit | FieldWithValidation> = [
    {
      minVal: null,
      maxVal: null,
      key: 'custom',
      label: 'Custom Value',
      status: false,
      pattern: '^((?:[0-9]|1[0-9]|2[0-3])(?:[.][0-9]{1,2})?|24(?:[.]00?)?)$',
      message: 'Accepts numbers in the range (0.00 - 24.00)',
    },
  ];

  propertySubType: Array<FilterFields> = [
    {
      label: 'Single Family Residence',
      key: 'single family residence',
      status: false,
    },
    {
      label: 'Condominium',
      key: 'condominium',
      status: false,
    },
    {
      label: 'Mobile Home',
      key: 'mobile home',
      status: false,
    },
    {
      label: 'Quadruplex',
      key: 'quadruplex',
      status: false,
    },
    {
      label: 'Townhouse',
      key: 'townhouse',
      status: false,
    },
    {
      label: 'Manufactured Home',
      key: 'manufactured home',
      status: false,
    },
    {
      label: 'Multi Family > 4',
      key: 'multi family > 4',
      status: false,
    },
    {
      label: 'MultiFamily',
      key: 'multi family',
      status: false,
    },
    {
      label: 'Manufactured on Land',
      key: 'manufactured on land',
      status: false,
    },
    {
      label: 'Apartment',
      key: 'apartment',
      status: false,
    },
  ];

  propertyType: Array<SubTypeFilterFields> = [
    {
      label: 'Property Type',
      key: 'residential',
      status: false,
      subType: JSON.parse(JSON.stringify(this.propertySubType)),
    },
  ];

  listingFilter = [
    {
      label: 'Off-Market in the last',
      key: 'DaysOffMarket',
      status: false,
      value: null,
      pattern: '^[1-9][0-9]*$',
      message: 'Accepts whole numbers, excluding zero',
    },
    {
      label: 'In the Market for more than',
      key: 'MinDaysOnMarket',
      status: false,
      value: null,
      pattern: '^[1-9][0-9]*$',
      message: 'Accepts whole numbers, excluding zero',
    },
    {
      label: 'Underpriced by',
      key: 'UnderpricePercentage',
      status: false,
      value: null,
      pattern: '^[1-9][0-9]*$',
      message: 'Accepts whole numbers, excluding zero',
    },
    {
      label: 'Flipped within',
      key: 'FlipDuration',
      status: false,
      value: null,
      pattern: '^[1-9][0-9]*$',
      message: 'Accepts whole numbers, excluding zero',
    },
    {
      label: 'Listed within the last',
      key: 'MaxDaysOnMarket',
      status: false,
      value: null,
      pattern: '^[1-9][0-9]*$',
      message: 'Accepts whole numbers, excluding zero',
    },
    {
      label: 'Sold Within',
      key: 'SoldWithin',
      status: false,
      value: null,
      pattern: '^[1-9][0-9]*$',
      message: 'Accepts whole numbers, excluding zero',
    },
    {
      label: 'List Price',
      key: 'ListPrice',
      status: false,
      subType: [
        {
          minVal: null,
          maxVal: null,
          key: 'custom',
          label: 'Custom Value',
          status: false,
          pattern: '',
          message: '',
        },
      ],
    },
  ];

  distressFilter = [
    {
      label: 'Foreclosure',
      key: 'DistressStatus',
      status: false,
      pattern: '',
      message: '',
      subType: [
        {
          key: 'Pre-Foreclosure',
          status: false,
          label: 'Pre Foreclosure',
        },
        {
          key: 'Auction',
          status: false,
          label: 'Auction',
        },
        {
          key: 'Bank Owned',
          status: false,
          label: 'REO / Bank Owned',
        },
        {
          key: 'HUD',
          status: false,
          label: 'HUD',
        },
      ],
    },
    {
      label: 'Distress Started Within last',
      key: 'DistressStartDateWithin',
      status: false,
      value: null,
      pattern: '',
      message: '',
    },
  ];

  propertyCharacteristics: Array<PropertyCharacteristicsFilterFields> = [
    {
      label: 'Bed',
      key: 'BedroomsTotal',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Bath',
      key: 'BathroomsTotalInteger',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Living Area (sqft.)',
      key: 'LivingArea',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Lot Size (sqft.)',
      key: 'LotSizeSquareFeet',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Parking Spaces',
      key: 'ParkingSpaces',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Stories',
      key: 'StoriesTotal',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Year Built',
      key: 'YearBuilt',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
  ];

  ownership: Array<PropertyCharacteristicsFilterFields> = [
    {
      label: 'Owned By',
      key: 'OwnerIsCorporation',
      status: false,
      subType: [
        {
          label: 'Individual',
          key: 'individual',
          status: false,
          value: 'n',
        },
        {
          label: 'Corporation',
          key: 'corporation',
          status: false,
          value: 'y',
        },
      ],
    },

    {
      label: 'Occupancy',
      key: 'OwnerOccupied',
      status: false,
      subType: [
        {
          label: 'Owner Occupied',
          key: 'OwnerOccupied',
          status: false,
          value: 'y',
        },
        {
          label: 'Not Owner Occupied',
          key: 'NotOwnerOccupied',
          status: false,
          value: 'n',
        },
      ],
    },
    {
      label: 'Ownership Duration',
      key: 'OwnershipDuration',
      status: false,
      subType: [
        {
          minVal: null,
          maxVal: null,
          key: 'custom',
          label: 'Custom Value',
          status: false,
          pattern: '^([1-9][0-9]?$|100|[0])$',
          message: 'Accepts number of years (0 - 100)',
        },
      ],
    },
    {
      label: 'Last Sale Date',
      key: 'LastSaleContractDate',
      status: false,
      subType: [
        {
          label: 'Custom Date',
          key: 'customDate',
          status: false,
          minVal: null,
          maxVal: null,
          pattern: '^(((0)?[0-9])|((1)[0-2]))(/)([0-2][0-9]|(3)[0-1])(/)(([1-9])[0-9]{3})$',
        },
      ],
    },
  ];

  taxAndAssessmentsFilter: Array<PropertyCharacteristicsFilterFields> = [
    {
      label: 'Annual Tax',
      key: 'TaxAnnualAmount',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Total Assessed Value',
      key: 'AssessedValue',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Assessed Land Value',
      key: 'AssessedLandValue',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Assessed Bldg. Value',
      key: 'AssessedImprovementValue',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
  ];

  value: Array<PropertyCharacteristicsFilterFields> = [
    {
      label: 'Last Sale Price',
      key: 'ClosePrice',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Estimated Value',
      key: 'SaleValue',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Rent Value',
      key: 'RentValue',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterOne)),
    },
    {
      label: 'Cap Rate',
      key: 'CapRate',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterFourSix)),
    },
  ];

  mortgage: Array<PropertyCharacteristicsFilterFields> = [
    {
      label: 'Number of Mortgages',
      key: 'MortgageCount',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterTwo)),
      type: 'range',
    },
    {
      label: 'Estimated Loan Balance',
      key: 'EstimatedLoanBalance',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterThree)),
      type: 'range',
    },
    {
      label: 'Total Est. Monthly Payment',
      key: 'TotEstMthlyMtgPymt',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterFour)),
      type: 'range',
    },
    {
      label: 'Current Est. LTV',
      key: 'CurrEstLTV',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterFive)),
      type: 'range',
    },
  ];

  insights: Array<PropertyCharacteristicsFilterFields> = [
    {
      label: 'Propensity to Sell',
      key: 'PropensityToSell',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterFourSix)),
    },
  ];

  mortgageType: Array<PropertyCharacteristicsFilterFields> = [
    {
      label: 'Term',
      key: 'MortgageTerm',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterFourSeven)),
      type: 'range',
    },
    {
      label: 'Principal Amount',
      key: 'MortgagePrincipal',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterEight)),
      type: 'range',
    },
    {
      label: 'Interest Rate',
      key: 'MortgageRate',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterTen)),
      type: 'range',
    },
    {
      label: 'Rate Type',
      key: 'MortgageRateType',
      status: false,
      subType: JSON.parse(JSON.stringify(this.rangeFilterNine)),
      type: 'enum',
    },
    {
      label: 'Age',
      key: 'MortgageRecordingDate',
      status: false,
      subType: [
        {
          label: 'Custom Date',
          key: 'customDate',
          status: false,
          minVal: null,
          maxVal: null,
          pattern: '^(((0)?[0-9])|((1)[0-2]))(/)([0-2][0-9]|(3)[0-1])(/)(([1-9])[0-9]{3})$',
        },
      ],
      type: 'range',
    },
  ];

  get propertyTypeFilter() {
    return JSON.parse(JSON.stringify(this.propertyType));
  }

  get propertyCharacteristicsFilters() {
    return JSON.parse(JSON.stringify(this.propertyCharacteristics));
  }

  get ownershipFilter() {
    return JSON.parse(JSON.stringify(this.ownership));
  }

  get taxAndAssessments() {
    return JSON.parse(JSON.stringify(this.taxAndAssessmentsFilter));
  }

  get valueFilter() {
    return JSON.parse(JSON.stringify(this.value));
  }

  get mortgageFilter() {
    return JSON.parse(JSON.stringify(this.mortgageFilterSchema()));
  }

  get insightFilter() {
    return JSON.parse(JSON.stringify(this.insights));
  }

  mortgageFilterSchema(): Array<Mortgages> {
    const typesToBeInclude = [
      {
        items: {
          MortgageTerm: 'FirstMortgageTerm',
          MortgagePrincipal: 'FirstMortgagePrincipal',
          MortgageRate: 'FirstMortgageRate',
          MortgageRateType: 'FirstMortgageRateType',
          MortgageRecordingDate: 'FirstMortgageRecordingDate',
        },
        label: 'First Mortgage',
        key: 'FirstMortgage',
      },
      {
        items: {
          MortgageTerm: 'SecondMortgageTerm',
          MortgagePrincipal: 'SecondMortgagePrincipal',
          MortgageRate: 'SecondMortgageRate',
          MortgageRateType: 'SecondMortgageRateType',
          MortgageRecordingDate: 'SecondMortgageRecordingDate',
        },
        label: 'Second Mortgage',
        key: 'SecondMortgage',
      },
    ];
    let schema: Array<Mortgages> = [];
    typesToBeInclude.filter((type, index) => {
      schema.push({
        key: type.key,
        label: type.label,
        status: false,
        mortgageType: [],
      });
      JSON.parse(JSON.stringify(this.mortgageType)).filter((el) => {
        for (const [referredKey, value] of Object.entries(type.items)) {
          if (referredKey === el.key) {
            schema[index].mortgageType.push({ ...el, key: value });
          }
        }
      });
    });
    return (schema = [...schema, ...JSON.parse(JSON.stringify(this.mortgage))]);
  }

  get filterSchema() {
    return {
      currentPropertytypeFilter: JSON.parse(JSON.stringify(this.propertyTypeFilter)),
      currentPropertyCharacteristicsFilter: JSON.parse(JSON.stringify(this.propertyCharacteristicsFilters)),
      currentOwnershipFilter: JSON.parse(JSON.stringify(this.ownershipFilter)),
      currentTaxAndAssessmentsFilter: JSON.parse(JSON.stringify(this.taxAndAssessments)),
      currentValueFilter: JSON.parse(JSON.stringify(this.valueFilter)),
      currentMortgageFilter: JSON.parse(JSON.stringify(this.mortgageFilter)),
      currentInsightFilter: JSON.parse(JSON.stringify(this.insightFilter)),
      currentListingFilter: JSON.parse(JSON.stringify(this.listingFilter)),
      currentDistressFilter: JSON.parse(JSON.stringify(this.distressFilter)),
    };
  }

  listingStatus = ['active', 'pending', 'active under contract'];

  offMarketStatus = ['active', 'pending', 'active under contract', 'sold'];

  distressCondition = {
    fclStatus: ['pre-foreclosure', 'auction', 'bank owned'],
    specialListingStatus: ['notice of default', 'lis pendens', 'short sale', 'auction', 'real estate owned'],
  };

  hudCondition = {
    specialListingStatus: [
      'hud owned',
      'usda owned',
      'government owned',
      'va owned',
      'freddie mac owned',
      'fannie mae owned',
    ],
  };
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';

@Component({
  selector: 'app-custom-email',
  templateUrl: './custom-email.component.html',
  styleUrls: ['./custom-email.component.scss']
})
export class CustomEmailComponent implements OnInit {
  @ViewChild('editor') editor: QuillEditorComponent;
  htmlText = '<p>Testing</p>';
  hasFocus = false;
  subject: string;
  quill:Quill;

  atValues = [
    { id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com' },
    { id: 2, value: 'Patrik Sjölin' },
  ];
  hashValues = [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' },
  ];
  quillConfig = {
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['italic', 'underline', 'strike'], // toggled buttons
        ['code-block'],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction

        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        //[{ 'font': [] }],
        //[{ 'align': [] }],

        ['clean'], // remove formatting button

        ['link'],
        ['csbtn'],
        ['btnwithname'],
        ['customImage'] ,
        ['image', 'video'],
        ['add']
      ],
      handlers: {
        csbtn: () => {
          this.addValue();
     
        },
        btnwithname: () => {
          this.addButton();
        },
        customImage: () => {
          this.addImage();
     
        },
      },
    },

    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@', '#'],
      source: (searchTerm, renderList, mentionChar) => {
        let values;

        if (mentionChar === '@') {
          values = this.atValues;
        } else {
          values = this.hashValues;
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (var i = 0; i < values.length; i++)
            if (
              ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
            )
              matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
    },
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range, context) => {
            console.log('enter');
            return true;
          },
        },
      },
    },
    
  };

  constructor(private elem: ElementRef) {}
  ngOnInit(): void {
    let icons = Quill.import('ui/icons');
    icons['csbtn'] = "Privacy Policy Button";
    icons['btnwithname'] = "[Cstbt]";
    icons['customImage'] = "[Image]";
  }

  onSelectionChanged = (event) => {
    if (event.oldRange == null) {
      this.onFocus();
    }
    if (event.range == null) {
      this.onBlur();
    }
  };

  onContentChanged = (event) => {
    console.log(event.html,'html');
    this.convertToVelocityTemplate(event.html);
  };

  convertToVelocityTemplate(html){
    const imageSrcPattern = /<img\s+[^>]*src="{{([^"]+?)}}"/gi;
    const altPattern = /<img\s+[^>]*alt="{{([^"]+?)}}"/gi;
    const velocityTemplate = html
        .replace(imageSrcPattern, (match, p1) => {
            return match.replace(/src="{{[^"]+}}/, `src="$${p1}"`);
        })
        .replace(altPattern, (match, p1) => {
            return match.replace(/alt="{{[^"]+}}/, `alt="$${p1}"`);
        })
        .replace(/{{([^}]+)}}/g, '$$$1');
      console.log(velocityTemplate,'velocityTemplate')
    return velocityTemplate;

  }

 
addValue() {
  const url = 'https://icloud.com'; 
  const buttonText = 'Policy';
  const range = this.editor.quillEditor.getSelection();

  if (range) {
    const linkHTML = `<a href="${url}" style="display: inline-block; padding: 10px; color: #007bff; text-decoration: none; border-radius: 5px;">${buttonText}</a>`;

    this.editor.quillEditor.clipboard.dangerouslyPasteHTML(range.index, linkHTML);
    this.editor.quillEditor.setSelection(range.index + linkHTML.length); 
  } else {
    console.error("Unable to get selection. Ensure Quill is initialized.");
  }
}

addButton() {
  const buttonName = prompt('Enter button name:');
    const buttonUrl = prompt('Enter button URL:');

  if (buttonName && buttonUrl) {
    const buttonHTML = `<a href="${buttonUrl}" class="custom-button" target="_blank">${buttonName}</a>`;
    const range = this.editor.quillEditor.getSelection();
    this.editor.quillEditor.clipboard.dangerouslyPasteHTML(range.index, buttonHTML);
    this.editor.quillEditor.setSelection(range.index + 1);  
}
}

addImage() {
  const imageSrc=''
  const imageName=''
  const imageHTML = `<div class="image-placeholder"><img src="{{imageSrc}}" style="width:100%; height:100%;"/></div><p>${imageName}</p>`;
  const range = this.editor.quillEditor.getSelection();
  this.editor.quillEditor.clipboard.dangerouslyPasteHTML(range.index, imageHTML); 
  this.editor.quillEditor.setSelection(range.index + 1); 
}


  onFocus = () => {
    console.log('On Focus');
  };
  onBlur = () => {
    console.log('Blurred');
  };

}

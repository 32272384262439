import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AuthorizeService } from './authorize.service';

@Directive({
  selector: '[pmxAuthorize]',
})
export class AuthorizeDirective implements OnInit {
  constructor(private authorizeService: AuthorizeService, private elementRef: ElementRef) {}

  @Input('pmxAuthorize') feature: string;
  // @HostBinding('disabled') isDisabled = null;
  // @HostBinding('title') tooltipMsg = null;

  // @HostListener('mouseenter') onMouseEnter() {
  //   console.log('Over the element : ');
  // }

  // @HostListener('mouseleave') onMouseLeave() {
  //   console.log('Left the element : ');
  // }

  ngOnInit() {
    if (!this.authorizeService.canView(this.feature)) {
      // this.viewContainerRef.clear();
      this.elementRef.nativeElement.remove();
    }
    // this.viewContainerRef.createEmbeddedView(this.templateRef);
    // } else if (!this.authorizeService.canAct(this.feature)) {
    //   this.elementRef.nativeElement.disabled = true;
    //   this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', true);
    //   this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', 'true');
    //   this.renderer.setAttribute(this.elementRef.nativeElement, 'title', 'This feature is disabled. Try upgrading.');
    // this.isDisabled = true;
    // this.tooltipMsg = 'This feature is disabled. Try upgrading.';
    // }
  }
}

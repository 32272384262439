import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { ReportsState } from 'src/app/reports/reports.state';
import { StripeService } from 'src/app/shared/service/stripe.service';
import { StripeApi } from '../../api/stripe.api';

@Component({
  selector: 'app-stripe-form',
  templateUrl: './stripe-form.component.html',
  styleUrls: ['./stripe-form.component.scss'],
})
export class StripeFormComponent implements AfterViewInit {
  @ViewChild('cardElement') cardElement: ElementRef;
  @ViewChild('paymentForm') paymentForm;
  @Output() buttonClicked = new EventEmitter<boolean>();
  @Input() triggeredFrom;
  onDestroyNotifier$ = new Subject();
  private card;
  userProfile: any;
  savedCards: any[] = [];
  hasSavedCards: boolean = false;
  isLoading = false;
  isDeletingCard: boolean = false;

  constructor(
    private stripeService: StripeService,
    private stripeApi: StripeApi,
    private cdRef: ChangeDetectorRef,
    private reportsState: ReportsState
  ) {}

  async ngAfterViewInit() {
    const stripe = await this.stripeService.getStripe();
    const elements = stripe.elements();

    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);
    if (this.triggeredFrom != 'signUp') {
      this.fetchSavedCards();
    }
  }

  async onSubmit() {
    this.isLoading = true;
    const stripe = await this.stripeService.getStripe();
    const result = await stripe.createToken(this.card);
    if (result && result.token && result.token.id) {
      this.sendTokenToServer(result.token.id);
    } else {
      this.isLoading = false;
    }
  }

  async openStripeForm() {
    const stripe = await this.stripeService.getStripe();
    stripe
      .createPaymentMethod({
        type: 'card',
        card: this.card,
      })
      .then((result) => {
        if (result.paymentMethod) {
          this.paymentForm.nativeElement.submit();
        }
      });
  }
  private sendTokenToServer(token: string) {
    let request = {
      token: token,
    };
    this.stripeApi.saveCardToStripe(request).subscribe(
      (response) => {
        this.isLoading = false;
        this.reportsState.isPaymentCardAdded = true;
        this.cdRef.detectChanges();
        if (this.triggeredFrom == 'signUp') {
          this.buttonClicked.emit(true);
        } else {
          this.fetchSavedCards();
        }
      },
      (error) => {
        this.isLoading = false;
        this.cdRef.detectChanges();
        console.error(error);
      }
    );
  }
  private fetchSavedCards() {
    this.stripeApi.getSavedCards().subscribe(
      (response) => {
        if (
          response &&
          response.status == 'OK' &&
          response.statusCode == 200 &&
          response.data &&
          response.data.length > 0
        ) {
          this.savedCards = response.data;
          this.hasSavedCards = this.savedCards.length > 0;
          this.reportsState.isPaymentCardAdded = true;
        } else {
          if (this.reportsState.isPaymentCardAdded) {
            this.reportsState.isPaymentCardAdded = false;
          }
          this.savedCards = [];
          this.hasSavedCards = false;
          this.card.clear();
        }
      },
      (error) => {
        if (this.reportsState.isPaymentCardAdded) {
          this.reportsState.isPaymentCardAdded = false;
        }
        console.error('Error fetching saved cards:', error);
      }
    );
  }
  async deleteCard(cardId) {
    this.isDeletingCard = true;
    this.stripeApi.deleteACard(cardId).subscribe(
      (data) => {
        this.isDeletingCard = false;
        this.fetchSavedCards();
      },
      (error) => {
        this.isDeletingCard = false;
        console.error('Error deleting card:', error);
      }
    );
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthorizeDirective } from './access-control/authorize.directive';

@NgModule({
  declarations: [AuthorizeDirective],
  imports: [CommonModule, MatSnackBarModule],
  exports: [AuthorizeDirective],
})
export class CoreModule {}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProspektrApi } from 'src/app/_shared/api/prospektr.api';

@Component({
  selector: 'app-onboard-request',
  templateUrl: './onboard-request.component.html',
  styleUrls: ['./onboard-request.component.scss'],
})
export class OnboardRequestComponent implements OnInit {
  onboardRequestLoading: boolean = true;
  onDestroyNotifier$ = new Subject();

  constructor(private activatedRoute: ActivatedRoute, private prospektrApi: ProspektrApi, public router: Router) {}

  ngOnInit() {
    if (
      this.activatedRoute.snapshot.paramMap.get('identifier') &&
      this.activatedRoute.snapshot.paramMap.get('identifier') !== ''
    ) {
      let params = {
        identifier: this.activatedRoute.snapshot.paramMap.get('identifier'),
      };

      this.prospektrApi
        .updateOnboardRequest(params)
        .pipe(takeUntil(this.onDestroyNotifier$))
        .subscribe(
          (res) => {
            if (res) {
              this.router.navigate(['info/requestStatus'], { state: { data: { res } } });
            }
          },
          (err) => {
            let error = err.error;
            this.router.navigate(['info/requestStatus'], { state: { data: { error } } });
          }
        );
    }
  }

  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}

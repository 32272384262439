import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NavigationModule } from '../navigation/navigation.module';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { SubscriptionPlanRoutingModule } from './subscription-plan-routing.module';

@NgModule({
  declarations: [PaymentFailedComponent, PaymentSuccessComponent],
  imports: [CommonModule, SubscriptionPlanRoutingModule, NavigationModule, MatButtonModule, MatProgressSpinnerModule],
})
export class SubscriptionPlanModule {}

import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientDashboardService } from 'src/app/client-dashboard/service/client-dashboard.service';
import { ClientDashboardState } from 'src/app/client-dashboard/state/client-dashboard.state';
import { CampaignService } from '../../service/campaign.service';
import { CampaignState } from '../../state/campaign.state';
import { UserState } from '../../state/user.state';

export interface ErrorObj {
  type: string;
  message: string;
  status: boolean;
}

@Component({
  selector: 'app-campaign-modal',
  templateUrl: './campaign-modal.component.html',
  styleUrls: ['./campaign-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CampaignModalComponent implements OnInit, OnDestroy {
  activeTabIndex: number = 0;
  campaignList = [];
  isLoadingPartnerList = false;
  partnerList = [];
  partnerType = 'agent';
  newCampaignForm: FormGroup;
  existingCampaignForm: FormGroup;
  inProgressCreateCampaign = false;
  onDestroyNotifier$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<CampaignModalComponent>,
    private campaignService: CampaignService,
    private clientService: ClientDashboardService,
    private clientState: ClientDashboardState,
    public campaignState: CampaignState,
    private userState: UserState,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.campaignState.isLoadingAddCampaignUserValue = null;
    this.campaignService.fetchCampaigns();
    this.clientService
      .fetchActivePartners()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(({ isLoading }) => {
        this.isLoadingPartnerList = isLoading;
      });
    this.campaignState.campaignList$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((campaignList) => {
      if (campaignList && campaignList.data && campaignList.data.length) {
        this.campaignList = campaignList.data.filter((campaign) => {
          return campaign.status == 'idle';
        });
      }
    });
    this.clientState.activePartnerList$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.partnerList = res;
    });
    this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res?.roles?.includes('agent')) {
        this.partnerType = 'lender';
      } else if (res?.roles?.includes('loanOfficer')) {
        this.partnerType = 'agent';
      }
    });
    this.newCampaignForm = this.formBuilder.group({
      campaignName: ['', [Validators.required, Validators.pattern('[A-Za-z0-9 _-]+')]],
      partnerId: ['', Validators.required],
    });
    this.existingCampaignForm = this.formBuilder.group({
      campaign: ['', Validators.required],
    });
  }

  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.activeTabIndex = tabChangeEvent.index;
    if (tabChangeEvent.index === 0) {
      this.existingCampaignForm.reset();
    } else {
      this.newCampaignForm.reset();
    }
  }

  proceedSelectedTabAction() {
    if (this.activeTabIndex === 0) {
      this.newCampaignForm.get('campaignName').markAsTouched();
      this.newCampaignForm.get('partnerId').markAsTouched();
      if (this.newCampaignForm.valid) {
        this.campaignState.isLoadingAddCampaignUserValue = true;
        this.campaignService
          .createCampaign({
            campaignName: this.newCampaignForm.get('campaignName').value.trim(),
            [`${this.partnerType}Id`]: this.newCampaignForm.get('partnerId').value,
            contactList: this.data.contactIdList,
          })
          .pipe(takeUntil(this.onDestroyNotifier$))
          .subscribe(({ isLoading }: any) => {
            this.inProgressCreateCampaign = isLoading;
            if (isLoading == false) {
              this.dialogRef.close();
            }
          });
      }
    }
    if (this.activeTabIndex === 1) {
      this.existingCampaignForm.get('campaign').markAsTouched();
      if (this.existingCampaignForm.valid) {
        this.campaignState.isLoadingAddCampaignUserValue = true;
        let inputParams = {
          contactList: this.data.contactIdList,
          campaignId: this.existingCampaignForm.get('campaign').value,
        };
        this.campaignService
          .createCampaignUsers(inputParams)
          .pipe(takeUntil(this.onDestroyNotifier$))
          .subscribe(({ isLoading }) => {
            this.inProgressCreateCampaign = isLoading;
            if (isLoading == false) {
              this.dialogRef.close();
            }
          });
      }
    }
  }

  proceedCancelAction() {
    this.newCampaignForm.reset();
    this.existingCampaignForm.reset();
    this.dialogRef.close();
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SavedSearchDialogComponent } from 'src/app/_shared/component/saved-search-dialog/saved-search-dialog.component';

export interface filteredData {
  value: any;
  name: string;
  placeDetails: { fullAddress: string };
  icon: string;
  createdDate: any;
  createdUserId: string;
  userId: string;
  isOwned: any;
  createdUserInfo: string;
  filterList: Array<any>;
}
@Component({
  selector: 'app-saved-search-card',
  templateUrl: './saved-search-card.component.html',
  styleUrls: ['./saved-search-card.component.scss'],
})
export class SavedSearchCardComponent implements OnInit {
  @Input() item: filteredData;
  @Input() canDelete: boolean = true;
  @Input() editNeeded: boolean = false;

  @Output('deleteSavedData') deleteSavedData: EventEmitter<any> = new EventEmitter();
  @Output('editSearch') editSearch: EventEmitter<any> = new EventEmitter();

  @Output('deleteSavedDataForClients') deleteSavedDataForClients: EventEmitter<any> = new EventEmitter();

  @Output('loadSearch') loadSearch: EventEmitter<any> = new EventEmitter();

  filterList: Array<any>;
  hasMoreFilters: boolean = false;
  filters: any = [];
  showAppNav = true;
  ownedByInvestor: boolean = true;
  onDestroyNotifier$ = new Subject();

  constructor(private router: Router, private dialog: MatDialog) {}

  ngOnInit() {
    if (Object.keys(this.item.filterList).length > 3) {
      this.hasMoreFilters = true;
    }

    if (this.router.url === '/dashboard') {
      this.showAppNav = false;
    }
  }
  deleteItem(item) {
    this.deleteSavedData.emit();
  }

  deleteItemForClients(item) {
    this.deleteSavedDataForClients.emit();
  }
  applyFilters(item) {
    this.loadSearch.emit();
  }
  editSavedSearch(item) {
    this.editSearch.emit(item);
  }

  showAllFilters(filterInfo, filters) {
    const _this = this;
    let content: string;
    let title: string;

    this.dialog.open(SavedSearchDialogComponent, {
      width: '35%',
      disableClose: true,
      data: {
        title: `${filters.name}`,
        content: filterInfo,
        address: filters.placeDetails.fullAddress,
        showPrimaryButton: true,
        proceedAction() {
          _this.applyFilters(filters);
        },
      },
    });
  }
}

<div class="prpty_visit">
  <button class="close" mat-icon-button (click)="close()" autofocus="false">
    <mat-icon>close</mat-icon>
  </button>
  <h4 class="font-12">Make an offer for the property</h4>
  <p class="text-secondary">Your offer will be sent to the agent for processing</p>
  <div class="wrap_body mt-4">
    <form [formGroup]="offerRequestForm">
      <ng-container>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input matInput formControlName="firstName" autocomplete="off" />
              <mat-error
                *ngIf="offerRequestFormControls.firstName.errors && offerRequestFormControls.firstName.errors.required"
                class="invalid-feedback"
              >
                First Name is required</mat-error
              >
              <mat-error
                *ngIf="offerRequestFormControls.firstName.errors && offerRequestFormControls.firstName.errors.pattern"
                class="invalid-feedback"
              >
                Enter valid names</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="lastName" autocomplete="off" />
              <mat-error
                *ngIf="offerRequestFormControls.lastName.errors && offerRequestFormControls.lastName.errors.required"
                class="invalid-feedback"
              >
                Last Name is required</mat-error
              >
              <mat-error
                *ngIf="offerRequestFormControls.lastName.errors && offerRequestFormControls.lastName.errors.pattern"
                class="invalid-feedback"
              >
                Enter valid names</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" autocomplete="off" />
              <mat-error
                *ngIf="offerRequestFormControls.email.errors && offerRequestFormControls.email.errors.required"
                class="invalid-feedback"
              >
                Email is required</mat-error
              >
              <mat-error
                *ngIf="offerRequestFormControls.email.errors && offerRequestFormControls.email.errors.pattern"
                class="invalid-feedback"
              >
                Invalid email format</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <mat-label>Phone</mat-label>
              <input matInput formControlName="phone" autocomplete="off" />
              <mat-error
                *ngIf="offerRequestFormControls.phone.errors && offerRequestFormControls.phone.errors.required"
                class="invalid-feedback"
              >
                Contact number is required</mat-error
              >
              <mat-error
                *ngIf="offerRequestFormControls.phone.errors && offerRequestFormControls.phone.errors.pattern"
                class="invalid-feedback"
              >
                Enter valid contact number</mat-error
              >
            </mat-form-field>
          </div>
        </div>
      </ng-container>
      <div class="row mt-2">
        <div class="col-8">
          <mat-form-field>
            <mat-label>Offer (How much would you like to offer?)</mat-label>
            <span matPrefix>$ &nbsp;</span>
            <input matInput formControlName="offer" autocomplete="off" />
            <mat-error
              *ngIf="offerRequestFormControls.phone.errors && offerRequestFormControls.phone.errors.required"
              class="invalid-feedback"
            >
              Offer amount is required</mat-error
            >
            <mat-error
              *ngIf="offerRequestFormControls.phone.errors && offerRequestFormControls.phone.errors.pattern"
              class="invalid-feedback"
            >
              Enter valid amount</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-7 col-12 mb-2 mb-sm-0">
          How do you plan to buy?
          <mat-error
            *ngIf="
              offerRequestFormControls.planToBuy.errors &&
              offerRequestFormControls.planToBuy.errors.required &&
              !initial
            "
            class="invalid-feedback"
          >
            Choose any plan to buy option</mat-error
          >
        </div>
        <div class="m-radio">
          <mat-radio-group formControlName="planToBuy">
            <mat-radio-button name="buy" value="Loan">Loan</mat-radio-button>
            <mat-radio-button class="ml-3" name="buy" value="Cash">All cash</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-7 col-12 mb-2 mb-sm-0">
          Have you seen the home in person?
          <mat-error
            *ngIf="
              offerRequestFormControls.isScheduledPropertyVisit.errors &&
              offerRequestFormControls.isScheduledPropertyVisit.errors.required &&
              !initial
            "
            class="invalid-feedback"
          >
            Choose any option</mat-error
          >
        </div>
        <div class="m-radio">
          <mat-radio-group formControlName="isScheduledPropertyVisit">
            <mat-radio-button name="visit" value="Yes">Yes</mat-radio-button>
            <mat-radio-button class="ml-3" name="visit" value="No">No</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <mat-form-field>
            <mat-label>Notes(optional)</mat-label>
            <input matInput formControlName="notes" autocomplete="off" />
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-2">
        <div *ngIf="checkBox" class="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="enableSubmit"
              (change)="enableSubmit = !enableSubmit"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" style="font-style: italic" for="flexCheckDefault">
              I agree to the below terms.
            </label>
          </div>
        </div>
        <div class="col text-right">
          <button
            [disabled]="!enableSubmit"
            mat-flat-button
            color="primary"
            (click)="requestOffer()"
            [pmx-progress-button]="makeOfferInProgress"
          >
            Start the Offer</button
          ><br />
        </div>
      </div>
      <div #ctaPolicy class="policyDetails" [termsPolicy]></div>
    </form>
  </div>
</div>

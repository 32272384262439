import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { AgentContactApi } from '../api/agent-contact.api';
@Injectable({
  providedIn: 'root',
})
export class SharePropertyService {
  constructor(private snackBar: MatSnackBar, private agentContactApi: AgentContactApi) {}

  sharePropertyDetails(params) {
    return new Observable((observer) => {
      this.agentContactApi.sharePropertyDetails(params).subscribe(
        (res) => {
          if (res.status && res.status == 'success' && res.message) {
            observer.next(res.message);
          }
        },
        (err) => {
          this.openSnackBar(err.error.message || 'Failed to schedule property visit', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

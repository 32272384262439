import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireApi {
  private getQuestionnaireApi = 'questionnaire';
  private questionResponseApi = 'questionnaire/response';
  private questions = 'questions';
  private updateResponse = 'updateQuestionnaireResponse'

  constructor(private http: HttpClient) { }

  getQuestionnaire(): Observable<any> {
    return this.http.get(`${environment.prospektrBackendBaseURL}${this.getQuestionnaireApi}`);
  }

  questionsResponse(params) {
    return this.http.post(`${environment.prospektrBackendBaseURL}${this.questionResponseApi}`, params);
  }

  getQuestions(): Observable<any> {
    return this.http.get(`${environment.prospektrBackendBaseURL}${this.questions}`);
  }
  updateQuestionireResponse(params): Observable<any> {
    return this.http.patch(`${environment.prospektrBackendBaseURL}${this.updateResponse}`, params);
  }
}

import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CurrencyFormatter',
})
export class CurrencyFormatterPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(price: any): any {
    if (price && price !== '--') {
      const numberToBeFormat = price;
      return Number.isNaN(parseFloat(numberToBeFormat))
        ? numberToBeFormat
        : this.currencyPipe.transform(numberToBeFormat, 'USD', 'symbol', '0.0-0');
    } else {
      return price;
    }
  }
}

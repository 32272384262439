<div class="d-inline-block ml-md-4 ml-0 linkshare">
  <input type="text" #inviteUrlField [value]="content" readonly />
  <button
    mat-stroked-button
    class="ml-1"
    type="button"
    color="primary"
    (click)="copyTextToClipboard(content)"
    matTooltip="Copy link"
    matTooltipPosition="above"
  >
    <mat-icon inline>content_copy</mat-icon> {{ copyBtnText }}
  </button>
  <button
    *ngIf="showPreviewBtn"
    mat-stroked-button
    class="ml-1"
    type="button"
    color="primary"
    (click)="openInNewTab(content)"
    matTooltip="Open link in new tab"
    matTooltipPosition="above"
  >
    <mat-icon inline>open_in_new</mat-icon> Preview
  </button>
</div>

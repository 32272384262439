import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentCalculatorState {
  private homePriceSub$ = new BehaviorSubject<any>(0);
  homePrice$ = this.homePriceSub$.asObservable();

  private propertyTaxSub$ = new BehaviorSubject<any>(0);
  propertyTax$ = this.propertyTaxSub$.asObservable();

  private landValueSub$ = new BehaviorSubject<any>(0);
  landValue$ = this.landValueSub$.asObservable();

  private hoaFeesSub$ = new BehaviorSubject<Array<any>>([]);
  hoaFees$ = this.hoaFeesSub$.asObservable();

  private stateSub$ = new BehaviorSubject<any>([]);
  state$ = this.stateSub$.asObservable();

  public get hoaFees(): Array<any> {
    return this.hoaFeesSub$.value;
  }

  public set hoaFees(array: Array<any>) {
    this.hoaFeesSub$.next(array);
  }

  public get homePrice() {
    return this.homePriceSub$.value;
  }

  public set homePrice(homePrice) {
    this.homePriceSub$.next(homePrice);
  }

  public get propertyTax() {
    return this.propertyTaxSub$.value;
  }

  public set propertyTax(propertyTaxRate) {
    this.propertyTaxSub$.next(propertyTaxRate);
  }

  public get landValue() {
    return this.landValueSub$.value;
  }

  public set landValue(landPrice) {
    this.landValueSub$.next(landPrice);
  }

  public get state() {
    return this.stateSub$.value;
  }

  public set state(stateOfProperty) {
    this.stateSub$.next(stateOfProperty);
  }
}

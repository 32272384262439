<mat-sidenav-container>
  <mat-sidenav #sidenav position="end" fixedInViewport="true" opened="false">
    <app-progress-indicator *ngIf="isArticleLoadingInProgress"></app-progress-indicator>
    <div class="sliding-sheet-header">
      <h4>{{ articleTitle }}</h4>
      <button type="button" (click)="sidenav.close()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="sliding-sheet-body" #articleContainer></div>
    <div class="not-found" *ngIf="!isArticleLoadingInProgress && !articleContent">
      <img class="img-fluid" width="60" src="~/../assets/images/notFound.svg" />
      <h4>Article not found!</h4>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

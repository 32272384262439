<div class="body_wrap dashboard_body">
  <div class="sticky-top sticky-offset">
    <div class="top_menu">
      <button mat-button class="d-md-none d-none"><img src="../../assets/images/menu.svg" /></button>
      <app-nav></app-nav>
    </div>
  </div>
  <div class="dash_base">
    <div class="container-fluid mt-40">
      <div class="container">
        <div class="paymentWrap">
          <div class="w-50 mw-100 m-auto whiteWrap">
            <div class="text-center badgeWrap">
              <div class="w-100 text-center">
                <div id="print-section">
                  <svg
                    version="1.1"
                    id="Capa_1"
                    width="75"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 29.756 29.756"
                    style="enable-background: new 0 0 29.756 29.756"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        style="fill: #86f0c7"
                        d="M29.049,5.009L28.19,4.151c-0.943-0.945-2.488-0.945-3.434,0L10.172,18.737l-5.175-5.173
                 c-0.943-0.944-2.489-0.944-3.432,0.001l-0.858,0.857c-0.943,0.944-0.943,2.489,0,3.433l7.744,7.752
                 c0.944,0.943,2.489,0.943,3.433,0L29.049,8.442C29.991,7.498,29.991,5.953,29.049,5.009z"
                      />
                    </g>
                  </svg>
                  <h4 class="mt-2">Thank you for upgrading your account.</h4>
                  <p class="text-secondary mt-2">
                    Your payment has been processed successfully<br />
                    and your account is now upgraded.
                  </p>
                  <div class="w-100 text-center" *ngIf="loader">
                    <mat-spinner [diameter]="30" class="mr-3"></mat-spinner>
                  </div>
                  <div class="well mb-2" *ngIf="!loader">
                    <div class="row mb-2">
                      <div class="col-6 text-secondary text-right"><label>Receipt #:</label></div>
                      <div class="col-6 text-left">
                        <b>{{ transactionDetails.invoiceId }}</b>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6 text-secondary text-right"><label> Amount:</label></div>
                      <div class="col-6 text-left">
                        <b>{{ transactionDetails.amount / 100 | currency: 'USD' }}</b>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-secondary text-right"><label>Payment Date:</label></div>
                      <div class="col-6 text-left">
                        <b> {{ transactionDetails.purchaseCompleteDate | date }} </b>
                      </div>
                    </div>
                  </div>
                </div>
                <p>Please print this page for your records.</p>
                <button color="primary" mat-stroked-button class="ml-2" (click)="print()">Print</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 text-center mt-4 mb-4">
      <button color="primary" mat-flat-button class="ml-2" (click)="goToDashboard()">Go to Dashboard</button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FavoriteService } from '../../../_shared/service/favorite.service';
import { FavoriteState } from '../../../_shared/state/favorite.state';

@Injectable({
  providedIn: 'root',
})
export class FavoriteFacade {
  onDestroyNotifier$ = new Subject();
  constructor(
    private favoriteService: FavoriteService,
    private favoriteState: FavoriteState,
    private snackBar: MatSnackBar
  ) {}
  $modalStatus = new BehaviorSubject({
    status: 'intial',
    errorInfo: {
      message: '',
      status: false,
      type: '',
    },
  });
  modalStatus = this.$modalStatus.asObservable();
  errorMessage = {
    common: 'Some Error occurred,Please try later',
  };

  resetFollowGroupList(followGroupList) {
    followGroupList.map((group) => {
      group.checked = false;
    });
    this.favoriteState.followGroupListValue = followGroupList;
  }

  favoriteByAction(data, groupList) {
    const action = data.action;
    const from = data.from;
    if (action === 'ADD' && from === 'Workspace') {
      this.addFavorite(data, groupList);
    } else if (action === 'ADD' && from === 'Recommendation') {
      this.createFavoritesByRecommendation(data, groupList);
    } else if (action === 'ADD' && from === 'Uploads') {
      this.addFavorite(data, groupList);
      // } else if (action === 'COPY') {
      //   this.copyFavorite(data, groupList);
      // } else if (action === 'MOVE') {
      //   this.moveFavorite(data, groupList);
    }
  }

  createGroupAndAddFavorite(groupName, data) {
    this.favoriteService
      .createGroup(groupName.trim())
      .pipe(takeUntil(this.onDestroyNotifier$))
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((response: any) => {
        if (response && response.groupResult && response.groupResult.id) {
          const groupList = [];
          groupList.push(response.groupResult.id);
          this.favoriteByAction(data, groupList);
        } else {
          if (response.Message) {
            const errorInfo = {
              status: true,
              type: 'new',
              message: response.Message,
            };
            this.changeModalStatus('loaderOff', errorInfo);
          } else {
            this.changeModalStatus('close');
            //   this.openSnackBar(this.errorMessage.common, 'snackbar-error');
          }
        }
      });
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }

  changeModalStatus(status, errorInfo?) {
    this.$modalStatus.next({
      status: status,
      errorInfo: errorInfo,
    });
  }

  createFavoritesByRecommendation(data, groupList) {
    const inputParams = {
      groupId: groupList,
      recommendationIdList: data.recommendedIdList,
    };
    this.favoriteService
      .createFavoritesByRecommendation(inputParams)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((response: any) => {
        if (response && response.status === 'success' && response.message) {
          this.openSnackBar(response.message, 'snackbar-success');
        } else {
          this.openSnackBar(this.errorMessage.common, 'snackbar-error');
        }
        this.changeModalStatus('close');
      });
  }

  // moveFavorite(data, groupList) {
  //   const inputParams = {
  //     followGroupList: groupList,
  //     favoriteList: data.propertyIdList,
  //   };
  //   this.favoriteService.moveFavorites(inputParams).subscribe((response: any) => {
  //     if (response && response.status === 'success' && response.Message) {
  //       this.openSnackBar(response.Message, 'snackbar-success');
  //     } else {
  //       this.openSnackBar(this.errorMessage.common, 'snackbar-error');
  //     }
  //     this.changeModalStatus('close');
  //   });
  // }

  // copyFavorite(data, groupList) {
  //   const inputParams = {
  //     followGroupList: groupList,
  //     favoriteList: data.propertyIdList,
  //   };
  //   this.favoriteService.copyFavorites(inputParams).subscribe((response: any) => {
  //     if (response && response.status === 'success' && response.Message) {
  //       this.openSnackBar(response.Message, 'snackbar-success');
  //     } else {
  //       this.openSnackBar(this.errorMessage.common, 'snackbar-error');
  //     }
  //     this.changeModalStatus('close');
  //   });
  // }

  addFavorite(data, group) {
    const inputParams = {
      PMXPropertyIdList: data.PMXPropertyIdList,
      GroupIds: group,
      stateList: data.stateList,
    };
    this.favoriteService
      .addFavorites(inputParams)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((response: any) => {
        if (response && response.status === 'success' && response.Message) {
          this.openSnackBar(response.Message, 'snackbar-success');
        } else if (response.error) {
          const message = response.error.Message ? response.error.Message : this.errorMessage.common;
          this.openSnackBar(message, 'snackbar-error');
        } else {
          const message = response.Message ? response.Message : this.errorMessage.common;
          this.openSnackBar(message, 'snackbar-error');
        }
        this.changeModalStatus('close');
      });
  }
  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}

import { Injectable } from '@angular/core';
import { HomehuntComponent } from 'src/app/_shared/component/company-footer/component/homehunt/homehunt.component';
import { RubyporchComponent } from 'src/app/_shared/component/company-footer/component/rubyporch/rubyporch.component';
import { DefaultComponent } from './component/default/default.component';
import { HomeriseComponent } from './component/homerise/homerise.component';

@Injectable({
  providedIn: 'root',
})
export class CompanyFooterService {
  constructor() {}
  getCompanyComponent(aliasName) {
    if (aliasName == 'homehunt') {
      return { data: null, component: HomehuntComponent };
    } else if (aliasName == 'rubyporch') {
      return { data: null, component: RubyporchComponent };
    } else if (aliasName == 'homerise') {
      return { data: null, component: HomeriseComponent };
    } else return { data: null, component: DefaultComponent };
  }
}

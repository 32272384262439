import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { UserState } from 'src/app/_shared/state/user.state';
import { AppState } from 'src/app/app.state';
import { SignupService } from 'src/app/signup/signup.service';
import { AgentContactService } from 'src/app/workspace/service/agent-contact.service';
import { AgentContactState } from 'src/app/workspace/state/agent-contact.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import constants from 'src/constant.json';

interface DialogContent {
  proceedAction?: any;
  proceedCancelAction?: any;
  sellingConsultantData?: any;
}

interface sellDuration {
  value: string;
  viewValue: string;
}
interface sellOptions {
  value: string;
  viewValue: string;
}
interface buyOptions {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-contact-selling-consultant',
  templateUrl: './contact-selling-consultant.component.html',
  styleUrls: ['./contact-selling-consultant.component.scss'],
})
export class ContactSellingConsultantComponent implements OnInit {
  onDestroyNotifier$ = new Subject();
  enableSubmit = true;
  type: string;
  sellingConsultantForm: FormGroup;
  requestSellingAgentInprogress: boolean;
  userProfile: any;
  showPassword: boolean = false;

  sellDuration: Array<sellDuration> = [
    { viewValue: 'As soon as possible', value: 'soon' },
    { viewValue: 'This month', value: 'This month' },
    { viewValue: 'In 1-2 month', value: 'In 1-2 month' },
    { viewValue: 'In 2-6 month', value: 'In 2-6 month' },
    { viewValue: 'I am not sure', value: 'Not sure' },
  ];

  sellOptions: Array<sellOptions> = [
    { viewValue: 'Upgrading my home', value: 'Upgrade my home' },
    { viewValue: 'Selling my second home', value: 'Selling second home' },
    { viewValue: 'Relocating', value: 'Relocating' },
    { viewValue: 'Retiring', value: 'Retiring' },
    { viewValue: 'Other', value: 'Other' },
  ];
  buyOptions: Array<buyOptions> = [
    { viewValue: 'Yes', value: 'Yes' },
    { viewValue: 'No', value: 'No' },
  ];
  agentInfo: object = {};
  listingData = null;
  propertyDetails$;
  loanApplicationUrlRequestInProgress = false;
  isDataLoading = false;
  address = {};
  fullAddress: string;
  alterNativeName: string;
  userInfo: any;

  constructor(
    public dialogRef: MatDialogRef<ContactSellingConsultantComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogContent,
    private formBuilder: FormBuilder,
    private agentContactState: AgentContactState,
    private appState: AppState,
    private propertyState: PropertyState,
    private signupService: SignupService,
    private snackBar: MatSnackBar,
    private userState: UserState,
    public agentContactService: AgentContactService,
    private changeDetect: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let propertyClientParams: any = {};
    this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res) {
        this.userProfile = res;
        propertyClientParams.email = encodeURIComponent(res?.user_profile?.email);
      }
      this.userProfile = Object.assign({}, this.userProfile?.user_profile);
    });
    this.propertyDetails$ = this.propertyState.activePropertyDetails$.subscribe((res: any) => {
      if (Object.keys(res).length == 0) {
        return;
      }
      if (res.listing && res.basic) {
        this.listingData = res.listing;
        let propertyData = (Object.keys(res.basic).length && res.basic) || this.listingData;
        if (propertyData?.PostalCode) {
          propertyClientParams.zip = propertyData?.PostalCode;
        }
        if (propertyData?.City && propertyData?.StateOrProvince) {
          propertyClientParams.city = propertyData?.City + ', ' + propertyData?.StateOrProvince;
        }
        if (propertyData?.FIPS) {
          propertyClientParams.county = propertyData?.FIPS;
        }
        if (Object.keys(propertyClientParams).length) {
          this.agentContactService
            .getPropertyClient(propertyClientParams)
            .pipe(takeUntil(this.onDestroyNotifier$))
            .subscribe(({ isLoading }) => {
              this.isDataLoading = isLoading;
              this.changeDetect.detectChanges();
            });
          this.fullAddress = propertyData.Address;
          this.address = {
            street: propertyData.StreetAddress,
            city: propertyData.City,
            State: propertyData.StateOrProvince,
            zip: propertyData.PostalCode,
          };
        }
      }
    });

    this.sellingConsultantForm = this.formBuilder.group({
      firstName: [
        { value: this.userProfile?.firstName || '', disabled: false },
        [Validators.required, Validators.pattern('[a-zA-Z ]*')],
      ],
      lastName: [
        { value: this.userProfile?.lastName || '', disabled: false },
        [Validators.required, Validators.pattern('[a-zA-Z ]*')],
      ],
      email: [
        { value: this.userProfile?.email || '', disabled: false },
        [Validators.required, Validators.pattern(constants.regex.validEmail)],
      ],
      phone: [
        { value: this.userProfile?.contact || '', disabled: false },
        [
          Validators.required,
          Validators.pattern('^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'),
        ],
      ],
      sellOptions: ['', [Validators.required]],
      buyOptions: ['', [Validators.required]],
      sellDuration: ['', [Validators.required]],
    });

    if (this.appState.authTokenValue?.idToken) {
      this.sellingConsultantForm.controls['email'].disable();
      this.sellingConsultantForm.controls['lastName'].disable();
      this.sellingConsultantForm.controls['firstName'].disable();
      this.sellingConsultantForm.controls['phone'].disable();
    }
    this.agentContactState.contactAgentRequestActionStatus$
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        this.requestSellingAgentInprogress = res;
      });
  }

  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
    this.dialogRef.close();
  }

  sendSellingConsultantRequest() {
    if (this.sellingConsultantForm.invalid) {
      return;
    }
    this.data.sellingConsultantData = this.sellingConsultantForm.value; //setting value to variable of dialog box

    if (this.appState.authTokenValue?.idToken) {
      this.data.proceedAction(this.data.sellingConsultantData, () => {});
    } else {
      this.agentContactState.propertyClientData$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
        this.agentInfo = res.agent ? res.agent : {};
        this.changeDetect.detectChanges();
      });
      let signUpParams = {
        email: this.sellingConsultantForm.value.email.toLowerCase(),
        firstName: this.sellingConsultantForm.value.firstName,
        lastName: this.sellingConsultantForm.value.lastName,
        phone: this.sellingConsultantForm.value.phone,
        role: 'employee',
        userId:
          (this.agentContactState &&
            this.agentContactState.propertyClientDataValue['agent'] &&
            this.agentContactState.propertyClientDataValue['agent'].shortId) ||
          '',
        domain: this.appState.appBaseUrlValue,
        interestedAreas: [
          {
            locality: this.propertyState.activePropertyDetailsValue['basic'].City,
            state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
          },
        ],
        createdBy: 'system',
        source: constants.SIGN_UP.source.requestSellingConsultant,
      };
      this.agentContactState.contactAgentRequestActionStatusValue = true;
      this.signupService
        .createUser(signUpParams)
        .pipe(
          takeUntil(this.onDestroyNotifier$),
          map(() => {
            this.data.proceedAction(this.data.sellingConsultantData, () => {});
          }),
          catchError((error) => {
            this.agentContactState.contactAgentRequestActionStatusValue = false;
            this.openSnackBar(error.message, 'snackbar-error');
            this.close();
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  get sellingConsultantFormControls() {
    return this.sellingConsultantForm.controls;
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BackendResponse } from '../interface/common.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanyApi {
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;

  constructor(private _http: HttpClient) {}

  getArticle(type: string): Observable<BackendResponse> {
    return this._http.get<BackendResponse>(`${this.prospektrBackendBaseURL}articles/${type}`);
  }
}

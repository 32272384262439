<app-company-nav-bar></app-company-nav-bar>
<div class="pl-0">
  <div class="logo_base text-center" *ngIf="isHamburger">
    <app-main-menu></app-main-menu>
  </div>
  <img
    *ngIf="clientDarkLogo"
    [src]="clientDarkLogo"
    class="color_invert mt-3 d-none d-sm-none pull-left"
    width="150px"
  />
  <div class="user_base" *ngIf="userState?.userDetail$ | async as userDetail">
    <div class="dropdown">
      <button
        mat-button
        [matMenuTriggerFor]="menu"
        class="dropdown-toggle"
        name="profile drop down"
        aria-label="profile drop down"
        tabindex="5"
      >
        <div class="user_img pull-left">
          <img [src]="userDetail?.user_profile.profileImage || defaultProfileImage" />
        </div>
        <p class="d-none d-sm-none d-md-none d-lg-block pull-left truncate" id="prof_name">
          {{ userDetail?.user_profile?.firstName }}
        </p>
      </button>
      <mat-menu #menu="matMenu" yPosition="below" xPosition="before" class="profileMenuWrap">
        <!-- <button *ngIf="showAllNavigation" class="d-block d-sm-none" routerLink="/workspace"
          routerLinkActive="active-link" mat-menu-item> <img src="../../assets/images/workSpaceDark.svg" height="16"
            class="mr-1"> Explore</button> -->
        <button
          routerLinkActive="active-link"
          mat-menu-item
          name="view account"
          (click)="redirectToProfile()"
          aria-label="view account"
        >
          <img src="../../assets/images/profileDark.svg" height="16" class="mr-1" alt="" /> Account
        </button>
        <button *ngIf="!hideLogOutSection" (click)="userLogout()" mat-menu-item name="logout" aria-label="logout">
          <img src="../../assets/images/logout.svg" height="16" class="mr-1 color_invert" /> Log out
        </button>
      </mat-menu>
    </div>
  </div>
  <div
    *ngIf="
      (!(userState.userDetail$ | async) && isCompanyLogoExist) || !(appState.companyInfo$ | async)?.customizationConfig
    "
    class="tenant_logo d-none d-sm-block float-left"
  >
    <a *ngIf="websiteUrl" [href]="websiteUrl">
      <img [src]="companyLogo || constant.darkLogo" height="100%" />
    </a>
    <img *ngIf="!websiteUrl" [src]="companyLogo || constant.darkLogo" height="100%" />
  </div>
  <div class="user_base mt-2" *ngIf="!(userState.userDetail$ | async) && viewSignIn">
    <!-- <button mat-stroked-button class="mr-3" style="border:1px solid $secondary "(click)="openLogInDialog()"  >Log In</button> -->
    <button mat-flat-button color="primary" (click)="openLogInDialog()">Sign In</button>
  </div>
  <ng-container *ngIf="userState.userDetail$ | async">
    <!-- <div
      class="tenant_logo d-none d-sm-block"
      *ngIf="(appState.companyInfo$ | async)?.type != constant.COMPANY_TYPE.default; else swiperContainer"
    >
      <a *ngIf="websiteUrl" [href]="websiteUrl">
        <img [src]="companyLogo || constant.darkLogo" height="100%" />
      </a>
      <img *ngIf="!websiteUrl" [src]="companyLogo || constant.darkLogo" height="100%" />
    </div> -->
    <div
      class="tenant_logo d-none d-sm-block"
      *ngIf="(appState.companyInfo$ | async)?.type != constant.COMPANY_TYPE.default"
    >
      <a *ngIf="websiteUrl" [href]="websiteUrl">
        <img [src]="companyLogo || constant.darkLogo" height="100%" />
      </a>
      <img *ngIf="!websiteUrl" [src]="companyLogo || constant.darkLogo" height="100%" />
    </div>
    <!-- <ng-template #swiperContainer>
      <swiper
        #swiper
        *ngIf="brandingInfo.length"
        [config]="swiperConfig.navBrandSwiper"
        class="d-none d-sm-block swiper-nav-container"
        [class.single-branding]="isSingleBranded"
        (mouseenter)="isSliderDisabled ? swiper.directiveRef.stopAutoplay() : null"
        (mouseleave)="isSliderDisabled ? swiper.directiveRef.startAutoplay() : null"
      >
        <div
          class="swiper-nav-item swiper-slide"
          *ngFor="let branding of brandingInfo"
          [ngbTooltip]="tipContent"
          [disableTooltip]="branding.isSelfBranded"
          data-container="body"
          placement="bottom"
          tooltipClass="tooltip-white"
        >
          <div class="user-info" *ngIf="branding.isSelfBranded; else selfBrandElse" matTooltip="Info about the action">
            <h3>{{ branding.user.firstName.charAt(0) }}. {{ branding.user.lastName }}</h3>
            <small
              >{{
                branding.user.type == 'agent'
                  ? 'Agent'
                  : branding.user.type == 'loanOfficer'
                  ? 'Loan Officer'
                  : 'Client'
              }}
            </small>
          </div>
          <ng-template #selfBrandElse>
            <div class="swiper-logo" [style.backgroundImage]="'url(' + branding.client.logo.dark + ')'"></div>
          </ng-template>
          <div class="img-round" [style.backgroundImage]="'url(' + branding.user.profileImg + ')'"></div>
          <ng-template #tipContent>
            <h3>{{ branding.user.firstName.charAt(0) }}. {{ branding.user.lastName }}</h3>
            <small
              >{{
                branding.user.type == 'agent'
                  ? 'Agent'
                  : branding.user.type == 'loanOfficer'
                  ? 'Loan Officer'
                  : 'Client'
              }}
            </small>
          </ng-template>
        </div>
      </swiper>
    </ng-template> -->
  </ng-container>

  <!-- <div class="cont-p" *ngIf="trialDaysRemaining && !isTrialExpired">
    <span class="report-count">{{ trialDaysRemaining }}</span>
    <span class="report-txt" *ngIf="trialDaysRemaining > 1">Days Remaining</span>
    <span class="report-txt" *ngIf="trialDaysRemaining <= 1">Day Remaining</span>
  </div>
  <div
    class="cont-p"
    *ngIf="
      trialDaysRemaining == 0 &&
      !reportsState.isPaymentCardAdded &&
      appState?.companyInfoValue?.appConfig?.valueReport?.isEnabled &&
      userRole == 'agent'
    "
  >
    <span class="report-txt">Trial Expired</span>
  </div> -->
  <!-- <div class="cont-p" *ngIf="isGracePeriod"><span class="grace-period">On Grace Period</span></div> -->

  <div class="notif_base d-none d-md-block" *ngIf="!planExpired && (userState.userDetail$ | async)">
    <div
      class="dropdown"
      *ngIf="
        userContact &&
        (companyName != 'homehunt' || (companyName == 'homehunt' && userType != 'agent')) &&
        !appState.companyInfoValue.appConfig?.valueReport?.isEnabled &&
        !appState.companyInfoValue.appConfig?.opportunityScanner?.isEnabled
      "
    >
      <button
        mat-icon-button
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="alertMenu"
        class="dropdown-toggle relative"
        tabindex="4"
        name="notifications"
        aria-label="notification"
      >
        <i class="material-icons">notifications </i>
        <span class="notif_mark" *ngIf="notificationCount > 0"> {{ notificationCount }} </span>
      </button>
      <mat-menu #alertMenu="matMenu" yPosition="below" class="alertMenuWrap" #menu>
        <div class="notifmsg_base">
          <div class="wrap" (click)="$event.stopPropagation()">
            <p
              style="font-size: 1.4rem; display: inline-flex; align-items: center"
              class="mb-0 text-danger"
              *ngIf="notificationCount >= 10"
            >
              10+ <span style="font-size: 1rem; margin-left: 5px" class="text-secondary">new notifications </span>
            </p>
            <p
              style="font-size: 1.4rem; display: inline-flex; align-items: center"
              class="mb-0 text-danger"
              *ngIf="notificationCount < 10"
            >
              {{ notificationCount }}
              <span style="font-size: 1rem; margin-left: 5px" class="text-secondary">new notifications</span>
            </p>
          </div>
          <ul class="list-group notif_list" (click)="$event.stopPropagation()">
            <li *ngIf="businessAreaAlertStatus" class="fixed-alert">
              <div class="row mx-2 my-2">
                <div class="col-9 px-0">
                  <h3>No Business Area added</h3>
                  <!-- <p>Clients cannot be assigned to you unless you add a business area</p> -->
                  <p>Please contact admin to add your interested business areas</p>
                </div>
                <!-- <div class="col-3 text-right px-0">
                  <button mat-stroked-button color="primary" class="mt-2"
                    (click)="goToBusinessArea(); menuTrigger.closeMenu()">
                    <mat-icon inline="true">add</mat-icon> Add
                  </button>
                </div> -->
              </div>
            </li>
            <li *ngIf="mlsAlertStatus" class="fixed-alert">
              <div class="row mx-2 my-2">
                <div class="col-9 px-0">
                  <h3>No MLS added</h3>
                  <p>You or your clients will not be able to view listed properties</p>
                </div>
                <div class="col-3 text-right px-0">
                  <button mat-stroked-button color="primary" class="mt-2" (click)="goToMls(); menuTrigger.closeMenu()">
                    <mat-icon inline="true">add</mat-icon> Add
                  </button>
                </div>
              </div>
            </li>
            <li *ngFor="let item of notificationData" [ngClass]="{ unread: !item.readDate }">
              <div class="wrap">
                <div class="notif_type" [ngClass]="item.alertType">
                  <img [src]="'../../assets/images/' + item.icon" width="22" [title]="item.type" />
                </div>
                <div class="row">
                  <div class="headname mt-1">
                    <b>{{ item.notificationMessage }}</b>
                  </div>
                  <div class="mb-0 mt-0 pr-0">
                    <label class="small"> {{ item.address }} </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="wrap">
            <a
              routerLink="/notification"
              mat-flat-button
              class="btn btn_100 text-white showAllnotify"
              href="#"
              aria-label="show all notifications"
              tabindex="5"
            >
              Show all notifications
            </a>
          </div>
        </div>
      </mat-menu>
    </div>
  </div>
  <div
    class="notif_base d-none d-lg-block"
    *ngIf="
      router.url != '/subscription/account-upgrade' &&
      isTrial &&
      !appState?.companyInfoValue?.appConfig?.valueReport?.isEnabled
    "
  >
    <div class="flexCenter">
      <div class="remainDays" *ngIf="planExpiryDate > 0">
        <span>{{ planExpiryDate }}</span> Days<br />
        Remaining
      </div>
      <div class="remainDays" *ngIf="planExpiryDate == 0">
        Your plan will<br />
        expire today
      </div>
      <div class="remainDays" *ngIf="planExpiryDate < 0">
        Your plan<br />
        has expired
      </div>

      <button mat-flat-button color="primary" class="ml-2 mt-1 addBtn" (click)="upgrade()">
        <p class="mb-0">Upgrade Now</p>
      </button>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DealAnalysisShareInput } from '../interface/deal-analysis.interface';

@Injectable({
  providedIn: 'root',
})
export class DealAnalysisApi {
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;

  constructor(private http: HttpClient) {}

  shareDealAnalysis(params: DealAnalysisShareInput): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}deal-analysis/${params.id}/share`, params);
  }

  deleteSharedDealDetails(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .delete(`${this.prospektrBackendBaseURL}deal-analysis/${params.dealId}/shared-user/${params._id}`)
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }
}

<nav class="navbar navbar-expand-md rubymenu" *ngIf="appState.companyInfo$ | async as companyInfo">
  <div class="container-fluid">
    <div class="row w-100 align-items-center">
      <div class="col-lg-4 col-3">
        <a [href]="companyInfo?.siteUrls?.home.link">
          <img
            [src]="companyInfo?.logo?.light"
            alt="logo"
            class="img-fluid d-none d-md-block"
            width="170px"
            align="left"
          />
          <div style="padding-top: 16px; color: white; font-weight: bold; font-size: 14px">
            &nbsp;&nbsp;A Continental Real Estate Group, Inc. Company
          </div>
          <img [src]="companyInfo?.logo?.iconDark" alt="logo_icon" class="img-fluid d-block d-md-none" width="80px" />
        </a>
      </div>
      <div class="col-lg-8 col-9 pr-0">
        <ul class="navbar-nav me-auto justify-content-end">
          <li class="nav-item line d-none d-md-block">
            <hr />
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="companyInfo?.siteUrls?.buy.link">{{
              companyInfo?.siteUrls?.buy.displayText
            }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [href]="companyInfo?.siteUrls?.sell.link">{{
              companyInfo?.siteUrls?.sell.displayText
            }}</a>
          </li>
          <li class="nav-item tel">
            <a class="nav-link" href="tel:1-877-735-5657">
              <mat-icon>local_phone</mat-icon>
              <span>1-877-735-5657</span>
            </a>
          </li>
          <li class="nav-item accnt">
            <div class="nav-link">
              <mat-icon>person</mat-icon>
            </div>
            <div class="RPdropdown_content">
              <a [href]="companyInfo?.siteUrls?.sell.link" target="_blank">Seller Login</a>
              <a (click)="authorizeService.openLogInDialog()">Buyer Login</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>

<div class="alertDialog text-center mb-3">
  <img mat-dialog-title class="mr-auto ml-auto mb-0" width="35" src="~/../assets/images/alert.svg" />
  <h1 mat-dialog-title class="mb-0">
    <b>{{ data.title ? data.title : '' }}</b>
  </h1>
  <div mat-dialog-content class="mt-2">
    <h6>{{ data.message ? data.message : '' }}</h6>
  </div>
  <div mat-dialog-actions>
    <div class="text-info mr-2">
      <button mat-flat-button [pmx-progress-button]="isLoading" color="primary" (click)="onProceed()">
        {{ (data && data.primaryBtnText) || 'Proceed' }}
      </button>
    </div>
    <div class="mr-2">
      <button mat-dialog-close mat-flat-button color="primary" cdkFocusInitial (click)="onNoClick()">
        {{ (data && data.secondaryBtnText) || 'Cancel' }}
      </button>
    </div>
  </div>
</div>

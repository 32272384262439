import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AgentContactApi {
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;
  private getPropertyClientApi = 'propertyClient';
  private schedulePropertyVisitApi = 'schedulePropertyVisit';
  private requestSellingAgentApi = 'requestSellingAgent';
  private sharePropertyDetailsApi = 'sharePropertyDetails';
  private makeAnOfferForAPropertyApi = 'suggestPropertyPrice';
  private homeValueReportRequestApi = 'icmaHomeValueReport';
  private preApprovedUrlApi = 'preApprovedUrl';

  private appQueryParams?: any;

  constructor(private http: HttpClient) {
    this.appQueryParams = JSON.parse(localStorage.getItem('appQueryParams'));
  }

  getPropertyClient(params): Observable<any> {
    if (this.appQueryParams) params['meta'] = this.appQueryParams;

    return this.http.get(`${this.prospektrBackendBaseURL}${this.getPropertyClientApi}`, {
      params: params,
    });
  }

  schedulePropertyVisit(params): Observable<any> {
    if (this.appQueryParams) params['meta'] = this.appQueryParams;

    return this.http.post(`${this.prospektrBackendBaseURL}${this.schedulePropertyVisitApi}`, params);
  }

  requestSellingAgent(params): Observable<any> {
    if (this.appQueryParams) params['meta'] = this.appQueryParams;

    return this.http.post(`${this.prospektrBackendBaseURL}${this.requestSellingAgentApi}`, params);
  }
  sharePropertyDetails(params): Observable<any> {
    if (this.appQueryParams) params['meta'] = this.appQueryParams;

    return this.http.post(`${this.prospektrBackendBaseURL}${this.sharePropertyDetailsApi}`, params);
  }

  makeAnOfferForAProperty(params): Observable<any> {
    if (this.appQueryParams) params['meta'] = this.appQueryParams;

    return this.http.post(`${this.prospektrBackendBaseURL}${this.makeAnOfferForAPropertyApi}`, params);
  }

  homeValueReportRequest(params): Observable<any> {
    if (this.appQueryParams) params['meta'] = this.appQueryParams;

    return this.http.post(`${this.prospektrBackendBaseURL}${this.homeValueReportRequestApi}`, params);
  }

  getPreApprovedUrl(params): Observable<any> {
    if (this.appQueryParams) params['meta'] = this.appQueryParams;

    return this.http.post(`${this.prospektrBackendBaseURL}${this.preApprovedUrlApi}`, params);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanListingService } from '../subscription-plan.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  transactionDetails: any;
  loader = false;

  constructor(private router: Router, private planListingService: PlanListingService) {}

  ngOnInit() {
    this.loader = true;
    this.planListingService.getPaymentStatus().subscribe((res) => {
      if (
        res &&
        res.status &&
        res.status === 'success' &&
        res.data &&
        res.data.clients &&
        res.data.clients.payments &&
        res.data.clients.payments[0]
      ) {
        this.transactionDetails = res.data.clients.payments[0];
        this.loader = false;
      }
    });
  }

  goToDashboard() {
    this.router.navigate(['dashboard']);
  }

  print() {
    let printContents;
    let popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Subscription Plan Invoice</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`);
    popupWin.document.close();
  }
}

<div [title]="property.address" (click)="openPropertyDetails(property)">
  <div class="card-image">
    <button
      *ngIf="!actions && detailsRequired"
      #toggleFavorite
      mat-icon-button
      class="follow-btn"
      aria-label="watch this property"
      (click)="followProperty(property); $event.stopPropagation()"
    >
      <mat-icon class="material-icons-new outline-favorite" *ngIf="!property.isFavorite"></mat-icon>
      <mat-icon class="text-danger" *ngIf="property.isFavorite">favorite</mat-icon>
    </button>
    <button
      *ngIf="actions"
      #toggleFavorite
      mat-icon-button
      class="follow-btn"
      aria-label="watch this property"
      (click)="actionTrigger('delete'); $event.stopPropagation()"
    >
      <!-- <mat-icon class="material-icons-new outline-favorite" *ngIf="!isFavorite"></mat-icon> -->
      <mat-icon class="text-danger">favorite</mat-icon>
    </button>
    <mat-checkbox
      *ngIf="isSelectable"
      color="primary"
      class="select-btn"
      (click)="$event.stopPropagation()"
      (change)="$event ? toggleSelect(property, $event.checked) : ''"
      [checked]="isSelected"
    >
    </mat-checkbox>
    <img
      class="img-fluid mx-auto property-image"
      [src]="listingImageBaseUrl + '?ListingId=' + (property.lstListingId || 'null') + '&imageName=1&height=219'"
    />
    <div class="property-badge-wrapper">
      <ng-container *ngFor="let notification of notificationList">
        <span
          class="property-badge"
          [ngClass]="notification.referenceType == 'recommendation' ? 'badge-recommendation' : 'badge-alert'"
          *ngIf="notification?.displayText?.toLowerCase() != property?.lstStandardStatus?.toLowerCase()"
        >
          {{ notification.displayText }}
        </span>
      </ng-container>

      <ng-container *ngIf="property.hasOwnProperty('viewStatus'); else noViewStatus">
        <span *ngIf="property.viewStatus == true" class="property-badge badge-viewStatus"> Investor Viewed </span>
      </ng-container>

      <ng-template #noViewStatus>
        <span
          *ngIf="property.filterStyleClass == 'listing'"
          class="property-badge sale-type"
          [ngClass]="property.filterStyleClass"
        >
          {{ property.lstStandardStatus }}
        </span>
        <span
          *ngIf="property.filterStyleClass == 'sold'"
          class="property-badge sale-type"
          [ngClass]="property.filterStyleClass"
        >
          Sold
        </span>
        <span
          *ngIf="property?.filterStyleClass == 'distressed'"
          class="property-badge sale-type"
          [ngClass]="property.filterStyleClass"
        >
          {{ property?.fclStatus || property.lstSpecialListingConditions | titlecase }}
        </span>
        <span
          *ngIf="property?.filterStyleClass == 'hud'"
          class="property-badge sale-type"
          [ngClass]="property.filterStyleClass"
        >
          {{ property?.lstSpecialListingConditions | titlecase }}
        </span>
      </ng-template>
    </div>
  </div>

  <div class="card-content mb-2 mx-0">
    <div class="pricing">
      <h5>${{ property.lstListPrice || property.currentAVMValue | number }}</h5>
      <div *ngIf="actions">
        <button class="float-right" mat-icon-button [matMenuTriggerFor]="favMenu" (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #favMenu="matMenu" id="fldproplist_action" class="mt-0" xPosition="before">
          <button *ngFor="let action of actions" mat-menu-item (click)="actionTrigger(action)">
            <i *ngIf="action === 'copy'" class="fa fa-clone mr-1" aria-hidden="true"></i>
            <i class="fa fa-arrows-alt mr-1" *ngIf="action === 'move'" aria-hidden="true"></i>
            <i class="fa fa-trash-o mr-1" *ngIf="action === 'delete'" aria-hidden="true"></i>
            <span>{{ action | titlecase }} </span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="mt-1">
      <div class="d-inline-block propinfo_amntis">
        <p class="my-0">
          <b>{{ property.bedroomsTotal || '-' }}</b> <small> Bed</small>
        </p>
      </div>
      <div class="d-inline-block propinfo_amntis">
        <p class="my-0">
          <b>{{ property.bathroomsTotalInteger || '-' }}</b
          ><small> Bath</small>
        </p>
      </div>
      <div class="d-inline-block propinfo_amntis">
        <p class="my-0">
          <b>{{ (property.livingArea | number) || '-' }}</b
          ><small> Sqft</small>
        </p>
      </div>
      <div class="d-inline-block propinfo_amntis">
        <p class="my-0">
          <b>{{ (property.lotSizeSquareFeet | number) || '-' }}</b
          ><small> Sqft.lot </small>
        </p>
      </div>
    </div>
    <p class="mt-1 prop-name">{{ property.address }}</p>
  </div>
</div>

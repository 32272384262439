import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SavedSearchState {
  $activeSaveSearches = new BehaviorSubject<Array<any>>([]);
  activeSaveSearches = this.$activeSaveSearches.asObservable();
  $newSaveSearch = new BehaviorSubject<any>({});
  newSaveSearch = this.$newSaveSearch.asObservable();

  public get activeSaveSearchesValue(): Array<any> {
    return this.$activeSaveSearches.value;
  }

  public set activeSaveSearchesValue(saveSearches: Array<any>) {
    this.$activeSaveSearches.next(saveSearches);
  }

  public set newSaveSearchValue(saveSearch: object) {
    this.$newSaveSearch.next(saveSearch);
  }
}

<div class="loadingMapFull" *ngIf="searchInProgress">
  <div class="flex_center fullHeight">
    <mat-spinner [diameter]="30" class="mr-3"></mat-spinner> Please wait while we load
  </div>
</div>
<div class="list_view container-fluid">
  <!-- <app--property-filter-bar></app-property-filter-bar> -->
  <div class="table_ctrl mt-1">
    <!-- <app-save-search class="float-right ml-2"></app-save-search> -->
    <ng-container *ngIf="listFacade.getLocalTrendsAvailabilityStatus() | async">
      <button mat-button class="btn local_trends_btn" (click)="openLocalTrends()" id="local_trends">
        <div class="localTrendsCont">
          <div class="loctrend_icon"></div>
          <span class="d-none d-md-block ml-2">Local Trends</span>
        </div>
      </button>
    </ng-container>
  </div>
  <!-- <app-list-view-filter-bar (addToFavoriteForList)="addToFavorite()"></app-list-view-filter-bar> -->
  <hr class="mt-2" />
  <div class="row">
    <!-- <div class="col-lg-7 col-md-7 col-sm-12 col-12">
      <button mat-button [matMenuTriggerFor]="menu" class="sort_btn"><span>Sort By</span></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>Item 1</button>
        <button mat-menu-item>Item 2</button>
      </mat-menu>
    </div> -->
    <div class="col-lg-5 col-md-5 col-sm-12 col-12 table_ctrl">
      <!-- <button class="float-right btn view_search mat-ripple ng-star-inserted"><img _ngcontent-qmu-c10=""
          src="../../assets/images/gear.svg" width="20"></button> -->
      <!-- <button class="float-right btn view_search mat-ripple ng-star-inserted mr-2 ml-2" (click)="addToFavorite()">
        <i _ngcontent-qno-c10="" class="fa fa-heart fa-1x pull-left mt-1 mr-1"></i>
        <span>Add to Favorites</span>
      </button> -->
      <!-- <button class="float-right mt-1 btn save_btn mat-button"><img _ngcontent-qmu-c10=""  src="../../assets/images/phone_line.svg" width="16" class="mr-1">Unlock Contact Info.</button> -->
    </div>
  </div>
  <div *ngIf="showPropertyList" class="row">
    <div class="col-lg-12 col-12">
      <div class="table_base">
        <div class="table-responsive table_wrap">
          <table
            mat-table
            matSort
            (matSortChange)="sortColumn($event)"
            [dataSource]="propertyDataSource"
            class="mat-elevation-z2 w-100"
          >
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  color="primary"
                  (change)="$event ? toggleSelectAll() : null"
                  [checked]="propertySelectionModel.hasValue() && isAllSelected()"
                  [indeterminate]="propertySelectionModel.hasValue() && !isAllSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  color="primary"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? toggleSelect(row, $event.checked) : null"
                  [checked]="propertySelectionModel.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="isFavorite">
              <th mat-header-cell *matHeaderCellDef>
                <!-- <i _ngcontent-qno-c10=""
                  class="fa fa-heart fa-1x pull-left mt-1 mr-1"></i> -->
                <mat-icon class="text-primary mt-1" style="font-size: 18px">favorite</mat-icon>
              </th>
              <td mat-cell *matCellDef="let element">
                <!-- <i _ngcontent-qno-c10="" class="fa fa-heart fa-1x pull-left mt-1 mr-1" *ngIf="element.isFavorite"></i> -->
                <mat-icon *ngIf="element.isFavorite" class="text-primary mt-1" style="font-size: 18px"
                  >favorite
                </mat-icon>
              </td>
            </ng-container>
            <ng-container matColumnDef="PropertySubType">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Property Type</th>
              <td mat-cell *matCellDef="let element">
                {{ element.PropertySubType }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Lst_FlipDuration">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Flip Duration<br />&nbsp;&nbsp;(Days)</th>
              <td mat-cell *matCellDef="let element" class="center">
                {{ element.Lst_FlipDuration }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Address">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Street Address</th>
              <td
                mat-cell
                *matCellDef="let element"
                (click)="openPropertyDetailsSheet(element)"
                matTooltip="{{ element.Address }}"
              >
                <a mat-button routerLink="." class="truncated list_address" style="font-size: 12px">
                  {{ element.Address }}
                </a>
              </td>
            </ng-container>
            <ng-container matColumnDef="Lst_StandardStatus">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Listing Status</th>
              <td mat-cell *matCellDef="let element" class="left">
                <span *ngIf="element.Lst_StandardStatus === 'Sold'" style="color: #3f51b5">{{
                  element.Lst_StandardStatus
                }}</span>
                <span
                  *ngIf="
                    element.Lst_StandardStatus === 'Active' || element.Lst_StandardStatus === 'Active Under Contract'
                  "
                  style="color: #8bc34a"
                  >{{ element.Lst_StandardStatus }}</span
                >
                <span *ngIf="element.Lst_StandardStatus === 'Pending'" style="color: #f69832">{{
                  element.Lst_StandardStatus
                }}</span>
                <span
                  *ngIf="
                    element.Lst_StandardStatus === 'NULL' ||
                    element.Lst_StandardStatus === 'Cancel/Withdrawn' ||
                    element.Lst_StandardStatus === 'Contingent' ||
                    element.Lst_StandardStatus === 'Delisted' ||
                    element.Lst_StandardStatus === 'Unknown' ||
                    element.Lst_StandardStatus === 'Unlisted'
                  "
                  style="color: #833f2a"
                  >{{ element.Lst_StandardStatus }}</span
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="Lst_ListPrice">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>List Price<br />&nbsp;&nbsp;(USD)</th>
              <td mat-cell *matCellDef="let element" class="center">
                {{ element.Lst_ListPrice | number }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Lst_ListingContractDate">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>On Market<br />&nbsp;&nbsp;(Days)</th>
              <td mat-cell *matCellDef="let element" class="center">
                {{ calculateDays(element) }}
              </td>
            </ng-container>

            <ng-container matColumnDef="BedroomsTotal">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Beds<br />&nbsp;&nbsp;(No.s)</th>
              <td mat-cell *matCellDef="let element" class="center">
                {{ element.BedroomsTotal }}
              </td>
            </ng-container>
            <ng-container matColumnDef="BathroomsTotalInteger">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Baths<br />&nbsp;&nbsp;(No.s)</th>
              <td mat-cell *matCellDef="let element" class="center">
                {{ element.BathroomsTotalInteger }}
              </td>
            </ng-container>
            <ng-container matColumnDef="LivingArea">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Living Area<br />&nbsp;&nbsp;(Sqft.)</th>
              <td mat-cell *matCellDef="let element" class="center">
                {{ element.LivingArea | number }}
              </td>
            </ng-container>
            <ng-container matColumnDef="LotSizeSquareFeet">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Lot Size<br />&nbsp;&nbsp;(Sqft.)</th>
              <td mat-cell *matCellDef="let element" class="center">
                {{ element.LotSizeSquareFeet | number }}
              </td>
            </ng-container>
            <ng-container matColumnDef="TaxAnnualAmount">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Tax Amount<br />&nbsp;&nbsp;(USD)</th>
              <td mat-cell *matCellDef="let element" class="right">
                {{ element.TaxAnnualAmount | number: '1.0-0' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="ClosePrice">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Sale Price<br />&nbsp;&nbsp;(USD)</th>
              <td mat-cell *matCellDef="let element" class="right">
                {{ element.ClosePrice | number: '1.0-0' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="LastSaleContractDate">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Last Sale<br />&nbsp;&nbsp;(Date)</th>
              <td mat-cell *matCellDef="let element" class="center">
                {{ element.lastSaleDate | date: 'yyyy-MM-dd' }}
              </td>
            </ng-container>
            <ng-container matColumnDef="OwnershipDuration">
              <th mat-header-cell mat-sort-header *matHeaderCellDef>Owned For<br />&nbsp;&nbsp;(Months / Years)</th>
              <td mat-cell *matCellDef="let element" class="center">
                {{ element.LastSaleContractDate | OwnedForStatus | IsValueExist }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnTitle; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columnTitle"></tr>
          </table>
          <div
            class="text-center alertInfo"
            *ngIf="
              propertyDataSource && propertyDataSource.data && propertyDataSource.data.length === 0 && !searchInProgress
            "
          >
            <div class="notFound">
              <img class="img-fluid" width="60" src="~/../assets/images/notFound.svg" />
              <h4>No property to display</h4>
            </div>
          </div>
        </div>
        <div class="paginationWrap">
          <button
            mat-flat-button
            class="float-left view_search mr-2 ml-2 mt-2"
            name="add to watchlist"
            aria-label="add to watchlist"
            (click)="addToFavorite()"
          >
            <!-- <i _ngcontent-qno-c10="" class="fa fa-heart fa-1x pull-left mt-1 mr-1"></i> -->
            <mat-icon class="text-primary float-left mr-0 mr-sm-2 mt-1" style="font-size: 20px">favorite</mat-icon>
            <span class="float-left d-none d-sm-block">{{ stringRes.ADD_TO_WATCH_LIST }}</span>
          </button>
          <mat-paginator
            *ngIf="this.listFacade.getIsFullPropertyListAvailable()"
            [pageSizeOptions]="[100]"
            showFirstLastButtons
          >
          </mat-paginator>
          <mat-paginator
            *ngIf="!this.listFacade.getIsFullPropertyListAvailable()"
            [pageSizeOptions]="[100]"
            (page)="pageChange($event)"
            showFirstLastButtons
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <app-status-bar></app-status-bar>
</div>

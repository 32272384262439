<div class="prpty_visit">
  <button class="close" mat-icon-button (click)="close()" autofocus="false">
    <mat-icon>close</mat-icon>
  </button>
  <h4 class="font-12">Get a Home Value Report</h4>
  <p class="text-secondary">A generated report will be sent to your email</p>
  <div class="wrap_body mt-4">
    <form [formGroup]="homeValueReportRequestForm">
      <!-- <ng-container *ngIf="!isLoggedInUser"> -->
      <!-- <ng-container> -->
      <div class="row">
        <div class="col">
          <mat-form-field>
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" autocomplete="off" />
            <mat-error
              *ngIf="
                homeValueReportRequestFormControls.firstName.errors &&
                homeValueReportRequestFormControls.firstName.errors.required
              "
              class="invalid-feedback"
            >
              First Name is required</mat-error
            >
            <mat-error
              *ngIf="
                homeValueReportRequestFormControls.firstName.errors &&
                homeValueReportRequestFormControls.firstName.errors.pattern
              "
              class="invalid-feedback"
            >
              Enter valid names</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" autocomplete="off" />
            <mat-error
              *ngIf="
                homeValueReportRequestFormControls.lastName.errors &&
                homeValueReportRequestFormControls.lastName.errors.required
              "
              class="invalid-feedback"
            >
              Last Name is required</mat-error
            >
            <mat-error
              *ngIf="
                homeValueReportRequestFormControls.lastName.errors &&
                homeValueReportRequestFormControls.lastName.errors.pattern
              "
              class="invalid-feedback"
            >
              Enter valid names</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" autocomplete="off" />
            <mat-error
              *ngIf="
                homeValueReportRequestFormControls.email.errors &&
                homeValueReportRequestFormControls.email.errors.required
              "
              class="invalid-feedback"
            >
              Email is required</mat-error
            >
            <mat-error
              *ngIf="
                homeValueReportRequestFormControls.email.errors &&
                homeValueReportRequestFormControls.email.errors.pattern
              "
              class="invalid-feedback"
            >
              Invalid email format</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field>
            <mat-label>Phone</mat-label>
            <input matInput formControlName="phone" autocomplete="off" />
            <mat-error
              *ngIf="
                homeValueReportRequestFormControls.phone.errors &&
                homeValueReportRequestFormControls.phone.errors.required
              "
              class="invalid-feedback"
            >
              Contact number is required</mat-error
            >
            <mat-error
              *ngIf="
                homeValueReportRequestFormControls.phone.errors &&
                homeValueReportRequestFormControls.phone.errors.pattern
              "
              class="invalid-feedback"
            >
              Enter valid contact number</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <!-- </ng-container> -->
      <div class="row mt-2">
        <div *ngIf="checkBox" class="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="enableSubmit"
              (change)="enableSubmit = !enableSubmit"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" style="font-style: italic" for="flexCheckDefault">
              I agree to the below terms.
            </label>
          </div>
        </div>
        <div class="col text-right">
          <button
            mat-flat-button
            [pmx-progress-button]="homeValueReportRequestInprogress"
            [disabled]="!enableSubmit"
            color="primary"
            (click)="requestHomeValue()"
          >
            Request Home Value
          </button>
          <br />
        </div>
      </div>
      <div #ctaPolicy class="policyDetails" [termsPolicy]></div>
    </form>
  </div>
</div>

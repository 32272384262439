import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
})
export class AreaChartComponent implements OnInit, OnChanges {
  @ViewChild('areaChart') areaChart: ElementRef;
  @Input() avmData: any;
  avmValues = [];
  avmHighValues = [];
  avmLowValues = [];
  avmValuationDates = [];
  updateFlag = false;
  constructor() {}

  private chart: Highcharts.Chart;

  Highcharts: typeof Highcharts = Highcharts;
  chartOptions = {
    chart: {
      renderTo: 'avmChart',
      type: 'line',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    xAxis: {},
    yAxis: {
      crosshair: true,
      title: {
        text: '',
      },
    },
    tooltip: {
      pointFormat: '${point.y:,.0f}',
    },
    series: [],
    legend: {},
    lang: {
      decimalPoint: '.',
      thousandsSep: ',',
    },
  };

  ngOnInit() {}

  ngOnChanges(): void {
    if (!this.avmData) {
      return;
    }
    this.avmValues = [];
    this.avmHighValues = [];
    this.avmLowValues = [];
    this.avmValuationDates = [];
    for (const avm of this.avmData) {
      this.avmValues.push(Number(avm.AVMValue.substr(1).replace(/,/g, '')));
      this.avmHighValues.push(Number(avm.AVMHighValue.substr(1).replace(/,/g, '')));
      this.avmLowValues.push(Number(avm.AVMLowValue.substr(1).replace(/,/g, '')));
      this.avmValuationDates.push(avm.AVMValuationDate);
    }
    this.createChart();
  }

  private createChart(): void {
    this.chartOptions.series = [
      {
        name: 'High Value',
        data: this.avmHighValues,
        type: 'line',
        color: '#fc7c9b',
      },
      {
        name: 'Value',
        data: this.avmValues,
        type: 'line',
        color: '#9591ff',
      },
      {
        name: 'Low Value',
        data: this.avmLowValues,
        type: 'line',
        color: '#cfbde9',
      },
    ];

    (this.chartOptions.xAxis = {
      categories: this.avmValuationDates,
      crosshair: true,
    }),
      (this.chartOptions.legend = {
        enabled: true,
        borderColor: 'transparent',
        align: 'center',
        x: 14,
        layout: 'horizontal',
        verticalAlign: 'bottom',
        borderWidth: 1,
      }),
      (this.updateFlag = true);
  }
}

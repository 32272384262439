import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'OwnedForStatus',
})
export class OwnedForStatusPipe implements PipeTransform {
  transform(lastSaleDate: any): any {
    if (lastSaleDate) {
      let diffToDay: any;
      let difference: any;
      let diffStr: any;
      difference = moment().diff(moment(lastSaleDate).format(), 'months');
      if (difference <= 0) {
        difference = moment().diff(moment(lastSaleDate).format(), 'days');
        diffToDay = moment().diff(moment(lastSaleDate).format(), 'days');
        diffStr = diffToDay + ` Day${diffToDay > 1 ? 's' : ''}`;
      } else {
        diffToDay = moment().diff(moment(lastSaleDate).format(), 'months');
        diffStr = diffToDay + ` Month${diffToDay > 1 ? 's' : ''}`;
        if (difference >= 12) {
          diffToDay = moment().diff(moment(lastSaleDate).format(), 'years');
          diffStr = diffToDay + ` Year${diffToDay > 1 ? 's' : ''}`;
        }
      }
      return diffStr;
    }
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent {
  @Input('leftText') leftText: string = 'Default';
  @Input('rightText') rightText: string = 'Pro';
  @Output() activeSelection = new EventEmitter<string>();
  constructor() {}

  selectionChanged(event) {
    if (!event.currentTarget.checked) return this.activeSelection.emit(this.leftText.toLowerCase());
    if (event.currentTarget.checked) return this.activeSelection.emit(this.rightText.toLowerCase());
  }
}

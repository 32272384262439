import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'YesOrNoConverter',
})
export class YesOrNoConverterPipe implements PipeTransform {
  transform(status: any): any {
    if (status && status !== undefined && status !== '--' && typeof status == 'string') {
      switch (status.toLowerCase()) {
        case 'y':
        case 't':
        case 'yes':
          status = 'Yes';
          break;

        default:
          status = 'No';
      }
    }
    if (typeof status == 'boolean') {
      status = status ? 'Yes' : 'No';
    }
    return status;
  }
}

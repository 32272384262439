import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeApi {
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;
  private cardsEndpoint = 'cards';

  constructor(private http: HttpClient) {}

  saveCardToStripe(tokenAndUser): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.cardsEndpoint}`, tokenAndUser);
  }

  getSavedCards(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${this.cardsEndpoint}`);
  }

  deleteACard(cardId): Observable<any> {
    return this.http.delete(`${this.prospektrBackendBaseURL}${this.cardsEndpoint}/${cardId}`);
  }
}

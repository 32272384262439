<footer class="hz-footer">
  <div class="privacy py-4">
    <div class="centered px-3 pt-2">
      Copyright Houwzer, Inc. {{ currentYear }}. All rights reserved. |
      <a href="https://houwzer.com/legal/terms" target="_blank">Terms and Conditions</a> |
      <a href="https://houwzer.com/legal/privacy" target="_blank">Privacy</a>
      <div class="sec-hr white"></div>
      Real Estate Brokerage Licensure: REO200200265 (DC), CQ1060583 (FL), 0226029260 (VA), 5000924 (MD), 1649096 (NJ),
      RB068203 (PA)
    </div>
    <hr />
    <div class="centered px-3">
      <p>
        Continental Real Estate Group, Inc. and Pleasant Realty, Inc. hold real estate brokerage licenses in multiple
        states. A list of our real estate licenses is available&nbsp;
        <a href="https://www.listingcheckout.com/RubyPorch/RubyPorch" target="_blank">here</a>.
      </p>
    </div>
  </div>
</footer>

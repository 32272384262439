<pktr-app-menu
  #pktrAppMenu
  *ngIf="!(appState.isAppLoading$ | async)"
  [env]="env"
  [idToken]="(appState.authToken$ | async)?.idToken || null"
  [appVersion]="appVersion"
></pktr-app-menu>

<div class="loadingMapFull opaque" *ngIf="appState.isAppLoading$ | async">
  <div class="flex_center fullHeight">
    <mat-spinner [diameter]="30" class="mr-3"></mat-spinner> Please wait while we load
  </div>
</div>

<app-deal-analysis-sheet
  *ngIf="sheetActive"
  [dealId]="dealAnalysisIdValue"
  [pmxPropertyId]="dealAnalysisPropertyIdValue"
></app-deal-analysis-sheet>

<app-property-details-sheet> </app-property-details-sheet>

<router-outlet></router-outlet>

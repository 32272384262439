import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppState } from 'src/app/app.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import constant from 'src/constant.json';
import { ActivityLoggerService } from '../../service/activity-logger.service';
import { UserState } from '../../state/user.state';

@Component({
  selector: 'app-copy-field',
  templateUrl: './copy-field.component.html',
  styleUrls: ['./copy-field.component.scss'],
})
export class CopyFieldComponent implements OnInit {
  @ViewChild('inviteUrlField') inviteUrlField: ElementRef;
  @Input() content = '';
  @Input() showPreviewBtn: boolean = false;
  copyBtnText = 'Copy';
  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2,
    private activityLoggerService: ActivityLoggerService,
    public appState: AppState,
    private userState: UserState,
    private propertyState: PropertyState
  ) { }

  ngOnInit(): void { }

  copyTextToClipboard(field) {
    let address = {
      city: this.propertyState.activePropertyDetailsValue['basic']?.City,
      state: this.propertyState.activePropertyDetailsValue['basic']?.StateOrProvince,
      street: this.propertyState.activePropertyDetailsValue['basic']?.StreetAddress,
      zip: this.propertyState.activePropertyDetailsValue['basic']?.PostalCode,
    };
    if (this.appState.authTokenValue?.idToken && this.userState.userDetails?.roles?.includes('investor'))
      this.logUserActivity(
        constant.LOGGER.propertyDetails.category,
        constant.LOGGER.propertyDetails.action.copyPropertyUrl.key,
        this.propertyState.activePropertyDetailsValue['basic'].PMXPropertyId,
        address
      );

    const selectedField = this.renderer.selectRootElement(this.inviteUrlField['nativeElement']);
    try {
      selectedField.focus();
      selectedField.select();
      document.execCommand('copy');
      selectedField.setSelectionRange(0, 0);
      selectedField.blur();
      this.copyBtnText = 'Copied';
      setTimeout(() => {
        this.copyBtnText = 'Copy';
      }, 2000);
    } catch (err) {
      console.error('Could not copy');
    }
  }
  logUserActivity(category, action, PMXPropertyId, address) {
    let loggerParams = {
      category: category,
      action: action,
    };
    let inputData = {
      PMXPropertyId: PMXPropertyId,
      address: address,
    };
    this.activityLoggerService.logActivity(loggerParams, inputData);
  }
  openInNewTab(url) {
    window.open(url, '_blank');
  }
}

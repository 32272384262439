import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-street-view',
  templateUrl: './street-view.component.html',
  styleUrls: ['./street-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StreetViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() defaultPlace;
  @Output() streetViewEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('streetView', { static: true }) streetView: ElementRef;
  streetViewInitVal: ElementRef;
  isError = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges() {
    this.streetViewInitVal = this.streetView;
    const streetViewService = new google.maps.StreetViewService();
    let streetViewMap: any = null;
    if (this.defaultPlace) {
      streetViewMap = new google.maps.StreetViewPanorama(this.streetView.nativeElement, {
        position: this.defaultPlace,
        addressControl: false,
        scrollwheel: false,
      });

      streetViewService.getPanorama({ location: this.defaultPlace, radius: 50 }, (data: any, status: any) => {
        if (status === 'OK') {
          this.isError = false;
          var headingDirection = google.maps.geometry.spherical.computeHeading(data.location.latLng, this.defaultPlace);
          streetViewMap.setPov({ heading: headingDirection, pitch: 0 });
        } else {
          this.isError = true;
        }
        this.cdr.detectChanges();
      });
      this.streetViewEvent.next(streetViewMap);
      this.cdr.detectChanges();
    } else {
      this.isError = false;
      streetViewMap = this.streetViewInitVal;
      this.cdr.detectChanges();
    }
  }

  ngOnDestroy() {
    this.defaultPlace = null;
  }
}

<div *ngIf="addressFlag" class="confirmDialogWrap text-left">
  <button
    mat-button
    mat-dialog-close
    [disabled]="propertyCreationInProgress"
    (click)="proceedCancelAction()"
    class="close"
  >
    <mat-icon>close</mat-icon>
  </button>
  <h3 mat-dialog-title>Add Property</h3>
  <div mat-dialog-content>Add property to your home list.</div>
  <form #addPropertyForm="ngForm" class="mt-4">
    <mat-form-field class="w-100">
      <input
        matInput
        #searchField
        [(ngModel)]="address"
        placeholder="Choose a property"
        name="autocompleteInput"
        (keydown.enter)="getPlaceFromGeocoder()"
      />
    </mat-form-field>
  </form>
  <div class="w-100 text-right">
    <!-- <button mat-button mat-dialog-close [disabled]="propertyCreationInProgress"
      (click)="proceedCancelAction()">Cancel</button> -->
    <button
      class="ml-2"
      mat-flat-button
      color="primary"
      [pmx-progress-button]="propertyCreationInProgress"
      (click)="saveProperty()"
    >
      Add
    </button>
  </div>
</div>
<div *ngIf="(!addressFlag && !isContinued) || params.propertyClaimFlag?.propertyClaimFlag" class="confirmDialogWrap">
  <div mat-dialog-content>
    <img src="assets/images/prop_question.png" width="60px" />
    <p class="title-text">You will be presented with a set of questions to verify your ownership of the property.</p>
  </div>
  <div class="w-100 text-center mt-3">
    <button class="close" mat-button mat-dialog-close (click)="addressFlag = true">
      <mat-icon>close</mat-icon>
    </button>
    <!-- <button mat-button mat-dialog-close (click)="addressFlag = true">Cancel</button> -->
    <button class="mt-3" mat-flat-button color="primary" (click)="isContinued = true">
      Continue
      <mat-icon>arrow_right_alt</mat-icon>
    </button>
  </div>
</div>
<app-questionnaire *ngIf="isContinued" [questionnaire]="questionnaire" (answerEvent)="getQuestionnaire($event)">
</app-questionnaire>

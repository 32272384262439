<div class="confirmDialogWrap">
  <button class="close" mat-button mat-dialog-close type="button" (click)="proceedCancelAction()">
    <mat-icon>close</mat-icon>
  </button>
  <div>
    <h5>Add Contact</h5>
    <div>
      <form [formGroup]="addContactForm" (ngSubmit)="saveContact()">
        <div class="mt-4 row align-items-center">
          <mat-form-field class="col-lg-12">
            <mat-label> List Name</mat-label>
            <mat-select formControlName="list">
              <mat-option *ngFor="let contactList of contactListOption" [value]="contactList._id">{{
                contactList.name
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="contactFormControls?.list?.errors" class="invalid-feedback">
              Contact Group is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mt-4 row align-items-center">
          <mat-form-field class="col-lg-6 col-6">
            <input matInput type="text" placeholder="First Name" formControlName="firstName" autocomplete="off" />
            <mat-error *ngIf="contactFormControls?.firstName?.errors" class="invalid-feedback">
              Name is required
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-lg-6 col-6">
            <input matInput type="text" placeholder="Last Name" formControlName="lastName" autocomplete="off" />
            <mat-error *ngIf="contactFormControls?.lastName?.errors" class="invalid-feedback">
              Last Name is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mt-4 row align-items-center">
          <mat-form-field class="col-lg-12">
            <input matInput type="text" placeholder="phone number" formControlName="phoneNumber" autocomplete="off" />
            <mat-error *ngIf="contactFormControls?.phoneNumber?.errors" class="invalid-feedback">
              Phone number is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="mt-4 row align-items-center">
          <mat-form-field class="col-lg-12">
            <input matInput type="email" placeholder="Email" formControlName="email" autocomplete="off" />
            <mat-error *ngIf="contactFormControls?.email?.errors" class="invalid-feedback">
              Email is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mt-4 row align-items-center">
          <mat-form-field class="col-lg-12">
            <input
              matInput
              type="text"
              formControlName="address"
              #searchField
              [(ngModel)]="autoCompleteInput"
              (addressComponents)="getAddressComponents($event)"
              placeholder="Find Property"
              name="autocompleteInput"
              aria-label="enter address"
              googleAutocomplete
            />
            <span style="color: red" *ngIf="invalidAddress">Address not found</span>
            <mat-error *ngIf="contactFormControls?.address?.errors" class="invalid-feedback">
              Address is required
            </mat-error>
          </mat-form-field>
        </div>

        <div mat-dialog-actions class="mb-0">
          <div class="w-100 text-right">
            <button class="ml-2" mat-flat-button color="primary" type="submit">Save</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

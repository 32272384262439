<div class="alertDialog text-center mb-3">
  <img mat-dialog-title class="mr-auto ml-auto mb-0" width="35" src="~/../assets/images/alert.svg" />
  <h1 mat-dialog-title class="text-warning mb-0">{{ data.title ? data.title : '' }}</h1>
  <div mat-dialog-content class="mt-2">
    <h6>{{ data.message ? data.message : '' }}</h6>
  </div>
  <div mat-dialog-actions *ngIf="data.type !== 'mapZoomControl' && data.type !== 'upgradeInfo'">
    <!--cdkFocusInitial -->
    <div>
      <button mat-flat-button (click)="continueAction()" [pmx-progress-button]="isProceedActionProceeding">
        {{ (data && data.primaryBtnText) || 'Proceed' }}
      </button>
    </div>
    <div class="mr-2">
      <button mat-dialog-close mat-flat-button color="primary" cdkFocusInitial (click)="proceedCancelAction()">
        {{ (data && data.secondaryBtnText) || 'Cancel' }}
      </button>
    </div>
  </div>
  <div *ngIf="data.type === 'mapZoomControl' || data.type === 'upgradeInfo'" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
  </div>
</div>

<div class="alertDialog text-center mb-3" *ngIf="!signupStatus; else signupSuccess">
  <button class="close float-right" mat-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>
  <!-- <img mat-dialog-title class="mr-auto ml-auto mb-0" width="35" src="~/../assets/images/alert.svg"> -->
  <!-- <h1 mat-dialog-title class="text-warning mb-0"> {{ data.title ? data.title : ''}} </h1>
  <div mat-dialog-content class="mt-2">
    <h6> {{ data.message ? data.message : ''}} </h6>
  </div> -->
  <!-- <div *ngIf="data && data.message" mat-dialog-content class="mt-2">
    <h6> {{data.message}} </h6>
  </div> -->
  <div *ngIf="isLoginVisible$ | async" class="log_form_wrap mt-2" id="signin_form">
    <div class="clearfix mx-auto">
      <img [src]="companyLogo" height="50" />

      <p class="my-0" style="color: #6f6f77">Log in to continue</p>
      <div
        class="alert mb-0"
        [ngClass]="{
          'alert-success': loginAlerts.type == 'success',
          'alert-danger': loginAlerts.type == 'error',
          'text-info': loginAlerts.type == 'info'
        }"
        *ngIf="loginAlerts && loginAlerts.type != ''"
      >
        {{ loginAlerts.message }}
      </div>

      <form class="signin_wrap" (submit)="authenticate(loginForm)" #loginForm="ngForm">
        <div class="scrollWrap">
          <div class="row">
            <div class="form-group col-md-10 mx-auto mb-0">
              <mat-form-field class="btn-block" appearance="outline">
                <mat-label for="email">Email </mat-label>
                <input
                  matInput
                  placeholder="Email"
                  name="email"
                  [pattern]="patterns.email.pattern"
                  [(ngModel)]="loginFields.email"
                  #email="ngModel"
                  required
                  aria-label="enter email"
                />
                <mat-error *ngIf="email.model && email.model != '' && !email.valid" class="invalid-feedback">
                  {{ patterns.email.message }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group col-md-10 mx-auto">
              <mat-form-field class="btn-block loginpassword" appearance="outline">
                <mat-label>Password</mat-label>
                <input
                  type="password"
                  matInput
                  placeholder="*****"
                  name="password"
                  [(ngModel)]="loginFields.password"
                  #password="ngModel"
                  required
                  aria-label="enter password"
                />
              </mat-form-field>
              <p class="w-100 text-right mb-0 font-italic">
                <a
                  class="pointer"
                  (click)="
                    isForgotPasswordVisibleSub$.next(true);
                    isLoginVisibleSub$.next(false);
                    isSignUpVisibleSub$.next(false)
                  "
                >
                  Forgot your Password?</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="form-group col-md-10 px-0 mx-auto">
          <button
            name="login"
            aria-label="login"
            mat-flat-button
            class="submit_btn w-100 py-2"
            color="primary"
            type="submit"
            [pmx-progress-button]="isUserAuthenticationProgress"
          >
            Login
          </button>
        </div>
      </form>

      <div class="login_swtch">
        <p class="text-center mb-0" id="fornew">Don't have an account?</p>
        <button
          class="btn text_btn"
          type="button"
          id="log_swtch_btn"
          (click)="
            isForgotPasswordVisibleSub$.next(false); isLoginVisibleSub$.next(false); isSignUpVisibleSub$.next(true)
          "
          name="go to sign up"
          aria-label="go to sign up"
        >
          Sign up
        </button>
      </div>
      <div class="form-group col-md-10 px-0 mx-auto">
        <button
          *ngIf="this.appState?.companyInfoValue?.awsCognitoConfig.enableAppleSignIn"
          class="social-button"
          title="Apple"
          (click)="signupService.logInWithIDP('SignInWithApple')"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="icon">
            <path
              d="M18.52 8.23c-.1.09-2 1.18-2 3.62 0 2.82 2.4 3.82 2.48 3.84-.01.06-.38 1.37-1.27 2.7-.78 1.17-1.6 2.34-2.86 2.34-1.25 0-1.58-.75-3.02-.75-1.41 0-1.91.77-3.06.77-1.15 0-1.95-1.08-2.86-2.41A11.9 11.9 0 0 1 4 12.04C4 8.36 6.33 6.4 8.62 6.4c1.22 0 2.23.82 3 .82.73 0 1.86-.87 3.25-.87.52 0 2.4.05 3.65 1.88zm-4.31-3.45c.57-.7.98-1.67.98-2.65 0-.13-.01-.27-.04-.38-.93.04-2.04.64-2.7 1.44a4.2 4.2 0 0 0-1.02 2.58c0 .15.02.3.03.34.06.01.16.03.25.03.84 0 1.9-.58 2.5-1.36z"
              fill="#000"
            ></path>
          </svg>
          Continue with Apple
        </button>
      </div>
      <div class="form-group col-md-10 px-0 mx-auto">
        <button
          *ngIf="this.appState?.companyInfoValue?.awsCognitoConfig.enableGoogleSignIn"
          class="social-button"
          title="Google"
          (click)="signupService.logInWithIDP('Google')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 256 262"
            width="30"
            height="20"
            id="google"
          >
            <path
              fill="#4285F4"
              d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
            ></path>
            <path
              fill="#34A853"
              d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
            ></path>
            <path
              fill="#FBBC05"
              d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
            ></path>
            <path
              fill="#EB4335"
              d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
            ></path>
          </svg>
          Continue with Google
        </button>
      </div>
      <!-- <p *ngIf="router.url != ('/workspace/map' ) && ('/workspace')" class="text-center mt-2"> <a class="pointer"
          (click)="navigateToPage('workspace')">Explore Properties</a>
      </p> -->
    </div>
  </div>

  <div *ngIf="isSignUpVisible$ | async" class="signup_form_wrap" id="signup_form">
    <div class="clearfix">
      <img [src]="companyLogo" height="50" />
      <!-- <h4 class="mt-1 d-none d-md-block">to our Real Estate Research Portal</h4> -->

      <div class="progressbase w-100">
        <div class="progressbar w-50"></div>
      </div>
      <div id="alerts" *ngIf="!isSignup">
        <div
          class="alert mb-0"
          [ngClass]="{ 'alert-danger': alerts.type == 'error', 'text-info': alerts.type == 'info' }"
          *ngIf="alerts && alerts.type != ''"
        >
          {{ alerts.message }}
        </div>
      </div>
      <form *ngIf="!isSignup" #form="ngForm" (submit)="onUserDetailSubmit()" [formGroup]="signUpForm">
        <div class="scrollWrap">
          <div class="row">
            <div class="form-group col-12 col-md-5 offset-xs-0 offset-md-1 pr-md-2 mb-0">
              <mat-form-field class="btn-block" appearance="outline">
                <mat-label>First Name</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="firstName"
                  id="firstName"
                  pattern="^[a-zA-Z]+$"
                  aria-describedby="firstName"
                  aria-label="Enter First Name"
                  name="enter first name"
                  required
                />
                <mat-error
                  *ngIf="f.firstName.errors && f.firstName.errors.required"
                  class="invalid-feedback ml-2"
                  aria-label="first name is required"
                >
                  First Name is required</mat-error
                >
                <mat-error
                  *ngIf="f.firstName.errors && f.firstName.errors.maxlength"
                  class="invalid-feedback ml-2"
                  aria-label="maximum 25 characters"
                >
                  Maximum 25 characters are allowed</mat-error
                >
                <mat-error
                  *ngIf="f.firstName.errors && f.firstName.errors.pattern"
                  class="invalid-feedback ml-2"
                  aria-label="enter only alphabets"
                >
                  Only alphabets are allowed</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-group col-12 col-md-5 pl-md-2 mb-0">
              <mat-form-field class="btn-block" appearance="outline">
                <mat-label for="lastName">Last Name</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="lastName"
                  id="lastName"
                  aria-describedby="lastName"
                  aria-label="enter last name"
                  name="enter last name"
                />
                <mat-error
                  *ngIf="f.lastName.errors && f.lastName.errors.pattern"
                  class="invalid-feedback ml-2"
                  aria-label="enter only alphabets"
                  >Only alphabets are allowed</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-group col-md-10 mx-auto mb-0">
              <mat-form-field class="btn-block" appearance="outline">
                <mat-label for="email">Email</mat-label>
                <input
                  matInput
                  type="email"
                  formControlName="email"
                  id="email"
                  aria-describedby="email"
                  required
                  aria-label="enter email"
                  name="enter email"
                />
                <mat-error
                  *ngIf="f.email.errors && f.email.errors.required"
                  class="invalid-feedback ml-2"
                  aria-label="email is required"
                  >Email is required</mat-error
                >
                <mat-error
                  *ngIf="f.email.errors && f.email.errors.pattern"
                  class="invalid-feedback ml-2"
                  aria-label="enter valid email"
                  >Email must be a valid email address</mat-error
                >
              </mat-form-field>
            </div>
            <div class="form-group col-md-10 mx-auto mb-0">
              <mat-form-field class="btn-block" appearance="outline">
                <mat-label for="password">Password</mat-label>
                <input
                  matInput
                  formControlName="password"
                  id="password"
                  aria-describedby="password"
                  name="enter password"
                  aria-label="enter password"
                  required
                  [type]="showPassword ? 'text' : 'password'"
                />
                <mat-icon matSuffix (click)="togglePasswordVisibility()" style="cursor: pointer">
                  {{ showPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error
                  *ngIf="f.password.errors && f.password.errors.required"
                  class="invalid-feedback mx-0"
                  aria-label="password is required"
                >
                  Password is required
                </mat-error>
                <mat-error *ngIf="f.password.errors && f.password.errors.minlength" class="invalid-feedback ml-2">
                  Password must be at least 6 characters</mat-error
                >
              </mat-form-field>
            </div>

            <div class="form-group col-md-10 mx-auto mb-0">
              <mat-form-field class="btn-block" appearance="outline">
                <mat-label for="phoneNumber">Phone No.</mat-label>
                <input
                  matInput
                  formControlName="phoneNumber"
                  id="phoneNumber"
                  aria-describedby="phoneNumber"
                  name="enter phone number"
                  aria-label="enter phone number"
                  [required]="required ? '' : null"
                />
                <mat-error
                  *ngIf="f.phoneNumber.errors && f.phoneNumber.errors.required"
                  class="invalid-feedback mx-0"
                  aria-label="phone No. is required"
                >
                  Phone No. is required
                </mat-error>
                <mat-error
                  *ngIf="f.phoneNumber.errors && f.phoneNumber.errors.pattern"
                  class="invalid-feedback mx-0"
                  aria-label="10 digits required"
                >
                  Phone number must be valid
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-10 mx-auto">
              <mat-form-field class="btn-block" appearance="outline">
                <mat-label> Interested Area *</mat-label>
                <mat-chip-list formControlName="interestedAreas" #chipList>
                  <mat-chip
                    *ngFor="let address of interestedAreas; let i = index"
                    [removable]="true"
                    (removed)="deleteAddress(i)"
                  >
                    {{ address.locality + ',' + address.state }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                  </mat-chip>
                  <input
                    #search
                    placeholder="Search a city"
                    formControlName="autoCompleteInput"
                    matInput
                    [matChipInputFor]="chipList"
                    (keydown.enter)="getPlaceFromGeocoder()"
                    name="autocompleteInput"
                    required
                    aria-label="enter interested area"
                  />
                </mat-chip-list>
                <mat-error
                  class="invalid-feedback ml-2"
                  *ngIf="f.interestedAreas.errors && f.interestedAreas.errors.message"
                >
                  {{ f.interestedAreas.errors.message }}</mat-error
                >
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="form-group col-md-10 mx-auto px-0">
          <button
            mat-flat-button
            class="submit_btn w-100 py-2"
            type="submit"
            name="submit"
            aria-label="submit"
            color="primary"
            [pmx-progress-button]="isSignUpInProgress"
          >
            Sign Up
          </button>
        </div>
      </form>

      <div class="login_swtch">
        <p class="text-center mb-0" id="fornew">Already have an account?</p>
        <button
          class="btn text_btn"
          type="button"
          id="log_swtch_btn"
          (click)="
            isForgotPasswordVisibleSub$.next(false); isLoginVisibleSub$.next(true); isSignUpVisibleSub$.next(false)
          "
          name="go to sign in"
          aria-label="go to sign in"
        >
          Log in
        </button>
      </div>
      <div class="form-group col-md-10 mx-auto px-0">
        <button
          *ngIf="this.appState?.companyInfoValue?.awsCognitoConfig.enableAppleSignIn"
          class="social-button"
          title="Apple"
          (click)="signupService.logInWithIDP('SignInWithApple')"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" class="icon">
            <path
              d="M18.52 8.23c-.1.09-2 1.18-2 3.62 0 2.82 2.4 3.82 2.48 3.84-.01.06-.38 1.37-1.27 2.7-.78 1.17-1.6 2.34-2.86 2.34-1.25 0-1.58-.75-3.02-.75-1.41 0-1.91.77-3.06.77-1.15 0-1.95-1.08-2.86-2.41A11.9 11.9 0 0 1 4 12.04C4 8.36 6.33 6.4 8.62 6.4c1.22 0 2.23.82 3 .82.73 0 1.86-.87 3.25-.87.52 0 2.4.05 3.65 1.88zm-4.31-3.45c.57-.7.98-1.67.98-2.65 0-.13-.01-.27-.04-.38-.93.04-2.04.64-2.7 1.44a4.2 4.2 0 0 0-1.02 2.58c0 .15.02.3.03.34.06.01.16.03.25.03.84 0 1.9-.58 2.5-1.36z"
              fill="#000"
            ></path>
          </svg>
          Continue with Apple
        </button>
      </div>
      <div class="form-group col-md-10 px-0 mx-auto">
        <button
          *ngIf="this.appState?.companyInfoValue?.awsCognitoConfig.enableGoogleSignIn"
          class="social-button"
          title="Google"
          (click)="signupService.logInWithIDP('Google')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid"
            viewBox="0 0 256 262"
            width="30"
            height="20"
            id="google"
          >
            <path
              fill="#4285F4"
              d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
            ></path>
            <path
              fill="#34A853"
              d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
            ></path>
            <path
              fill="#FBBC05"
              d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
            ></path>
            <path
              fill="#EB4335"
              d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
            ></path>
          </svg>
          Continue with Google
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isForgotPasswordVisible$ | async" class="signup_form_wrap" id="signup_form">
    <div class="clearfix">
      <div class="row">
        <div class="col-lg-12 text-center">
          <div class="ver_wrap">
            <!-- <img [src]="(appState.companyInfo$ | async).logo.dark" style="width: 200px;" class="mb-3"> -->
            <div class="w-100">
              <div class="clearfix text-center">
                <div class="ver_base">
                  <img [src]="companyLogo" height="50" />
                  <small class="text-muted" *ngIf="!resetFlag" [hidden]="resetPasswordAlerts.message"
                    >Password reset link will be sent to your email</small
                  >
                  <div
                    class="alert"
                    [ngClass]="{
                      'alert-success': resetPasswordAlerts.type == 'success',
                      'alert-danger': resetPasswordAlerts.type == 'danger',
                      'text-info pl-0': resetPasswordAlerts.type == 'info'
                    }"
                    *ngIf="resetPasswordAlerts && resetPasswordAlerts.type != ''"
                  >
                    {{ resetPasswordAlerts.message }}
                  </div>
                  <form
                    class="mt-3"
                    *ngIf="!resetFlag"
                    (ngSubmit)="sendPasswordResetLink(forgotPasswordField.email)"
                    #forgotPasswordForm="ngForm"
                  >
                    <div class="scrollWrap">
                      <div class="row">
                        <div class="form-group col-md-10 mx-auto mb-0">
                          <mat-form-field class="btn-block" appearance="outline">
                            <mat-label for="email"> Email </mat-label>
                            <input
                              matInput
                              placeholder="Enter your email"
                              name="email"
                              [pattern]="patterns.email.pattern"
                              [(ngModel)]="forgotPasswordField.email"
                              #email="ngModel"
                              required
                            />
                            <mat-error *ngIf="email.errors && email.errors.required" class="invalid-feedback ml-2">
                              Email is required
                            </mat-error>
                            <mat-error *ngIf="email.errors && email.errors.pattern" class="invalid-feedback ml-2">
                              {{ patterns.email.message }}</mat-error
                            >
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-md-10 mx-auto px-0">
                      <button
                        mat-flat-button
                        color="primary"
                        class="submit_btn w-100 btn-lg"
                        type="submit"
                        [pmx-progress-button]="resetLinkInprogress"
                      >
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
                <p class="col-md-12 mt-3" *ngIf="!resetLinkInprogress">
                  &nbsp; Remember your password?
                  <a
                    class="pointer"
                    (click)="
                      isForgotPasswordVisibleSub$.next(false);
                      isLoginVisibleSub$.next(true);
                      isSignUpVisibleSub$.next(false)
                    "
                    >Log in</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #signupSuccess>
  <button class="close float-right" mat-button (click)="onNoClick()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="signup-success">
    <img src="../../../assets/images/mail.svg" alt="Sign up success" />
    <h3>Sign Up Completed</h3>
    <h4>Just one more step.</h4>
    <p class="signup-info">
      We have sent you an activation email.<br />
      Please click on the link in the email to verify your account to access all the features.
    </p>
    <p class="signup-warning">Please check your spam, just in case.</p>
  </div>
</ng-template>

import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PropertyState } from 'src/app/workspace/state/property.state';
import { environment } from 'src/environments/environment';
import { PropertyService } from '../../service/property.service';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss'],
})
export class PropertyCardComponent implements OnInit, OnChanges {
  @Input() actions: any;
  @Input() isSelectable: any;
  @Input() isSelected: any;
  @Input() property: any;
  @Input() detailsRequired: any;

  @Input() type?: 'watchlist' | 'basic' = 'basic';

  @Output() toggleFavorite = new EventEmitter<string>();
  @Output() triggeredActionCopy = new EventEmitter<any>();
  @Output() triggeredActionMove = new EventEmitter<any>();
  @Output() triggeredActionDelete = new EventEmitter<any>();
  @Output() toggleSelectAll = new EventEmitter<any>();
  @Output() toggleSelectCard = new EventEmitter<any>();

  listingImageBaseUrl = environment.listingImageBaseUrl;
  notificationList: Array<any>;
  selected: any;
  selection = new SelectionModel<any>(true, []);
  showCheckbox: boolean = false;

  constructor(private propertyState: PropertyState, private propertyService: PropertyService) {}

  ngOnInit(): void {
    // if (this.PropertyType) {
    //   for (const data in this.propertyDetails) {
    //     if (data === 'Address') {
    //       this.propertyDetails.address = this.propertyDetails['Address'];
    //       delete this.propertyDetails['Address'];
    //     }
    //     if (data === 'BedroomsTotal') {
    //       this.propertyDetails.bedroomsTotal = this.propertyDetails['BedroomsTotal'];
    //       delete this.propertyDetails['BedroomsTotal'];
    //     }
    //     if (data === 'BathroomsTotalInteger') {
    //       this.propertyDetails.bathroomsTotalInteger = this.propertyDetails['BathroomsTotalInteger'];
    //       delete this.propertyDetails['BathroomsTotalInteger'];
    //     }
    //     if (data === 'LivingArea') {
    //       this.propertyDetails.livingArea = this.propertyDetails['LivingArea'];
    //       delete this.propertyDetails['LivingArea'];
    //     }
    //     if (data === 'LotSizeSquareFeet') {
    //       this.propertyDetails.lotSizeSquareFeet = this.propertyDetails['LotSizeSquareFeet'];
    //       delete this.propertyDetails['LotSizeSquareFeet'];
    //     }
    //     if (data === 'CurrSalesPrice') {
    //       this.propertyDetails.lstListPrice = this.propertyDetails['CurrSalesPrice'];
    //       delete this.propertyDetails['CurrSalesPrice'];
    //     }
    //   }
    //   this.property = this.propertyDetails;
    // }
  }

  followProperty(property) {
    this.toggleFavorite.emit(property);
  }

  openPropertyDetails(property) {
    if (this.detailsRequired == false) {
      return;
    }
    property.logInput = true;
    property.PMXPropertyId = property.PMXPropertyId ? property.PMXPropertyId : property.pmxPropertyId;
    property.Lst_SI_ListingId = property.Lst_SI_ListingId ? property.Lst_SI_ListingId : property.siListingId;
    this.propertyState.propertyDetailsSheetInput = property;
  }
  selectAll() {
    this.toggleSelectAll.emit();
  }

  toggleSelect(property, value) {
    this.selected = {
      id: property.id,
      selected: value,
    };
    this.toggleSelectCard.emit(this.selected);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.property?.currentValue != changes?.property?.previousValue) {
      let propertyValue = changes?.property?.currentValue.notificationList;
      const newArr = propertyValue?.sort(
        (a, b) => new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf()
      );
      this.notificationList = [...new Map(newArr?.map((item) => [item['referenceType'], item])).values()];

      let propertyFilterClassParams = {
        Lst_specialListingConditions: changes?.property?.currentValue?.lstSpecialListingConditions,
        Lst_StandardStatus: changes?.property?.currentValue?.lstStandardStatus,
        Fcl_Status: changes?.property?.currentValue?.fclStatus,
        Fcl_EndDate: changes?.property?.currentValue?.fclEndDate,
      };
      let filterStyleClassFromList = this.propertyService.getPropertyStatus(propertyFilterClassParams);
      this.property.filterStyleClass = filterStyleClassFromList;
    }
  }

  actionTrigger(action) {
    if (action == 'copy') {
      this.triggeredActionCopy.emit(action);
    } else if (action == 'move') {
      this.triggeredActionMove.emit(action);
    } else if (action == 'delete') {
      this.triggeredActionDelete.emit(action);
    }
  }
}

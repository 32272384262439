<form class="select-search-form" [formGroup]="selectSearchForm">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <mat-select
      multiple="{{ multiple }}"
      formControlName="selectCtrl"
      [panelClass]="['select-search-dropdown-panel', panelClass]"
      [disableOptionCentering]="true"
      (click)="isHideSearch == 'show' ? '' : reset()"
      (closed)="isFilterExists ? reset() : ''"
      [value]="multiple == false ? selectedValue : []"
    >
      <mat-select-trigger *ngIf="multiple == false">{{ selectedValue?.displayText }}</mat-select-trigger>
      <mat-select-trigger *ngIf="multiple == true">
        <ng-container
          *ngIf="
            selectedOptions$.value?.length && selectedOptions$.value?.length < options.length;
            else allSelectTrigger
          "
        >
          {{ selectedOptions$.value[0]?.displayText || '' }}
          <span *ngIf="selectedOptions$.value?.length > 1">
            (+{{ selectedOptions$.value?.length - 1 }} {{ selectedOptions$.value?.length == 2 ? 'other' : 'others' }})
          </span>
        </ng-container>
        <ng-template #allSelectTrigger>
          <ng-container *ngIf="selectedOptions$.value?.length; else noSelectTrigger"> All </ng-container>
        </ng-template>
        <ng-template #noSelectTrigger>
          {{ label }}
        </ng-template>
      </mat-select-trigger>
      <!-- <cdk-virtual-scroll-viewport appendOnly itemSize="40" [style.height.px]="256"> -->
      <div *ngIf="canSearch" class="search-option">
        <mat-icon class="search-icon" matPrefix aria-label="Search Icon">search</mat-icon>
        <input
          matInput
          type="text"
          class="search-input"
          tabindex="0"
          placeholder="Search"
          autocomplete="off"
          [formControlName]="'searchCtrl'"
          (keydown)="$event.stopPropagation()"
        />
        <button
          *ngIf="searchCtrl.value"
          matSuffix
          mat-icon-button
          class="btn-clear"
          aria-label="Clear"
          (click)="reset()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="filteredOptions$ | async as filteredOptions">
        <mat-option
          #allOption
          *ngIf="multiple && canSelectAll && !searchCtrl.value && !isFilterExists"
          [value]="allOptionValue"
          (click)="toggleAllSelection()"
        >
          {{ allOptionValue.displayText }}
        </mat-option>
        <mat-option *ngFor="let option of filteredOptions" [value]="option" (click)="toggleOneSelection()">
          {{ option.displayText }}
        </mat-option>
        <app-progress-indicator
          *ngIf="!lazyLoadComplete"
          [size]="20"
          [inline]="true"
          class="mb-2"
        ></app-progress-indicator>
        <span *ngIf="!filteredOptions.length && lazyLoadComplete" class="no-results"> Not Found </span>
      </ng-container>
      <!-- </cdk-virtual-scroll-viewport> -->
    </mat-select>
    <mat-error *ngIf="selectCtrl.errors?.required"> {{ label }} required </mat-error>
    <!-- <mat-hint class="mat-error" *ngIf="required && !selectedOptions$.value.length"> {{ label }} required </mat-hint> -->
  </mat-form-field>
</form>

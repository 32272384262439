import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AgentContactState {
  private contactAgentRequestActionStatusSub$ = new BehaviorSubject<boolean>(false);
  contactAgentRequestActionStatus$ = this.contactAgentRequestActionStatusSub$.asObservable();

  public get contactAgentRequestActionStatusValue(): boolean {
    return this.contactAgentRequestActionStatusSub$.value;
  }

  public set contactAgentRequestActionStatusValue(status: boolean) {
    this.contactAgentRequestActionStatusSub$.next(status);
  }

  private propertyClientDataSub$ = new BehaviorSubject<any>({});
  propertyClientData$ = this.propertyClientDataSub$.asObservable();

  public get propertyClientDataValue(): object {
    return this.propertyClientDataSub$.value;
  }

  public set propertyClientDataValue(data: object) {
    this.propertyClientDataSub$.next(data);
  }

  private loanApplicationUrlRequestStatusSub$ = new BehaviorSubject<boolean>(false);
  loanApplicationUrlRequestStatus$ = this.loanApplicationUrlRequestStatusSub$.asObservable();

  public get loanApplicationUrlRequestStatusValue(): boolean {
    return this.loanApplicationUrlRequestStatusSub$.value;
  }

  public set loanApplicationUrlRequestStatusValue(status: boolean) {
    this.loanApplicationUrlRequestStatusSub$.next(status);
  }

  private loanApplicationUrlSub$ = new BehaviorSubject<any>({});
  loanApplicationUrl$ = this.loanApplicationUrlSub$.asObservable();

  public get loanApplicationUrlValue(): object {
    return this.loanApplicationUrlSub$.value;
  }

  public set loanApplicationUrlValue(data: object) {
    this.loanApplicationUrlSub$.next(data);
  }

  private loanApplicationUrlRequestFormStatusSub$ = new BehaviorSubject<boolean>(false);
  loanApplicationUrlRequestFormStatus$ = this.loanApplicationUrlRequestFormStatusSub$.asObservable();

  public get loanApplicationUrlRequestFormStatusValue(): boolean {
    return this.loanApplicationUrlRequestFormStatusSub$.value;
  }

  public set loanApplicationUrlRequestFormStatusValue(status: boolean) {
    this.loanApplicationUrlRequestFormStatusSub$.next(status);
  }
}

import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EnvType } from '@prospektr/pktr-app-menu';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import packageInfo from '../../package.json';
import { AuthorizeService } from './_core/access-control/authorize.service';
import { FilterService } from './_shared/service/filter.service';
import { DealAnalysisState } from './_shared/state/deal-analysis.state';
import { AppState } from './app.state';
import { PropertyState } from './workspace/state/property.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  env = environment.env as EnvType;
  appVersion = packageInfo.version;
  sheetActive = false;
  canViewPropertyDetailsSheet: boolean = false;
  dealAnalysisIdValue;
  dealAnalysisPropertyIdValue;

  constructor(
    private dealAnalysisState: DealAnalysisState,
    public appState: AppState,
    private router: Router,
    private filterService: FilterService,
    public authorizeService: AuthorizeService,
    public propertyState: PropertyState
  ) {
    this.appState.companyInfo$.subscribe((res: any) => {
      let companyInfo = res;
      if (companyInfo?.googleTagManagerId) {
        let scriptSrc = 'https://www.googletagmanager.com/gtm.js?id=' + companyInfo.googleTagManagerId;
        var firstScriptElement = window.document.getElementsByTagName('script')[0];
        let scriptElement = window.document.createElement('script');
        scriptElement.async = true;
        scriptElement.src = scriptSrc;
        firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
        this.filterService.dataLayerFilterValue('gtm.js', null);
      }
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.filterService.dataLayerFilterValue('navigation', { path: event.urlAfterRedirects }); // added to trigger the custom  navigation event
    });
  }

  ngOnInit() {
    this.dealAnalysisState.$openDealAnalysisSheet.subscribe((res) => {
      this.sheetActive = res;
    });
    this.dealAnalysisState.dealAnalysisPropertyId$.subscribe((res) => {
      this.dealAnalysisPropertyIdValue = res;
    });
    this.dealAnalysisState.dealAnalysisId$.subscribe((res) => {
      this.dealAnalysisIdValue = res;
    });
  }
}

<div class="alertDialog">
  <div class="text-center btn-block mb-2">
    <img src="~/../../assets/images/mail.svg" width="70" class="img-fluid" />
  </div>
  <div mat-dialog-content>
    <p class="body-text">To access this feature please verify your account with the email sent to your email id.</p>
  </div>
  <div mat-dialog-actions>
    <div class="text-center btn-block">
      <button
        mat-flat-button
        mat-dialog-close
        color="primary"
        class="btn-block btn-lg"
        cdkFocusInitial
        (click)="navigateToPage()"
      >
        OK
      </button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { ProspektrApi } from '../api/prospektr.api';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private router: Router,
    private appState: AppState,
    private prospektrApi: ProspektrApi,
    private snackBar: MatSnackBar
  ) { }

  signInWithSocialMedia(code: string) {
    this.appState.setAppLoadingState('socialLogin', true);
    let appleSignInParams = { type: 'SignInWithApple', code };
    this.router.navigate([], {
      queryParams: {},
      replaceUrl: true,
      state: { skipUpdate: true },
    });
    this.prospektrApi.socialMediaSignIn(appleSignInParams).subscribe(
      () => {
        this.appState.setAppLoadingState('socialLogin', false);
      },
      () => {
        this.snackBar.open('Session expired please login again', '', {
          duration: 3000,
          panelClass: ['snackbar-error'],
          verticalPosition: 'top',
        });
      }
    );
  }
}

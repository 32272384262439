import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppState } from 'src/app/app.state';
import { PropertyService } from 'src/app/workspace/service/property.service';
import { PropertyState } from 'src/app/workspace/state/property.state';
import { PropertySheetFacade } from '../property-sheet.facade';

@Component({
  selector: 'app-listing-info',
  templateUrl: './listing-info.component.html',
  styleUrls: ['./listing-info.component.scss'],
})
export class ListingInfoComponent implements OnInit {
  constructor(
    public propertySheetFacade: PropertySheetFacade,
    private propertyState: PropertyState,
    private propertyService: PropertyService,
    private appState: AppState
  ) {}

  activeProperty: any = {};
  test = false;
  activeProperty$;
  listingDetails$;
  mlsImage$;
  mlsLogo: any = null;
  listingData;
  mlsBoard: string;
  url: string;
  disclaimer: string = null;
  text: string;
  RawMlsStatus: any;
  showMore = false;
  mlsDisclaimer: string = '';

  ngOnInit() {
    this.listingDetails$ = this.propertyState.activePropertyDetails$.subscribe((res: any) => {
      if (res.listing == null) {
        return;
      }

      this.mlsDisclaimer = this.generateDisclaimer(this.appState.companyInfoValue?.mlsDisclaimer, {
        mlsName: this.listingData?.OriginatingSystemName,
      });

      this.listingData = res.listing ? res.listing : {};
      if (this.listingData && this.listingData.MlsBoard) {
        this.propertyService.getMlsImageInfo(this.listingData.MlsBoard);
        this.RawMlsStatus = this.listingData.RawMlsStatus ? this.listingData.RawMlsStatus : '';
        // this.listingData.MlsBoard='MREIS';
        // this.listingData.MlsStatus='Withdrawn';
      }
    });
    this.propertyState.mlsImage$.subscribe((res: any) => {
      this.mlsLogo = res.logoUrl || '';
      this.disclaimer = res.disclaimer || '';
    });
  }

  onShow() {
    this.showMore = !this.showMore;
  }

  calculateDays(listingData) {
    if (
      listingData.StandardStatus === 'Active' ||
      listingData.StandardStatus === 'Active Under Contract' ||
      listingData.StandardStatus === 'Pending'
    ) {
      return listingData.ListingContractDate ? moment().diff(listingData.ListingContractDate, 'days') + ' days' : '--';
    } else {
      return '--';
    }
  }

  ngOnDestroy() {
    this.listingDetails$.unsubscribe();
  }

  private generateDisclaimer(template: string, { mlsName }): string {
    template = template.replace('<#copyYear#>', new Date().getFullYear().toString());
    template = template.replace('<#mlsName#>', mlsName);
    return template;
  }
}

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import constants from 'src/constant.json';
import { ProspektrApi } from '../_shared/api/prospektr.api';
import { AppState } from '../app.state';
import { VerifyLoginService } from '../verify-login/verify-login.service';

type IDPType = 'Google' | 'SignInWithApple';

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private prospektrApi: ProspektrApi,
    private verifyLogInService: VerifyLoginService,
    private appState: AppState
  ) { }

  createUser(signUpData: any): Observable<any> {
    if (!signUpData.source) {
      signUpData.source = constants.SIGN_UP.source.externalSource;
    }

    const appQueryParams = localStorage.getItem('appQueryParams');
    if (appQueryParams) {
      signUpData['meta'] = JSON.parse(appQueryParams);
    }

    return this.prospektrApi.createUser(signUpData).pipe(
      tap((res) => {
        if (res && res.data && res.data.userId) {
          this.verifyLogInService.verifyLogin().subscribe();
        }
      })
    );
  }

  signUpContinue(signUpData: any): Observable<any> {
    let response = null;
    return new Observable((observer) => {
      observer.next({ inProgress: true });
      this.prospektrApi
        .signUpContinue(signUpData)
        .pipe(
          finalize(() => {
            observer.next({ inProgress: false, response });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            response = res;
          },
          (error) => {
            response = error.error;
          }
        );
    });
  }
  logInWithIDP(idp: IDPType) {
    let redirectionUrl = `${this.appState?.companyInfoValue?.awsCognitoConfig.userPoolDomainUrl}/oauth2/authorize?client_id=${this.appState?.companyInfoValue?.awsCognitoConfig.clientId}&redirect_uri=${this.appState?.companyInfoValue?.awsCognitoConfig.redirectUrl}&response_type=code&identity_provider=${idp}`;
    this.document.location.href = redirectionUrl;
  }
}

<div class="row pt-0" style="padding-left: 300px; padding-bottom: 0px; padding-top: 5px">
  <button
    class="pull-right close-summary px-0 py-0 mx-auto close"
    style="min-width: unset"
    (click)="close()"
    mat-button
  >
    <mat-icon inline>close</mat-icon>
  </button>
</div>
<mat-progress-bar class="mt-4" mode="determinate" [value]="(questionObj.activeStage / questionObj.totalStage) * 100">
</mat-progress-bar>
<div *ngIf="questionObj.activeStage < questionObj.totalStage; else qnaSuccess" class="qna-form">
  <form [formGroup]="qnaForm">
    <h5 class="my-2 text-center">{{ questionObj.question }}</h5>
    <div class="option-group">
      <button
        mat-button
        type="button"
        class="option"
        [ngClass]="{ active: selectedAnswer && option == selectedAnswer.response }"
        *ngFor="let option of optionKeyList"
        [value]="option"
        (click)="onAnswerSubmit(questionObj, option)"
      >
        <div *ngIf="subType == 'currency'; else noType">
          ${{ questionObj.options[option] | toNumber | number: '1.0-0' }}
        </div>

        <ng-template #noType>{{ questionObj.options[option] }}</ng-template>
      </button>
    </div>
    <!-- <div class="form-group col-md-12 mt-5 text-center" *ngIf="questionObj.activeStage/questionObj.totalStage*100">
      <button mat-button class="continue-back" type="button" (click)="goToPreviousState()">
        <span class="mr-2">&#8592;</span>Back
      </button>
    </div> -->
  </form>
  <div class="progress-loader" *ngIf="inProgress">
    <mat-spinner [diameter]="30" class="ml-1 mr-3"></mat-spinner>
  </div>
</div>
<ng-template #qnaSuccess>
  <div class="qna-success">
    <h3 *ngIf="status; else messageType"><img src="../../../assets/images/success.svg" /><br />{{ message }}</h3>
    <ng-template #messageType>
      <h5><span>&#9888;</span><br />{{ message }}</h5>
    </ng-template>
  </div>
</ng-template>

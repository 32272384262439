import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { PublicRecordApi } from 'src/app/_shared/api/public-record.api';
import { BarChartInput } from 'src/app/_shared/interface/chart.interface';
import { ProspektrApi } from '../../_shared/api/prospektr.api';
import { MyHomeState } from '../state/my-home.state';
import { LoanComputationService } from './loan-computation.service';

@Injectable({
  providedIn: 'root',
})
export class MyHomeService {
  verificationSub: Subscription;
  verificationObservable;

  constructor(
    private myHomeState: MyHomeState,
    private prospektrApi: ProspektrApi,
    private snackBar: MatSnackBar,
    private loanComputationService: LoanComputationService,
    private publicRecordApi: PublicRecordApi,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.initSubscription();
  }

  initSubscription() {
    this.saveMyHomeState();
  }

  saveMyHomeState() {
    this.myHomeState.homeListUpdate$.pipe(debounceTime(5000)).subscribe((homeList) => {
      if (homeList.length) {
        this.updateMyHomeMortgageValues([homeList])
          .pipe()
          .subscribe((res) => {});
      }
    });
  }

  saveHome(params): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true, addressValidation: true });
      this.prospektrApi
        .saveHome(params)
        .pipe(
          finalize(() => {
            // observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (
              res &&
              res.message &&
              (res.message === 'Property not found' || res.message === 'Property exists in My Property')
            ) {
              this.openSnackBar(res.message, 'snackbar-warning');
              return observer.next({ isLoading: false, addressValidation: false });
            } else {
              if (res) {
                if (res.data && res.status && res.status.scoreStatus) {
                  const homeList: any = this.setInitialProjection(res.data);
                  this.myHomeState.mortgageListValue = homeList.mortgageList;
                  this.myHomeState.activeHomeValue = homeList;
                  let queryParam = { id: res.data.id };
                  this.router.navigate(['/my-property'], {
                    relativeTo: this.activatedRoute,
                    queryParams: queryParam,
                  });
                  this.getHomeList().subscribe((res) => {});
                  return observer.next({
                    status: res.status.scoreStatus,
                    message: res.message,
                    isLoading: false,
                    addressValidation: true,
                  });
                }
                if (res.data == null && res.status && res.status.scoreStatus == false) {
                  return observer.next({
                    status: res.status.scoreStatus,
                    message: res.message,
                    isLoading: false,
                    addressValidation: true,
                  });
                } else {
                  return observer.next({ data: res.data, isLoading: false, addressValidation: true });
                }

                // this.setActiveHome(res.data);
              }

              this.getHomeList().subscribe((res) => {});
              this.openSnackBar(res.message, 'snackbar-success');
            }
          },
          (err) => {
            this.openSnackBar(
              err && err.error && err.error.message ? err.error.message : 'Failed to add to My Property',
              'snackbar-error'
            );
            return observer.next({ isLoading: false, addressValidation: false });
          }
        );
    });
  }

  getHomeList(): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getHomeList()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.data && res.data.length) {
              const homeList = [];
              res.data.forEach((home) => {
                homeList.push(this.setInitialProjection(home));
              });
              this.myHomeState.myHomeListValue = homeList;
            } else {
              this.myHomeState.myHomeListValue = [];
            }
          },
          (err) => {
            this.myHomeState.myHomeListValue = [];
          }
        );
    });
  }

  getPropertyValues(params): Observable<any> {
    return new Observable((observer) => {
      this.prospektrApi
        .getPropertyValues(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.data) {
              let isValueExists = this.myHomeState.activeHomePropertyValue.find(
                (prop) => prop.pmxPropertyId == params.pmxPropertyId
              );
              let propertyValues = this.myHomeState.activeHomePropertyValue;
              if (!isValueExists) propertyValues.push(res.data);
              this.myHomeState.activeHomePropertyValue = propertyValues;
            }
            this.myHomeState.activeHomePropertyLoaderValue = false;
          },
          (err) => {
            this.myHomeState.activeHomePropertyLoaderValue = false;
          }
        );
    });
  }

  setInitialProjection(home) {
    if (home && home.mortgageList && home.mortgageList.length) {
      const mortgageList = home.mortgageList.map((mortgage) => {
        let loanComputation = this.loanComputationService.computeTotalLoan(
          mortgage.principal,
          mortgage.interest / 100,
          mortgage.term,
          new Date(mortgage.startDate)
        );
        mortgage = { ...mortgage, ...loanComputation };
        mortgage.activeRefinance = this.loanComputationService.computeBalanceLoan(
          loanComputation.principalBalance,
          mortgage.interest / 100,
          15 * 12
        );
        return mortgage;
      });
      home.mortgageList = mortgageList;
    }
    return home;
    // this.myHomeState.activeHomeValue = home;
  }

  getCompoundLoanStats(mortgages: Array<any>): BarChartInput {
    let currentTotalPrincipal = 0;
    let currentPrincipalPaid = 0;
    let currentPrincipalBalance = 0;
    let currentInterestPaid = 0;
    let currentTotalLoan = 0;
    let projectedTotalLoan = 0;
    let currentTotalBalance = 0;
    let projectedTotalBalance = 0;
    let currentInterestBalance = 0;
    let projectedInterestBalance = 0;
    let currentPaymentMonthly = 0;
    let projectedPaymentMonthly = 0;

    mortgages.forEach((mortgage) => {
      currentTotalPrincipal += mortgage.principalPaid + mortgage.principalBalance;
      currentPrincipalPaid += mortgage.principalPaid;
      currentPrincipalBalance += mortgage.principalBalance;
      currentInterestPaid += mortgage.interestPaid;
      currentTotalLoan += mortgage.totalBalance + mortgage.totalPaid;
      projectedTotalLoan += mortgage.totalPaid + mortgage.activeRefinance.totalBalance;
      currentTotalBalance += mortgage.totalBalance;
      projectedTotalBalance += mortgage.activeRefinance.totalBalance;
      currentInterestBalance += mortgage.interestBalance;
      projectedInterestBalance += mortgage.activeRefinance.interestBalance;
      currentPaymentMonthly += mortgage.paymentMonthly;
      projectedPaymentMonthly += mortgage.activeRefinance.paymentMonthly;
    });
    const loanStats = {
      categories: [
        'Total Principal',
        'Principal Paid',
        'Principal Balance',
        'Interest Paid',
        'Total Loan',
        'Total Balance',
        'Interest Balance',
        'Payment Monthly',
      ],
      values: [
        {
          name: 'Current',
          data: [
            currentTotalPrincipal,
            currentPrincipalPaid,
            currentPrincipalBalance,
            currentInterestPaid,
            currentTotalLoan,
            currentTotalBalance,
            currentInterestBalance,
            currentPaymentMonthly,
          ],
        },
        {
          name: 'Projected',
          data: [
            null,
            null,
            null,
            null,
            projectedTotalLoan,
            projectedTotalBalance,
            projectedInterestBalance,
            projectedPaymentMonthly,
          ],
          color: '#3f51b5',
        },
      ],
    };
    // console.log('loanStats : ', loanStats);
    return loanStats;
  }

  updateMyHomeValuesInState(editedHome) {
    let homeList = this.myHomeState.myHomeListValue;
    let existingHomeIndex = homeList.findIndex((home) => home.PMXPropertyId == editedHome.PMXPropertyId);
    if (existingHomeIndex != -1) {
      if (editedHome.hasOwnProperty('checked')) delete editedHome.checked;
      if (editedHome.hasOwnProperty('refinance')) delete editedHome.refinance;
      if (editedHome.hasOwnProperty('lender')) delete editedHome.lender;
      if (editedHome.hasOwnProperty('principal')) delete editedHome.principal;
      if (editedHome.hasOwnProperty('interest')) delete editedHome.interest;
      if (editedHome.hasOwnProperty('term')) delete editedHome.term;
      if (editedHome.hasOwnProperty('startDate')) delete editedHome.startDate;
      if (editedHome.hasOwnProperty('monthlyPayment')) delete editedHome.monthlyPayment;
      if (editedHome.hasOwnProperty('createType')) delete editedHome.createType;
      if (editedHome.hasOwnProperty('extraPayment')) delete editedHome.extraPayment;
      if (editedHome.hasOwnProperty('updatedDate')) delete editedHome.updatedDate;
      if (editedHome.hasOwnProperty('balancePrincipal')) delete editedHome.balancePrincipal;
      homeList.splice(existingHomeIndex, 1, editedHome);
      // homeList.map((home) => {
      //   this.setInitialProjection(home);
      // });
      this.myHomeState.myHomeListValue = homeList;
    }
  }

  updateMyHomeValues(params, loadNewData?): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .updateMyHome(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (params && params[0]) {
              if (params[0].mortgageList && params[0].mortgageList.length) {
                if (loadNewData) {
                  this.myHomeState.selectedMortgageIndexValue = params[0].mortgageList.length - 1;
                }
                this.myHomeState.mortgageListValue = params[0].mortgageList;
              }
            }
            this.getHomeList().subscribe((res) => {});
          },
          (err) => {
            this.openSnackBar(
              err && err.error && err.error.message ? err.error.message : 'Failed to update My Property',
              'snackbar-error'
            );
          }
        );
    });
  }

  deleteMyHomeProperty(homeId): Observable<any> {
    return new Observable((observer) => {
      this.prospektrApi
        .deleteMyHome(homeId)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (res && res.message && res.message === 'Property not found') {
              this.openSnackBar(res.message, 'snackbar-warning');
            } else {
              let newHomeValueList = this.myHomeState.myHomeListValue.filter((homeValue) => {
                return homeValue.id != homeId;
              });
              this.myHomeState.myHomeListValue = newHomeValueList;
              if (newHomeValueList && newHomeValueList[0]?.id) {
                this.router.navigate([], {
                  relativeTo: this.activatedRoute,
                  queryParams: { id: newHomeValueList[0]?.id || null },
                  queryParamsHandling: 'merge',
                });
                this.myHomeState.mortgageListValue = newHomeValueList[0]?.mortgageList
                  ? newHomeValueList[0]?.mortgageList
                  : [];
                this.myHomeState.activeHomeValue = newHomeValueList[0];
              }
              this.openSnackBar(res.message, 'snackbar-success');
              observer.next();
            }
          },
          (err) => {
            this.openSnackBar(err.error.message, 'snackbar-error');
          }
        );
    });
  }

  contactAgent(params): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .contactAgent(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (response: any) => {
            this.openSnackBar(response.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(err.error.message, 'snackbar-error');
          }
        );
    });
  }

  contactAgentInfo(): Observable<any> {
    return new Observable((observer) => {
      let result = {};
      observer.next({ isLoading: true });
      this.prospektrApi
        .contactAgentInfo()
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false, result: result });
            observer.complete();
          })
        )
        .subscribe(
          (response: any) => {
            if (response?.data) {
              result = response.data;
            }
          },
          (err) => {
            this.openSnackBar(err.error.message, 'snackbar-error');
          }
        );
    });
  }

  notifyContactAgent(params): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .notifyContactAgent(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (response: any) => {
            this.openSnackBar(response.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar(err.error.message, 'snackbar-error');
          }
        );
    });
  }

  contactLender(params): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .contactLender(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (response: any) => {
            if (response.message) {
              this.openSnackBar(response.message, 'snackbar-success');
            }
            if (response.data) {
              window.open(response.data, '_blank');
            }
          },
          (err) => {
            this.openSnackBar(err.error.message, 'snackbar-error');
          }
        );
    });
  }

  deleteMortgage(id): Observable<any> {
    return new Observable((observer) => {
      this.prospektrApi
        .deleteMortgage(id)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (response: any) => {
            let newMortgageList = this.myHomeState.mortgageListValue.filter((mortgage) => {
              return mortgage.id != id;
            });
            this.myHomeState.mortgageListValue = newMortgageList;
            const activeHome: any = this.myHomeState.activeHomeValue;
            activeHome.mortgageList = newMortgageList;
            this.myHomeState.activeHomeValue = this.setInitialProjection(activeHome);
            if (newMortgageList && newMortgageList.length) {
              this.myHomeState.selectedMortgageIndexValue = 0;
            }
            this.openSnackBar(response.message, 'snackbar-success');
            observer.next();
            observer.complete();
          },
          (err) => {
            this.openSnackBar(err.error.message, 'snackbar-error');
          }
        );
    });
  }

  getPropertyDetails(params) {
    this.publicRecordApi.getPropertyDetails(params).subscribe(
      (response: any) => {
        if (response.data) {
          this.myHomeState.activePropertyDetailsValue = response.data;
        }
      },
      (err) => {
        this.myHomeState.activePropertyDetailsValue = null;
      }
    );
  }
  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }

  updateMyHomeMortgageValues(params): Observable<any> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .updateMyHome(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            if (params && params[0]) {
              if (params[0].mortgageList && params[0].mortgageList.length) {
                this.myHomeState.mortgageListValue = params[0].mortgageList;
              }
            }
          },
          (err) => {
            this.openSnackBar(
              err && err.error && err.error.message ? err.error.message : 'Failed to update My Property',
              'snackbar-error'
            );
          }
        );
    });
  }

  getWeather(lat: number, lng: number) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getWeather(lat, lng)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next(res);
          },
          (err) => {
            this.openSnackBar(
              err && err.error && err.error.message ? err.error.message : 'Failed to update My Property',
              'snackbar-error'
            );
          }
        );
    });
  }
}

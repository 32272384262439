import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { CompanyFooterDirective } from './company-footer.directive';
import { CompanyFooterService } from './company-footer.service';

interface CompanyFooter {
  data: any;
  uniqueMlsList: any;
  currentYear: string;
}

@Component({
  selector: 'app-company-footer',
  templateUrl: './company-footer.component.html',
  styleUrls: ['./company-footer.component.scss'],
})
export class CompanyFooterComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() uniqueMlsList;
  mobileQuery: MediaQueryList;
  @ViewChild(CompanyFooterDirective, { static: false }) appCompanyFooter!: CompanyFooterDirective;

  // Subscription to unsubscribe on component destroy
  onDestroyNotifier$ = new Subject();

  constructor(
    private CompanyFooterService: CompanyFooterService,
    private appState: AppState,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.appState.companyInfo$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      if (res && Object.keys(res).length) {
        if (res?.alias) {
          const companyComponent = this.CompanyFooterService.getCompanyComponent(res.alias);
          const viewContainerRef = this.appCompanyFooter.viewContainerRef;
          viewContainerRef.clear();
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(companyComponent.component);
          const componentRef = viewContainerRef.createComponent<CompanyFooter>(componentFactory);
          componentRef.instance.data = companyComponent.data;
          componentRef.instance.uniqueMlsList = this.uniqueMlsList;
          componentRef.instance.currentYear = moment().format('YYYY');
        } else {
          const viewContainerRef = this.appCompanyFooter.viewContainerRef;
          viewContainerRef.clear();
        }
        this.cdr.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import constants from 'src/constant.json';
import { AuthToken } from './shared/interface/auth.interface';

interface LoadingState {
  trigger?: string;
  isLoading?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AppState {
  private inUnsavedState = false;

  public get isUnsaved(): boolean {
    return this.inUnsavedState;
  }

  public set isUnsaved(v: boolean) {
    this.inUnsavedState = v;
  }

  /**
   * State to manage global app loading progress indicator
   */
  private loadingState: LoadingState = {};
  private isAppLoadingSub$ = new BehaviorSubject<boolean>(true);
  isAppLoading$ = this.isAppLoadingSub$.asObservable();

  /**
   * Set the app loading state for a specific trigger. This will help to trigger the same loader
   * and only turn off the loader if no trigger is active
   *
   * @param {string} trigger A name to show what triggered the loading. Should be same for one initiator
   * @param {boolean} isLoading Whether the loading is turned on or off
   * @returns {void}
   */
  public setAppLoadingState(trigger: string, isLoading: boolean): void {
    if (isLoading) {
      if (!this.loadingState.hasOwnProperty(trigger)) {
        this.loadingState[trigger] = isLoading;
      }
      this.isAppLoadingSub$.next(true);
    } else if (!isLoading) {
      if (this.loadingState.hasOwnProperty(trigger)) {
        delete this.loadingState[trigger];
      }
      if (!Object.values(this.loadingState).includes(true))
        setTimeout(() => {
          this.isAppLoadingSub$.next(false);
        }, 1000);
    }
  }

  public getAppLoadingState(trigger) {
    return this.loadingState[trigger];
  }

  private companyInfoSub$ = new BehaviorSubject<any>(null);
  companyInfo$ = this.companyInfoSub$.asObservable();

  public get companyInfoValue() {
    return this.companyInfoSub$.value;
  }

  public set companyInfoValue(value) {
    this.companyInfoSub$.next(value);
  }

  private appBaseUrlSub$ = new BehaviorSubject<any>('');
  appBaseUrl$ = this.appBaseUrlSub$.asObservable();

  public get appBaseUrlValue(): string {
    return this.appBaseUrlSub$.value;
  }

  public set appBaseUrlValue(value: string) {
    this.appBaseUrlSub$.next(value);
  }

  private appCompanyAliasSub$ = new BehaviorSubject<any>(constants.defaultLogin);
  appCompanyAlias$ = this.appCompanyAliasSub$.asObservable();

  public get appCompanyAliasValue(): string {
    return this.appCompanyAliasSub$.value;
  }

  public set appCompanyAliasValue(value: string) {
    this.appCompanyAliasSub$.next(value);
  }

  private $clientInfo = new BehaviorSubject<any>({});
  clientInfo = this.$clientInfo.asObservable();

  public get clientInfoSubValue(): any {
    return this.clientInfoSub$.value;
  }

  public set clientInfoSubValue(client: any) {
    this.clientInfoSub$.next(client);
  }

  private $connectionInfo = new BehaviorSubject<any>({});
  connectionInfo = this.$connectionInfo.asObservable();

  public get connectionInfoValue() {
    return this.$connectionInfo.value;
  }

  public set connectionInfoValue(value) {
    this.$connectionInfo.next(value);
  }

  private clientInfoSub$ = new BehaviorSubject<any>({
    client: {
      logo: {
        light: constants.lightLogo,
        dark: constants.darkLogo,
      },
      appTheme: {
        primaryColor: constants.appPrimaryColor,
        secondaryColor: constants.appSecondaryColor,
      },
      name: constants.APPLICATION_NAME,
    },
    user: {},
  });
  clientInfo$ = this.clientInfoSub$.asObservable();

  public get clientInfoValue() {
    return this.$clientInfo.value;
  }

  public set clientInfoValue(value) {
    this.$clientInfo.next(value);
  }

  private partnerInfoSub$ = new BehaviorSubject<any>({});
  partnerInfo$ = this.partnerInfoSub$.asObservable();

  public get partnerInfoValue(): any {
    return this.partnerInfoSub$.value;
  }

  public set partnerInfoValue(partner: any) {
    this.partnerInfoSub$.next(partner);
  }

  private navigatedFromSub$ = new BehaviorSubject<any>({});
  navigatedFrom$ = this.navigatedFromSub$.asObservable();

  public get navigatedFrom() {
    return this.navigatedFromSub$.value;
  }

  public set navigatedFrom(value) {
    this.navigatedFromSub$.next(value);
  }

  private curActiveLinkSub$ = new BehaviorSubject<any>({});
  curActiveLink$ = this.curActiveLinkSub$.asObservable();

  public get curActiveLink() {
    return this.curActiveLinkSub$.value;
  }

  public set curActiveLink(value) {
    this.curActiveLinkSub$.next(value);
  }

  private selectedSearchToLoadSub$ = new BehaviorSubject<any>({});
  selectedSearchToLoad$ = this.selectedSearchToLoadSub$.asObservable();

  public get selectedSearchToLoad() {
    return this.selectedSearchToLoadSub$.value;
  }

  public set selectedSearchToLoad(value) {
    this.selectedSearchToLoadSub$.next(value);
  }

  private workspaceViewSub$ = new BehaviorSubject<string>('map');
  workspaceView$ = this.workspaceViewSub$.asObservable();

  public get workspaceView() {
    return this.workspaceViewSub$.value;
  }

  public set workspaceView(value) {
    this.workspaceViewSub$.next(value);
  }

  /**
   * State to store user auth tokens.
   * Application should use this state to get auth tokens across the app.
   */
  private authTokenSub$ = new BehaviorSubject<AuthToken | null>(null);
  authToken$ = this.authTokenSub$.asObservable();

  public get authTokenValue(): AuthToken | null {
    return this.authTokenSub$.value;
  }

  public set authTokenValue(value: AuthToken | null) {
    this.authTokenSub$.next(value);
  }
}

import { Injectable } from '@angular/core';
import { AppState } from 'src/app/app.state';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService {
  constructor(private appState: AppState) {}

  setCookie(name: string, value: string, expirationDays: number) {
    let cookieDomain = '';

    if (['local.prospektr.ai', 'localhost'].includes(this.getBaseUrl())) {
      cookieDomain = this.appState.companyInfoValue?.domain;
    } else {
      cookieDomain = this.appState.companyInfoValue?.cookieDomain;
    }

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    const cookieValue = `${encodeURIComponent(
      value
    )}; expires=${expirationDate.toUTCString()}; domain= ${cookieDomain}; path=/; SameSite=Strict`;

    document.cookie = `${name}=${cookieValue}`;
  }

  getCookieValue(cookieName: string) {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  }

  clearAllCookies() {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      let domain = '';
      const eqPos = cookie.indexOf('=');
      const cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      if (this.getBaseUrl() != 'local.prospektr.ai') {
        domain = '.' + this.appState.companyInfoValue?.cookieDomain;
      } else {
        domain = '.' + this.appState.companyInfoValue?.domain;
      }

      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=${domain}; path=/; SameSite=Strict`;
    }
  }

  removeCookie(name: string): void {
    let domain = '';
    if (this.getBaseUrl() != 'local.prospektr.ai') {
      domain = '.' + this.appState.companyInfoValue?.domain;
    } else {
      domain = '.' + this.appState.companyInfoValue?.cookie;
    }

    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain= ${domain}; path=/; SameSite=Strict`;
  }

  getBaseUrl(): string {
    let baseUrl = '';
    if (window && window.location && window.location.hostname) {
      baseUrl = window.location.hostname;
    }
    return baseUrl;
  }
}

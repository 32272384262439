import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { AppState } from 'src/app/app.state';
import { PropertyFilterConfig } from 'src/app/property-filter/config/property-filter.config';
import { PropertyFilter } from 'src/app/property-filter/interface/property-filter.interface';
import { PropertyFilterService } from 'src/app/property-filter/service/property-filter.service';
import { PropertyFilterState } from 'src/app/property-filter/state/property-filter.state';
import { ShortNumberPipe } from 'src/app/shared/pipe/short-number.pipe';
import { FilterService } from '../../service/filter.service';
import { UserState } from '../../state/user.state';

@Component({
  selector: 'app-primary-filters',
  templateUrl: './primary-filters.component.html',
  styleUrls: ['./primary-filters.component.scss'],
})
export class PrimaryFiltersComponent implements OnInit {
  @ViewChild('forSaleMenuTrigger', { static: true }) forSaleMenuTrigger: MatMenuTrigger;
  @ViewChild('homeTypeMenuTrigger', { static: true }) homeTypeMenuTrigger: MatMenuTrigger;
  @ViewChild('maxMinPriceMenuTrigger', { static: true }) maxMinPriceMenuTrigger: MatMenuTrigger;
  @ViewChild('maxMinBedBathMenuTrigger', { static: true }) maxMinBedBathMenuTrigger: MatMenuTrigger;
  PropertyFilterConfig = PropertyFilterConfig;
  allSelected: boolean = false;
  isCheckedAll: boolean;
  appliedFilter?: Partial<PropertyFilter>;
  onDestroyNotifier$: Subject<void> = new Subject<void>();
  statusToggleBtnCtrl = new FormControl('forSale');
  sliderControl: FormControl;
  defaultListingTypeFilters: any;
  tempPriceValue = [];
  isInputFieldChange: any;
  filterForm = this._fb.group({
    lPrice: [null, this.minMaxValidator('min', 'hPrice')],
    hPrice: [null, this.minMaxValidator('max', 'lPrice')],
    lBed: [null, this.minMaxValidator('min', 'hBed')],
    hBed: [null, this.minMaxValidator('max', 'lBed')],
    lBath: [null, this.minMaxValidator('min', 'hBath')],
    hBath: [null, this.minMaxValidator('max', 'lBath')],
    homeType: [[]],
    isForSale: [null],
    isPreFcl: [null],
    isAuction: [null],
    isReo: [null],
    isHud: [null],
    includePending: [null],
    soldWithin: [null],
  });
  selectedFiltersCount: number;
  userDetails: any;
  bedToggleBtnCtrl = new FormControl((!this.appliedFilter?.hBed && Number(this.appliedFilter?.lBed)) || '');
  bathToggleBtnCtrl = new FormControl((!this.appliedFilter?.hBath && Number(this.appliedFilter?.lBath)) || '');
  constructor(
    private _fb: FormBuilder,
    public propertyFilterService: PropertyFilterService,
    private appState: AppState,
    private _cdr: ChangeDetectorRef,
    public shortNumber: ShortNumberPipe,
    private propertyFilterState: PropertyFilterState,
    public userState: UserState,
    public authorizeService: AuthorizeService,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {
    this.appliedFilter = this.propertyFilterService.getPrimaryFilterFromUrl();
    let distressFilter = this.propertyFilterService.hasAnyKey(this.appliedFilter);
    if (distressFilter) {
      // if (!Object.keys(this.userDetails || {}).length) {
      //   (this.appliedFilter.isPreFcl = null), (this.appliedFilter.isAuction = null), (this.appliedFilter.isReo = null);
      // }
      this.isCheckLoginIn();
    }
    this.filterForm.patchValue(this.appliedFilter);
    if (this.appliedFilter?.soldWithin) {
      this.statusToggleBtnCtrl.setValue('sold');
    }
    this.userState.userDetail$.subscribe((res) => {
      if (!res) return;
      this.userDetails = res;
    });
  }

  ngAfterViewInit(): void {
    this.propertyFilterState.appliedFilter$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      this.filterForm.reset();
      this.appliedFilter = this.propertyFilterService.getPrimaryFilterFromUrl();
      let distressFilter = this.propertyFilterService.hasAnyKey(this.appliedFilter);
      if (distressFilter) {
        this.isCheckLoginIn();
      }
      let jsonObject = this.convertStringBooleans(this.appliedFilter);
      this.filterForm.patchValue(this.appliedFilter);
      this.bedToggleBtnCtrl.setValue(this.appliedFilter?.lBed);
      this.bathToggleBtnCtrl.setValue(this.appliedFilter?.lBath);
      this.selectedFiltersCount = this.propertyFilterService.getFilterDisplayList(jsonObject).length;
      if (this.appliedFilter?.soldWithin) {
        this.statusToggleBtnCtrl.setValue('sold');
      } else {
        this.statusToggleBtnCtrl.setValue('forSale');
      }
    });
    this.filterForm.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((value) => {
      this._onFilterFormChange(value);
    });
    // this.appliedFilter = this.propertyFilterService.getPrimaryFilterFromUrl();
    // this.filterForm.patchValue(this.appliedFilter);

    this.statusToggleBtnCtrl.valueChanges.subscribe((newValue) => {
      if (newValue == 'sold') {
        this.filterForm.patchValue({
          soldWithin: this.appliedFilter?.soldWithin ? this.appliedFilter?.soldWithin : 30,
        });
        this._resetListingTypeValue();
      } else {
        this.filterForm.patchValue({
          soldWithin: null,
        });
        this._resetListingTypeValue();
      }
      // Do something with the new value
    });
    this._handleBedBathFilter();
  }
  /**
   * @private
   */
  _onFilterFormChange(formValue: Partial<PropertyFilter>) { }

  isCheckLoginIn() {
    if (!Object.keys(this.userDetails || {}).length) {
      (this.appliedFilter.isPreFcl = null),
        (this.appliedFilter.isAuction = null),
        (this.appliedFilter.isReo = null),
        (this.appliedFilter.isHud = null);
      // this.appliedFilter.isForSale = true;
    }
  }

  setListingFilters() {
    this.defaultListingTypeFilters = this.appState.companyInfoValue?.defaultPropertyFilter;
    this.filterForm.patchValue({
      soldWithin: null,
      isForSale: this.appliedFilter.isForSale ? this.appliedFilter.isForSale : this.defaultListingTypeFilters.isForSale,
      isPreFcl: this.appliedFilter.isPreFcl ? this.appliedFilter.isPreFcl : this.defaultListingTypeFilters.isPreFcl,
      isAuction: this.appliedFilter.isAuction ? this.appliedFilter.isAuction : this.defaultListingTypeFilters.isAuction,
      isReo: this.appliedFilter.isReo ? this.appliedFilter.isReo : this.defaultListingTypeFilters.isReo,
      isHud: this.appliedFilter.isHud ? this.appliedFilter.isHud : this.defaultListingTypeFilters.isHud,
      includePending: this.appliedFilter.includePending
        ? this.appliedFilter.includePending
        : this.defaultListingTypeFilters.includePending,
    });
  }

  /**
   * @private
   */
  _applyFilter(): void {
    if (this.filterForm.invalid) return;
    let formValue = this.filterForm.value;
    if (
      formValue?.homeType &&
      (formValue?.homeType.length === 0 || (formValue?.homeType.length === 1 && formValue?.homeType[0].length === 0))
    ) {
      delete formValue.homeType;
    }

    if (this.statusToggleBtnCtrl.value == 'sold') {
      this._resetListingTypeValue();
    } else {
      this.defaultFilterCheck(formValue);
    }
    let modifiedFormValue = this.removeFalseValues(this.filterForm.value);

    this.filterService.setDetailedFilterForBaseFilter(null);
    this.propertyFilterService.setPrimaryFilterToUrl(modifiedFormValue);
    this.closeMatMenus();
    this._cdr.detectChanges();
  }

  defaultFilterCheck(modifiedFormValue) {
    if (
      !modifiedFormValue?.isForSale &&
      !modifiedFormValue?.isPreFcl &&
      !modifiedFormValue?.isReo &&
      !modifiedFormValue?.isAuction &&
      !modifiedFormValue?.isHud
    ) {
      if (!Object.keys(this.userDetails || {}).length) {
        modifiedFormValue.isForSale = true;
      } else {
        this.defaultListingTypeFilters = this.appState.companyInfoValue?.defaultPropertyFilter;
        modifiedFormValue.isForSale = this.defaultListingTypeFilters.isForSale;
        modifiedFormValue.isPreFcl = this.defaultListingTypeFilters.isPreFcl;
        modifiedFormValue.isAuction = this.defaultListingTypeFilters.isAuction;
        modifiedFormValue.isReo = this.defaultListingTypeFilters.isReo;
        modifiedFormValue.isHud = this.defaultListingTypeFilters.isHud;
        modifiedFormValue.includePending = this.defaultListingTypeFilters.includePending;
      }
    }
  }

  removeFalseValues(obj) {
    if (obj.homeType?.length === 0 || (obj.homeType?.length === 1 && obj.homeType[0]?.length === 0)) {
      delete obj.homeType; // If true, delete homeType from obj
    }
    const filteredObject = { ...obj }; // Create a shallow copy of the object
    const keysToRemove = ['isForSale', 'isPreFcl', 'isAuction', 'isHud', 'includePending', 'isReo'];
    keysToRemove.forEach((key) => {
      if (filteredObject[key] === false) {
        delete filteredObject[key];
      }
    });
    return filteredObject;
  }

  closeMatMenus() {
    this.filterForm.reset();
    this.appliedFilter = null;
    this.maxMinPriceMenuTrigger.closeMenu();
    this.forSaleMenuTrigger.closeMenu();
    this.homeTypeMenuTrigger.closeMenu();
    this.maxMinBedBathMenuTrigger.closeMenu();
  }

  private _handleBedBathFilter(): void {
    this.bedToggleBtnCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((value) => {
      this.filterForm.patchValue(
        { lBed: value, hBed: this.filterForm.get('hBed').value || null },
        { emitEvent: false }
      );
    });

    this.bathToggleBtnCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((value) => {
      this.filterForm.patchValue(
        { lBath: value, hBath: this.filterForm.get('hBath').value || null },
        { emitEvent: false }
      );
    });
  }

  _resetListingTypeValue() {
    this.filterForm.patchValue({
      isForSale: this.statusToggleBtnCtrl.value == 'sold' ? null : this.appliedFilter?.isForSale,
      isPreFcl: this.statusToggleBtnCtrl.value == 'sold' ? null : this.appliedFilter?.isPreFcl,
      isAuction: this.statusToggleBtnCtrl.value == 'sold' ? null : this.appliedFilter?.isAuction,
      isReo: this.statusToggleBtnCtrl.value == 'sold' ? null : this.appliedFilter?.isReo,
      isHud: this.statusToggleBtnCtrl.value == 'sold' ? null : this.appliedFilter?.isHud,
      includePending: this.statusToggleBtnCtrl.value == 'sold' ? null : this.appliedFilter?.includePending,
    });
  }

  isChecked() {
    return (
      this.filterForm.get('isAuction').value &&
      this.filterForm.get('isPreFcl').value &&
      this.filterForm.get('isReo').value
    );
  }

  minMaxValidator(type: string, supportCtrlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.filterForm?.get(supportCtrlName)?.value || !control.value) return null;
      let minMaxError = false;
      if (type === 'min' && +control.value > +this.filterForm.get(supportCtrlName)?.value) {
        minMaxError = true;
      } else if (type === 'max' && +this.filterForm.get(supportCtrlName).value > +control.value) {
        minMaxError = true;
      }
      if (minMaxError) {
        control.markAsTouched();
        this.filterForm?.markAsTouched();
        return { minMaxError: true };
      }
      return null; // No error
    };
  }
  isAllSelected() {
    if (this.isChecked()) {
      return false;
    }
    const isAnyTrue = ['isAuction', 'isPreFcl', 'isReo'].some((key) => this.filterForm.get(key).value);
    if (isAnyTrue) {
      this.isCheckedAll = false;
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
    return this.allSelected;
  }

  selectAll(event: MatCheckboxChange) {
    if (event.checked) {
      if (!Object.keys(this.userDetails || {}).length) {
        this.authorizeService.openLogInDialog();
        this.forSaleMenuTrigger.closeMenu();
        return (event.source.checked = false);
      } else {
        this.filterForm.patchValue({
          isPreFcl: true,
          isAuction: true,
          isReo: true,
        });
      }
    } else {
      this.filterForm.patchValue({
        isPreFcl: null,
        isAuction: null,
        isReo: null,
      });
    }
    this.allSelected = event.checked;
  }

  toggleSelection(homeType: any): void {
    const selectedHomeTypes = this.filterForm.get('homeType')?.value || [];
    const index = selectedHomeTypes.indexOf(homeType.value);
    if (index === -1) {
      selectedHomeTypes.push(homeType.value);
    } else {
      selectedHomeTypes.splice(index, 1);
    }
    this.filterForm.get('homeType')?.setValue(selectedHomeTypes);
  }

  isSelected(homeType: any): boolean {
    const selectedHomeTypes = this.filterForm.get('homeType')?.value || [];
    return selectedHomeTypes.indexOf(homeType.value) !== -1;
  }

  convertStringBooleans(obj) {
    for (let key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      }
      if (typeof obj[key] === 'string') {
        const trimmedValue = obj[key].trim();
        if (trimmedValue === 'true') {
          obj[key] = true;
        } else if (trimmedValue === 'false') {
          obj[key] = false;
        } else if (!isNaN(trimmedValue) && trimmedValue !== '') {
          obj[key] = Number(trimmedValue);
        }
      }
    }
    return obj;
  }

  updateDistressStatus(event: MatCheckboxChange) {
    if (event.checked) {
      if (!Object.keys(this.userDetails || {}).length) {
        this.authorizeService.openLogInDialog();
        this.forSaleMenuTrigger.closeMenu();
        return (event.source.checked = false);
      }
    }
  }
  _resetFilter(type) {
    if (type == 'price') {
      this.filterForm.patchValue({ lPrice: null, hPrice: null });
    }
    if (type == 'homeType') {
      this.filterForm.patchValue({ homeType: null });
    }
    if (type == 'bedBath') {
      this.bedToggleBtnCtrl.setValue(null);
      this.bathToggleBtnCtrl.setValue(null);
      this.filterForm.patchValue({ lBed: null, hBed: null, lBath: null, hBath: null });
    }
    if (type == 'listing') {
      this.defaultListingTypeFilters = this.appState.companyInfoValue?.defaultPropertyFilter;
      this.statusToggleBtnCtrl.setValue('forSale');
      this.filterForm.patchValue({
        soldWithin: null,
        isForSale: this.defaultListingTypeFilters.isForSale,
        isPreFcl: this.defaultListingTypeFilters.isPreFcl,
        isAuction: this.defaultListingTypeFilters.isAuction,
        isReo: this.defaultListingTypeFilters.isReo,
        isHud: this.defaultListingTypeFilters.isHud,
        includePending: this.defaultListingTypeFilters.includePending,
      });
    }
    this.propertyFilterService.setPrimaryFilterToUrl(this.filterForm.value);
    this.closeMatMenus();
    this.appliedFilter = this.filterForm.value;
  }
}

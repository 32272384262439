<div class="alertDialog">
  <button
    *ngIf="data.type == 'resetLink' || data.type == 'signUpSuccess'"
    class="close float-right"
    mat-button
    (click)="onNoClick()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="text-center btn-block mb-2">
    <img src="~/../../assets/images/mail.svg" width="50" class="img-fluid" *ngIf="data.type === 'signUp'" />
    <img src="~/../../assets/images/profileUpdate.svg" width="50" class="img-fluid" *ngIf="data.type === 'profile'" />
    <img
      src="~/../../assets/images/confirmpassword.svg"
      width="50"
      class="img-fluid"
      *ngIf="data.type === 'confirmPassword'"
    />
    <img src="~/../../assets/images/success.svg" width="50" class="img-fluid" *ngIf="data.type === 'signUpSuccess'" />
    <img src="~/../../assets/images/mail.svg" width="50" class="img-fluid" *ngIf="data.type === 'resetLink'" />
  </div>
  <h1
    mat-dialog-title
    class="text-center"
    [ngClass]="{
      'text-success': data.type === 'signUp' || data.type === 'confirmPassword',
      'text-profileMsg': data.type === 'profile',
      'text-warning': data.type === 'resetLink',
      'text-dark': data.type === 'signUpSuccess'
    }"
  >
    {{ data.title ? data.title : '' }}
  </h1>
  <div mat-dialog-content class="text-center">
    <p
      *ngIf="data.type != 'signUpSuccess'"
      [ngClass]="{ 'text-center': data.type === 'signUp' || data.type === 'confirmPassword' }"
    >
      {{ data.message ? data.message : 'Default Message' }}
    </p>
    <p [ngClass]="{ 'text-center': data.type === 'signUp' || data.type === 'confirmPassword' }">
      {{ data.signupAlert }}
    </p>
    <p *ngIf="data.type == 'signUpSuccess'" [innerHTML]="data.message"></p>
  </div>
  <div *ngIf="data.type != 'resetLink'" mat-dialog-actions>
    <div class="text-center btn-block">
      <button
        *ngIf="data.type != 'signUpSuccess'"
        mat-dialog-close
        cdkFocusInitial
        mat-flat-button
        color="primary"
        class="btn-lg"
      >
        Ok
      </button>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Json } from '../interface/common.interface';
import { WindowService } from './window.service';

@Injectable()
export class GTMService {
  previousEventData: Json;
  filteredEventData = {};

  constructor(private windowService: WindowService) {
    this.windowService.nativeWindow.dataLayer = this.windowService.nativeWindow.dataLayer || [];
  }

  updateEvent(eventType, data: Json): void {
    const newEventData: Json = { event: eventType, ...data };
    //const filteredEventData = this.resetPreviousFields(this.previousEventData, data); // needs to modify this function
    this.windowService.nativeWindow.dataLayer.push(newEventData);
    this.previousEventData = newEventData;
  }

  filterEmptyFields(data: Json): Json {
    const filteredSet = {};
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key]) && !(data[key] as []).length) filteredSet[key] = undefined;
      else if (data[key] != null) filteredSet[key] = data[key];
      else filteredSet[key] = undefined;
    });
    return filteredSet;
  }

  resetPreviousFields(oldData: Json, newData: Json): Json {
    if (oldData && Object.keys(oldData).length)
      Object.keys(oldData).forEach((oldKey) => {
        if (!Object.keys(newData).includes(oldKey)) newData[oldKey] = undefined;
      });
    Object.keys(newData).forEach((newKey) => {
      if (newData[newKey] == null) newData[newKey] = undefined;
    });
    return newData;
  }
}

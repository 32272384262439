import { Injectable } from '@angular/core';
import { UserState } from 'src/app/_shared/state/user.state';
import { AppState } from 'src/app/app.state';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  isTrialExpireDayReminderTurnedOff: boolean;

  constructor(private appState: AppState, private userState: UserState) {}

  get isProfileUpdated() {
    if (!this.appState.authTokenValue?.idToken) return false;

    return this.userState.userDetails?.workPlace && Object.keys(this.userState.userDetails?.workPlace).length
      ? true
      : false;
  }

  get isPlanActive() {
    return this.userState.userDetails?.isPlanActive || false;
  }

  get userRole() {
    return this.userState.userDetails?.userRole || null;
  }

  get userScope() {
    if (this.userState.userDetails?.emailVerificationDate) {
      return 'full';
    }
    return 'trial';
  }

  get roles() {
    return this.userState.userDetails?.roles || null;
  }

  // clearLocalStorage() {
  //   localStorage.clear();
  //   this.userState.userDetails = null;
  //   this.appState.workspaceView = null;
  //   this.userState.userLocation = null;
  // }

  get isTrialAccountExpired() {
    if (!this.appState.authTokenValue?.idToken) return false;
    // 'Active'
    if (this.userState.userDetails?.trialAccount === 'Expired') return true;

    return false;
  }

  get trialAccountExpiredDay() {
    if (!this.appState.authTokenValue?.idToken) return null;

    return this.userState.userDetails?.trialLeft || null;
  }

  get showReminder() {
    return !this.isTrialExpireDayReminderTurnedOff;
  }

  set showReminderStatus(status) {
    this.isTrialExpireDayReminderTurnedOff = status;
  }

  get exportingDetails() {
    if (!this.appState.authTokenValue?.idToken) return null;

    return this.userState.userDetails?.exportFavorites || null;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-container-page',
  templateUrl: './container-page.component.html',
  styleUrls: ['./container-page.component.scss'],
})
export class ContainerPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportsService } from 'src/app/reports/reports.service';
import { ProspektrApi } from '../../api/prospektr.api';
import { UserState } from '../../state/user.state';
import { WarningMsgComponent } from '../warning-msg/warning-msg.component';

@Component({
  selector: 'app-plan-information',
  templateUrl: './plan-information.component.html',
  styleUrls: ['./plan-information.component.scss'],
})
export class PlanInformationComponent implements OnInit {
  onDestroyNotifier$ = new Subject();
  plans = [];
  code = new FormControl();
  currentCode: any;
  totalPlans: any;
  connectedLoanOfficers: any;
  loanOfficer = new FormControl();
  selectedPlan: any = [];
  loading = true;
  spin = false;
  activePlanId: any;
  currentPlan: any = [];
  currentIndex: any;
  subscriptionEndDate: string;
  connectedLoanOfficerId: any;
  activePromoCode: any;
  existingSubscriptionEndDate: any;
  isConnected: boolean;
  activePlan: any;
  nextPlan: any;
  nextPlanId: any;
  nextLoanOfficer: any;
  nextPlanStartDate: string;
  isIntrial: boolean;
  loanOfficerChange: boolean;
  promoCodeChange: boolean;
  initialLO: any;

  @Input() userRole;

  constructor(
    private reportsService: ReportsService,
    private userState: UserState,
    private prospektrApi: ProspektrApi,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loading = true;
    if (this.userRole == 'agent') {
      this.reportsService.getConnectedLoanOfficers();
    }
    this.updatePlans();
  }

  updatePlans() {
    this.isConnected = false;
    this.userState.userPlanStatus.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((data) => {
      if (data && !data.trialExpired && data.daysRemaining) {
        this.isIntrial = true;
      } else {
        this.isIntrial = false;
      }
      this.activePlanId = data?.planId;
      this.connectedLoanOfficerId =
        data?.loanOfficerId == null || data?.loanOfficerId == undefined ? '' : data.loanOfficerId;
      this.initialLO = data?.loanOfficerId == null || data?.loanOfficerId == undefined ? '' : data.loanOfficerId;
      this.activePromoCode = data?.discounts;
      this.existingSubscriptionEndDate = data?.existingSubscriptionEndDate;
      this.nextPlan = data?.transitionToSubscriptionDetails
        ? data?.transitionToSubscriptionDetails
        : data?.transitionToPlanDetails;
    });
    this.userState.connectedLoanOfficers.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((data) => {
      this.connectedLoanOfficers = data.filter((item) => item.isCardAdded === true);
      if (this.connectedLoanOfficerId) {
        this.loanOfficer.setValue(this.connectedLoanOfficerId);
        this.reportsService.getPlans(this.connectedLoanOfficerId);
      } else {
        this.loanOfficer.setValue('');
        this.reportsService.getPlans('');
      }
      this.userState.plans.subscribe((data) => {
        this.loading = false;
        this.plans = data;
        if (this.activePlanId) {
          let i = this.plans.findIndex((x) => x.id == this.activePlanId);
          this.selectedPlan = this.plans[i];
          this.activePlan = this.plans[i];
        } else {
          // If there is no activeplanID in plan-stats endpoint, then pay per report will be the default one
          let i = this.plans.findIndex((x) => x.interval != 'month');
          this.selectedPlan = this.plans[i];
          this.activePlanId = this.plans[i]?.id;
        }
        if (this.activePromoCode) {
          let i = this.plans.findIndex((x) => x.id == this.activePlanId);
          if (i != -1) {
            this.plans[i]['discountPrice'] = this.activePromoCode.discountedPrice;
            this.plans[i]['offerApplied'] = true;
            this.plans[i]['promoCode'] = this.activePromoCode.promoCode;
          }
        }
        if (this.nextPlan) {
          this.nextPlanId = this.nextPlan?.planId ? this.nextPlan?.planId : this.nextPlan._id;
          this.nextLoanOfficer = this.nextPlan?.loanOfficerId ? this.nextPlan.loanOfficerId : '';
          let date = new Date(this.existingSubscriptionEndDate);
          let month = date.getMonth() + 1;
          this.nextPlanStartDate = date.getDate() + '/' + month + '/' + date.getFullYear();
        }
      });
    });
  }

  getPlansByLoanOfficerId(event) {
    this.loading = true;
    this.plans = [];
    this.reportsService.getPlans(this.loanOfficer.value);
    this.userState.plans.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((data) => {
      this.plans = data;
    });
  }

  applyCode(id, index) {
    if (index != -1) {
      this.currentIndex = index;
      this.spin = true;
    }
    let obj = {
      planId: id,
      promoCode: this.code.value,
    };
    let i = this.plans.findIndex((x) => x.id == id);
    this.prospektrApi
      .applyCode(obj)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(
        (res: any) => {
          this.spin = false;
          this.openSnackBar(res.message, 'snackbar-success');
          this.plans[i]['discountPrice'] = res.data.discountedPrice;
          this.plans[i]['offerApplied'] = true;
          this.plans[i]['promoCode'] = this.code.value;
          this.currentCode = this.plans[i]['promoCode'];
        },
        (err: any) => {
          this.spin = false;
          this.plans[i]['offerApplied'] = false;
          this.plans[i]['discountPrice'] = '';
          this.code.reset();
          this.openSnackBar(err.error.message, 'snackbar-warning');
        }
      );
  }

  removeCode(id) {
    let i = this.plans.findIndex((x) => x.id == id);
    this.plans[i]['offerApplied'] = false;
    this.plans[i]['discountPrice'] = '';
    this.plans[i]['promoCode'] = '';
    this.code.reset();
  }

  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  save() {
    if (this.isIntrial == true && this.selectedPlan.interval == 'month') {
      const dialogRef = this.dialog.open(WarningMsgComponent, {
        width: '250px',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result == 'cancel') {
        } else {
          this.subscribePlan();
        }
      });
    } else {
      this.subscribePlan();
    }
  }

  subscribePlan() {
    this.loading = true;

    this.initialLO == this.loanOfficer.value ? (this.loanOfficerChange = false) : (this.loanOfficerChange = true);
    this.activePromoCode?.promoCode == this.selectedPlan?.promoCode
      ? (this.promoCodeChange = false)
      : (this.promoCodeChange = true);
    let obj = {
      planId: this.selectedPlan.id,
      promoCode: this.selectedPlan?.promoCode,
      loanOfficerId: this.loanOfficer.value,
      loanOfficerChange: this.loanOfficerChange,
      promoCodeChange: this.promoCodeChange,
    };
    this.prospektrApi
      .subscribePlan(obj)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(
        (res: any) => {
          this.loading = false;
          this.currentPlan = this.selectedPlan;
          this.code.reset();
          if (res.data.existingSubscriptionEndDate) {
            let date = new Date(res.data.existingSubscriptionEndDate);
            let month = date.getMonth() + 1;
            // this.isConnected = true;
            this.subscriptionEndDate = date.getDate() + '/' + month + '/' + date.getFullYear();
            this.openSnackBar(
              res.message + '.' + 'This Plan will be effective only after ' + this.subscriptionEndDate,
              'snackbar-success'
            );
          } else {
            this.openSnackBar(res.message, 'snackbar-success');
          }
          this.reportsService.getPlanStatus();
          setTimeout(() => {
            this.updatePlans();
          }, 3000);
        },
        (err: any) => {
          this.loading = false;
          // if (
          //   this.currentPlanLO != '' &&
          //   this.loanOfficer.value != '' &&
          //   this.currentPlanLO != this.loanOfficer.value
          // ) {
          //   this.loanOfficerChange = true;
          // }
          this.code.reset();
          this.openSnackBar(err.error.message, 'snackbar-error');
        }
      );
  }

  choosePlan(id) {
    this.activePlanId = id;
    this.connectedLoanOfficerId = this.loanOfficer.value;
    let i = this.plans.findIndex((x) => x.id == id);
    this.selectedPlan = this.plans[i];
  }

  private openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { SubscriptionState } from 'src/app/_shared/state/subscription.state';
import { UserState } from 'src/app/_shared/state/user.state';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class SubscriptionPlanService {
  $isPlanActive = new BehaviorSubject<boolean>(false);
  isPlanActive = this.$isPlanActive.asObservable();

  constructor(
    private http: HttpClient,
    private subscriptionState: SubscriptionState,
    private userState: UserState,
    private appState: AppState
  ) {
    if (this.appState.authTokenValue?.idToken) {
      this.getSubscriptionPlan();
    }
  }

  getSubscriptionPlan() {
    if (!this.userState.userDetails) return;

    // if (this.userState.userDetails.roles && this.userState.userDetails.roles.indexOf('companyManager') > -1) return;

    this.http.get(environment.prospektrBackendBaseURL + 'getSubscriptionPlan').subscribe((res: any) => {
      const user = JSON.parse(JSON.stringify(this.userState.userDetails));
      if (res && res.status === 'success' && res.data) {
        //  this.$planUsage.next(res.data);
        this.subscriptionState.planUsageValue = res.data;
        if (res.data.renewalDate) {
          if (moment().isAfter(res.data.renewalDate, 'day')) {
            user.renewalDate = res.data.renewalDate;
            user.isPlanActive = false;
            user.userRole = 'trial';
          } else {
            this.$isPlanActive.next(true);
            user.renewalDate = res.data.renewalDate;
            user.isPlanActive = true;
            user.userRole = 'client';
          }
        } else {
          this.$isPlanActive.next(true);
          user.renewalDate = res.data.renewalDate;
          user.isPlanActive = true;
          user.userRole = 'client';
        }
        this.userState.userDetails = user;
        return true;
      } else {
        return false;
      }
    });
  }
}

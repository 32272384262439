import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SavedSearchService } from 'src/app/_shared/service/saved-search.service';
import { SavedSearchState } from 'src/app/_shared/state/saved-search.state';
import { WorkspaceState } from 'src/app/_shared/state/workspace.state';

@Injectable({
  providedIn: 'root',
})
export class SaveSearchFacade {
  constructor(
    private workspaceState: WorkspaceState,
    private savedSearchState: SavedSearchState,
    private savedSearchService: SavedSearchService
  ) {}

  getCurrentView(): Observable<any> {
    return this.workspaceState.workspaceView$;
  }

  getActiveSaveSearches(): Observable<any> {
    return this.savedSearchState.$activeSaveSearches;
  }

  saveNewSearchAndFilters(input: string, searchFlag: boolean, filterFlag: boolean) {
    const params = {
      saveSearchName: input,
      isSearchStatus: searchFlag,
      isFilterStatus: filterFlag,
    };
    this.savedSearchState.newSaveSearchValue = params;
  }

  rePopulateSavedSearch(savedSearch) {
    this.savedSearchService.rePopulateSavedSearch(savedSearch);
  }

  setOpenedViewSearchStatus(status) {
    this.workspaceState.openedViewSearchValue = status;
  }

  getOpenedViewSearchStatus(): Observable<any> {
    return this.workspaceState.openedViewSearch$;
  }
}

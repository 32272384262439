<div class="col-sm-12 showFileListWrap">
  <div class="mb-3" [hidden]="isContactFileListVisible">
    <button (click)="goBack()" type="button" mat-button class="btn-prefix-icon" name="back to contacts">
      <mat-icon> arrow_back </mat-icon>Contacts
    </button>
    > <strong class="ml-3"> {{ selectFileInfo.name }}</strong>
  </div>
  <form>
    <div class="row align-items-center mb-2">
      <mat-form-field class="col-sm-5 col-9">
        <mat-label> Search in table</mat-label>
        <input
          #searchCtrl
          matInput
          autocomplete="off"
          placeholder="Search"
          name="csvGroupName"
          [formControl]="searchFormCtrl"
        />
      </mat-form-field>
      <div class="col-sm-1 col-3">
        <button mat-button color="primary" class="float-right" (click)="searchFormCtrl.reset()" name="reset search">
          Reset
        </button>
      </div>
    </div>
  </form>
  <h6>{{ champaignType }}</h6>
  <p *ngIf="uniqueRecord">
    Total Unique Records <b>{{ uniqueRecord }}</b>
  </p>
</div>
<div class="col-lg-12 mt-3 table-csv-file" [hidden]="!isContactFileListVisible">
  <div class="table_base">
    <div class="table-responsive table_wrap">
      <table mat-table [dataSource]="contactGroupDataSource" class="mat-elevation-z2 w-100" matSort>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="align-center">Status</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="align-center"
            [ngClass]="{
              'text-danger': element.status == 'invalid',
              'text-success': element.status == 'valid' || 'completed',
              'text-warning': element.status == 'pending'
            }"
          >
            <mat-icon class="text-success" *ngIf="element.status == 'completed'" matTooltip="Verified Contact">
              done</mat-icon
            >
            <div *ngIf="element.status === 'pending'" matTooltip="Contact Verification In Progress">
              <mat-spinner [diameter]="13" [strokeWidth]="2"></mat-spinner>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">Contact CSV File</th>
          <td mat-cell *matCellDef="let element" class="align-left">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="addressCount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-center">Total Record</th>
          <td mat-cell *matCellDef="let element" class="align-center">{{ element.contactCount }}</td>
        </ng-container>
        <ng-container matColumnDef="validAddressCount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-center">Valid Address Count</th>
          <td mat-cell *matCellDef="let element" class="align-center">{{ element.validAddressCount }}</td>
        </ng-container>
        <ng-container matColumnDef="validEmailCount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-center">Valid Email Count</th>
          <td mat-cell *matCellDef="let element" class="align-center">{{ element.validEmailCount }}</td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-center">Created Date</th>
          <td mat-cell *matCellDef="let element" class="align-center">
            {{ element.createdDate | simpleDate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="actionsWrap align-right">Actions</th>
          <td mat-cell *matCellDef="let element" class="actionsWrap align-right" (click)="$event.stopPropagation()">
            <ng-container *ngIf="element.createdBy != 'system'">
              <button
                *ngIf="element.status != 'pending' && !element.isBeingDeleted"
                (click)="deleteFile(element._id)"
                class="delete mat-icon-button"
              >
                <mat-icon>delete</mat-icon>
              </button>
              <mat-spinner *ngIf="element.isBeingDeleted" [diameter]="13" [strokeWidth]="2"></mat-spinner>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="csvFileColumnList; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: csvFileColumnList"
          [ngClass]="{ 'disabled-row': row.status == 'pending' || row.isBeingDeleted }"
          (click)="open(row)"
        ></tr>
      </table>
    </div>
    <div class="no-data-placeholder text-center py-4" *ngIf="!csvFileListCount">
      <img src="assets/images/partnerholder.png" width="260px" />
      <h5>Uploaded contacts will be listed here</h5>
    </div>
    <mat-paginator #contactGroupPaginator [pageSizeOptions]="[10, 20]" showFirstLastButtons> </mat-paginator>
  </div>
</div>
<!-- table 2 -->
<div class="col-lg-12 mt-2 table-csv-record" [hidden]="isContactFileListVisible">
  <div class="table_base">
    <div class="table-responsive table_wrap">
      <table mat-table [dataSource]="contactDataSource" class="mat-elevation-z2 w-100" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              color="primary"
              (change)="$event ? masterToggle($event) : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <div>
              <mat-checkbox
                color="primary"
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleSelect(row, $event.checked) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="contactStatus">
          <th mat-header-cell *matHeaderCellDef class="align-center">Valid Email</th>
          <td mat-cell *matCellDef="let element" class="align-center">
            <mat-icon class="text-danger" *ngIf="!element.hasValidEmail" matTooltip="Invalid contact"> close</mat-icon>
            <mat-icon class="text-success" *ngIf="element.hasValidEmail" matTooltip="Valid contact"> done</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="addressStatus">
          <th mat-header-cell *matHeaderCellDef class="align-center">Valid Address</th>
          <td mat-cell *matCellDef="let element" class="align-center">
            <mat-icon class="text-danger" *ngIf="!element.hasValidAddress" matTooltip="Invalid address">
              close</mat-icon
            >
            <mat-icon class="text-success" *ngIf="element.hasValidAddress" matTooltip="Valid address"> done</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">First Name</th>
          <td mat-cell *matCellDef="let element" class="align-left">{{ element.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">Last Name</th>
          <td mat-cell *matCellDef="let element" class="align-left">{{ element.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">Phone</th>
          <td mat-cell *matCellDef="let element" class="align-left">{{ element.phone }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">Email</th>
          <td mat-cell *matCellDef="let element" class="align-left">{{ element.email }}</td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">Address</th>
          <td mat-cell *matCellDef="let element" class="align-left">
            <ng-container
              *ngIf="element.hasValidAddress && element.streetAddress && element.city && element.state && element.zip"
            >
              <div
                [propertyAddress]="
                  element.streetAddress + ', ' + element.city + ', ' + element.state + ' ' + element.zip
                "
              >
                {{ element.streetAddress }}, {{ element.city }}, {{ element.state }} {{ element.zip }}
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                !element.hasValidAddress || !element.streetAddress || !element.city || !element.state || !element.zip
              "
            >
              <div>{{ element.streetAddress }} {{ element.city }} {{ element.state }} {{ element.zip }}</div>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="city">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">City</th>
          <td mat-cell *matCellDef="let element" class="align-left">{{ element.city }}</td>
        </ng-container>
        <ng-container matColumnDef="state">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">State</th>
          <td mat-cell *matCellDef="let element" class="align-left">{{ element.state }}</td>
        </ng-container>
        <ng-container matColumnDef="zip">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">Zip</th>
          <td mat-cell *matCellDef="let element" class="align-left">{{ element.zip }}</td>
        </ng-container>
        <ng-container matColumnDef="invitationStatus">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-center">Invitation Status</th>
          <td mat-cell *matCellDef="let element" class="align-center">
            <mat-icon
              class="text-success"
              *ngIf="
                element[constant.CAMPAIGN_TYPE.investorInvitation]?.invitedDate ||
                element[constant.CAMPAIGN_TYPE.investorInvitation]?.invitedDate
              "
              >done
            </mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="watchlistStatus">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-center">
            {{ stringRes.WATCH_LIST }} Status
          </th>
          <td mat-cell *matCellDef="let element" class="align-center">
            <mat-icon class="text-success" *ngIf="element.inWatchlist">done </mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="csvRecordColumnList; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: csvRecordColumnList"></tr>
      </table>
    </div>
    <div class="no-data-placeholder text-center py-4" *ngIf="!csvRecordListCount">
      <img src="assets/images/partnerholder.png" width="260px" />
      <h5>No contacts to show</h5>
    </div>
    <mat-paginator #contactPaginator [pageSizeOptions]="[10, 20]" showFirstLastButtons> </mat-paginator>
  </div>
</div>
<div class="section-in-progress" *ngIf="isDataLoading">
  <mat-spinner [diameter]="30" class="mr-3"></mat-spinner>
  Please wait while we load
</div>

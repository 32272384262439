import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AppState } from 'src/app/app.state';
import {
  ForgotPasswordAlerts,
  ForgotPasswordInputErrorCheck,
  ResetPasswordFields,
} from 'src/app/forgot-password/forgot-password-common-interface';
import { ForgotPasswordService } from 'src/app/forgot-password/forgot-password.service';
import { LoginAlerts, LoginFieldInput, LoginFieldInputErrorCheck } from 'src/app/login/login.interface';
import { LoginService } from 'src/app/login/login.service';
import { PropertyFilter } from 'src/app/property-filter/interface/property-filter.interface';
import { DialogComponent } from 'src/app/shared/modules/alerts/dialog/dialog.component';
import { LocalStorageService } from 'src/app/shared/service/local-storage.service';
import { ValidatorsService } from 'src/app/shared/service/validators.service';
import { SignupAlerts } from 'src/app/signup/signup.interface';
import { SignupService } from 'src/app/signup/signup.service';
import { VerifyLoginService } from 'src/app/verify-login/verify-login.service';
import constants from 'src/constant.json';
import { UserState } from '../../state/user.state';

const errorObj = { type: 'info', message: '* Please fill mandatory fields' };

@Component({
  selector: 'app-signup-dialog',
  templateUrl: './signup-dialog.component.html',
  styleUrls: ['./signup-dialog.component.scss'],
})
export class SignupDialogComponent implements OnInit, AfterViewInit {
  @Input() required: boolean = false;

  @ViewChild('form') form;
  @ViewChild('search') search;
  @ViewChild('forgotPasswordForm') forgotPasswordForm;

  userResetToken: string;
  forgotPasswordField: any = { userName: '' };
  resetPasswordFields: ResetPasswordFields;
  resetPasswordAlerts: ForgotPasswordAlerts = errorObj;
  resetPasswordpatterns: ForgotPasswordInputErrorCheck;
  showResetPwdForm: boolean;
  resetFlag: boolean;
  isPasswordResetSuccessfull: boolean;
  resetLinkInprogress: boolean = false;
  signUpForm: FormGroup;
  isSignup: boolean;
  isSignUpInProgress: boolean = false;
  signupStatus: boolean = false;
  submitted = false;
  signupDetails: any;
  patterns: any;
  interestedAreas = [];
  userId;
  signUpContinueToken = null;
  userAddress;
  alerts: SignupAlerts = errorObj;
  loginFields: LoginFieldInput = { email: '', password: '' };
  loginAlerts: LoginAlerts = errorObj;
  loginPatterns: LoginFieldInputErrorCheck;
  isUserAuthenticationProgress: boolean;
  constant: any = constants;
  isSignUpVisibleSub$ = new BehaviorSubject<boolean>(false);
  isSignUpVisible$ = this.isSignUpVisibleSub$.asObservable();
  isLoginVisibleSub$ = new BehaviorSubject<boolean>(true);
  isLoginVisible$ = this.isLoginVisibleSub$.asObservable();
  isForgotPasswordVisibleSub$ = new BehaviorSubject<boolean>(false);
  isForgotPasswordVisible$ = this.isForgotPasswordVisibleSub$.asObservable();
  autoComplete: google.maps.places.Autocomplete;
  accessToken: string;
  validatingLinkInProgress: boolean;
  websiteUrl: string;
  companyLogo: any;
  companySpecificLogo: string;
  onDestroyNotifier$ = new Subject();
  showPassword: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SignupDialogComponent>,
    public formBuilder: FormBuilder,
    public appState: AppState,
    public signupService: SignupService,
    private activatedRoute: ActivatedRoute,
    public validators: ValidatorsService,
    private changeDetect: ChangeDetectorRef,
    public storage: LocalStorageService,
    public loginService: LoginService,
    public router: Router,
    private userState: UserState,
    private appService: AppService,
    private forgotPasswordService: ForgotPasswordService,
    private dialog: MatDialog,
    public verifyLoginService: VerifyLoginService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngAfterViewInit() {
    this.isSignUpVisible$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      setTimeout(() => {
        if (res) this.initAutoComplete();
      }, 200);
    });
  }

  ngOnInit() {
    this.userState.userDetail$.subscribe((res) => {
      this.loginFields.email = res?.user_profile?.email || '';
    });
    this.appState.companyInfo$.subscribe((res: any) => {
      this.companyLogo = res?.logo?.dark || '';
    });
    this.userId = this.appState?.companyInfoValue?.signUpId;
    this.signUpContinueToken = this.activatedRoute.snapshot.paramMap.get('identifier');
    this.patterns = this.validators.patterns;
    this.signUpForm = this.formBuilder.group({
      firstName: [
        '',
        Validators.compose([Validators.maxLength(25), Validators.pattern('[a-zA-Z ]*'), Validators.required]),
      ],
      lastName: ['', Validators.pattern('[a-zA-Z ]*')],
      email: ['', [Validators.required, Validators.pattern(constants.regex.validEmail)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      interestedAreas: Validators.compose([Validators.required]),
      autoCompleteInput: '',
      phoneNumber: [''],
    });

    this.phoneNumberRequiredValidator();
    this.resetFlag = false;
  }

  phoneNumberRequiredValidator() {
    const phoneNumberControl = this.signUpForm.get('phoneNumber');
    if (this.appState?.companyInfoValue.isPhoneNumberValidate) {
      phoneNumberControl.setValidators([Validators.required]);
      phoneNumberControl.setValidators(
        Validators.pattern('^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$')
      ),
        (this.required = true);
    } else {
      phoneNumberControl.setValidators(
        Validators.pattern('^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$')
      ),
        (this.required = false);
    }
    phoneNumberControl.updateValueAndValidity();
  }

  restrictAlphabets(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  get f() {
    return this.signUpForm.controls;
  }

  onUserDetailSubmit() {
    this.submitted = true;
    if (!this.interestedAreas.length) {
      this.signUpForm.controls.interestedAreas.setErrors({ message: 'Interested Areas is required' });
      return;
    }
    if (this.signUpForm.invalid) {
      return;
    }
    if (this.isSignUpInProgress) {
      return;
    }
    this.signupDetails = this.signUpForm.value;
    const signUpObject = {
      email: this.signupDetails.email.toLowerCase(),
      firstName: this.signupDetails.firstName,
      lastName: this.signupDetails.lastName,
      phone: this.signupDetails.phoneNumber,
      password: this.signupDetails.password,
      role: 'employee',
      userId: this.userId,
      domain: this.appState.appBaseUrlValue,
      interestedAreas: this.interestedAreas,
      createdBy: '',
      source: constants.SIGN_UP.source.signUpDialog,
    };

    this.isSignUpInProgress = true;

    this.signupService
      .createUser(signUpObject)
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        finalize(() => {
          this.isSignUpInProgress = false;
          // set default filter after signup
          let defaultPropertyFilter = this.appState.companyInfoValue?.defaultPropertyFilter;
          let queryParams: Record<string, any> = JSON.parse(JSON.stringify(this._route.snapshot.queryParams));
          if (defaultPropertyFilter) {
            const defaultFilter: Partial<PropertyFilter> = defaultPropertyFilter;
            queryParams = { ...queryParams, ...defaultFilter };
            this._router.navigate([], {
              queryParams,
              queryParamsHandling: 'merge',
            });
          }
        })
      )
      .subscribe(
        (response) => {
          if (!response?.data) return;
          this.submitted = false;
          if (!response?.data?.emailVerificationDate) {
            this.signupStatus = true;
          } else {
            this.signupStatus = false;
            this.onNoClick();
          }
        },
        ({ error }: HttpErrorResponse) => {
          this.alerts = { type: 'error', message: error.message };
          setTimeout(() => {
            this.alerts = errorObj;
          }, 3000);
        }
      );
  }

  addAddress() {
    if (this.userAddress && this.userAddress.address_components) {
      let locality = this.userAddress.address_components.find((address) => address.types.includes('locality'));
      if (!locality)
        locality = this.userAddress.address_components.find((address) =>
          address.types.includes('administrative_area_level_3')
        );
      let state = this.userAddress.address_components.find((address) =>
        address.types.includes('administrative_area_level_1')
      );
      if (locality && state) {
        let addressStatus = this.interestedAreas.find((address) => {
          return address.locality == locality.long_name && address.state == state.short_name;
        });
        if (!addressStatus) {
          this.interestedAreas.push({
            locality: locality.long_name ? locality.long_name : '',
            state: state.short_name ? state.short_name : '',
          });
        }
        this.signUpForm.controls.interestedAreas.setErrors(null);
        this.userAddress = {};
        this.f.autoCompleteInput.setValue(' ');
        this.changeDetect.detectChanges();
      }
    }
  }

  deleteAddress(index) {
    this.interestedAreas.splice(index, 1);
    this.changeDetect.detectChanges();
  }

  initAutoComplete() {
    this.autoComplete = new google.maps.places.Autocomplete(this.search.nativeElement, {
      componentRestrictions: { country: 'US' },
      types: ['(cities)'],
    });
    google.maps.event.addListener(this.autoComplete, 'place_changed', () => {
      const place: google.maps.places.PlaceResult = this.autoComplete.getPlace();
      if (place && place.address_components) {
        this.userAddress = place;
        this.addAddress();
      }
    });
  }
  getPlaceFromGeocoder() {
    new google.maps.Geocoder().geocode({ address: this.f.autoCompleteInput.value }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results && results.length) {
        this.userAddress = results[0];
      }
    });
  }

  authenticate(loginForm) {
    this.signupStatus = false;
    if (!loginForm.valid) {
      return;
    }
    if (!this.isUserAuthenticationProgress) {
      this.isUserAuthenticationProgress = true;
      this.loginService
        .userAuthenticate(loginForm.value.email.toLowerCase(), loginForm.value.password)
        .pipe(
          takeUntil(this.onDestroyNotifier$),
          finalize(() => {
            this.isUserAuthenticationProgress = false;
            setTimeout(() => {
              this.loginAlerts = errorObj;
            }, 3000);
          })
        )
        .subscribe(
          () => {
            this.dialogRef.close();
            let queryParams = this.activatedRoute.snapshot.queryParams;

            if (Object.keys(queryParams).length != 0) {
              queryParams = Object.keys(queryParams)
                .filter((key) => key !== 'login')
                .reduce((obj, key) => {
                  obj[key] = queryParams[key];
                  return obj;
                }, {});
            }

            this.router.navigate([], {
              queryParams: queryParams,
              replaceUrl: true,
              state: { skipUpdate: true },
            });
          },
          (err) => {
            this.loginAlerts = { type: 'error', message: err?.message };
          }
        );
    }
  }

  // verifyLoginUsingAccessToken() {
  //   this.alerts = { message: '', type: '' };
  //   this.verifyLoginService
  //     .verifyLogin()
  //     .pipe(takeUntil(this.onDestroyNotifier$))
  //     .subscribe((res) => {
  //       if (res && res.status && res.path) {
  //         if (!this.signupStatus) this.onNoClick();
  //         this.loginAlerts = { message: res.message, type: 'info' };
  //       } else {
  //         this.alerts = { message: res.message, type: 'danger' };
  //       }
  //     });
  // }

  sendPasswordResetLink(data: string) {
    if (!this.forgotPasswordForm.valid) {
      return false;
    }
    this.resetLinkInprogress = true;
    this.forgotPasswordService
      .getPasswordResetLink(data)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(
        (res: { Message: string }) => {
          this.resetLinkInprogress = false;
          if (res && res.Message) {
            this.onNoClick();
            this.dialog.open(DialogComponent, {
              width: '355px',
              data: {
                type: 'resetLink',
                message: 'A reset link has been sent to your registered email',
                title: 'Reset Email Sent',
              },
            });
            this.resetPasswordAlerts = { type: 'info', message: res.Message };
            this.forgotPasswordForm.resetForm();
            setTimeout(() => {
              this.resetPasswordAlerts = errorObj;
            }, 3000);
          }
        },
        (error: { Message: string }) => {
          this.resetLinkInprogress = false;
          this.resetPasswordAlerts = { type: 'danger', message: error.Message };
          setTimeout(() => {
            this.resetPasswordAlerts = errorObj;
          }, 3000);
        }
      );
  }
  getClientInfo() {
    if (this.userId) {
      if (
        (this.appState.clientInfoSubValue &&
          this.appState.clientInfoSubValue.user &&
          this.appState.clientInfoSubValue.user.id != this.userId) ||
        (this.appState.clientInfoSubValue && !this.appState.clientInfoSubValue.user)
      ) {
        const param = { id: this.userId };
        this.appService.getBrandingPartnersInfo(param).pipe(takeUntil(this.onDestroyNotifier$)).subscribe();
      }
    }
  }

  get formValueForResetPwd() {
    return { passCode: '', password: '', confirmPassword: '' };
  }

  onNoClick() {
    // this.router.navigate(['workspace']);
    this.dialogRef.close();
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}

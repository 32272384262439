import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppState } from 'src/app/app.state';

@Injectable({
  providedIn: 'root',
})
export class LogInGuard implements CanActivate {
  returnurl = true;
  constructor(private router: Router, private appState: AppState) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.appState.authTokenValue?.idToken) {
      return true;
    } else {
      if (this.returnurl) {
        sessionStorage.setItem('redirectUrl', state.url);
        this.returnurl = false;
      }
      this.router.navigate(['login']);
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchState {
  private searchAddressSub$ = new BehaviorSubject<any>({
    initialSearch: true,
  });
  searchAddress$ = this.searchAddressSub$.asObservable();
  private searchInitiatedSub$ = new BehaviorSubject<boolean>(false);
  searchInitiated$ = this.searchInitiatedSub$.asObservable();
  private initialLoginSub$ = new BehaviorSubject<boolean>(false);
  initialLogin$ = this.initialLoginSub$.asObservable();
  fullSearchAddress = null;
  searchInitFrom = null;

  get searchAddressValue() {
    return this.searchAddressSub$.getValue();
  }

  set searchAddressValue(address: any) {
    this.searchAddressSub$.next(this.formatSearchAddress(address));
  }

  formatSearchAddress(address) {
    if (address && (address.initialSearch || address.isFormatted)) {
      this.fullSearchAddress = address.fullAddress;
      return address;
    }
    const searchFields: any = {};
    const addressComponents = (address && address.address_components) || [];

    if (addressComponents && addressComponents.length > 0) {
      addressComponents.forEach((addressFields) => {
        if (addressFields && addressFields.short_name && addressFields.types && addressFields.types[0]) {
          switch (addressFields.types[0]) {
            case 'street_number':
              Object.assign(searchFields, { StreetNumber: addressFields.short_name });
              break;
            case 'route':
              Object.assign(searchFields, { StreetName: addressFields.short_name });
              break;
            case 'locality':
              Object.assign(searchFields, { City: addressFields.short_name });
              break;
            case 'administrative_area_level_1':
              Object.assign(searchFields, { State: addressFields.short_name });
              break;
            case 'postal_code':
              Object.assign(searchFields, { PostalCode: addressFields.short_name });
              break;
          }
        }
      });

      if (address && address.geometry && address.geometry.location) {
        searchFields.center = address.geometry.location.toJSON();
      }

      if (address && address.formatted_address) {
        this.fullSearchAddress = address.formatted_address;
        searchFields.fullAddress = address.formatted_address;
      }
      return searchFields;
    }
  }

  public get searchInitiatedValue(): boolean {
    return this.searchInitiatedSub$.value;
  }

  public set searchInitiatedValue(status: boolean) {
    this.searchInitiatedSub$.next(status);
  }

  public get initialLoginValue(): boolean {
    return this.initialLoginSub$.value;
  }

  public set initialLoginValue(status: boolean) {
    this.initialLoginSub$.next(status);
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CompanyApi } from '../api/company.api';
import { Article } from '../interface/article.interface';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  constructor(private companyApi: CompanyApi) {}

  getArticle(article: string): Observable<{ isLoading?: boolean; data?: Article }> {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.companyApi
        .getArticle(article)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(({ data }) => {
          observer.next({ data });
        });
    });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PropertyFilter } from '../interface/property-filter.interface';

@Injectable({
  providedIn: 'root',
})
export class PropertyFilterState {
  /**
   * Get applied filter from the URL as an observable
   * This will emit the applied filters whenever the URL changes
   */
  private appliedFilterSub$: BehaviorSubject<Partial<PropertyFilter>> = new BehaviorSubject<Partial<PropertyFilter>>(
    {}
  );
  appliedFilter$: Observable<Partial<PropertyFilter>> = this.appliedFilterSub$.asObservable();

  get appliedFilter(): Partial<PropertyFilter> {
    return this.appliedFilterSub$.value;
  }

  set appliedFilter(value: Partial<PropertyFilter>) {
    this.appliedFilterSub$.next(value);
  }

  /**
   * Get applied sort by from the URL as an observable
   * This will emit the sort by  whenever the URL changes
   */
  private propertySortFieldsSub$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  propertySortFields$: Observable<any> = this.propertySortFieldsSub$.asObservable();

  get propertySortFields(): Partial<any> {
    return this.propertySortFieldsSub$.value;
  }

  set propertySortFields(value: Partial<any>) {
    this.propertySortFieldsSub$.next(value);
  }

  private isFilterSheetOpenSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isFilterSheetOpen$: Observable<boolean> = this.isFilterSheetOpenSub$.asObservable();

  get isFilterSheetOpen(): boolean {
    return this.isFilterSheetOpenSub$.value;
  }

  set isFilterSheetOpen(value: boolean) {
    this.isFilterSheetOpenSub$.next(value);
  }

  private savedFiltersSub$ = new BehaviorSubject<any>({});
  savedFilters$ = this.savedFiltersSub$.asObservable();

  public get savedFiltersValue(): any {
    return this.savedFiltersSub$.value;
  }
  public set savedFiltersValue(details: object) {
    this.savedFiltersSub$.next(details);
  }
}

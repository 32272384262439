<div class="mt-2">
  <h6 class="mb-2" *ngIf="sharedDealDetailsCount">Shared with :</h6>
  <h6 class="mb-2" *ngIf="!sharedDealDetailsCount">Shared with nobody</h6>
  <table mat-table [dataSource]="tableDataSource" class="mat-elevation-z2 w-100" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef></th>
      <div>
        <td mat-cell *matCellDef="let element">
          {{ element.firstName | titlecase }}
          <span class="email"> ( {{ element.email }} ) </span>
        </td>
      </div>
    </ng-container>
    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef class="actionsWrap"></th>
      <td mat-cell *matCellDef="let element" class="actionsWrap">
        <button mat-icon-button aria-label="delete" class="delete" (click)="deleteSharedDeal(element)">
          <i class="material-icons" style="font-size: 20px" matTooltip="Remove"> delete </i>
        </button>
      </td>
    </ng-container>
    <!-- <tr mat-header-row *matHeaderRowDef="tableColumnList; sticky: true"></tr> -->
    <tr mat-row *matRowDef="let row; columns: tableColumnList"></tr>
  </table>
</div>

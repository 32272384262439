import { Injectable } from '@angular/core';

interface CustomWindow extends Window {
  dataLayer: any[];
}

@Injectable()
export class WindowService {
  get nativeWindow(): CustomWindow {
    return getWindow();
  }

  set nativeWindow(value: CustomWindow) {
    setWindow(value);
  }
}

function getWindow(): any {
  return window;
}

function setWindow(value): void {
  window = value;
}

<button class="close" mat-icon-button (click)="close()" autofocus="false">
  <mat-icon>close</mat-icon>
</button>

<h3>Share</h3>
<div class="share_prop">
  <div class="directshare">
    <form [formGroup]="sharePropertyForm">
      <div class="row">
        <div class="col-md-6 col-12">
          <mat-form-field class="w-100" *ngIf="!appState.authTokenValue?.idToken">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" autocomplete="off" />
            <mat-error
              *ngIf="sharePropertyFormControls.name?.errors && sharePropertyFormControls.name?.errors?.required"
              class="invalid-feedback"
            >
              Your name is required</mat-error
            >
            <mat-error *ngIf="sharePropertyFormControls.name?.errors?.pattern" class="invalid-feedback">
              Enter a valid name</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-md-6 col-12">
          <mat-form-field class="w-100" *ngIf="!appState.authTokenValue?.idToken">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" autocomplete="off" />
            <mat-error
              *ngIf="sharePropertyFormControls.email?.errors && sharePropertyFormControls.email?.errors?.required"
              class="invalid-feedback"
            >
              Your email is required</mat-error
            >
            <mat-error *ngIf="sharePropertyFormControls.email?.errors?.pattern" class="invalid-feedback">
              Invalid email address</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-md-6 col-12 mt-2">
          <mat-form-field class="w-100">
            <mat-label>Recipient Name</mat-label>
            <input matInput formControlName="recipientName" autocomplete="off" />
            <mat-error
              *ngIf="
                sharePropertyFormControls.recipientName?.errors &&
                sharePropertyFormControls.recipientName?.errors?.required
              "
              class="invalid-feedback"
            >
              Name is required</mat-error
            >
            <mat-error *ngIf="sharePropertyFormControls.recipientName?.errors?.pattern" class="invalid-feedback">
              Enter a valid name</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-md-6 col-12 mt-2">
          <mat-form-field class="w-100">
            <mat-label>Recipient Email</mat-label>
            <input matInput formControlName="recipientEmail" autocomplete="off" />
            <mat-error
              *ngIf="
                sharePropertyFormControls.recipientEmail?.errors &&
                sharePropertyFormControls.recipientEmail?.errors?.required
              "
              class="invalid-feedback"
            >
              Email is required</mat-error
            >
            <mat-error *ngIf="sharePropertyFormControls.recipientEmail?.errors?.pattern" class="invalid-feedback">
              Invalid email format</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-12">
          <button
            mat-flat-button
            color="primary"
            class="float-right"
            [disabled]="!enableButton"
            (click)="sendUserDetails()"
          >
            <mat-icon inline>send</mat-icon> Send
          </button>
        </div>
      </div>
    </form>
  </div>
  <hr *ngIf="enableSocialShare || enableCopyLink" />
  <div *ngIf="dealDetails">
    <app-shared-deal-details [dealData]="data.dealData"></app-shared-deal-details>
  </div>

  <div class="socialshare mt-3" *ngIf="enableSocialShare">
    <span>Share link via</span>
    <button class="fb" shareButton="facebook" [url]="shareUrl">
      <img src="/assets/images/facebook.svg" />
    </button>
    <button class="twtr" shareButton="twitter" [url]="shareUrl">
      <img src="/assets/images/twitter.svg" />
    </button>
    <button class="lkdn" shareButton="linkedin" [url]="shareUrl">
      <img src="/assets/images/linkedin.svg" />
    </button>
    <button class="wtsap" shareButton="whatsapp" [url]="shareUrl">
      <img src="/assets/images/whatsapp.svg" />
    </button>
  </div>

  <div class="mt-4" *ngIf="enableCopyLink">
    <span>Or copy link </span>
    <app-copy-field [content]="copyUrl"></app-copy-field>
  </div>
</div>

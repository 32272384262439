import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from 'src/app/app.state';

export interface DialogData {
  content: string;
  title: string;
}
@Component({
  selector: 'app-activate-modal',
  templateUrl: './activate-modal.component.html',
  styleUrls: ['./activate-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivateModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ActivateModalComponent>,
    private router: Router,
    private _ngZone: NgZone,
    private appState: AppState
  ) {}

  ngOnInit() {}

  navigateToPage() {
    const redirectTo = this.appState.navigatedFrom;

    this._ngZone.run(() => {
      this.dialogRef.close();
      if (redirectTo) {
        this.router.navigate([redirectTo]);
      } else {
        this.router.navigate(['workspace']);
      }
    });
  }
}

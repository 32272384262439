import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, CanDeactivate, Router } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { ProfileComponent } from '../../profile/profile.component';
import { LocalStorageService } from '../../shared/service/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileSetupGuard implements CanActivate, CanDeactivate<ProfileComponent> {
  constructor(
    private storage: LocalStorageService,
    private appState: AppState,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  canActivate() {
    if (this.appState.authTokenValue?.idToken) {
      if (this.storage.isProfileUpdated) {
        return true;
      } else {
        this.router.navigate(['profile'], { queryParams: { redirectTo: 'profile' } });
      }
    } else {
      return true;
    }
  }

  canDeactivate(profile: ProfileComponent, route, state, nextState) {
    if (nextState.url == '/login') return true;
    if (this.storage.isProfileUpdated) return true;
    this.router.navigate(['profile'], { queryParams: { redirectTo: 'profile' } });
    this.snackBar.open('Please provide your Office Address to continue', 'Dismiss', {
      duration: 5000,
      panelClass: ['snackbar-warning'],
      verticalPosition: 'top',
    });
    return false;
  }
}

import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SetAddress } from 'src/app/shared/interface/generic-interfaces';
import { UserAddress } from 'src/app/shared/interface/user-details';
import constants from 'src/constant.json';
import { MyHomeService } from '../../service/my-home.service';

@Component({
  selector: 'app-create-my-home-dialogue',
  templateUrl: './create-my-home-dialogue.component.html',
  styleUrls: ['./create-my-home-dialogue.component.scss'],
})
export class CreateMyHomeDialogueComponent implements OnInit, AfterViewInit {
  @ViewChild('searchField') searchField: any;
  address;
  addressSearchAutoComplete: google.maps.places.Autocomplete;
  searchType = '';
  propertyCreationInProgress: boolean = false;
  addressDetails: UserAddress = {
    formattedAddress: null,
    addressComponents: {},
    userLocation: {},
  };
  questionnaire;
  isContinued = false;
  addressFlag = true;
  verificationAddress: any;
  quizId: Number;
  params: any = {};
  onDestroyNotifier$ = new Subject();
  constant: any = constants;

  constructor(
    public dialogRef: MatDialogRef<CreateMyHomeDialogueComponent>,
    private snackBar: MatSnackBar,
    private myHomeService: MyHomeService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    if (this.data && this.data.propertyClaimFlag) {
      this.address = `${this.data.street || ''}, ${this.data.city || ''}, ${this.data.state || ''} ${
        this.data.zip || ''
      }`;
      this.params = this.data;
    } else if (this.data && this.data.street && this.data.city && this.data.state && this.data.zip) {
      this.address = `${this.data.street || ''}, ${this.data.city || ''}, ${this.data.state || ''} ${
        this.data.zip || ''
      }`;
      this.params = this.data;
      this.saveProperty();
    }
  }

  ngAfterViewInit() {
    this.getAutocompleteAddress();
  }

  getAutocompleteAddress() {
    if (this.searchField) {
      this.addressSearchAutoComplete = new google.maps.places.Autocomplete(this.searchField.nativeElement, {
        componentRestrictions: { country: 'US' },
        types: ['geocode'],
      });
      google.maps.event.addListener(this.addressSearchAutoComplete, 'place_changed', () => {
        const place: google.maps.places.PlaceResult = this.addressSearchAutoComplete.getPlace();
        if (place && place.address_components) {
          this.setAddress({ place: place, searchType: this.searchType });
        } else {
          this.setAddress({ place: null, searchType: '' });
        }
      });
    }
  }
  setAddress(event: SetAddress) {
    if (
      event.place &&
      event.place.address_components &&
      event.place.formatted_address &&
      event.place.geometry.location
    ) {
      this.addressDetails.addressComponents = event.place.address_components;
      this.addressDetails.formattedAddress = event.place.formatted_address;
      this.addressDetails.userLocation = event.place.geometry.location.toJSON();
      this.address = event.place.formatted_address;
    } else {
      this.addressDetails.addressComponents = {};
      this.addressDetails.formattedAddress = null;
      this.addressDetails.userLocation = {};
      return false;
    }
  }

  getPlaceFromGeocoder() {
    new google.maps.Geocoder().geocode({ address: this.address }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results && results.length) {
        this.address = results[0].formatted_address;
      }
    });
  }

  async saveProperty() {
    let subPremise: string = null;
    this.quizId = new Date().valueOf();
    if (!Object.keys(this.params).length) {
      if (!this.addressDetails || !Object.keys(this.addressDetails.addressComponents).length) {
        if (!this.address) {
          this.openSnackbar('Please choose a property with valid address.', 'snackbar-warning');
          return false;
        }

        await new google.maps.Geocoder().geocode({ address: this.address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results && results.length) {
            this.addressDetails.addressComponents = results[0].address_components;
            this.addressDetails.formattedAddress = results[0].formatted_address;
            this.addressDetails.userLocation = results[0].geometry.location.toJSON();
          } else {
            this.openSnackbar('Please choose a property with valid address.', 'snackbar-warning');
            return false;
          }
        });
      }

      if (
        this.addressDetails &&
        this.addressDetails.addressComponents &&
        this.addressDetails.addressComponents.length > 0
      ) {
        this.addressDetails.addressComponents.forEach((addressFields) => {
          if (addressFields && addressFields.short_name && addressFields.types && addressFields.types[0]) {
            switch (addressFields.types[0]) {
              case 'street_number':
                Object.assign(this.params, { streetNumber: addressFields.short_name });
                break;
              case 'route':
                Object.assign(this.params, { streetName: addressFields.short_name });
                break;
              case 'locality':
                Object.assign(this.params, { city: addressFields.short_name });
                break;
              case 'administrative_area_level_1':
                Object.assign(this.params, { state: addressFields.short_name });
                break;
              case 'postal_code':
                Object.assign(this.params, { zip: addressFields.short_name });
                break;
              case 'subpremise':
                subPremise = addressFields.short_name;
                break;
            }
          }
        });
      }
    }

    if (!this.params.streetNumber && !this.params.streetName && !this.params.street) {
      this.openSnackbar('Please choose a property with valid address.', 'snackbar-warning');
      return false;
    } else if (this.params.streetNumber && this.params.streetName) {
      this.params = { ...this.params, street: this.params.streetNumber + ' ' + this.params.streetName };
      delete this.params.streetNumber;
      delete this.params.streetName;
    } else if (this.params.streetNumber && !this.params.streetName) {
      this.params = { ...this.params, street: this.params.streetNumber };
      delete this.params.streetNumber;
    } else if (!this.params.streetNumber && this.params.streetName) {
      this.params = { ...this.params, street: this.params.streetName };
      delete this.params.streetName;
    } else if (this.params.street) {
      this.params = { ...this.params, street: this.params.street };
    }

    if (this.params && !Object.keys(this.params).length) {
      this.openSnackbar('Please choose a property with valid address', 'snackbar-warning');
      return false;
    } else {
      if ((this.params.street && this.params.zip) || (this.params.street && this.params.city && this.params.state)) {
        // if (this.data && Object.keys(this.data).length) {
        //   this.data.proceedAction();
        // }
        if (subPremise) {
          let subPreFix = subPremise.toLowerCase();
          let isPrefixIncluded = this.constant.UNIT_PREFIX.some((prefix) => subPreFix.includes(prefix.toLowerCase()));
          if (isPrefixIncluded == true) {
            this.params.street += ' ' + subPremise;
          } else {
            this.params.street += ' # ' + subPremise;
          }
        }
        this.myHomeService
          .saveHome(this.params)
          .pipe(takeUntil(this.onDestroyNotifier$))
          .subscribe(
            (response: any) => {
              if (response && response.status == false) {
                this.addressFlag = false;
                this.isContinued = true;
                this.questionnaire = {
                  id: null,
                  question: null,
                  options: {},
                  subType: null,
                  activeStage: 1,
                  totalStage: 1,
                  message: response.message,
                  status: response.status,
                };
              } else {
                this.propertyCreationInProgress = response.isLoading;
                if (!response.isLoading && response.addressValidation) {
                  this.verificationAddress = this.params;
                  this.getQuestionnaire(response);
                  // this.dialogRef.close();
                } else {
                  this.addressDetails = {
                    formattedAddress: null,
                    addressComponents: {},
                    userLocation: {},
                  };
                }
              }
            },
            (error) => {}
          );
      } else {
        this.openSnackbar('Please choose a property with valid address.', 'snackbar-warning');
        return false;
      }
    }
  }

  proceedCancelAction() {
    this.dialogRef.close();
    this.isContinued = false;
  }

  openSnackbar(message, style) {
    this.snackBar.open(message, '', {
      duration: 3000,
      panelClass: [style],
      verticalPosition: 'top',
    });
  }

  getQuestionnaire(event = null) {
    if (event && event.close) {
      this.proceedCancelAction();
    } else {
      this.addressFlag = false;
      // this.isContinued = true;
      let answerResponse: any = {
        city: this.verificationAddress.city,
        state: this.verificationAddress.state,
        street: this.verificationAddress.street,
        zip: this.verificationAddress.zip,
        questAns: {
          qId: '',
          response: '',
        },
      };
      if (event.data) {
        this.questionnaire = {
          id: event.data.qId,
          question: event.data.question,
          options: event.data.responseOption,
          subType: event.data.subType,
          activeStage: event.data.stage - 1,
          totalStage: 6,
          response: null,
        };
      }

      if (event && event.id) {
        answerResponse.questAns.qId = event.id;
        answerResponse.questAns.response = event.response;
        answerResponse.questAns.quizId = this.quizId;
        this.myHomeService
          .saveHome(answerResponse)
          .pipe(takeUntil(this.onDestroyNotifier$))
          .subscribe((response: any) => {
            if (!response.isLoading) {
              if (response && response.data && response.data.qId) {
                this.questionnaire = {
                  id: response.data.qId,
                  question: response.data.question,
                  options: response.data.responseOption,
                  subType: response.data.subType,
                  activeStage: response.data.stage - 1,
                  totalStage: 6,
                  response: null,
                  message: response.message,
                };
              } else {
                this.questionnaire = {
                  id: null,
                  question: null,
                  options: {},
                  subType: null,
                  activeStage: 1,
                  totalStage: 1,
                  message: response.message,
                  status: response.status,
                };
                if (response.status) {
                  setTimeout(() => {
                    this.proceedCancelAction();
                    window.location.reload();
                  }, 2000);
                }
              }
            }
          });
      }
    }
  }
  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NumberToTextConverter',
})
export class NumberToTextConverterPipe implements PipeTransform {
  transform(digit: any): any {
    if (digit === 1) {
      return 'First';
    } else if (digit === 2) {
      return 'Second';
    } else if (digit === 3) {
      return 'Third';
    } else if (digit === 4) {
      return 'Fourth';
    }
  }
}

<!-- stripe-form.component.html -->

<form #paymentForm method="post" [hidden]="savedCards.length > 0" class="add-card-container">
  <div #cardElement id="cardElement"></div>
  <button type="button" class="btn btn-primary mt-3 submit-btn" (click)="onSubmit()" [disabled]="isLoading">
    <ng-container *ngIf="!isLoading"> Submit Card </ng-container>
    <span *ngIf="isLoading">&nbsp;&nbsp;<i class="fa fa-spinner fa-spin"></i> Adding...</span>
  </button>
</form>

<div *ngIf="savedCards.length > 0">
  <h5 class="mt-3">Saved Card</h5>
  <div class="card-container">
    <div *ngFor="let card of savedCards" class="card">
      <div class="card-details">
        <p class="last4">•••• {{ card.card.last4 }}</p>
        <p class="brand">{{ card.card.brand }}</p>
        <p class="expiry">{{ card.card.exp_month }}/{{ card.card.exp_year }}</p>
      </div>
      <div class="delete-btn" (click)="deleteCard(card.id)">
        <i *ngIf="!isDeletingCard" class="fa fa-trash-o mr-1"></i>
        <i *ngIf="isDeletingCard" class="fa fa-spinner fa-spin mr-1"></i>
      </div>
    </div>
  </div>
</div>

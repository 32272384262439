import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { isEqual, uniqWith } from 'lodash-es';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SelectionModel } from '@angular/cdk/collections';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { PartialProperty } from 'src/app/_shared/interface/property.interface';
import { AppState } from 'src/app/app.state';
import { WorkspaceService } from 'src/app/workspace/service/workspace.service';
import { ListState } from 'src/app/workspace/state/list.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import MESSAGE from 'src/assets/resource/strings.json';
import { ListFacade } from '../list.facade';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  favoriteActionCompletedStatus;
  frontEndSortAndPagination = false;
  isFullPropertyListAvailable = true;
  propertyDataSource = new MatTableDataSource<PartialProperty>(null);
  propertyList: Array<PartialProperty>;
  propertySelectionModel = new SelectionModel<PartialProperty>(true, []);
  searchInProgress: boolean = false;
  selectedPMXPropertyIdList = [];
  selectedStateList = [];
  stringRes: any = MESSAGE;
  totalPropertyCount: number = null;
  columnTitle = [
    'select',
    'isFavorite',
    'Address',
    'Lst_StandardStatus',
    'Lst_ListPrice',
    'Lst_ListingContractDate',
    'PropertySubType',
    'Lst_FlipDuration',
    'BedroomsTotal',
    'BathroomsTotalInteger',
    'LivingArea',
    'LotSizeSquareFeet',
    'ClosePrice',
    'LastSaleContractDate',
    'TaxAnnualAmount',
    'OwnershipDuration',
  ];

  // Subscription to unsubscribe on component destroy
  onDestroyNotifier$ = new Subject();
  @Input() showPropertyList: any;

  constructor(
    private authorizeService: AuthorizeService,
    private cdr: ChangeDetectorRef,
    private appState: AppState,
    private workspaceService: WorkspaceService,
    public listFacade: ListFacade,
    public listState: ListState,
    public propertyState: PropertyState
  ) {}

  ngOnInit() {
    this.appState.workspaceView = 'list';

    this.propertyState.listPropertySet$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (!res || !res.length) this.propertyList = [];
      this.propertyDataSource.data = res;
      console.log('LIST : ', res);
      this.cdr.detectChanges();
      console.log('DATASOURCE : ', this.propertyDataSource);
    });

    this.propertyState.paginationApiProgress$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.searchInProgress = res;
      this.cdr.detectChanges();
    });

    this.propertyState.propertyCount$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.totalPropertyCount = res.totalRecord;
      // this.cdr.detectChanges();
    });
  }

  calculateDays(activeProperty) {
    if (
      activeProperty.Lst_StandardStatus === 'Active' ||
      activeProperty.Lst_StandardStatus === 'Active Under Contract' ||
      activeProperty.Lst_StandardStatus === 'Pending'
    ) {
      return activeProperty.Lst_ListingContractDate
        ? moment().diff(activeProperty.Lst_ListingContractDate, 'days')
        : null;
    } else {
      return null;
    }
  }

  ngAfterViewInit() {
    this.favoriteActionCompletedStatus = this.listFacade
      .getFavoriteActionCompletedStatus()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        if (res) {
          this.propertySelectionModel.clear();
          this.selectedPMXPropertyIdList = [];
          this.selectedStateList = [];
          this.cdr.detectChanges();
        }
      });
  }

  ngOnDestroy() {
    this.favoriteActionCompletedStatus.unsubscribe();
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  pageChange(event) {
    if (event) {
      this.listFacade.pagination(event.pageIndex);
    }
  }

  sortColumn(event) {
    if (!this.listFacade.getIsFullPropertyListAvailable()) {
      this.listFacade.listSort(event);
    }
  }

  toggleSelectAll() {
    if (this.isAllSelected()) {
      this.propertySelectionModel.clear();
      this.selectedPMXPropertyIdList = [];
      this.selectedStateList = [];
    } else {
      this.propertyDataSource.data.forEach((row) => this.propertySelectionModel.select(row));
      this.selectedPMXPropertyIdList = this.propertyDataSource.data.map((property) => {
        return property['PMXPropertyId'];
      });
      this.selectedStateList = this.propertyDataSource.data.map((property) => {
        return property['StateOrProvince'];
      });
    }
  }

  isAllSelected() {
    return this.selectedPMXPropertyIdList.length === this.propertyDataSource.data.length;
  }

  toggleSelect(row, checked) {
    this.propertySelectionModel.toggle(row);
    if (checked) {
      this.selectedPMXPropertyIdList.push(row.PMXPropertyId);
      this.selectedStateList.push(row.StateOrProvince);
    } else {
      this.selectedPMXPropertyIdList = this.selectedPMXPropertyIdList.filter((pmxPropertyId) => {
        return pmxPropertyId !== row.PMXPropertyId;
      });
      this.selectedStateList = this.selectedStateList.filter((stateOrProvince) => {
        return stateOrProvince !== row.StateOrProvince;
      });
    }
  }

  preserveCheckboxSelection() {
    if (this.propertyDataSource && this.propertyDataSource.data && this.propertyDataSource.data.length > 0) {
      this.propertyDataSource.data.forEach((row) => {
        if (this.selectedPMXPropertyIdList.includes(row['PMXPropertyId'])) {
          this.propertySelectionModel.select(row);
        }
      });
    }
  }

  openPropertyDetailsSheet(property) {
    if (this.authorizeService.canAct('mapControl')) {
      this.workspaceService.openPropertyDetailsSheet(property);
    }
  }

  addToFavorite() {
    if (this.authorizeService.canAct('favorites')) {
      if (this.authorizeService.canAct('mapControl')) {
        if (
          this.selectedPMXPropertyIdList &&
          this.selectedPMXPropertyIdList.length > 0 &&
          this.selectedStateList &&
          this.selectedStateList.length > 0
        ) {
          this.selectedStateList = uniqWith(this.selectedStateList, isEqual);
          this.listFacade.openFavoriteModal(this.selectedPMXPropertyIdList);
        }
      }
    }
  }
  openLocalTrends() {
    this.listFacade.setOpenLocalTrendsStatus(true);
  }
}

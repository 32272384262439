import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  selectedFile: File | null = null;
  droppedFile: File | null = null;
  hasFile = false;
  isValidSize = false;
  uploadImgStarted = false;
  portFolioList: any;
  bannerImage: any;
  url: any;
  isLoading: boolean = false;
  disableButton: boolean = true;
  @Output() uploadedFile = new EventEmitter<object>();
  @Input() imageType: any;

  constructor(private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.isLoading = false;
  }

  onFileSelected(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.selectedFile = event.target.files[0];
      if (
        this.selectedFile.type != 'image/jpeg' &&
        this.selectedFile.type != 'image/png' &&
        this.selectedFile.type != 'image/webp'
      ) {
        this.openSnackBar('Upload image file', 'snackbar-warning');
        return;
      }
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.bannerImage = event.target.result;
        if (this.bannerImage) {
          this.disableButton = false;
        }
      };
    }
    this.hasFile = true;
  }

  onFileDropped(event: any) {
    event.preventDefault();
    this.selectedFile = event.dataTransfer.files[0];
    if (
      this.selectedFile.type != 'image/jpeg' &&
      this.selectedFile.type != 'image/png' &&
      this.selectedFile.type != 'image/webp'
    ) {
      this.openSnackBar('Upload image file', 'snackbar-warning');
      return;
    }
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      var reader = new FileReader();
      this.selectedFile = event.dataTransfer.files[0];
      reader.readAsDataURL(event.dataTransfer.files[0]);
      reader.onload = (event) => {
        this.bannerImage = event.target.result;
        if (this.bannerImage) {
          this.disableButton = false;
        }
      };
    }
    this.hasFile = true;
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  selectImage(file) {
    let uploadFile = {
      file: file,
      type: this.imageType,
    };
    this.uploadedFile.emit(uploadFile);
    this.isLoading = true;
  }

  private openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Json } from '../interface/common.interface';

@Injectable({
  providedIn: 'root',
})
export class FiltersState {
  constructor() {}

  /**
   * To store the list of applied filters as required by the Property Search API requests.
   * Values in this state will be passed as QueryParams in the Property Search APIs.
   */
  private appliedFilterSetSub$ = new BehaviorSubject<Json>(null);
  appliedFilterSet$ = this.appliedFilterSetSub$.asObservable();
  public get appliedFilterSetValue(): Json {
    return this.appliedFilterSetSub$.value;
  }
  public set appliedFilterSetValue(filterSet: Json) {
    this.appliedFilterSetSub$.next(filterSet);
  }

  private detailedFilterListSub$ = new BehaviorSubject<any>(null);
  detailedFilterList$ = this.detailedFilterListSub$.asObservable();
  $isSoldWith = new BehaviorSubject<boolean>(false);
  isSoldWith = this.$isSoldWith.asObservable();
  basicFilterList = {};

  appliedListingStatus = ['active', 'active under contract'];

  get basicFilterListValue() {
    return this.basicFilterList;
  }

  set basicFilterListValue(filters) {
    this.basicFilterList = filters;
  }

  set detailedFilterListValue(filters) {
    this.detailedFilterListSub$.next(filters);
  }

  get detailedFilterListValue() {
    return this.detailedFilterListSub$.getValue();
  }

  set isSoldWithInValue(filters) {
    this.$isSoldWith.next(filters);
  }

  get isSoldWithInValue() {
    return this.$isSoldWith.getValue();
  }
}

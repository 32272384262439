import { Injectable } from '@angular/core';
import constants from 'src/constant.json';

@Injectable({
  providedIn: 'root',
})
export class ValidatorsService {
  constructor() {}
  get patterns() {
    return {
      email: {
        pattern: constants.regex.validEmail,
        message: 'Enter a valid email',
      },
      userName: {
        pattern: '^([a-zA-Z]|[a-zA-Z][a-zA-Z0-9.,$; @]+)$',
        message: 'Enter a valid user name',
      },
    };
  }
}

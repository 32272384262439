import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Contact, ContactGroup } from '../interface/contact.interface';

@Injectable({
  providedIn: 'root',
})
export class ContactState {
  private contactGroupListSub$ = new BehaviorSubject<Array<ContactGroup>>(null);
  contactGroupList$ = this.contactGroupListSub$.asObservable();
  public get contactGroupListValue(): Array<ContactGroup> {
    return this.contactGroupListSub$.value;
  }
  public set contactGroupListValue(contactGroupList: Array<ContactGroup>) {
    this.contactGroupListSub$.next(contactGroupList);
  }

  private contactListSub$ = new BehaviorSubject<Array<Contact>>(null);
  public contactList$ = this.contactListSub$.asObservable();
  public get contactListValue(): Array<Contact> {
    return this.contactListSub$.value;
  }
  public set contactListValue(contactList: Array<Contact>) {
    this.contactListSub$.next(contactList);
  }

  public contactsListSub$ = new BehaviorSubject<Array<Contact>>(null);
  public contactsList$ = this.contactsListSub$.asObservable();
  public get contactsListValue(): Array<Contact> {
    return this.contactsListSub$.value;
  }
  public set contactsListValue(contactList: Array<Contact>) {
    this.contactsListSub$.next(contactList);
  }

  public allContactsListSub$ = new BehaviorSubject<Array<Contact>>(null);
  public allContactsList$ = this.allContactsListSub$.asObservable();
  public get allContactsListValue(): Array<Contact> {
    return this.allContactsListSub$.value;
  }
  public set allContactsListValue(contactList: Array<Contact>) {
    this.allContactsListSub$.next(contactList);
  }

  private selectedContactsListSub$ = new BehaviorSubject(null);
  selectedContactsList$ = this.selectedContactsListSub$.asObservable();
  public get selectedContactsListValue() {
    return this.selectedContactsListSub$.value;
  }
  public set selectedContactsListValue(campaign) {
    this.selectedContactsListSub$.next(campaign);
  }
}

<div id="save_base">
  <button
    mat-flat-button
    [matMenuTriggerFor]="saveSearchMenu"
    id="savepop_btn"
    aria-label="save search"
    class="save_search"
    color="primary"
  >
    Save Search
  </button>
  <mat-menu (click)="$event.stopPropagation()" #saveSearchMenu="matMenu" class="savepop_btnWrap">
    <div (click)="$event.stopPropagation()" class="savedpop_base">
      <div class="clearfix"></div>
      <div class="savesearch_base">
        <form [formGroup]="saveFilterForm" (ngSubmit)="saveSearch()">
          <mat-form-field>
            <input
              matInput
              type="text"
              placeholder="Enter name"
              name="saveSearchFilterInput"
              formControlName="savedSearch"
              required
              autocomplete="off"
              aria-label="enter search name"
            />
          </mat-form-field>
          <div>
            <button type="submit" mat-button class="btn submit_btn blue_btn pl-4 pr-4 ml-1" aria-label="save">
              Save
            </button>
            <button
              mat-button
              class="btn submit_btn cancelBtn line_btn pl-4 pr-4 ml-1"
              (click)="cancelSaveSearch()"
              aria-label="cancel"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </mat-menu>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import constants from 'src/constant.json';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FavoriteApi {
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;

  constructor(private http: HttpClient) {}

  fetchFavoriteByGroup(favoriteGroupId: string, params: any): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}favorite-group/${favoriteGroupId}`, {
      params: params,
    });
  }

  fetchFavoriteIdsByGroup(favoriteGroupId: string): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}favorite-group/${favoriteGroupId}/favoriteIds`);
  }

  getAllFavorites(): Observable<any> {
    return this.http.post(environment.prospektrBackendBaseURL + constants.API.fetchFavorite, null);
  }

  fetchFollowGroups(): Observable<any> {
    return this.http.post<any>(environment.prospektrBackendBaseURL + constants.API.fetchFollowedGroupName, null);
  }

  createGroup(params): Observable<any> {
    return this.http.post<any>(environment.prospektrBackendBaseURL + constants.API.createFollowGroup, params);
  }

  deleteGroup(groupId): Observable<any> {
    const params = {
      id: groupId,
    };
    return this.http.post<any>(environment.prospektrBackendBaseURL + constants.API.deleteFollowGroup, params);
  }

  copyFavorites(inputParams): Observable<any> {
    const params = {
      favoriteList: inputParams.favoriteList,
      followGroupList: inputParams.followGroupList,
    };
    return this.http.post<any>(environment.prospektrBackendBaseURL + constants.API.copyFavorite, params);
  }

  moveFavorite(inputParams): Observable<any> {
    const params = {
      favoriteList: inputParams.favoriteList,
      followGroupList: inputParams.followGroupList,
    };
    return this.http.post<any>(environment.prospektrBackendBaseURL + constants.API.moveFavorite, params);
  }

  addFavorites(data): Observable<any> {
    const params = {
      followGroupList: data.GroupIds,
      PMXPropertyIdList: data.PMXPropertyIdList,
      stateList: data.stateList,
    };
    return this.http.post<any>(environment.prospektrBackendBaseURL + constants.API.addFavorite, params);
  }

  createFavoritesByRecommendation(params): Observable<any> {
    return this.http.post(environment.prospektrBackendBaseURL + constants.API.favoriteRecommendation, params);
  }

  unFollowFavorite(params): Observable<any> {
    return this.http.post<any>(
      environment.prospektrBackendBaseURL + constants.API.unfollowPropertyFromAllGroup,
      params
    );
  }

  exportFavoriteToExternal(params): Observable<any> {
    return this.http.post<any>(`${environment.prospektrBackendBaseURL}exportFavoriteToExternal`, params);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { MapConfig } from 'src/app/_shared/config/map.config';
import { WorkspaceState } from 'src/app/_shared/state/workspace.state';
import MESSAGE from 'src/assets/resource/strings.json';
import { StatusBarFacade } from '../../../workspace/component/map/status-bar.facade';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
})
export class StatusBarComponent implements OnInit, OnDestroy {
  constructor(
    public statusBarFacade: StatusBarFacade,
    public authorizeService: AuthorizeService,
    private mapConfig: MapConfig,
    public workspaceState: WorkspaceState
  ) {}
  stringRes: any = MESSAGE;
  hotPropertyCount = 0;
  warmPropertyCount = 0;
  coldPropertyCount = 0;
  activePropensityList = [];
  defaultZoomLevel = this.mapConfig.defaultZoom;
  isDataAvailable = true;
  noDataAvailableMessage = '';
  noDataAvailable;
  activePropensity;
  propensityCount;
  onDestroyNotifier$ = new Subject();

  ngOnInit() {
    this.initSubscription();
    this.getPropensityCount();
  }

  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  initSubscription() {
    this.activePropensity = this.statusBarFacade
      .getActivePropensityList$()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        this.activePropensityList = res;
      });
    this.noDataAvailable = this.statusBarFacade
      .getNoDataAvailableMessage()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        if (res) {
          this.isDataAvailable = false;
          this.noDataAvailableMessage = res;
        } else {
          this.isDataAvailable = true;
          this.noDataAvailableMessage = '';
        }
      });
  }

  getPropensityCount() {
    this.propensityCount = this.statusBarFacade
      .getPropensityCount$()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        if (res) {
          this.hotPropertyCount = res.hot;
          this.warmPropertyCount = res.warm;
          this.coldPropertyCount = res.cold;
        } else {
          this.hotPropertyCount = 0;
          this.warmPropertyCount = 0;
          this.coldPropertyCount = 0;
        }
      });
  }

  setFilterStatus(event, type, status) {
    if (this.authorizeService.canAct('mapControl')) {
      event.stopPropagation();
      this.statusBarFacade.setActivePropensityList(type, status);
    }
  }

  openLocalTrends() {
    this.statusBarFacade.setOpenLocalTrendsStatus(true);
  }
}

<mat-card class="mb-3">
  <mat-card-content>
    <mat-card-title>
      <h4>{{ item.name }}</h4>
      <button mat-icon-button aria-label="delete" class="delete" *ngIf="canDelete" (click)="deleteItem(item)">
        <i class="material-icons" style="font-size: 18px" matTooltip="Remove"> delete </i>
      </button>
      <button
        (click)="$event.stopPropagation()"
        mat-icon-button
        aria-label="delete"
        class="delete"
        *ngIf="item.isOwned && !canDelete"
        (click)="deleteItemForClients(item)"
      >
        <i class="material-icons" style="font-size: 18px" matTooltip="Remove"> delete </i>
      </button>
    </mat-card-title>
    <div class="address">
      {{ item.placeDetails?.fullAddress }}
    </div>
    <div class="clearfix"></div>
    <ng-container *ngFor="let filter of item.filterList | slice: 0:4">
      <div class="filter-items">
        <span
          ><b>
            {{ filter.key }}
          </b>
          <span matTooltip="{{ filter.value }}" matTooltipPosition="above">
            {{ filter.value }}
          </span>
        </span>
      </div>
    </ng-container>
    <div class="clearfix"></div>
    <!-- <div class="clearfix" style="height: 67px">&nbsp;</div> -->
    <div class="saved-filters" *ngIf="hasMoreFilters">
      <a (click)="showAllFilters(item.filterList, item)">Show all Filters</a>
    </div>
    <div class="clearfix"></div>
    <!-- <div class="saved-filters" *ngIf="!hasMoreFilters">&nbsp;</div> -->
    <mat-card-footer>
      <div class="width-30">
        <em>{{ item.createdDate | simpleDate }}</em>
        <div *ngIf="!item.isOwned">Added by {{ item.createdUserInfo }}</div>
      </div>

      <button mat-button color="primary" (click)="applyFilters(item)">View Properties</button>
      <button mat-icon-button aria-label="delete" class="delete" (click)="editSavedSearch(item)" *ngIf="editNeeded">
        <mat-icon matTooltip="Create a copy of this search">content_copy</mat-icon>
      </button>
    </mat-card-footer>
  </mat-card-content>
</mat-card>

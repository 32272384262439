import { Component, Input } from '@angular/core';
interface CompanyNavBar {
  data: any;
}
@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements CompanyNavBar {
  constructor() {}

  @Input() data;
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, take } from 'rxjs/operators';

import { BehaviorSubject, Subject } from 'rxjs';
import { PublicRecordApi as _PublicRecordApi } from 'src/app/_shared/api/public-record.api';
import { MapProperty } from 'src/app/_shared/interface/property.interface';
import { FavoriteState } from 'src/app/_shared/state/favorite.state';
import { Utility } from 'src/app/_shared/utility/utility';
import { PropertiesByGeoDefaultParams } from '../../shared/interface/properties-interface';
import { PublicRecordApi } from '../api/public-record.api';
import { MapState } from '../state/map.state';
import { PropertyState } from '../state/property.state';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  PropertiesByGeoDefaultParams: PropertiesByGeoDefaultParams;
  utility = new Utility();
  $searchOnMap = new BehaviorSubject<any>(false);
  searchOnMap = this.$searchOnMap.asObservable().pipe(debounceTime(500));
  $searchOnList = new BehaviorSubject<any>(false);
  searchOnList = this.$searchOnList.asObservable().pipe(debounceTime(500));
  canViewMapControl: boolean = false;
  private eventSubject = new Subject<any>();

  constructor(
    private _publicRecordApi: _PublicRecordApi,
    private favoriteState: FavoriteState,
    private propertyState: PropertyState,
    private publicRecordApi: PublicRecordApi,
    private mapState: MapState,
    private snackBar: MatSnackBar
  ) {
    this.initSubscription();
  }

  initSubscription() {
    this.propertyState.schoolsForPropertyParam$.subscribe((res) => {
      //console.log('$#%@ property-service-10'); // do not remove
      if (res && !this.isEmptyObject(res)) {
        this.getSchoolDistrictsForProperty(res);
        this.getSchoolsForProperty(res);
      }
    });
    this.updatePropertyFavoriteState();
  }

  updatePropertyFavoriteState() {
    let mapPropertySet: MapProperty = null;
    this.favoriteState.favoritePropertyIdList$.subscribe((favoritePropertyIdList) => {
      // Update properties in propertyState.mapPropertySet with favorite status
      if (this.propertyState.mapPropertySetValue && Object.keys(this.propertyState.mapPropertySetValue).length) {
        Object.keys(this.propertyState.mapPropertySetValue).forEach((propertyKey) => {
          if (!mapPropertySet) mapPropertySet = {};
          mapPropertySet[propertyKey] = this.propertyState.mapPropertySetValue[propertyKey];
          mapPropertySet[propertyKey].propertyList = this.propertyState.mapPropertySetValue[
            propertyKey
          ].propertyList.map((property) => {
            if (favoritePropertyIdList.includes(property.PMXPropertyId)) {
              property.isFavorite = true;
            } else {
              property.isFavorite = false;
            }
            return property;
          });
        });
        this.propertyState.mapPropertySetValue = mapPropertySet;
      }

      // Update properties in propertyState.listPropertySet with favorite status
      if (this.propertyState.listPropertySetValue?.length) {
        this.propertyState.listPropertySetValue = this.propertyState.listPropertySetValue.map((property) => {
          if (favoritePropertyIdList.includes(property.PMXPropertyId)) {
            property.isFavorite = true;
          } else {
            property.isFavorite = false;
          }
          return property;
        });
      }
    });
  }

  sendEvent(event: any) {
    this.eventSubject.next(event);
  }

  getEvent() {
    return this.eventSubject.asObservable();
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  getMlsImageInfo(mlsBoard) {
    const params = {
      params: mlsBoard,
    };

    this._publicRecordApi.getMlsImageDetails(params).subscribe(
      (res) => {
        if (res && res.data) {
          this.propertyState.mlsImageValue = res.data;
        }
      },
      (err) => {
        this.propertyState.mlsImageValue = null;
      }
    );
  }

  getListingData(PMXPropertyId) {
    if (
      this.propertyState.listingInformationValue &&
      this.propertyState.listingInformationValue['PMXPropertyId'] &&
      this.propertyState.listingInformationValue['PMXPropertyId'] === PMXPropertyId
    ) {
      this.propertyState.listingInformationValue = this.propertyState.listingInformationValue;
    } else {
      const params = {
        PMXPropertyId: PMXPropertyId,
      };
      this.publicRecordApi.getListingData(params).subscribe(
        (res) => {
          if (res && res.Status && res.Data && res.Data.Records && res.Data.Records.length) {
            let listingInfo = { ...res.Data.Records[0], ...params };
            this.propertyState.listingInformationValue = listingInfo;
          } else {
            this.propertyState.listingInformationValue = params;
          }
        },
        (err) => {
          this.propertyState.listingInformationValue = {};
        }
      );
    }
  }

  getSchoolDistrictsForProperty(params) {
    if (
      this.propertyState.activeSchoolDistrictsForPropertyValue &&
      this.propertyState.activeSchoolDistrictsForPropertyValue['input'] &&
      JSON.stringify(this.propertyState.activeSchoolDistrictsForPropertyValue['input']) === JSON.stringify(params)
    ) {
      this.propertyState.activeSchoolDistrictsForPropertyValue =
        this.propertyState.activeSchoolDistrictsForPropertyValue;
    } else {
      this._publicRecordApi.getSchoolDistrictsForProperty(params).subscribe(
        (res) => {
          //console.log('$#%@ property-service-24'); // do not remove
          if (res && res.Status && res.Status.Code && res.Status.Code === 200 && res.Data && res.Data.SchoolDistricts) {
            this.propertyState.activeSchoolDistrictsForPropertyValue = {
              districts: res.Data.SchoolDistricts,
              input: params,
            };
          } else if (res && res.Status && res.Status.Code && res.Status.Code === 200) {
            this.propertyState.activeSchoolDistrictsForPropertyValue = { input: params };
          }
        },
        (err) => {
          this.propertyState.activeSchoolDistrictsForPropertyValue = {};
        }
      );
    }
  }

  getSchoolsForProperty(params) {
    if (
      this.propertyState.activeSchoolsForPropertyValue &&
      this.propertyState.activeSchoolsForPropertyValue['input'] &&
      JSON.stringify(this.propertyState.activeSchoolsForPropertyValue['input']) === JSON.stringify(params)
    ) {
      this.propertyState.activeSchoolsForPropertyValue = this.propertyState.activeSchoolsForPropertyValue;
    } else {
      let requestParams = {};
      requestParams = { ...params, Radius: 5 };
      this._publicRecordApi.getSchoolsForProperty(requestParams).subscribe(
        (res) => {
          //console.log('$#%@ property-service-25'); // do not remove
          if (res && res.Status && res.Status.Code && res.Status.Code === 200 && res.Data && res.Data.Schools) {
            this.propertyState.activeSchoolsForPropertyValue = { schools: res.Data.Schools, input: params };
          } else if (res && res.Status && res.Status.Code && res.Status.Code === 200) {
            this.propertyState.activeSchoolsForPropertyValue = { input: params };
          }
        },
        (err) => {
          this.propertyState.activeSchoolsForPropertyValue = {};
        }
      );
    }
  }

  searchParamsFormatter(searchData) {
    let searchAddress = {};
    if (searchData && searchData['StreetNumber'] && searchData['StreetName']) {
      Object.assign(searchAddress, { StreetAddress: searchData['StreetNumber'] + ' ' + searchData['StreetName'] });
    } else if (searchData && searchData['StreetNumber']) {
      Object.assign(searchAddress, { StreetAddress: searchData['StreetNumber'] });
    } else if (searchData && searchData['StreetName']) {
      Object.assign(searchAddress, { StreetAddress: searchData['StreetName'] });
    }
    if (searchData && searchData['City']) {
      Object.assign(searchAddress, { City: searchData['City'] });
    }
    if (searchData && searchData['State']) {
      Object.assign(searchAddress, { State: searchData['State'] });
    }
    if (searchData && searchData['PostalCode']) {
      Object.assign(searchAddress, { PostalCode: searchData['PostalCode'] });
    }
    return searchAddress;
  }

  getStreetName(res) {
    let streetName = res.StreetName;
    if (res.StreetDirPrefix) streetName = res.StreetDirPrefix + ' ' + streetName;
    if (res.StreetSuffix) streetName = streetName + ' ' + res.StreetSuffix;
    if (res.StreetDirSuffix) streetName = streetName + ' ' + res.StreetDirSuffix;
    return streetName;
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }

  fetchListingsByMlsNumber(mlsNumber: string) {
    this.publicRecordApi.getPropertiesByMlsNumber(mlsNumber).subscribe(
      (res) => {
        if (res && res.length > 0) {
          let selectedListing = null;
          // use the results to find the closest listing based on the current map coordinates and display it
          this.mapState.mapBounds$.pipe(take(1)).subscribe((boundingBox) => {
            let centerLat = (boundingBox.NELatitude + boundingBox.SWLatitude) / 2;
            let centerLng = (boundingBox.NELongitude + boundingBox.SWLongitude) / 2;
            let minDistance = 10000000;
            for (var i = 0; i < res.length; i++) {
              let distance = this.calcDistance(centerLat, centerLng, res[i].Latitude, res[i].Longitude);
              if (distance < minDistance) {
                selectedListing = res[i];
              }
            }
          });
          let addressObj: any = {
            StreetAddress: selectedListing.StdAddress.UnparsedAddress,
            City: selectedListing.StdAddress.City,
            State: selectedListing.StdAddress.StateOrProvince,
            PostalCode: selectedListing.StdAddress.PostalCode,
            logInput: true,
          };

          this.propertyState.propertyDetailsSheetInput = addressObj;
          //this.propertyState.sharedPMXPropertyValue = Number(pmxPropertyId);
        } else {
          console.error('Could not find listings for mls number');
          this.snackBar.open('Could not find a listing with that listing number', '', {
            duration: 3000,
            verticalPosition: 'top',
          });
        }
      },
      (err) => {
        console.error('Could not find listings for mls number');
        this.snackBar.open('Could not find a listing with that listing number', '', {
          duration: 3000,
          verticalPosition: 'top',
        });
      }
    );
  }

  calcDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = this.toRad(lat2 - lat1);
    var dLon = this.toRad(lon2 - lon1);
    lat1 = this.toRad(lat1);
    lat2 = this.toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(Value) {
    return (Value * Math.PI) / 180;
  }
}

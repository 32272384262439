<div
  class="sectioninfo mt-4"
  *ngIf="!['Expired', 'Canceled', 'Deleted', 'Withdrawn', 'Cancel/Withdrawn'].includes(RawMlsStatus)"
>
  <h4 class="headTitle">
    <img src="../../../assets/images/listing_data.png" class="mr-1" width="22" /> Listing Information
  </h4>
  <div class="row">
    <div class="col-lg-12">
      <ul class="pl-0 w-100 prop_char" *ngIf="listingData; else noListingInfo">
        <div *ngIf="RawMlsStatus != 'Deleted' && listingData.MlsBoard == 'MREIS'">
          <li>
            <span>Listing Status: </span>
            <span>{{ listingData.RawMlsStatus | IsValueExist }}</span>
          </li>
        </div>
        <div *ngIf="listingData.MlsBoard != 'MREIS'">
          <li>
            <span>Listing Status: </span>
            <span>{{ listingData.StandardStatus | IsValueExist }}</span>
          </li>
        </div>
        <li>
          <span>List Price: </span>
          <span *ngIf="listingData.StandardStatus !== 'Sold'">{{
            listingData.ListPrice | CurrencyFormatter | IsValueExist
          }}</span>
          <span *ngIf="listingData.StandardStatus === 'Sold'">--</span>
        </li>
        <li>
          <span>Original List Price: </span>
          <span>{{ listingData.OriginalListPrice | CurrencyFormatter | IsValueExist }}</span>
        </li>
        <li>
          <span>Sold Price: </span>
          <span>{{ listingData.ClosePrice | CurrencyFormatter | IsValueExist }}</span>
        </li>
        <li *ngIf="listingData.MlsBoard != 'MREIS'">
          <span>Listing Contract Date: </span>
          <span>{{ listingData.ListingContractDate | date: 'mediumDate' | IsValueExist }}</span>
        </li>
        <li *ngIf="listingData.MlsBoard != 'MREIS'">
          <span>Days on Market: </span>
          <span>{{ calculateDays(listingData) }}</span>
        </li>
        <li>
          <span>Flipped Within (Days): </span>
          <span *ngIf="(listingData.FlipDuration | IsValueExist) == '--'">--</span>
          <span *ngIf="(listingData.FlipDuration | IsValueExist) != '--'"
            >{{ listingData.FlipDuration | IsValueExist }} days</span
          >
        </li>
        <!-- <li><span>Flip Events: </span> 
          <span>--</span>
        </li> -->
        <li>
          <span>MLS Board: </span>
          <span>{{ listingData.MlsBoard | IsValueExist }}</span>
        </li>
        <li>
          <span>MLS Number: </span>
          <span>{{ listingData.MlsListingNumber | IsValueExist }}</span>
        </li>
        <li class="public_remarks">
          <span>Public Remarks: </span>
          <span>
            {{ showMore ? listingData.PublicRemarks : (listingData.PublicRemarks | slice: 0:150) }}
            <a *ngIf="!showMore" style="cursor: pointer" (click)="onShow()">Show More</a>
            <a *ngIf="showMore" style="cursor: pointer" (click)="onShow()">Show Less</a>
          </span>
        </li>
        <li class="disclaimer">
          <p class="mb-2">
            <b>Last Checked:</b> In the last 15 minutes | <b>Last Updated:</b>
            {{ listingData.ModificationTimestamp | date: 'yyyy-MM-dd' }} | <b>Source:</b>
            {{ listingData.OriginatingSystemName }}
            <img src="{{ mlsLogo }}" height="40" class="ml-2" />
          </p>

          <div class="mb-2 copyright">Disclaimer: {{ mlsDisclaimer }}</div>
        </li>
      </ul>
      <ng-template #noListingInfo>
        <div class="flex_center notFound text-center mt-3 mb-3">
          <div>
            <!-- <img src="../../../assets/images/notFound.svg" width="100" class="img-fluid mb-1"> -->
            <h4>No listing information available</h4>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

// tslint:disable:max-line-length
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DealAnalysisConfig {
  defaultLineChartValue = new Array(5).fill(0);
  years = ['1', '2', '3', '4', '5'];
  stateInsuranceRatio = {
    // alabama
    al: {
      value2K: 0.013,
      value3K: 0.0099,
      value4K: 0.0086,
      value5K: 0.0076,
    },
    // alaska
    ak: {
      value2K: 0.0066,
      value3K: 0.006,
      value4K: 0.0052,
      value5K: 0.005,
    },
    // arizona
    az: {
      value2K: 0.0076,
      value3K: 0.0066,
      value4K: 0.0058,
      value5K: 0.0053,
    },
    // arkansas
    ar: {
      value2K: 0.0144,
      value3K: 0.0115,
      value4K: 0.0097,
      value5K: 0.0092,
    },
    // california
    ca: {
      value2K: 0.0041,
      value3K: 0.0039,
      value4K: 0.0038,
      value5K: 0.0038,
    },
    // colorado
    co: {
      value2K: 0.0126,
      value3K: 0.0103,
      value4K: 0.0088,
      value5K: 0.0073,
    },
    // connecticut
    ct: {
      value2K: 0.0074,
      value3K: 0.0065,
      value4K: 0.006,
      value5K: 0.0056,
    },
    // District of Columbia
    dc: {
      value2K: 0.0055,
      value3K: 0.005,
      value4K: 0.0048,
      value5K: 0.0046,
    },
    // delaware
    de: {
      value2K: 0.0054,
      value3K: 0.0051,
      value4K: 0.0048,
      value5K: 0.0048,
    },
    // florida
    fl: {
      value2K: 0.0144,
      value3K: 0.0121,
      value4K: 0.0099,
      value5K: 0.0086,
    },
    // georgia
    ga: {
      value2K: 0.01,
      value3K: 0.0085,
      value4K: 0.0076,
      value5K: 0.0071,
    },
    // hawaii
    hi: {
      value2K: 0.0018,
      value3K: 0.0017,
      value4K: 0.0017,
      value5K: 0.0017,
    },
    // idaho
    id: {
      value2K: 0.007,
      value3K: 0.0061,
      value4K: 0.0056,
      value5K: 0.0052,
    },
    // illinois
    il: {
      value2K: 0.0087,
      value3K: 0.0073,
      value4K: 0.0066,
      value5K: 0.0061,
    },
    // indiana
    in: {
      value2K: 0.0096,
      value3K: 0.0081,
      value4K: 0.0072,
      value5K: 0.0065,
    },
    // iowa
    ia: {
      value2K: 0.0101,
      value3K: 0.0085,
      value4K: 0.0077,
      value5K: 0.0071,
    },
    // kansas
    ks: {
      value2K: 0.0159,
      value3K: 0.0131,
      value4K: 0.0111,
      value5K: 0.0096,
    },
    // kentucky
    ky: {
      value2K: 0.0117,
      value3K: 0.0095,
      value4K: 0.0078,
      value5K: 0.0064,
    },
    // louisiana
    la: {
      value2K: 0.0133,
      value3K: 0.0109,
      value4K: 0.0093,
      value5K: 0.0082,
    },
    // maine
    me: {
      value2K: 0.0069,
      value3K: 0.0061,
      value4K: 0.0057,
      value5K: 0.0052,
    },
    // maryland
    md: {
      value2K: 0.0056,
      value3K: 0.0051,
      value4K: 0.0049,
      value5K: 0.0049,
    },
    // massachusetts
    ma: {
      value2K: 0.0074,
      value3K: 0.0064,
      value4K: 0.0059,
      value5K: 0.0056,
    },
    // michigan
    mi: {
      value2K: 0.0086,
      value3K: 0.0072,
      value4K: 0.0064,
      value5K: 0.0059,
    },
    // minnesota
    mn: {
      value2K: 0.0113,
      value3K: 0.01,
      value4K: 0.0089,
      value5K: 0.0081,
    },
    // mississippi
    ms: {
      value2K: 0.0131,
      value3K: 0.0111,
      value4K: 0.0103,
      value5K: 0.0089,
    },
    // missouri
    mo: {
      value2K: 0.0125,
      value3K: 0.0104,
      value4K: 0.0088,
      value5K: 0.0082,
    },
    // montana
    mt: {
      value2K: 0.012,
      value3K: 0.0094,
      value4K: 0.0081,
      value5K: 0.0065,
    },
    // nebraska
    ne: {
      value2K: 0.0132,
      value3K: 0.0104,
      value4K: 0.0091,
      value5K: 0.0076,
    },
    // nevada
    nv: {
      value2K: 0.0054,
      value3K: 0.005,
      value4K: 0.0047,
      value5K: 0.0046,
    },
    // newHampshire
    nh: {
      value2K: 0.0053,
      value3K: 0.0049,
      value4K: 0.0043,
      value5K: 0.0042,
    },
    // newJersey
    nj: {
      value2K: 0.0066,
      value3K: 0.0058,
      value4K: 0.0054,
      value5K: 0.0052,
    },
    // newMexico
    nm: {
      value2K: 0.0093,
      value3K: 0.0077,
      value4K: 0.0068,
      value5K: 0.0062,
    },
    // newYork
    ny: {
      value2K: 0.0069,
      value3K: 0.0061,
      value4K: 0.0056,
      value5K: 0.0053,
    },
    // northCarolina
    nc: {
      value2K: 0.0069,
      value3K: 0.0067,
      value4K: 0.0059,
      value5K: 0.0053,
    },
    // northDakota
    nd: {
      value2K: 0.0116,
      value3K: 0.0087,
      value4K: 0.0062,
      value5K: 0.0051,
    },
    // ohio
    oh: {
      value2K: 0.0081,
      value3K: 0.007,
      value4K: 0.0062,
      value5K: 0.0057,
    },
    // oklahoma
    ok: {
      value2K: 0.0179,
      value3K: 0.0148,
      value4K: 0.0123,
      value5K: 0.0101,
    },
    // oregon
    or: {
      value2K: 0.0062,
      value3K: 0.0054,
      value4K: 0.005,
      value5K: 0.0046,
    },
    // pennsylvania
    pa: {
      value2K: 0.0066,
      value3K: 0.0057,
      value4K: 0.0053,
      value5K: 0.0051,
    },
    // rhodeIsland
    ri: {
      value2K: 0.0076,
      value3K: 0.0071,
      value4K: 0.0066,
      value5K: 0.0061,
    },
    // southCarolina
    sc: {
      value2K: 0.0103,
      value3K: 0.0089,
      value4K: 0.0077,
      value5K: 0.007,
    },
    // southDakota
    sd: {
      value2K: 0.013,
      value3K: 0.0106,
      value4K: 0.0089,
      value5K: 0.0081,
    },
    // tennessee
    tn: {
      value2K: 0.0115,
      value3K: 0.009,
      value4K: 0.0077,
      value5K: 0.0068,
    },
    // texas
    tx: {
      value2K: 0.0147,
      value3K: 0.0114,
      value4K: 0.0091,
      value5K: 0.0081,
    },
    // utah
    ut: {
      value2K: 0.0053,
      value3K: 0.0046,
      value4K: 0.0042,
      value5K: 0.0041,
    },
    // vermont
    vt: {
      value2K: 0.0046,
      value3K: 0.004,
      value4K: 0.0039,
      value5K: 0.0037,
    },
    // virginia
    va: {
      value2K: 0.0075,
      value3K: 0.0065,
      value4K: 0.0058,
      value5K: 0.0052,
    },
    // washington
    wa: {
      value2K: 0.0056,
      value3K: 0.005,
      value4K: 0.0048,
      value5K: 0.0044,
    },
    // westVirginia
    wv: {
      value2K: 0.0099,
      value3K: 0.0083,
      value4K: 0.0075,
      value5K: 0.0069,
    },
    // wisconsin
    wi: {
      value2K: 0.0069,
      value3K: 0.0058,
      value4K: 0.0054,
      value5K: 0.005,
    },
    // wyoming
    wy: {
      value2K: 0.008,
      value3K: 0.0069,
      value4K: 0.0062,
      value5K: 0.0047,
    },
  };

  expensePieChart = [
    {
      name: 'Property Taxes',
      y: 0,
      selected: true,
      key: 'propertyTax',
      color: '#3f51b5',
    },
    {
      name: 'Insurance',
      y: 0,
      key: 'insurance',
      color: '#6e38e3',
    },
    {
      name: 'HOA Fee',
      y: 0,
      key: 'hoaFee',
      color: '#7a5195',
    },
    {
      name: 'Property Management',
      y: 0,
      key: 'propertyManagement',
      color: '#bc5090',
    },
    {
      name: 'Repairs & Maintenance',
      y: 0,
      key: 'maintenance',
      color: '#ef5675',
    },
    {
      name: 'Warranty Service',
      y: 0,
      key: 'warrantyService',
      color: '#ff764a',
    },
    {
      name: 'Accounting',
      y: 0,
      key: 'accounting',
      color: '#ffa600',
    },
  ];

  incomePieChart = [
    {
      name: 'Operating Income',
      y: 0,
      selected: true,
      key: 'operatingIncome',
      color: '#3f51b5',
    },
    {
      name: 'Monthly Rent',
      y: 0,
      key: 'monthlyRent',
      color: '#ffa600',
    },
  ];

  salePredictionLineChart = [
    // {
    //   type: 'line',
    //   name: 'Operating Income',
    //   data: this.defaultLineChartValue,
    //   key: 'operatingIncome',
    // },
    // {
    //   type: 'line',
    //   name: 'Operating Expenses',
    //   data: this.defaultLineChartValue,
    //   key: 'operatingExpense',
    // },
    // {
    //   type: 'line',
    //   name: 'Cash Flow',
    //   data: this.defaultLineChartValue,
    //   key: 'cashFlow',
    // },
    // {
    //   type: 'line',
    //   name: 'Taxable Income',
    //   data: this.defaultLineChartValue,
    //   key: 'taxableIncome',
    // },
    {
      type: 'line',
      name: 'Your Equity',
      data: this.defaultLineChartValue,
      key: 'equity',
    },
    {
      type: 'line',
      name: 'Profit On Sale',
      data: this.defaultLineChartValue,
      key: 'profitOnSale',
    },

    // {
    //   type: 'line',
    //   name: 'Net Proceeds',
    //   data: this.defaultLineChartValue,
    //   key: 'netProceed',
    // },
    {
      type: 'line',
      name: 'Total Cash Invested',
      data: this.defaultLineChartValue,
      key: 'totalCashInvested',
    },
  ];

  returnIndicatorLineChart = [
    {
      type: 'line',
      name: 'Cap Rate on Purchase Price',
      data: this.defaultLineChartValue,
      key: 'capRateOnPurchasePriceRatio',
    },
    // {
    //   type: 'line',
    //   name: 'Cap Rate on FMV',
    //   data: this.defaultLineChartValue,
    //   key: 'capRateOnFMVRatio',
    // },
    {
      type: 'line',
      name: 'Cash on Cash Return',
      data: this.defaultLineChartValue,
      key: 'cashOnCashReturnRatio',
    },
    // {
    //   type: 'line',
    //   name: 'Return on Investment',
    //   data: this.defaultLineChartValue,
    //   key: 'returnOnInvestmentRatio',
    // },
    // {
    //   type: 'line',
    //   name: 'Cap Rate on Current Value',
    //   data: this.defaultLineChartValue,
    //   key: 'capRateOnCurrentValueRatio',
    // },
  ];

  salePredictionSettings = {
    title: {
      text: '',
    },
    subtitle: {
      text: null,
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    xAxis: {
      title: {
        text: 'Year',
      },
      categories: this.years,
    },
  };

  returnIndicatorSettings = {
    title: {
      text: '',
    },
    subtitle: {
      text: null,
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        format: '{value}%',
      },
    },
    xAxis: {
      title: {
        text: 'Year',
      },
      categories: this.years,
    },
  };

  tooltipText = {
    purchasePriceInput:
      'If the property is currently on the market, this is the list price.\n If the property was sold within the last six months, this is the sold price.\n If the property was sold before the last six months, this value comes from our valuation model.',
    downPaymentInput:
      'Your down payment to purchase the property.\n You can either enter a percentage of the purchase price or a dollar amount',
    landValueInput: 'The value of just the land. We populate this from tax records, but you can modify it.',
    closingCostsInput:
      'These are costs of purchasing the property. Our default is based on 1% of the purchase price.\n You can modify it but make sure to consider the following costs:\n Title Search & Insurance\n Recording Fees\n State Transfer Tax\n County Transfer Tax\n Ability to add more custom items',
    renovationCostsInput:
      'If the property needs some renovation before renting our or sale, please consider the following rehab costs:\n Appliances & HVAC, Carpets & Flooring, Electrical and Plumbing repairs, Landscaping, Deck, Painting',
    loanAmountInput:
      'Your loan amount is the Purchase Price minus the Down Payment.\n This is automatically calculated from your Purchase Price and Down Payment above',
    interestRateInput:
      'Your annual interest rate for the mortgage.\n Note that we only consider fixed rate mortgage for now.\n In the near future we will provide you a way to enter a variable rate mortgage',
    termInput: 'The term of your loan in years',
    monthlyMortgagePaymentInput:
      'This is your monthly mortgage payment without taxes or insurance.\n It is automatically calculated.',
    propertyTaxesInput:
      'Annual property taxes - we populate this from the tax records.\n Your property taxes may change year-to-year based on your property value.',
    insuranceInput:
      "Your annual home owner's insurance costs for the property based on state and purchase price based averages we maintain.\n You may consider a landlord insurance and/or modify this to your needs",
    hoaFeeInput: "Your Homeowner's Association fee. If we have it we will auto-calculate this",
    propertyManagementInput:
      'Property management fees typically are in the range 8 - 10% of your rental income, but you can modify it',
    repairsAndMaintenanceInput: 'This is calculated as a part of the annual rent for the property',
    improvementsInput: 'You may enter this as a percentage of the annual rent for the property',
    warrantyServiceInput:
      'If you opt to get a warranty service for the property, please enter a fixed annual cost of such warranty',
    accountingInput: 'If you pay for tax and accounting services from a CPA or some Tax Software, please enter it here',
    monthlyRentInput:
      'This is your total monthly rent for the property for all the rentable units.\n We will pre-populate this field with the expected rent computed by our rent valuation model',
    rentChurnInput: 'This is the number of times you think a rental unit may be come vacant in a year.',
    newTenantSearchTimeInput: 'Number of months it takes you on average to find a new tenant',
    assessedPropertyValueInput:
      'The value of the property as determined by the tax assessor.\n We auto populate this value from the tax records.',
    fairMarketValueInput: 'This is the market value of the property as computed by our valuation model.',
    salesCommissionInput:
      'The sales commission you would pay a real estate agent at the time of sale of your property.\n If you sell the property by yourself you can save on this sales commission.',
    sellerAgentCommissionInput:
      'The sales commission you would pay a seller agent at the time of sale of your property.\n If you sell the property by yourself you can save on this sales commission.',
    buyerAgentCommissionInput:
      'The sales commission you would pay a buyer agent at the time of sale of your property.\n If you sell the property by yourself you can save on this sales commission.',
    grossRentInput:
      'The total possible rent that you may collect from your tenant(s). \n Gross Rent = Monthly Rent * 12',
    vacancyInput: `The amount of rent that you don't collect due to vacancies. This is computed from the Rent Churn and New Tenant Search Time. \n Vacancy Cost = Monthly Rent * Rent Churn * New Tenant Search Time`,
    operatingIncomeInput:
      'Total income generated by the property less the vacancy costs. \n Operating Income = Gross Rent - Vacancy Cost',
    operatingExpenseInput:
      'Total of all the expenses of renting out operating a property. Note that this does not include mortgage payments. \n  Operating Expenses = Sum of all the expense items above.',
    IsAdvanceOperatingExpenseInput:
      'The total operating expense is the sum of all above mentioned expenses.',
    netOperatingIncomeInput: `Net Operating Income (NOI) is the net income generated by the property without considering mortgage payments.
    NOI = Operating Income - Operating Expenses`,
    cashFlowInput: `The total cash you will retain from a renting the property as income. So, if you have a mortgage on the property then you will deduct all mortgage payments to arrive at your net cash in hand.
    Note that this is not your taxable income. See further below for that.
    Cash Flow = Net Operating Income - Mortgage Payments
    If there is no mortgage on the property:
    Cash Flow = Net Operating Income`,
    taxDeductionInput: `All your expenses except the mortgage principal payment is deductible for tax purposes. In addition to your expenses, you can also deduct a depreciation on the property.`,
    taxableIncome: 'Taxable Income = Operating Income -Total Deductions',
    mortgageInterestInput: `The portion of your mortgage payments that goes toward the interest on the mortgage.
    Annual Mortgage Interest = Annual Mortgage Payment - Annual Principal Paid
    Annual Principal Paid = Prior Year Loan Balance - Current Loan Balance`,
    depreciationInput: `IRS defines a common tax deduction for real estate. The most commonly used depreciation model is where the building value of the property depreciates over a period of 27.5 years.
    Annual Depreciation = (Purchase Price - Land Value + Closing Costs + Renovation Costs) / 27.5`,
    propertyValueInput: `The current market value of the property computed using the Purchase Price and the Year-over-Year increase in property value.`,
    yourEquityInput: `The portion of the property's value that you own.
    If you have a mortgage:
    Your Equity = Property Value - Mortgage Balance
    If you don't have a mortgage:
    Your Equity = Property Value`,
    sellingCostsInput: `The cost of selling the property is the sales commission you may pay.
    Selling Costs = Property Value * Sales Commission%`,
    cumulativeCashFlowInput: `The net cash you retain from year to year.
    Cumulative Cash Flow = Total of yearly cash flow for all the years of owning the property`,
    totalCashInvestedInput: `The is the total amount you invested at the time of purchasing the property.
    Total Cash Invested = Down Payment + Closing Costs + Renovation Costs`,
    netProceedsInput: `Net Proceeds is the cash you are left with in hand if you decide to sell the property.
    Net Proceeds = Your Equity - Selling Costs
    or
    Net Proceeds = Property Value - Mortgage Balance - Selling Costs`,
    profitOnSaleInput: `The net profit you would make on the sale is your total return on your cash invested.
    Profit On Sale = Net Proceeds + Cumulative Cash Flow - Total Cash Invested`,
    capRateOnPurchasePriceInput: `The rate of return on the purchase price of the property. Note that it is not dependent on your actual investment but the price of the property only. It compares your NOI to the Purchase Price.
    Cap Rate on Purchase Price = NOI / Purchase Price`,
    capRateOnFMVInput: `The rate of return on the Fair Market Value of the property. Note that it is not dependent on your actual investment but the market value of the property only. It compares your NOI to the Fair Market Value.
    Cap Rate on FMV = NOI / Fair Market Value`,
    cashOnCashReturnInput: `This return indicator measures your rate of return based on the cash you invested in the property. This is one of the important measures for you to compare your investment against other income investments such as stocks or bonds.
    Cash on Cash Return = Annual Cash Flow / Total Cash Invested`,
    returnOnInvestmentInput: `This the total return on the cash you invested upfront if you were to sell the property. This is an important indicator to compare your investment against the buying and selling of other investments such as stocks.
    ROI = (Your Equity - Selling Costs + Cumulative Cash Flow - Total Cash Invested) / Total Cash Invested
    or
    ROI = Profit on Sale / Total Cash Invested`,
    returnOnEquityInput: `This measures the rate of return on your equity if you continue to hold and rent the property.
    Return on Equity = Annual Cash Flow / Your Equity`,
  };

  evaluationTableToolTip = {
    montlyRent: 'This is your total rental amount you may receive from the property',
    expenses:
      'This is the total of all expenses associated with renting out and operating a property,\n which includes property tax, insurance, HOA fees, property management, repairs & maintenance, warranty service \n and accounting.',
    netOperatingIncome: 'Rental income - Expenses',
    mortgagePayment: 'This is your mortgage payment excluding taxes and insurance.',
    cashFlow: 'Net Operating Income - Mortgage Payment',
    totalCashInvested: 'This is the total amount you invested at the time of purchasing the property',
    yourEquity: 'Property value - Mortgage balance',
    propertyValue: 'This is computed using the purchase price and the year-over-year increase in property value.',
    netProceeds: 'It is the cash you are left with in if you decide to sell your property',
    ProfitOnSale: 'Net Proceeds + Cumulative Cash Flow - Total cash invested',
    ROI: 'Profit on sale / Total Cash inveted',
    CashOnCash: 'Annual Cashflow / Total Cash Invested'
  };
}

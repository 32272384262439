import { Component, Input } from '@angular/core';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { AppState } from 'src/app/app.state';

interface CompanyNavBar {
  data: any;
}
@Component({
  selector: 'app-rubyporch',
  templateUrl: './rubyporch.component.html',
  styleUrls: ['./rubyporch.component.scss'],
})
export class RubyporchComponent implements CompanyNavBar {
  constructor(public authorizeService: AuthorizeService, public appState: AppState) {}

  @Input() data;

  openLogInDialog() {
    this.authorizeService.openLogInDialog();
  }
}

import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { } from 'events';
import * as moment from 'moment';
import { SwiperComponent } from 'ngx-swiper-wrapper';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ProspektrApi } from 'src/app/_shared/api/prospektr.api';
import { SubscriptionState } from 'src/app/_shared/state/subscription.state';
import { MainMenuComponent } from 'src/app/navigation/main-menu/main-menu.component';
import { NotificationService } from 'src/app/notifications/service/notification.service';
import { NotificationState } from 'src/app/notifications/state/notification.state';
import { ReportsState } from 'src/app/reports/reports.state';
import constants from 'src/constant.json';
import { AuthorizeService } from '../../_core/access-control/authorize.service';
import { SwiperConfig } from '../../_shared/config/swiper.config';
import { UserState } from '../../_shared/state/user.state';
import { AppState } from '../../app.state';
import { LocalStorageService } from '../../shared/service/local-storage.service';
import { SubscriptionPlanService } from '../../shared/service/subscription-plan.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  @Input() isProfilePicUpdated: Observable<boolean>;
  @Input() isProfileUpdated: Observable<boolean>;
  @Output() navEvent: EventEmitter<string> = new EventEmitter<string>();
  userContact: any;
  userProfileImg: any;
  userName: string;
  showAllNavigation = false;
  notificationCount = 0;
  notificationData = [];
  planExpiryDate: any;
  isTrial: boolean;
  public planExpired: boolean;
  hideLogOutSection: boolean;
  alertCount;
  businessAreaAlertStatus = 0;
  mlsAlertStatus = 0;
  isHamburger: boolean = true;
  canUpgradePlan = false;
  websiteUrl: string;
  canViewInvestor: boolean;
  canViewProfile: boolean;
  isSingleBranded = false;
  isSliderDisabled = true;
  isCompanyLogoExist = true;
  viewSignIn = true;
  brandingInfo = [];
  clientDarkLogo = null;
  constant: any = constants;
  defaultProfileImage = constants.userAvatar;
  companyDetails: object = {};
  companyLogo: any;
  userType: string = '';
  companyName: string = '';
  signUpUrl: string = '/signup/';
  userRole: string;
  upgradeSnackbar: MatSnackBarRef<any>;

  // Subject used to unsubscribe Subscriptions on OnDestroy
  private onDestroyNotifier$ = new Subject();

  // Subscription for unsubscribing on OnDestroy
  private initialLoginSub$ = new Subscription();
  private alertList$: Subscription = new Subscription();
  private alertListCount$: Subscription = new Subscription();
  private planUsage$: Subscription = new Subscription();

  @ViewChild(SwiperComponent) swiperCompRef?: SwiperComponent;
  @ViewChild(MainMenuComponent) mainMenu: MainMenuComponent;

  constructor(
    media: MediaMatcher,
    public router: Router,
    private storage: LocalStorageService,
    private subscriptionService: SubscriptionPlanService,
    public appState: AppState,
    private authorizeService: AuthorizeService,
    public swiperConfig: SwiperConfig,
    public userState: UserState,
    private notificationState: NotificationState,
    private notificationService: NotificationService,
    private subscriptionState: SubscriptionState,
    public prospektrApi: ProspektrApi,
    public reportsState: ReportsState,
    private snackbar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 767.98px)');
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngAfterViewInit() {
    if (this.mainMenu) {
      this.mainMenu.initStart();
    }
  }

  ngOnInit() {
    this.appState.authToken$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (!res?.accessToken) return;
      this.subscriptionService.getSubscriptionPlan();
    });

    this.appState.clientInfo$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      this.clientDarkLogo = res.client.logo.dark;
    });
    this.canViewInvestor = this.authorizeService.canView('clientBranding');
    this.canUpgradePlan = this.authorizeService.canView('planUpgrade');
    this.canViewProfile = this.authorizeService.canView('profile');

    combineLatest([this.userState.userDetail$, this.appState.companyInfo$])
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(([userInfo, companyInfo]) => {
        if (userInfo?.roles?.length > 0) {
          this.userRole = userInfo.roles[0];
        }

        const currentView = this.mobileQuery.matches ? 'mobile' : 'desktop';
        const userSession = userInfo ? 'identified' : 'guest';

        if (
          companyInfo?.alias &&
          companyInfo?.customizationConfig?.companyNavBar?.length &&
          companyInfo?.customizationConfig?.companyNavBar[0]?.activeView.includes(currentView) &&
          companyInfo?.customizationConfig?.companyNavBar[0]?.sessionStatus.includes(userSession) &&
          companyInfo?.customizationConfig?.companyNavBar[0]?.config?.active
        ) {
          if (companyInfo?.customizationConfig?.companyNavBar[0]?.config?.hasLogo) this.isCompanyLogoExist = false;
          if (companyInfo?.customizationConfig?.companyNavBar[0]?.config?.hasLogIn) this.viewSignIn = false;
          if (companyInfo?.customizationConfig?.companyNavBar[0]?.config?.showMenu) this.isHamburger = true;
          else this.isHamburger = false;
        } else {
          this.isHamburger = true;
        }
        if (!this.userState.userDetails?.user_profile) {
          this.isHamburger = false;
        }
        this.websiteUrl = companyInfo?.websiteUrl;
        this.signUpUrl = `/signup/${companyInfo?.signUpId ?? ''}`;
        this.hideLogOutSection = companyInfo?.isTokenAuthEnabled || false;
        this.userType = this.userState.userDetails?.userType;
        this.companyName = companyInfo?.alias || '';
        this.companyDetails = companyInfo;
        this.companyLogo = companyInfo?.logo?.dark || null;
        if (userInfo?.utm_source) {
          this.companyLogo = companyInfo?.logo?.alternateLogo || null;
        }
        if (this.mainMenu) {
          this.mainMenu.initStart();
        }
      });

    this.appState.connectionInfo.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res && Array.isArray(res) && res.length) {
        this.isSliderDisabled = res.length > 2;
        this.brandingInfo = res;
        setTimeout(() => {
          if (this.swiperCompRef && this.swiperCompRef.directiveRef) {
            if (res.length == 1) {
              this.isSingleBranded = true;
              this.swiperCompRef.config.slidesPerView = 1;
              this.swiperCompRef.config.slidesPerGroup = 1;
              this.swiperCompRef.config.autoplay = {};
              this.swiperCompRef.config.loop = false;
            }
            this.swiperCompRef.directiveRef.update();
            this.swiperCompRef.directiveRef.stopAutoplay();
          }
        });
      }
    });
    this.subscriptionService.isPlanActive.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res) {
        this.planExpired = false;
      } else {
        this.planExpired = true;
      }
    });
    this.showAllNavigation = this.storage.isProfileUpdated;
    if (this.appState.authTokenValue?.idToken) {
      this.updateUserInformation();
    }
    if (this.isProfilePicUpdated) {
      this.isProfilePicUpdated.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((isPicUrlUpdated) => {
        if (isPicUrlUpdated) {
          this.userProfileImg = this.userState.userDetails?.user_profile.profileImage;
        }
      });
    }
    if (this.isProfileUpdated) {
      this.isProfileUpdated.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((isProfileUpdated) => {
        this.showAllNavigation = isProfileUpdated;
        this.updateUserInformation();
      });
    }

    this.notificationState.notificationPropertyList.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      let propertyNotificationInfo = [];
      res.forEach((property) => {
        const newArr = property.notificationList.sort(
          (a, b) => new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf()
        );
        let notificationList = [...new Map(newArr.map((item) => [item['referenceType'], item])).values()];
        notificationList.filter((element) => {
          if (element['readStatus'] == false) {
            let data = {
              address: property.address,
              referenceType: element['referenceType'],
              notificationMessage: element['displayText'],
            };
            propertyNotificationInfo.push(data);
          }
        });
        let data = this.notificationService.formatAlerts(propertyNotificationInfo);
        this.notificationData = data;
      });
      this.notificationCount = propertyNotificationInfo.length;
      this.alertCount = propertyNotificationInfo.length;
    });
    this.subscriptionState.planUsage.pipe(takeUntil(this.onDestroyNotifier$)).subscribe(
      (res) => {
        this.getPlanUsageCount(res);
      },
      (err) => {
        console.log('err:', err);
      }
    );
    this.userState.businessAreaList$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (!this.authorizeService.canView('businessArea')) {
        return false;
      }
      this.businessAreaAlertStatus = 0;
      if (res && !res.length) {
        this.businessAreaAlertStatus = 1;
      }
      this.notificationCount = this.businessAreaAlertStatus + this.mlsAlertStatus + this.alertCount;
    });

    this.userState.mlsList$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (!this.authorizeService.canView('mls')) {
        return false;
      }
      this.mlsAlertStatus = 0;
      if (res && !res.length) {
        this.mlsAlertStatus = 1;
      }
      this.notificationCount = this.mlsAlertStatus + this.businessAreaAlertStatus + this.alertCount;
      if (
        res &&
        !res.length &&
        this.appState.companyInfoValue.appConfig?.valueReport?.isEnabled
      ) {
        if (this.userRole == 'agent') {
          if (this.isHamburger) {
            this.isHamburger = false;
            this.cdr.detectChanges();
            this.openSnackbar('MLS not added!!', false);
            this.router.navigate(['profile'], { queryParams: { redirectTo: 'mls' } });
          } else {
            this.isHamburger = true;
            this.cdr.detectChanges();
          }
        } else if (this.userRole == 'appraiser' && !this.reportsState.mlsAddShown) {
          this.reportsState.mlsAddShown = true;
          this.openSnackbar('MLS not added!!', false);
          this.router.navigate(['profile'], { queryParams: { redirectTo: 'mls' } });
        }
      }
    });

    this.userState.userDetail$.subscribe((res) => {
      if (res?.user_profile?.contact) {
        this.userContact = true;
      }
    });

    const pathname = window.location.pathname;
    if (pathname && pathname != '' && this.appState.companyInfoValue?.appConfig?.valueReport?.isEnabled) {
      let lPaths = pathname.split('/');
      if (lPaths && lPaths.length > 0 && lPaths[lPaths.length - 1] == 'shared') {
        this.viewSignIn = false;
      }
    }

  }

  ngOnDestroy() {
    this.clientDarkLogo = null;
    this.companyLogo = null;
    this.companyDetails = {};
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
    this.alertList$.unsubscribe();
    this.planUsage$.unsubscribe();
    this.alertListCount$.unsubscribe();
    this.initialLoginSub$.unsubscribe();
  }

  getPlanUsageCount(res: any) {
    if (res && res.renewalDate) {
      const remainingDays = Math.round(moment(res.renewalDate).diff(moment(new Date()), 'days', true));
      this.planExpiryDate = remainingDays;
    }
    this.isTrial = res.isTrial;
  }

  userLogout() {
    this.prospektrApi
      .logOutUser()
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        finalize(() => {
          localStorage.clear();
          sessionStorage.clear();
          this.appState.authTokenValue = null;
          this.appState.clientInfoSubValue = {
            client: {
              logo: {
                light: constants.lightLogo,
                dark: constants.darkLogo,
              },
              appTheme: {
                primaryColor: constants.appPrimaryColor,
                secondaryColor: constants.appSecondaryColor,
              },
              name: constants.APPLICATION_NAME,
            },
            user: null,
          };
          this.appState.partnerInfoValue = {};
          if (this.appState.companyInfoValue?.appConfig?.valueReport?.isEnabled) {
            window.location.href = 'signup';
          } else if (this.appState.companyInfoValue?.appConfig?.opportunityScanner?.isEnabled) {
            window.location.href = 'login';
          } else {
            window.location.href = 'workspace';
          }
        })
      )
      .subscribe();
  }

  updateUserInformation() {
    this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res) {
        this.userProfileImg = res.user_profile.profileImage;
        this.userProfileImg = res.user_profile.profileImage;
        const firstName = res.user_profile.firstName;
        const lastName = res.user_profile.lastName;
        this.userName = firstName + ' ' + lastName;
      }
    });
  }
  navigationEvent(navigationPath) {
    this.navEvent.next(navigationPath);
  }

  upgrade() {
    this.router.navigate(['/subscription/account-upgrade']);
  }

  goToBusinessArea() {
    this.appState.navigatedFrom = this.router.url;
    this.router.navigate(['/profile'], {
      queryParams: {
        redirectTo: 'businessArea',
      },
    });
  }

  goToMls() {
    this.appState.navigatedFrom = this.router.url;
    this.router.navigate(['/profile'], {
      queryParams: {
        redirectTo: 'mls',
      },
    });
  }
  openLogInDialog() {
    if (this.appState.companyInfoValue?.appConfig?.valueReport?.isEnabled) {
      this.router.navigate(['/signup']);
    } else if (this.appState.companyInfoValue?.appConfig?.opportunityScanner?.isEnabled) {
      this.router.navigate(['/login']);
    } else {
      this.authorizeService.openLogInDialog();
    }
  }

  redirectToProfile() {
    this.appState.navigatedFrom = this.router.url;
    this.router.navigate(['/profile']);
  }

  openSnackbar(upgradeMsg, success = false) {
    this.upgradeSnackbar = this.snackbar.open(upgradeMsg, 'Dismiss', {
      duration: 3000,
      panelClass: [success ? 'snackbar-success' : 'snackbar-error'],
      verticalPosition: 'top',
    });
  }
}

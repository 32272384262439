import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SearchApi {
  prospektrBackendBaseURL = environment.prospektrBackendBaseURL;
  saveSearchApi = 'searchLogger';
  loadSearchApi = 'loadLastSearchFromLog';

  constructor(private appState: AppState, private http: HttpClient) {}

  getLastSearch(): Observable<any> {
    return new Observable((observer) => {
      this.http.post(`${this.prospektrBackendBaseURL}${this.loadSearchApi}`, null).subscribe(
        (res: any) => {
          if (res && res.data && Object.keys(res.data).length) {
            observer.next({ initialSearch: true, ...res.data });
          } else {
            observer.next({ initialSearch: true });
          }
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  setSearch(params) {
    if (this.appState.authTokenValue?.idToken) {
      this.http.post(`${this.prospektrBackendBaseURL}${this.saveSearchApi}`, params).subscribe();
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss'],
})
export class EmailTemplateComponent implements OnInit {
  constructor() {}
  @Input() data;
  @Output() onBack = new EventEmitter<any>();

  ngOnInit() {}
  onclose() {
    this.onBack.emit(false);
  }
}

<ng-container *ngIf="!(statusBarFacade.getIsSearchProcessingValue() | async)">
  <div class="footer_menu footerPad">
    <div *ngIf="statusBarFacade.countBar && statusBarFacade.countBar.isPropensityBar && isDataAvailable">
      <div class="row mr-0 ml-0">
        <div class="col-lg-8 col-sm-8 col-md-12 col-12 px-0">
          <ul class="list-group list-group-horizontal">
            <li class="status-item">
              <!-- <img src="../../assets/images/property.png" width="50"
                class="pull-left mr-2 d-none d-sm-none d-md-none d-lg-block"> -->
              <div class="status-info">
                <p class="headname pull-left d-none d-sm-block">Total Properties</p>
                <p class="headname pull-left d-block d-sm-none">Properties</p>
                <h2 *ngIf="statusBarFacade.propertyInBoundsCount === -1">{{ statusBarFacade.totalPropertyCount }}</h2>
                <h2 *ngIf="statusBarFacade.propertyInBoundsCount >= 0">
                  {{ statusBarFacade.propertyInBoundsCount }}<small>/{{ statusBarFacade.totalPropertyCount }}</small>
                </h2>
              </div>
            </li>
            <!-- <li *ngIf="hotPropertyCount && authorizeService.canView('propensityFilter')"
              matTooltip="Click to view Hot properties" (click)="setFilterStatus($event, 'hot', true)" class="link hot"
              [ngClass]="{'activeLink': activePropensityList.includes('hot')}">
              <div class="status"></div>
              <p class="headname">Hot <span *ngIf="activePropensityList.includes('hot')"
                  class="close d-none d-sm-block">
                  <i class="material-icons" (click)="setFilterStatus($event, 'hot', false)"> cancel</i></span></p>
              <h2>{{ hotPropertyCount }}</h2>
            </li>
            <li *ngIf="warmPropertyCount && authorizeService.canView('propensityFilter')"
              matTooltip="Click to view Warm properties" (click)="setFilterStatus($event, 'warm', true)"
              class="link warm" [ngClass]="{'activeLink':activePropensityList.includes('warm')}">
              <div class="status bg-warning"></div>
              <p class="headname">Warm <span *ngIf="activePropensityList.includes('warm')"
                  class="close d-none d-sm-block">
                  <i class="material-icons" (click)="setFilterStatus($event, 'warm', false)"> cancel</i></span></p>
              <h2>{{ warmPropertyCount }}</h2>
            </li>
            <li *ngIf="coldPropertyCount && authorizeService.canView('propensityFilter')"
              matTooltip="Click to view Cold properties" (click)="setFilterStatus($event, 'cold', true)"
              class="link cold" [ngClass]="{'activeLink':activePropensityList.includes('cold')}">
              <div class="status bg-info"></div>
              <p class="headname">Cold <span *ngIf="activePropensityList.includes('cold')"
                  class="close d-none d-sm-block">
                  <i class="material-icons" (click)="setFilterStatus($event, 'cold', false)"> cancel</i></span></p>
              <h2>{{ coldPropertyCount }} </h2>
            </li> -->

            <li *ngIf="hotPropertyCount && authorizeService.canView('propensityFilter')" class="static hot">
              <div class="status"></div>
              <p class="headname">Hot</p>
              <h2>{{ hotPropertyCount }}</h2>
            </li>

            <li *ngIf="warmPropertyCount && authorizeService.canView('propensityFilter')" class="static warm">
              <div class="status bg-warning"></div>
              <p class="headname">Warm</p>
              <h2>{{ warmPropertyCount }}</h2>
            </li>
            <li *ngIf="coldPropertyCount && authorizeService.canView('propensityFilter')" class="static cold">
              <div class="status bg-info"></div>
              <p class="headname">Cold</p>
              <h2>{{ coldPropertyCount }}</h2>
            </li>

            <li *ngIf="statusBarFacade.favoriteCount">
              <p class="headname">
                <img src="../../assets/images/marker-watch.png" style="padding-right: 2px" /> {{ stringRes.WATCHED }}
                <span class="close d-none d-sm-block"></span>
              </p>
              <h2>{{ statusBarFacade.favoriteCount }}</h2>
              <!-- <mat-icon class="text-primary">visibility</mat-icon>{{stringRes.WATCHED}} -->
              <!-- <p class="headname d-block d-sm-none"><mat-icon class="text-primary">visibility</mat-icon>{{stringRes.WATCHED}}</p> -->
            </li>
          </ul>
        </div>

        <div class="col-lg-4 col-sm-4 col-md-12 col-12 text-right center-xy">
          <div
            style="padding-right: 8px; flex: auto"
            *ngIf="statusBarFacade.getLocalTrendsAvailabilityStatus() | async"
          >
            <button mat-button (click)="openLocalTrends()" class="btn local_trends_btn" id="local_trends">
              <div class="localTrendsCont">
                <div class="loctrend_icon"></div>
                <span class="mobile-hidden ml-2">Local Trends</span>
              </div>
            </button>
          </div>
          <!-- <app-save-search class="float-right btn-large"></app-save-search> -->
        </div>
      </div>
    </div>

    <div
      class="text-center alertInfo"
      *ngIf="statusBarFacade.countBar && !statusBarFacade.countBar.isPropensityBar && isDataAvailable"
    >
      <h5 class="pt-0 pb-0 mb-0" *ngIf="statusBarFacade.countBar.totalCount && !statusBarFacade.countBar.filterApplied">
        We found <span>{{ statusBarFacade.countBar.totalCount }}</span> properties
      </h5>
      <h5 class="pt-0 pb-0 mb-0" *ngIf="statusBarFacade.countBar.totalCount && statusBarFacade.countBar.filterApplied">
        <span>{{ statusBarFacade.countBar.filterCount }}</span>
        <ng-container *ngIf="!workspaceState.activeWorkspaceModeValue?.isDefault">
          of <span>{{ statusBarFacade.countBar.totalCount }}</span>
        </ng-container>
        properties matched your filters
      </h5>
      <p class="pt-0 pb-0 mb-0 errorMsg">{{ statusBarFacade.countBar.errorMsg }}</p>
    </div>

    <div class="text-center alertInfo" *ngIf="!isDataAvailable">
      <h5 class="pt-0 pb-0 mb-0">{{ noDataAvailableMessage }}</h5>
    </div>

    <div class="text-center alertInfo" *ngIf="statusBarFacade.switchBarStatus">
      <p class="pt-0 pb-0 mb-0">{{ statusBarFacade.switchMsg }}</p>
    </div>
  </div>
</ng-container>

// tslint:disable:max-line-length
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { DealAnalysisApi } from '../api/deal-analysis.api';
import { ProspektrApi } from '../api/prospektr.api';
import { PublicRecordApi } from '../api/public-record.api';
import { DealAnalysisConfig } from '../config/deal-analysis.config';
import { BackendResponse } from '../interface/common.interface';
import {
  AccountingCostInput,
  AccountingCostOutput,
  CapRateOnFMVInput,
  CapRateOnFMVOutput,
  CapRateOnPurchasePriceInput,
  CapRateOnPurchasePriceOutput,
  CashFlowInput,
  CashFlowOutput,
  CashOnCashReturnInput,
  CashOnCashReturnOutput,
  ComputationCache,
  CumulativeCashFlowInput,
  CumulativeCashFlowOutput,
  DealAnalysisShareInput,
  DealComputationInput,
  DealComputedResult,
  DepreciationInput,
  DepreciationOutput,
  EquityAppreciationInput,
  EquityAppreciationOutput,
  GrossRentInput,
  GrossRentOutput,
  HoaFeeInput,
  HoaFeeOutput,
  InsuranceAmountInput,
  InsuranceAmountOutput,
  MaintenanceCostInput,
  MaintenanceCostOutput,
  MonthlyMortgagePaymentInput,
  MonthlyMortgagePaymentOutput,
  MonthlyRentInput,
  MonthlyRentOutput,
  MortgageBalanceInput,
  MortgageBalanceOutput,
  MortgageInterestInput,
  MortgageInterestOutput,
  MortgagePaymentInput,
  MortgagePaymentOutput,
  NetOperatingIncomeInput,
  NetOperatingIncomeOutput,
  NetProceedInput,
  NetProceedOutput,
  OperatingExpenseInput,
  OperatingExpenseOutput,
  OperatingIncomeInput,
  OperatingIncomeOutput,
  ProfitOnSaleInput,
  ProfitOnSaleOutput,
  PropertyManagementCostInput,
  PropertyManagementCostOutput,
  PropertyTaxAmountInput,
  PropertyTaxAmountOutput,
  PropertyValueInput,
  PropertyValueOutput,
  ReturnOnEquityInput,
  ReturnOnEquityOutput,
  ReturnOnInvestmentInput,
  ReturnOnInvestmentOutput,
  SellingCostInput,
  SellingCostOutput,
  TaxableIncomeInput,
  TaxableIncomeOutput,
  TotalCashInvestedInput,
  TotalCashInvestedOutput,
  TotalDeductionInput,
  TotalDeductionOutput,
  VacancyInput,
  VacancyOutput,
  WarrantyServiceCostInput,
  WarrantyServiceCostOutput,
} from '../interface/deal-analysis.interface';
import { DealAnalysisState } from '../state/deal-analysis.state';

@Injectable({
  providedIn: 'root',
})
export class DealAnalysisService {
  cGrossRentCache: ComputationCache = { parameter: null, result: null };
  cMonthlyRentCache: ComputationCache = { parameter: null, result: null };
  cVacancyCache: ComputationCache = { parameter: null, result: null };
  cOperatingIncomeCache: ComputationCache = { parameter: null, result: null };
  cPropertyTaxAmountCache: ComputationCache = { parameter: null, result: null };
  cInsuranceAmountCache: ComputationCache = { parameter: null, result: null };
  cHoaFeeCache: ComputationCache = { parameter: null, result: null };
  cPropertyManagementCostCache: ComputationCache = { parameter: null, result: null };
  cMaintenanceCostCache: ComputationCache = { parameter: null, result: null };
  cWarrantyServiceCostCache: ComputationCache = { parameter: null, result: null };
  cAccountingCostCache: ComputationCache = { parameter: null, result: null };
  cOperatingExpenseCache: ComputationCache = { parameter: null, result: null };
  cNetOperatingIncomeCache: ComputationCache = { parameter: null, result: null };
  cMonthlyMortgagePaymentCache: ComputationCache = { parameter: null, result: null };
  cMortgagePaymentCache: ComputationCache = { parameter: null, result: null };
  cCashFlowCache: ComputationCache = { parameter: null, result: null };
  cMortgageInterestCache: ComputationCache = { parameter: null, result: null };
  cDepreciationCache: ComputationCache = { parameter: null, result: null };
  cTotalDeductionCache: ComputationCache = { parameter: null, result: null };
  cTaxableIncomeCache: ComputationCache = { parameter: null, result: null };
  cPropertyValueCache: ComputationCache = { parameter: null, result: null };
  cMortgageBalanceCache: ComputationCache = { parameter: null, result: null };
  cEquityAppreciationCache: ComputationCache = { parameter: null, result: null };
  cSellingCostCache: ComputationCache = { parameter: null, result: null };
  cNetProceedCache: ComputationCache = { parameter: null, result: null };
  cTotalCashInvestedCache: ComputationCache = { parameter: null, result: null };
  cProfitOnSaleCache: ComputationCache = { parameter: null, result: null };
  cCapRateOnPurchasePriceCache: ComputationCache = { parameter: null, result: null };
  cCapRateOnFMVCache: ComputationCache = { parameter: null, result: null };
  cCashOnCashReturnCache: ComputationCache = { parameter: null, result: null };
  cReturnOnInvestmentCache: ComputationCache = { parameter: null, result: null };
  cReturnOnEquityCache: ComputationCache = { parameter: null, result: null };

  dealComputedResult: DealComputedResult = {};

  constructor(
    private snackBar: MatSnackBar,
    private prospektrApi: ProspektrApi,
    private dealAnalysisState: DealAnalysisState,
    private dealAnalysisConfig: DealAnalysisConfig,
    private dealAnalysisApi: DealAnalysisApi,
    private publicRecordApi: PublicRecordApi,
    private appState: AppState,
  ) { }

  analyseDeal(dealValues: DealComputationInput, term: number): DealComputedResult {
    for (let year = 1; year <= term; year++) {
      dealValues.uYear = year;
      const cTaxableIncomeObj = this.computeTaxableIncome(dealValues);
      const cCapRateOnPurchasePriceObj = this.computeCapRateOnPurchasePriceRatio(dealValues);
      const cCapRateOnCurrentPropertyValueObj = this.computeCapRateOnCurrentPropertyValue(dealValues);
      const cCapRateOnFMVObj = this.computeCapRateOnFMVRatio(dealValues);
      const cCashOnCashReturnObj = this.computeCashOnCashReturnRatio(dealValues);
      const cReturnOnInvestmentObj = this.computeReturnOnInvestmentRatio(dealValues);
      const cReturnOnEquityObj = this.computeReturnOnEquityRatio(dealValues);

      this.dealComputedResult[year] = {
        revenue: {
          grossRent: Math.round(cTaxableIncomeObj.grossRent),
          monthlyRent: Math.round(cTaxableIncomeObj.monthlyRent),
          vacancy: Math.round(cTaxableIncomeObj.vacancy),
          operatingIncome: Math.round(cTaxableIncomeObj.operatingIncome),
        },
        expense: {
          propertyTax: Math.round(cTaxableIncomeObj.propertyTax),
          insurance: Math.round(cTaxableIncomeObj.insurance),
          hoaFee: Math.round(cTaxableIncomeObj.hoaFee),
          otherExpenses: Math.round(cTaxableIncomeObj.otherExpenses),
          propertyManagement: Math.round(cTaxableIncomeObj.propertyManagement),
          maintenance: Math.round(cTaxableIncomeObj.maintenance),
          warrantyService: Math.round(cTaxableIncomeObj.warrantyService),
          accounting: Math.round(cTaxableIncomeObj.accounting),
          operatingExpense: Math.round(cTaxableIncomeObj.operatingExpense),
        },
        cashFlow: {
          netOperatingIncome: Math.round(cCapRateOnPurchasePriceObj.netOperatingIncome),
          mortgagePayment: Math.round(cReturnOnEquityObj.mortgagePayment),
          cashFlow: Math.round(cReturnOnEquityObj.cashFlow),
        },
        taxDeduction: {
          mortgageInterest: Math.round(cTaxableIncomeObj.mortgageInterest),
          depreciation: Math.round(cTaxableIncomeObj.depreciation),
          totalDeduction: Math.round(cTaxableIncomeObj.totalDeduction),
          taxableIncome: Math.round(cTaxableIncomeObj.taxableIncome),
        },
        equityAppreciation: {
          propertyValue: Math.round(cReturnOnEquityObj.propertyValue),
          mortgageBalance: Math.round(cReturnOnEquityObj.mortgageBalance),
          equity: Math.round(cReturnOnEquityObj.equity),
        },
        salePrediction: {
          sellingCost: Math.round(cReturnOnInvestmentObj.sellingCost),
          cumulativeCashFlow: Math.round(cReturnOnInvestmentObj.cumulativeCashFlow),
          totalCashInvested: Math.round(cReturnOnInvestmentObj.totalCashInvested),
          netProceed: Math.round(cReturnOnInvestmentObj.netProceed),
          profitOnSale: Math.round(cReturnOnInvestmentObj.profitOnSale),
        },
        returnIndicator: {
          capRateOnPurchasePriceRatio: cCapRateOnPurchasePriceObj.capRateOnPurchasePriceRatio && Math.round(cCapRateOnPurchasePriceObj.capRateOnPurchasePriceRatio * 10000) / 10000,
          capRateOnCurrentValueRatio: cCapRateOnCurrentPropertyValueObj.capRateOnCurrentValueRatio && Math.round(cCapRateOnCurrentPropertyValueObj.capRateOnCurrentValueRatio * 10000) / 10000,
          capRateOnFMVRatio: cCapRateOnFMVObj.capRateOnFMVRatio && Math.round(cCapRateOnFMVObj.capRateOnFMVRatio * 10000) / 10000,
          cashOnCashReturnRatio: cCashOnCashReturnObj.cashOnCashReturnRatio && Math.round(cCashOnCashReturnObj.cashOnCashReturnRatio * 10000) / 10000,
          returnOnInvestmentRatio: cReturnOnInvestmentObj.returnOnInvestmentRatio && Math.round(cReturnOnInvestmentObj.returnOnInvestmentRatio * 10000) / 10000,
          returnOnEquityRatio: cReturnOnEquityObj.returnOnEquityRatio && Math.round(cReturnOnEquityObj.returnOnEquityRatio * 10000) / 10000,
        },
      };
    }
    // this.logAnalysis(this.dealComputedResult);
    return this.dealComputedResult;
  }

  // Revenue Computation Section

  computeGrossRent(input: GrossRentInput): GrossRentOutput {
    let cGrossRent: any = {};
    // if (_.isEqual(input, this.cGrossRentCache.parameter)) {
    //   cGrossRent = this.cGrossRentCache.result;
    // } else {
    cGrossRent.grossRent = input.uMonthlyRent * 12 * Math.pow(input.uMonthlyRentYoYRatio + 1, input.uYear - 1);
    //   this.cGrossRentCache = { parameter: input, result: cGrossRent };
    // }
    return { ...cGrossRent };
  }

  computeMonthlyRent(input: MonthlyRentInput): MonthlyRentOutput {
    let cMonthlyRent: any = {};
    // if (_.isEqual(input, this.cMonthlyRentCache.parameter)) {
    //   cMonthlyRent = this.cMonthlyRentCache.result;
    // } else {
    cMonthlyRent.monthlyRent = input.uMonthlyRent * Math.pow(input.uMonthlyRentYoYRatio + 1, input.uYear - 1);
    //   this.cMonthlyRentCache = { parameter: input, result: cMonthlyRent };
    // }
    return { ...cMonthlyRent };
  }

  computeVacancy(input: VacancyInput): VacancyOutput {
    let cMonthlyRent;
    let cVacancy: any = {};
    // if (_.isEqual(input, this.cMonthlyRentCache.parameter)) {
    //   cMonthlyRent = this.cMonthlyRentCache.result;
    // } else {
    cMonthlyRent = this.computeMonthlyRent(input);
    // }
    // if (_.isEqual(input, this.cVacancyCache.parameter)) {
    //   cVacancy = this.cVacancyCache.result;
    // } else {
    cVacancy.vacancy = -(cMonthlyRent.monthlyRent * input.uRentChurnRatio * input.uTenantSearchDuration);
    // }
    return { ...cMonthlyRent, ...cVacancy };
  }

  computeOperatingIncome(input: OperatingIncomeInput): OperatingIncomeOutput {
    let cGrossRent;
    let cVacancy;
    let cOperatingIncome: any = {};
    let cOtherExpenses;
    // if (_.isEqual(input, this.cGrossRentCache.parameter)) {
    //   cGrossRent = this.cGrossRentCache.result;
    // } else {
    cGrossRent = this.computeGrossRent(input);
    // }
    // if (_.isEqual(input, this.cVacancyCache.parameter)) {
    //   cVacancy = this.cVacancyCache.result;
    // } else {
    cVacancy = this.computeVacancy(input);
    // }
    // if (_.isEqual(input, this.cOperatingIncomeCache.parameter)) {
    //   cOperatingIncome = this.cOperatingIncomeCache.result;
    // } else {
    const operatingIncome = cGrossRent.grossRent + cVacancy.vacancy;
    cOperatingIncome = { operatingIncome };
    cOtherExpenses = this.computeOtherExpenses(cOperatingIncome, input);
    return { ...cGrossRent, ...cVacancy, ...cOperatingIncome, ...cOtherExpenses };
  }

  computeOtherExpenses(cOperatingIncome, input) {
    let cOtherExpenses: any = {};
    let otherExpenses = cOperatingIncome.operatingIncome * input.uOtherExpenses;
    cOtherExpenses = { otherExpenses };
    return { ...cOtherExpenses };
  }

  // Expense Computation Section

  computePropertyTaxAmount(input: PropertyTaxAmountInput): PropertyTaxAmountOutput {
    let cPropertyTaxAmount: any = {};
    // if (_.isEqual(input, this.cPropertyTaxAmountCache.parameter)) {
    //   cPropertyTaxAmount = this.cPropertyTaxAmountCache.result;
    // } else {
    let propertyTax = input.uAssessedPropertyValue * Math.pow(input.uAssessedPropertyValueYoYRatio + 1, input.uYear - 1) * (input.uPropertyTaxAmount / input.uAssessedPropertyValue);
    propertyTax = isNaN(propertyTax) ? 0 : propertyTax;
    cPropertyTaxAmount = { propertyTax };
    // }
    return { ...cPropertyTaxAmount };
  }

  computeInsuranceAmount(input: InsuranceAmountInput): InsuranceAmountOutput {
    let cInsuranceAmount: any = {};
    // if (_.isEqual(input, this.cInsuranceAmountCache.parameter)) {
    //   cInsuranceAmount = this.cInsuranceAmountCache.result;
    // } else {
    let insurance = 0;
    if ((this.appState.appCompanyAliasValue == 'opscan' && input.uInsuranceAmount == 0) || this.dealAnalysisState.isuInsuranceAmountEdited) {
      insurance = 0;
    } else if (input.uInsuranceAmount == 0 && input.uState) {
      // const cBuildingValue = (input.uFairMarketValue - input.uLandValue) * Math.pow(1 + input.uFairMarketValueYoYRatio, input.uYear - 1);
      const cBuildingValue = (input.uPurchasePrice - input.uLandValue) * Math.pow(1 + input.uFairMarketValueYoYRatio, input.uYear - 1);
      let insuranceRatioKey = '';
      if (cBuildingValue <= 200000) {
        insuranceRatioKey = 'value2K';
      } else if (cBuildingValue > 200000 && cBuildingValue <= 300000) {
        insuranceRatioKey = 'value3K';
      } else if (cBuildingValue > 300000 && cBuildingValue <= 400000) {
        insuranceRatioKey = 'value4K';
      } else if (cBuildingValue > 400000) {
        insuranceRatioKey = 'value5K';
      }
      if (input.uState.length != 0 || Object.keys(input.uState).length != 0) {
        const insuranceRatio = this.dealAnalysisConfig.stateInsuranceRatio[input.uState.toLowerCase()][insuranceRatioKey];
        insurance = insuranceRatio * cBuildingValue;
      }
    } else {
      insurance = input.uInsuranceAmount * Math.pow(1 + input.uFairMarketValueYoYRatio, input.uYear - 1);
    }
    cInsuranceAmount = { insurance };
    // }
    return { ...cInsuranceAmount };
  }

  computeHoaFee(input: HoaFeeInput): HoaFeeOutput {
    let cHoaFee: any = {};
    // if (_.isEqual(input, this.cHoaFeeCache.parameter)) {
    //   cHoaFee = this.cHoaFeeCache.result;
    // } else {
    const hoaFee = input.uHoaFee * Math.pow(1 + input.uHoaFeeYoYRatio, input.uYear - 1);
    cHoaFee = { hoaFee };
    // }
    return { ...cHoaFee };
  }

  computePropertyManagementCost(input: PropertyManagementCostInput): PropertyManagementCostOutput {
    let cOperatingIncome;
    let cPropertyManagementCost: any = {};

    // if (_.isEqual(input, this.cOperatingIncomeCache.parameter)) {
    //   cOperatingIncome = this.cOperatingIncomeCache.result;
    // } else {
    cOperatingIncome = this.computeOperatingIncome(input);
    // }

    // if (_.isEqual(input, this.cPropertyManagementCostCache.parameter)) {
    //   cPropertyManagementCost = this.cPropertyManagementCostCache.result;
    // } else {
    const propertyManagement = cOperatingIncome.operatingIncome * input.uPropertyManagementRatio;
    cPropertyManagementCost = { propertyManagement };
    // }
    return { ...cOperatingIncome, ...cPropertyManagementCost };
  }

  computeMaintenanceCost(input: MaintenanceCostInput): MaintenanceCostOutput {
    let cGrossRent;
    let cMaintenanceCost: any = {};
    // if (_.isEqual(input, this.cGrossRentCache.parameter)) {
    //   cGrossRent = this.cGrossRentCache.result;
    // } else {
    cGrossRent = this.computeGrossRent(input);
    // }
    const maintenance = cGrossRent.grossRent * input.uMaintenanceRatio;
    cMaintenanceCost = { maintenance };
    return { ...cGrossRent, ...cMaintenanceCost };
  }

  computeWarrantyServiceCost(input: WarrantyServiceCostInput): WarrantyServiceCostOutput {
    const cWarrantyServiceCost = input.uWarrantyServiceCost * Math.pow(input.uWarrantyServiceYoYRatio + 1, input.uYear - 1);
    return { warrantyService: cWarrantyServiceCost };
  }

  computeAccountingCost(input: AccountingCostInput): AccountingCostOutput {
    const cAccountingCost = input.uAccountingCost * Math.pow(input.uAccountingYoYRatio + 1, input.uYear - 1);
    return { accounting: cAccountingCost };
  }

  computeOperatingExpense(input: OperatingExpenseInput): OperatingExpenseOutput {
    let cPropertyTaxAmountObj;
    let cInsuranceAmountObj;
    let cHoaFeeObj;
    let cPropertyManagementCostObj;
    let cMaintenanceCostObj;
    let cWarrantyServiceCostObj;
    let cAccountingCostObj;
    let cOperatingExpenseObj: any = {};
    let cOperatingIncome;

    // if (_.isEqual(input, this.cPropertyTaxAmountCache.parameter)) {
    //   cPropertyTaxAmountObj = this.cPropertyTaxAmountCache.result;
    // } else {
    cPropertyTaxAmountObj = this.computePropertyTaxAmount(input);
    //   this.cPropertyTaxAmountCache = {
    //     parameter: input,
    //     result: cPropertyTaxAmountObj,
    //   };
    // }
    // if (_.isEqual(input, this.cInsuranceAmountCache.parameter)) {
    //   cInsuranceAmountObj = this.cInsuranceAmountCache.result;
    // } else {
    cInsuranceAmountObj = this.computeInsuranceAmount(input);
    //   this.cInsuranceAmountCache = {
    //     parameter: input,
    //     result: cInsuranceAmountObj,
    //   };
    // }
    // if (_.isEqual(input, this.cHoaFeeCache.parameter)) {
    //   cHoaFeeObj = this.cHoaFeeCache.result;
    // } else {
    cHoaFeeObj = this.computeHoaFee(input);
    //   this.cHoaFeeCache = {
    //     parameter: input,
    //     result: cHoaFeeObj,
    //   };
    // }
    // if (_.isEqual(input, this.cPropertyManagementCostCache.parameter)) {
    //   cPropertyManagementCostObj = this.cPropertyManagementCostCache.result;
    // } else {
    cPropertyManagementCostObj = this.computePropertyManagementCost(input);
    //   this.cPropertyManagementCostCache = {
    //     parameter: input,
    //     result: cPropertyManagementCostObj,
    //   };
    // }
    // if (_.isEqual(input, this.cMaintenanceCostCache.parameter)) {
    //   cMaintenanceCostObj = this.cMaintenanceCostCache.result;
    // } else {
    cMaintenanceCostObj = this.computeMaintenanceCost(input);
    //   this.cMaintenanceCostCache = {
    //     parameter: input,
    //     result: cMaintenanceCostObj,
    //   };
    // }
    // if (_.isEqual(input, this.cWarrantyServiceCostCache.parameter)) {
    //   cWarrantyServiceCostObj = this.cWarrantyServiceCostCache.result;
    // } else {
    cWarrantyServiceCostObj = this.computeWarrantyServiceCost(input);
    //   this.cWarrantyServiceCostCache = {
    //     parameter: input,
    //     result: cWarrantyServiceCostObj,
    //   };
    // }
    // if (_.isEqual(input, this.cAccountingCostCache.parameter)) {
    //   cAccountingCostObj = this.cAccountingCostCache.result;
    // } else {
    cAccountingCostObj = this.computeAccountingCost(input);
    //   this.cAccountingCostCache = {
    //     parameter: input,
    //     result: cAccountingCostObj,
    //   };
    // }
    // if (_.isEqual(input, this.cOperatingExpenseCache.parameter)) {
    //   cOperatingExpenseObj = this.cOperatingExpenseCache.result;
    //   this.cOperatingExpenseCache = {
    //     parameter: input,
    //     result: cOperatingExpenseObj,
    //   };
    // } else {
    let operatingExpense;
    if (this.dealAnalysisState.isAdvancedModeOPenValue == false) {
      cOperatingIncome = this.computeOperatingIncome(input);
      operatingExpense = cPropertyTaxAmountObj.propertyTax + cOperatingIncome.otherExpenses;
      cOperatingExpenseObj = { operatingExpense };
    } else
      operatingExpense =
        cPropertyTaxAmountObj.propertyTax +
        cInsuranceAmountObj.insurance +
        cHoaFeeObj.hoaFee +
        cPropertyManagementCostObj.propertyManagement +
        cMaintenanceCostObj.maintenance +
        cWarrantyServiceCostObj.warrantyService +
        cAccountingCostObj.accounting;
    cOperatingExpenseObj = { operatingExpense };
    //   this.cOperatingExpenseCache = {
    //     parameter: input,
    //     result: cOperatingExpenseObj,
    //   };
    // }
    return {
      ...cPropertyTaxAmountObj,
      ...cInsuranceAmountObj,
      ...cHoaFeeObj,
      ...cPropertyManagementCostObj,
      ...cMaintenanceCostObj,
      ...cWarrantyServiceCostObj,
      ...cAccountingCostObj,
      ...cOperatingExpenseObj,
    };
  }

  // Cash flow computation section

  computeNetOperatingIncome(input: NetOperatingIncomeInput): NetOperatingIncomeOutput {
    let cOperatingExpenseObj;
    let cNetOperatingIncomeObj: any = {};

    // if (_.isEqual(input, this.cOperatingExpenseCache.parameter)) {
    //   cOperatingExpenseObj = this.cOperatingExpenseCache.result;
    // } else {
    cOperatingExpenseObj = this.computeOperatingExpense(input);
    //   this.cOperatingExpenseCache = {
    //     parameter: input,
    //     result: cOperatingExpenseObj,
    //   };
    // }
    // if (_.isEqual(input, this.cNetOperatingIncomeCache.parameter)) {
    //   cNetOperatingIncomeObj = this.cNetOperatingIncomeCache.result;
    // } else {
    const netOperatingIncome = cOperatingExpenseObj.operatingIncome - cOperatingExpenseObj.operatingExpense;
    cNetOperatingIncomeObj = { netOperatingIncome };
    //   this.cNetOperatingIncomeCache = {
    //     parameter: input,
    //     result: cNetOperatingIncomeObj,
    //   };
    // }
    return {
      ...cOperatingExpenseObj,
      ...cNetOperatingIncomeObj,
    };
  }

  computeMonthlyMortgagePayment(input: MonthlyMortgagePaymentInput): MonthlyMortgagePaymentOutput {
    const cMonthlyMortgagePayment =
      (input.uLoanAmount * Math.pow(1 + input.uInterestRate / 12, input.uLoanTerm * 12) * input.uInterestRate) / 12 / (Math.pow(1 + input.uInterestRate / 12, input.uLoanTerm * 12) - 1);
    return { monthlyMortgage: cMonthlyMortgagePayment };
  }

  computeMortgagePayment(input: MortgagePaymentInput): MortgagePaymentOutput {
    let cMortgagePaymentObj;
    let cMonthlyMortgagePayment = this.computeMonthlyMortgagePayment(input);
    let mortgagePayment = 0;
    if (input.uLoanTerm >= input.uYear) {
      cMonthlyMortgagePayment.monthlyMortgage = Math.round(cMonthlyMortgagePayment.monthlyMortgage);
      mortgagePayment = cMonthlyMortgagePayment.monthlyMortgage * 12;
    }
    cMortgagePaymentObj = { mortgagePayment };
    return { ...cMonthlyMortgagePayment, ...cMortgagePaymentObj };
  }

  computeCashFlow(input: CashFlowInput): CashFlowOutput {
    let cCashFlowObj: any = {};
    let cNetOperatingIncomeObj = this.computeNetOperatingIncome(input);
    let cMortgagePaymentObj = this.computeMortgagePayment(input);
    const cashFlow = cNetOperatingIncomeObj.netOperatingIncome - cMortgagePaymentObj.mortgagePayment;
    cCashFlowObj = { cashFlow };
    return { ...cNetOperatingIncomeObj, ...cMortgagePaymentObj, ...cCashFlowObj };
  }

  // Tax Deduction Computation Section

  computeMortgageInterest(input: MortgageInterestInput): MortgageInterestOutput {
    let cMortgageInterestObj: any = {};
    let cMonthlyMortgagePaymentObj = this.computeMonthlyMortgagePayment(input);
    let mortgageInterest = 0;
    if (input.uLoanTerm >= input.uYear) {
      mortgageInterest =
        cMonthlyMortgagePaymentObj.monthlyMortgage * 12 * input.uYear -
        (Math.pow(input.uInterestRate / 12 + 1, input.uYear * 12) - 1) * (cMonthlyMortgagePaymentObj.monthlyMortgage / (input.uInterestRate / 12) - input.uLoanAmount) -
        (cMonthlyMortgagePaymentObj.monthlyMortgage * 12 * (input.uYear - 1) -
          (Math.pow(input.uInterestRate / 12 + 1, (input.uYear - 1) * 12) - 1) * (cMonthlyMortgagePaymentObj.monthlyMortgage / (input.uInterestRate / 12) - input.uLoanAmount));
    }
    cMortgageInterestObj = { mortgageInterest };
    return { ...cMonthlyMortgagePaymentObj, ...cMortgageInterestObj };
  }

  computeDepreciation(input: DepreciationInput): DepreciationOutput {
    let cDepreciationObj: any = {};
    let depreciation = 0;
    if (input.uYear <= 27.5) {
      depreciation = (input.uPurchasePrice - input.uLandValue + input.uClosingCost + input.uRenovationCost) / 27.5;
    }
    cDepreciationObj = { depreciation };
    return { ...cDepreciationObj };
  }

  computeTotalDeduction(input: TotalDeductionInput): TotalDeductionOutput {
    let cTotalDeductionObj: any = {};
    let cOperatingExpenseObj = this.computeOperatingExpense(input);
    let cMortgageInterestObj = this.computeMortgageInterest(input);
    let cDepreciationObj = this.computeDepreciation(input);
    let totalDeduction = cOperatingExpenseObj.operatingExpense + cMortgageInterestObj.mortgageInterest + cDepreciationObj.depreciation;
    cTotalDeductionObj = { totalDeduction };
    return { ...cOperatingExpenseObj, ...cMortgageInterestObj, ...cDepreciationObj, ...cTotalDeductionObj };
  }

  computeTaxableIncome(input: TaxableIncomeInput): TaxableIncomeOutput {
    let cTaxableIncomeObj: any = {};
    let cTotalDeductionObj = this.computeTotalDeduction(input);
    let taxableIncome = cTotalDeductionObj.operatingIncome - cTotalDeductionObj.totalDeduction;
    cTaxableIncomeObj = { taxableIncome };
    return { ...cTotalDeductionObj, ...cTaxableIncomeObj };
  }

  // Equity Appreciation Calculation Section

  computePropertyValue(input: PropertyValueInput): PropertyValueOutput {
    let cPropertyValueObj: any = {};
    // const propertyValue = input.uFairMarketValue * Math.pow(1 + input.uFairMarketValueYoYRatio, input.uYear - 1);
    const propertyValue = input.uPurchasePrice * Math.pow(1 + input.uFairMarketValueYoYRatio, input.uYear - 1);
    cPropertyValueObj = { propertyValue };
    return { ...cPropertyValueObj };
  }

  computeMortgageBalance(input: MortgageBalanceInput): MortgageBalanceOutput {
    let cMortgageBalanceObj: any = {};
    let cMonthlyMortgagePaymentObj = this.computeMonthlyMortgagePayment(input);
    let mortgageBalance = 0;
    if (input.uLoanTerm >= input.uYear) {
      mortgageBalance =
        input.uLoanAmount - (Math.pow(1 + input.uInterestRate / 12, input.uYear * 12) - 1) * (cMonthlyMortgagePaymentObj.monthlyMortgage / (input.uInterestRate / 12) - input.uLoanAmount);
    }
    cMortgageBalanceObj = { mortgageBalance };
    return { ...cMonthlyMortgagePaymentObj, ...cMortgageBalanceObj };
  }

  computeEquityAppreciation(input: EquityAppreciationInput): EquityAppreciationOutput {
    let cEquityAppreciationObj: any = {};
    let cPropertyValueObj = this.computePropertyValue(input);
    let cMortgageBalanceObj = this.computeMortgageBalance(input);
    const equity = cPropertyValueObj.propertyValue - cMortgageBalanceObj.mortgageBalance;
    cEquityAppreciationObj = { equity };
    return { ...cPropertyValueObj, ...cMortgageBalanceObj, ...cEquityAppreciationObj };
  }

  // Profit On Sales Computation Section

  computeSellingCost(input: SellingCostInput): SellingCostOutput {
    let cSellingCostObj: any = {};
    let cPropertyValueObj = this.computePropertyValue(input);
    const sellingCost = cPropertyValueObj.propertyValue * input.uSalesCommissionRatio;
    cSellingCostObj = { sellingCost };
    return { ...cPropertyValueObj, ...cSellingCostObj };
  }

  computeNetProceed(input: NetProceedInput): NetProceedOutput {
    let cNetProceedObj: any = {};
    let cEquityAppreciationObj = this.computeEquityAppreciation(input);
    let cSellingCostObj = this.computeSellingCost(input);
    const netProceed = cEquityAppreciationObj.equity - cSellingCostObj.sellingCost;
    cNetProceedObj = { netProceed };
    return { ...cEquityAppreciationObj, ...cSellingCostObj, ...cNetProceedObj };
  }

  computeCumulativeCashFlow(input: CumulativeCashFlowInput): CumulativeCashFlowOutput {
    let cCumulativeCashFlowObj: any = {};
    let cumulativeCashFlow = 0;
    const uYear = input.uYear;
    for (let year = 1; year <= uYear; year++) {
      input.uYear = year;
      const cCashFlowObj = this.computeCashFlow(input);
      cumulativeCashFlow += cCashFlowObj.cashFlow;
    }
    cCumulativeCashFlowObj = { cumulativeCashFlow };
    return { ...cCumulativeCashFlowObj };
  }

  computeTotalCashInvested(input: TotalCashInvestedInput): TotalCashInvestedOutput {
    let cTotalCashInvestedObj: any = {};
    let totalCashInvested = input.uPurchasePrice - input.uLoanAmount + input.uClosingCost + input.uRenovationCost;
    cTotalCashInvestedObj = { totalCashInvested };
    return { ...cTotalCashInvestedObj };
  }

  computeProfitOnSale(input: ProfitOnSaleInput): ProfitOnSaleOutput {
    let cProfitOnSaleObj: any = {};
    let cNetProceedObj = this.computeNetProceed(input);
    let cCumulativeCashFlowObj = this.computeCumulativeCashFlow(input);
    let cTotalCashInvestedObj = this.computeTotalCashInvested(input);
    const profitOnSale = cNetProceedObj.netProceed + cCumulativeCashFlowObj.cumulativeCashFlow - cTotalCashInvestedObj.totalCashInvested;
    cProfitOnSaleObj = { profitOnSale };
    return { ...cNetProceedObj, ...cCumulativeCashFlowObj, ...cProfitOnSaleObj };
  }

  // Return Indicator Computation Section

  computeCapRateOnPurchasePriceRatio(input: CapRateOnPurchasePriceInput): CapRateOnPurchasePriceOutput {
    let cCapRateOnPurchasePriceObj: any = {};
    let cNetOperatingIncomeObj = this.computeNetOperatingIncome(input);
    let capRateOnPurchasePriceRatio = cNetOperatingIncomeObj.netOperatingIncome / input.uPurchasePrice;
    capRateOnPurchasePriceRatio = isFinite(capRateOnPurchasePriceRatio) ? capRateOnPurchasePriceRatio : null;
    cCapRateOnPurchasePriceObj = { capRateOnPurchasePriceRatio };
    return { ...cNetOperatingIncomeObj, ...cCapRateOnPurchasePriceObj };
  }

  computeCapRateOnCurrentPropertyValue(input) {
    let cCapRateOnCurrentValueObj: any = {};
    let cNetOperatingIncomeObj = this.computeNetOperatingIncome(input);
    let capRateOnCurrentValueRatio = cNetOperatingIncomeObj.netOperatingIncome / input.uPropertyCurrentValue;
    capRateOnCurrentValueRatio = isFinite(capRateOnCurrentValueRatio) ? capRateOnCurrentValueRatio : null;
    cCapRateOnCurrentValueObj = { capRateOnCurrentValueRatio };
    return { ...cNetOperatingIncomeObj, ...cCapRateOnCurrentValueObj };
  }

  computeCapRateOnFMVRatio(input: CapRateOnFMVInput): CapRateOnFMVOutput {
    let cCapRateOnFMVObj: any = {};
    let cNetOperatingIncomeObj = this.computeNetOperatingIncome(input);
    let cPropertyValueObj = this.computePropertyValue(input);
    let capRateOnFMVRatio = cNetOperatingIncomeObj.netOperatingIncome / cPropertyValueObj.propertyValue;
    capRateOnFMVRatio = isFinite(capRateOnFMVRatio) ? capRateOnFMVRatio : null;
    cCapRateOnFMVObj = { capRateOnFMVRatio };
    return { ...cNetOperatingIncomeObj, ...cPropertyValueObj, ...cCapRateOnFMVObj };
  }

  computeCashOnCashReturnRatio(input: CashOnCashReturnInput): CashOnCashReturnOutput {
    let cCashOnCashReturnObj: any = {};
    let cCashFlowObj = this.computeCashFlow(input);
    let cTotalCashInvestedObj = this.computeTotalCashInvested(input);
    let cashOnCashReturnRatio = cCashFlowObj.cashFlow / cTotalCashInvestedObj.totalCashInvested;
    cashOnCashReturnRatio = isFinite(cashOnCashReturnRatio) ? cashOnCashReturnRatio : null;
    cCashOnCashReturnObj = { cashOnCashReturnRatio };
    return { ...cCashFlowObj, ...cTotalCashInvestedObj, ...cCashOnCashReturnObj };
  }

  computeReturnOnInvestmentRatio(input: ReturnOnInvestmentInput): ReturnOnInvestmentOutput {
    let cReturnOnInvestmentObj: any = {};
    let cProfitOnSaleObj = this.computeProfitOnSale(input);
    let cTotalCashInvestedObj = this.computeTotalCashInvested(input);
    let returnOnInvestmentRatio = cProfitOnSaleObj.profitOnSale / cTotalCashInvestedObj.totalCashInvested;
    returnOnInvestmentRatio = isFinite(returnOnInvestmentRatio) ? returnOnInvestmentRatio : null;
    cReturnOnInvestmentObj = { returnOnInvestmentRatio };
    return { ...cProfitOnSaleObj, ...cTotalCashInvestedObj, ...cReturnOnInvestmentObj };
  }

  computeReturnOnEquityRatio(input: ReturnOnEquityInput): ReturnOnEquityOutput {
    let cReturnOnEquityObj: any = {};
    let cCashFlowObj = this.computeCashFlow(input);
    let cEquityAppreciationObj = this.computeEquityAppreciation(input);
    let returnOnEquityRatio = cCashFlowObj.cashFlow / cEquityAppreciationObj.equity;
    returnOnEquityRatio = isFinite(returnOnEquityRatio) ? returnOnEquityRatio : null;
    cReturnOnEquityObj = { returnOnEquityRatio };
    return { ...cCashFlowObj, ...cEquityAppreciationObj, ...cReturnOnEquityObj };
  }

  logAnalysis(dealResult) {
    const dealTable = {};
    Object.values(dealResult).forEach((yearlyValue, index, array) => {
      if (index === 0 || index === 1 || index === array.length - 11 || index === array.length - 1) {
        Object.values(yearlyValue).forEach((valueGroup) => {
          for (const key in valueGroup) {
            if (Object.prototype.hasOwnProperty.call(valueGroup, key)) {
              const value = valueGroup[key];
              if (!Object.prototype.hasOwnProperty.call(dealTable, key)) {
                dealTable[key] = [];
              }
              dealTable[key][(index + 1).toString()] = value;
            }
          }
        });
      }
    });
    console.table(dealTable);
  }

  fetchDeals(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .getDealList(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (res && res.data.length != 0) {
              this.dealAnalysisState.dealListValue = res.data;
              this.dealAnalysisState.dealListCountValue = res.totalCount;
            } else {
              this.dealAnalysisState.dealListValue = null;
              this.dealAnalysisState.dealListCountValue = 0;
            }
          },
          (err) => {
            this.dealAnalysisState.dealListValue = null;
            this.dealAnalysisState.dealListCountValue = 0;
          }
        );
    });
  }

  fetchDealsById(id) {
    return new Observable((observer) => {
      this.prospektrApi.getDealById(id).subscribe(
        (res: any) => {
          if (res && res.data) {
            this.dealAnalysisState.dealIdValue = res.data;
            observer.next(res);
          } else {
            this.dealAnalysisState.dealIdValue = null;
          }
        },
        (err) => {
          this.dealAnalysisState.dealIdValue = null;
          this.openSnackBar('something Went Wrong ', 'snackbar-error');
          observer.next(err);
        }
      );
    });
  }
  renameDeal(dealId, dealName) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .updateDeal({ id: dealId, name: dealName })
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            this.openSnackBar(res.message, 'snackbar-success');
          },
          (err) => {
            let message = 'Failed to update deal';
            if (err && err.error && err.error.message) {
              message = err.error.message;
            }
            this.openSnackBar(message, 'snackbar-error');
          }
        );
    });
  }

  deleteDeal(dealId) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .deleteDeal(dealId)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: BackendResponse) => {
            this.openSnackBar(res.message, 'snackbar-success');
          },
          (err) => {
            this.openSnackBar('Deleting deal failed.', 'snackbar-error');
          }
        );
    });
  }

  saveDeal(params) {
    return new Observable((observer) => {
      let response = {};
      this.prospektrApi
        .saveDeal(params)
        .pipe(
          finalize(() => {
            observer.next(response);
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            this.openSnackBar(res.message, 'snackbar-success');
            let inputParams: any = {
              limit: 10,
              offset: 0,
            };
            let fetchDealsParams = { ...inputParams, PMXPropertyId: params.PMXPropertyId };
            this.fetchDeals(fetchDealsParams).subscribe();
            inputParams = { ...inputParams, orderBy: 'updatedDate', orderType: 'desc', searchQuery: '' };
            this.getDealAnalysisByAddress(inputParams).subscribe();
            response = res;
          },
          (err) => {
            let message = 'Failed to save deal';
            if (err && err.error && err.error.message) {
              message = err.error.message;
            }
            this.openSnackBar(message, 'snackbar-error');
          }
        );
    });
  }
  shareDealAnalysis(shareData: DealAnalysisShareInput): Observable<any> {
    return new Observable((observer) => {
      this.dealAnalysisApi.shareDealAnalysis(shareData).subscribe(
        (res) => {
          if (res.status && res.status == 'OK' && res.message) {
            observer.next(res.message);
          }
          this.fetchDealsById(shareData.id).subscribe((res: any) => { });
        },
        (err) => {
          this.openSnackBar(err.error.message || 'Failed to share deal analysis', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }

  getDealAnalysisByAddress(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .dealAnalysisByAddress(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (!params.searchQuery && params.offset != 0 && res?.data?.records?.length == 0) {
              observer.next({ isPageIndexChanged: true });
              params.offset = params.offset - params.limit;
              this.getDealAnalysisByAddress(params).subscribe((res: any) => { });
            } else if (res?.data?.records && Array.isArray(res.data.records) && res.data.records.length) {
              this.dealAnalysisState.savedDealsValue = res.data.records;
              this.dealAnalysisState.savedDealsCountValue = res.data.totalRecordCount;
            } else {
              this.dealAnalysisState.savedDealsValue = [];
              this.dealAnalysisState.savedDealsCountValue = 0;
            }
          },
          (err) => {
            this.dealAnalysisState.savedDealsValue = [];
            this.dealAnalysisState.savedDealsCountValue = 0;
          }
        );
    });
  }

  getPropertyDetails(propertyObj) {
    this.publicRecordApi.getPropertyDetails(propertyObj).subscribe((res) => {
      if (res && res.data) {
        this.dealAnalysisState.activePropertyDetailsValue = res.data;
      } else {
        this.dealAnalysisState.activePropertyDetailsValue = null;
      }
    });
  }
  private openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }

  public getHoaFee(input: Array<{ fee: number; recurrence: string }>): number {
    let hoaFeeTotal = 0;
    input.forEach((hoaFeeSet) => {
      if (hoaFeeSet.fee) {
        if (hoaFeeSet.recurrence && hoaFeeSet.recurrence !== 'Not Applicable') {
          switch (hoaFeeSet.recurrence) {
            case 'Annually':
            case 'Yearly':
              hoaFeeTotal += hoaFeeSet.fee;
              break;
            case 'Monthly':
              hoaFeeTotal += hoaFeeSet.fee * 12;
              break;
            case 'Daily':
              hoaFeeTotal += hoaFeeSet.fee * 365;
              break;
            case 'Weekly':
              hoaFeeTotal += hoaFeeSet.fee * 52;
              break;
            case 'Bi-Weekly':
              hoaFeeTotal += hoaFeeSet.fee * 26;
              break;
            case 'Bi-Monthly':
              hoaFeeTotal += hoaFeeSet.fee * 6;
              break;
            case 'Quarterly':
              hoaFeeTotal += hoaFeeSet.fee * 4;
              break;
            case 'Semi-Annually':
            case 'Semi Annual':
            case 'Semi-Annual':
            case 'Semiannually':
              hoaFeeTotal += hoaFeeSet.fee * 2;
              break;
            case 'Seasonal':
              hoaFeeTotal += hoaFeeSet.fee;
              break;
          }
        }
      }
    });
    return hoaFeeTotal;
  }

  deleteSharedDeal(params) {
    return new Observable((observer) => {
      this.dealAnalysisApi.deleteSharedDealDetails(params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }
}

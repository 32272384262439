import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-homerise',
  templateUrl: './homerise.component.html',
  styleUrls: ['./homerise.component.scss'],
})
export class HomeriseComponent implements OnInit {
  constructor() {}

  @Input() data;
  @Input() uniqueMlsList;
  @Input() currentYear;

  ngOnInit(): void {}
  openLogInDialog() {
    // this.authorizeService.openLogInDialog();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Campaign } from '../interface/campaign.interface';
import { Contact } from '../interface/contact.interface';

@Injectable({
  providedIn: 'root',
})
export class CampaignState {
  private campaignListSub$ = new BehaviorSubject(null);
  campaignList$ = this.campaignListSub$.asObservable();

  public get campaignListValue() {
    return this.campaignListSub$.value;
  }

  public set campaignListValue(campaign) {
    this.campaignListSub$.next(campaign);
  }

  private isLoadingCampaignListSub$ = new BehaviorSubject<boolean>(false);
  isLoadingCampaignList$ = this.isLoadingCampaignListSub$.asObservable();

  public get isLoadingCampaignListValue() {
    return this.isLoadingCampaignListSub$.value;
  }

  public set isLoadingCampaignListValue(status) {
    this.isLoadingCampaignListSub$.next(status);
  }

  private agentMarketingCampaignListSub$ = new BehaviorSubject(null);
  agentMarketingCampaignList$ = this.agentMarketingCampaignListSub$.asObservable();

  public get agentMarketingCampaignListValue() {
    return this.agentMarketingCampaignListSub$.value;
  }

  public set agentMarketingCampaignListValue(campaign) {
    this.agentMarketingCampaignListSub$.next(campaign);
  }

  private isLoadingAgentMarketingCampaignListSub$ = new BehaviorSubject<boolean>(false);
  isLoadingAgentMarketingCampaignList$ = this.isLoadingAgentMarketingCampaignListSub$.asObservable();

  public get isLoadingAgentMarketingCampaignListValue() {
    return this.isLoadingAgentMarketingCampaignListSub$.value;
  }

  public set isLoadingAgentMarketingCampaignListValue(status) {
    this.isLoadingAgentMarketingCampaignListSub$.next(status);
  }

  private isEditEnabledSub$ = new BehaviorSubject<boolean>(false);
  isEditEnabled$ = this.isEditEnabledSub$.asObservable();

  public get isEditEnabledValue() {
    return this.isEditEnabledSub$.value;
  }

  public set isEditEnabledValue(status) {
    this.isEditEnabledSub$.next(status);
  }

  private isLoadingAddCampaignUserSub$ = new BehaviorSubject<boolean>(null);
  isLoadingAddCampaignUser$ = this.isLoadingAddCampaignUserSub$.asObservable();

  public get isLoadingAddCampaignUserValue() {
    return this.isLoadingAddCampaignUserSub$.value;
  }

  public set isLoadingAddCampaignUserValue(status) {
    this.isLoadingAddCampaignUserSub$.next(status);
  }

  private openCreateCampaignSheetSub$ = new BehaviorSubject<boolean>(false);
  openCreateCampaignSheet$ = this.openCreateCampaignSheetSub$.asObservable();

  public get openCreateCampaignSheetValue(): boolean {
    return this.openCreateCampaignSheetSub$.value;
  }

  public set openCreateCampaignSheetValue(status: boolean) {
    this.openCreateCampaignSheetSub$.next(status);
  }

  private campaignToEditSub$ = new BehaviorSubject<Campaign>(null);
  campaignToEdit$ = this.campaignToEditSub$.asObservable();

  public get campaignToEditValue(): Campaign {
    return this.campaignToEditSub$.value;
  }

  public set campaignToEditValue(campaign: Campaign) {
    this.campaignToEditSub$.next(campaign);
  }

  public openCampaignStatusSheetSub$ = new BehaviorSubject<boolean>(false);
  openCampaignStatusSheet$ = this.openCampaignStatusSheetSub$.asObservable();
  public get openCampaignStatusSheetValue(): boolean {
    return this.openCampaignStatusSheetSub$.value;
  }
  public set openCampaignStatusSheetValue(status: boolean) {
    this.openCampaignStatusSheetSub$.next(status);
  }

  public campaignInfoSub$ = new BehaviorSubject<Array<Contact>>(null);
  public campaignInfo$ = this.campaignInfoSub$.asObservable();
  public get campaignInfoValue(): Array<Contact> {
    return this.campaignInfoSub$.value;
  }
  public set campaignInfoValue(contactList: Array<Contact>) {
    this.campaignInfoSub$.next(contactList);
  }

  public agentMarketingCampaignInfoSub$ = new BehaviorSubject<Array<Contact>>(null);
  public agentMarketingCampaignInfo$ = this.agentMarketingCampaignInfoSub$.asObservable();

  public get agentMarketingCampaignInfoValue(): Array<Contact> {
    return this.agentMarketingCampaignInfoSub$.value;
  }
  public set agentMarketingCampaignInfoValue(contactList: Array<Contact>) {
    this.agentMarketingCampaignInfoSub$.next(contactList);
  }
}

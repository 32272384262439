<div class="confirmDialogWrap">
  <button class="close" mat-icon-button mat-dialog-close (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
  <div>
    <h3 mat-dialog-title>{{ data.action || 'add' | titlecase }} to Watchlist</h3>
    <div mat-dialog-content class="scroller" id="heart_follow">
      <mat-radio-group [formControl]="groupListCtrl" (change)="showNewGroupForm = false; newGroupCtrl.reset()">
        <mat-radio-button
          *ngFor="let group of favoriteState.followGroupList$ | async; let i = index"
          color="primary"
          class="m-2"
          [value]="group.id"
          [checked]="i == 0"
        >
          <span class="ml-2"> {{ group.name }} </span>
        </mat-radio-button>
      </mat-radio-group>
      <a
        color="primary"
        class="m-2 new-group"
        *ngIf="!showNewGroupForm"
        (click)="showNewGroupForm = true; groupListCtrl.reset()"
      >
        + New Group
      </a>
      <mat-form-field class="mx-2" *ngIf="showNewGroupForm">
        <mat-label>New group name</mat-label>
        <input
          matInput
          [formControl]="newGroupCtrl"
          autocomplete="off"
          [autofocus]="true"
          [readonly]="isGroupCreationInProgress || isFavoriteInProgress"
        />
        <mat-spinner *ngIf="isGroupCreationInProgress" matSuffix diameter="16"></mat-spinner>
        <mat-error *ngIf="!newGroupCtrl.pristine && newGroupCtrl?.errors?.minlength">
          Group name should have at least 3 characters
        </mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <mat-error>{{ formErrorText }}</mat-error>
      <div class="fav-modal-footer">
        <div class="text-primary mb-2 selected">
          <mat-icon inline>info</mat-icon>
          <span class="pl-1">{{ data.dataList?.length }} properties selected</span>
        </div>
        <button
          mat-flat-button
          [disabled]="isInvalidForm"
          [pmx-progress-button]="isFavoriteInProgress"
          color="primary"
          (click)="formSubmit()"
        >
          Proceed
        </button>
      </div>
    </div>
  </div>
</div>

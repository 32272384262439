import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
  content: string;
  title: string;
  showPrimaryButton: boolean;
  showSecondaryButton: boolean;
  primaryButtonText: string;
  secondaryButtonText: string;
  proceedCancelAction?: any;
  proceedAction?: any;
}
@Component({
  selector: 'app-large-modal',
  templateUrl: './large-modal.component.html',
  styleUrls: ['./large-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LargeModalComponent implements AfterViewInit {
  @ViewChild('templateRefName') el: ElementRef;
  title: string = '';
  showPrimaryButton: boolean = true;
  showSecondaryButton: boolean = false;
  primaryButtonText: string = 'OK';
  secondaryButtonText: string = 'Cancel';

  constructor(
    public dialogRef: MatDialogRef<LargeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.renderer.setStyle(this.el.nativeElement, 'background', '.wrapper-xs {\n    padding: 20\npx\n 0;\n}');
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.data?.content);
    this.title = this.data?.title;
    this.secondaryButtonText = this.data?.secondaryButtonText;
    this.primaryButtonText = this.data?.primaryButtonText;
    this.showPrimaryButton = this.data?.showPrimaryButton;
    this.showSecondaryButton = this.data?.showSecondaryButton;
    this.cdr.detectChanges();
  }

  primaryAction() {
    if (this.data.proceedAction) {
      this.data.proceedAction(true, (endResult) => {
        if (endResult && endResult.status) {
        }
      });
    }
  }
  secondaryAction() {
    if (this.data.proceedCancelAction) {
      this.data.proceedCancelAction(true, (endResult) => {
        if (endResult && endResult.status) {
        }
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '../../app.state';

@Injectable({
  providedIn: 'root',
})
export class RedirectionGuard implements CanActivate {
  constructor(private router: Router, private appState: AppState) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable((observer) => {
      this.appState.companyInfo$.subscribe((res) => {
        if (res?.appConfig?.valueReport?.isEnabled) {
          if (this.appState.authTokenValue?.idToken) {
            if (
              route &&
              route.url &&
              route.url[0] &&
              route.url[0].path &&
              ['reports', 'users', 'myClients', 'dashboard'].indexOf(route.url[0].path) > -1
            ) {
              observer.next(true);
            } else {
              this.router.navigate(['dashboard']);
              observer.next(false);
            }
          } else {
            this.router.navigate(['signup/user']);
            observer.next(false);
          }
        } else if (res.appConfig?.opportunityScanner?.isEnabled) {
          if (this.appState.authTokenValue?.idToken) {
            if (
              route &&
              route.url &&
              route.url[0] &&
              route.url[0].path &&
              ['opportunityScanner', 'users', 'dashboard', 'workspace'].indexOf(route.url[0].path) > -1
            ) {
              observer.next(true);
            } else if (route &&
              route.routeConfig &&
              route.routeConfig.children &&
              ((route.routeConfig.children[0] &&
                route.routeConfig.children[0].redirectTo == 'map') ||
                (route.routeConfig.children[1] &&
                  route.routeConfig.children[1].path == 'map') ||
                (route.routeConfig.children[2] &&
                  route.routeConfig.children[2].path == 'map'))) {
              observer.next(true);
            } else {
              this.router.navigate(['opportunityScanner']);
              observer.next(false);
            }
          } else {
            this.router.navigate(['login']);
            observer.next(false);
          }
        } else {
          observer.next(true);
        }
      });
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'simpleDate',
})
export class SimpleDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return;
    if (!moment(value).isValid()) return value;

    const date = moment(value);

    if (date.isSame(new Date(), 'day')) return date.format('h:mm a');

    // if (date.isAfter(moment().subtract(1, 'year'), 'day')) return date.format('MMM D');
    if (date.isSame(new Date(), 'year')) return date.format('MMM D');

    return date.format('MMM D, YYYY');
  }
}

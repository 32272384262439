import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { ActivityLoggerService } from 'src/app/_shared/service/activity-logger.service';
import { GeoIpService } from 'src/app/_shared/service/geo-ip.service';
import { UserState } from 'src/app/_shared/state/user.state';
import { AppState } from 'src/app/app.state';
import { PropertyFilterService } from 'src/app/property-filter/service/property-filter.service';
import { PropertyFilterState } from 'src/app/property-filter/state/property-filter.state';
import constant from 'src/constant.json';
import { SearchApi } from '../api/search.api';
import { MapState } from '../state/map.state';
import { PropertyState } from '../state/property.state';
import { SearchState } from '../state/search.state';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  isInitialLastSearch: boolean = true;
  prevSearch: any;
  pathParams: any;

  constructor(
    private searchApi: SearchApi,
    private searchState: SearchState,
    private appState: AppState,
    private geoIpService: GeoIpService,
    private activatedRoute: ActivatedRoute,
    private propertyState: PropertyState,
    private mapState: MapState,
    private userState: UserState,
    private router: Router,
    private location: Location,
    private activityLoggerService: ActivityLoggerService,
    public propertyFilterService: PropertyFilterService,
    private propertyFilterState: PropertyFilterState
  ) {
    this.initSubscription();
  }

  initSubscription() {
    this.activatedRoute.queryParams.pipe(debounceTime(1000)).subscribe((params) => {
      const path = this.location.path();
      let lPath = path ? path.split('/') : [];
      let route = lPath.length > 1 ? lPath[1] : '';
      let propertyId = params.propertyId
        ? params.propertyId
        : lPath.length > 1
        ? lPath[lPath.length - 1].split('?')[0]
        : null;

      if (propertyId && propertyId !== '' && propertyId !== 'map' && route == 'workspace') {
        this.mapState.mapZoomValue = 16;
        let propertyDetailsInput: any = {
          PMXPropertyId: propertyId,
          logInput: true,
        };
        this.propertyState.propertyDetailsSheetInput = propertyDetailsInput;
        this.propertyState.sharedPMXPropertyValue = propertyId;
        if (params.userId) {
          this.userActivity(
            constant.LOGGER.recommendation.category,
            constant.LOGGER.recommendation.action.recommendationEmail.key,
            params.userId,
            propertyId
          );
        }
      } else if (route == 'workspace' || route == 'verifyAccount') {
        if (params.search && params.search !== '') {
          this.getPropertyList(params.search);
          this.saveSearch();
        } else if (this.appState.authTokenValue?.idToken) {
          //&& this.isInitialLastSearch
          this.getLastSearch();
        } else {
          this.fetchUserLocation();
        }
      } else if (route == '') {
        this.fetchUserLocation();
      }
    });
  }

  userActivity(category, action, userId, propertyId) {
    let loggerParams = {
      category: category,
      action: action,
    };
    let inputData = { userId: userId, propertyId: propertyId };
    this.activityLoggerService.userActivity(loggerParams, inputData);

    const queryParams = { ...this.activatedRoute.snapshot.queryParams };
    delete queryParams['userId'];

    this.activatedRoute.snapshot.queryParams = queryParams;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
    });
  }

  getLastSearch() {
    this.searchApi
      .getLastSearch()
      .pipe(take(1))
      .subscribe((res) => {
        this.isInitialLastSearch = false;
        if (res && res.center) {
          this.setQueryParams(res);
        } else {
          this.searchState.searchAddressValue = {
            isFormatted: true,
            center: this.userState.userDetails?.workPlace?.userLocation,
            fullAddress: this.userState.userDetails?.workPlace?.formattedAddress,
          };
        }
        if (res.filters && Object.keys(res.filters) && Object.keys(res.filters).length > 0) {
          if (this.propertyFilterState.appliedFilter && Object.keys(this.propertyFilterState.appliedFilter).length)
            return;
          this.propertyFilterService.setPrimaryFilterToUrl(res.filters);
        }
      });
  }

  setQueryParams(params: any) {
    let fullAddress = params.center ? params.fullAddress : this.userState.userDetails?.workPlace?.formattedAddress;
    let filters = params.filters;
    const queryParams: Record<string, any> = JSON.parse(JSON.stringify(this.activatedRoute.snapshot.queryParams));
    if (queryParams && Object.keys(queryParams).length) filters = queryParams;
    this.propertyFilterService.setPrimaryFilterToUrl(filters, fullAddress);

    // const queryParams = {
    //   search: fullAddress,
    // };
    // this.router.navigate(['workspace/map'], {
    //   queryParams: queryParams,
    //   queryParamsHandling: 'merge',
    //   // replaceUrl: true,
    //   state: { skipUpdate: true },
    // });
  }

  saveSearch() {
    this.searchState.searchAddress$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      )
      .subscribe((res: any) => {
        if (!res.initialSearch && JSON.stringify(this.prevSearch) != JSON.stringify(res)) {
          this.prevSearch = res;
          const params = {
            ...res,
            filters: this.propertyFilterState.appliedFilter,
          };
          this.searchApi.setSearch(params);
        }
      });
  }

  updateSearchAddress(params) {
    if (params && Object.keys(params).length) {
      const searchAddress = this.searchState.searchAddressValue;
      for (const addressKey in params) {
        if (Object.prototype.hasOwnProperty.call(params, addressKey)) {
          searchAddress[addressKey] = params[addressKey];
        }
      }
      this.searchState.searchAddressValue = searchAddress;
    }
  }

  getUserLocationAddress(locationDetails) {
    let locationAddress = locationDetails.city + ', ' + locationDetails.state + ', ' + locationDetails.country;
    return locationAddress;
  }

  getPropertyList(location) {
    new google.maps.Geocoder().geocode({ address: location }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results && results.length) {
        this.searchState.searchAddressValue = results[0];
      } else {
        this.fetchUserLocation();
      }
    });
  }

  fetchUserLocation() {
    this.geoIpService.getGeoIpInfo().subscribe((res) => {
      let location = this.getUserLocationAddress(res);
      this.getPropertyList(location);
    });
  }
}

import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SqftToAcresConverter',
})
export class SqftToAcresConverterPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(landArea: any): any {
    const oneAcre = 43560;
    if (landArea && !Number.isNaN(parseFloat(landArea))) {
      landArea = parseFloat(landArea);
      return landArea > oneAcre
        ? this.decimalPipe.transform(landArea / oneAcre, '1.2-2') + '<small> Acres</small>'
        : this.decimalPipe.transform(landArea) + '<small> Sq.ft</small>';
    } else {
      return landArea;
    }
  }
}

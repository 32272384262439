import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PublicRecordApi {
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;
  getMlsApi = 'getMls';
  private getPropertyDetailsApi = 'property';
  private getSchoolDistrictsForPropertyApi = 'getSchoolDistrictsForProperty';
  private getSchoolsForPropertyApi = 'getSchoolsForProperty';
  private getHomeValueApi = 'getHomeValue';

  constructor(private http: HttpClient) {}

  getPropertyDetails(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getPropertyDetailsApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }

  getHomeValue(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getHomeValueApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }

  getMlsImageDetails(params): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}mls/${params.params}`);
  }

  getSchoolDistrictsForProperty(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getSchoolDistrictsForPropertyApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }

  getSchoolsForProperty(params): Observable<any> {
    return new Observable((observer) => {
      this.http
        .post(`${this.prospektrBackendBaseURL}${this.getSchoolsForPropertyApi}`, params)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err.error);
          }
        );
    });
  }

  getPortfolioBannerImages(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}portfolio/banner-image`);
  }

  getPortfolioProfileImages(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}portfolio/profile-image`);
  }

  getToken(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}getToken`);
  }
}

import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AgentContactState } from 'src/app/workspace/state/agent-contact.state';
import { ProspektrApi } from '../api/prospektr.api';

interface loggerParam {
  category: string;
  action: string;
}

@Injectable({
  providedIn: 'root',
})
export class ActivityLoggerService {
  constructor(private prospektrApi: ProspektrApi, private agentContactState: AgentContactState) {}

  logActivity(loggerParam: loggerParam, data) {
    this.prospektrApi
      .logActivity({ ...loggerParam, data: data })
      .pipe(finalize(() => {}))
      .subscribe(
        (res) => {},
        (err) => {
          this.agentContactState.contactAgentRequestActionStatusValue = false;
        }
      );
  }

  userActivity(loggerParam: loggerParam, data) {
    this.prospektrApi
      .userActivity({ ...loggerParam, data: data })
      .pipe(finalize(() => {}))
      .subscribe(
        (res) => {},
        (err) => {}
      );
  }
}

<div class="row px-3">
  <div class="col-md-12 col-sm-12 col-12 px-0">
    <a class="drop" (click)="fileInput.click()"
      ><div id="drop-zone" class="dropzone" (drop)="onFileDropped($event)" (dragover)="onDragOver($event)">
        <img class="upload" src="../../../../assets/images/upload-image.svg" height="36" />
        <p class="drag-title">
          Drag & drop files to attach, or
          <input
            type="file"
            #fileInput
            style="display: none"
            accept="image/png, image/gif, image/jpeg"
            (change)="onFileSelected($event)"
          />
          <span class="browse">Browse</span>
        </p>
        <div>
          <p class="img-dms">*Preferred Image Dimensions : 1920 X 300px</p>
        </div>
      </div>
      <span *ngIf="isValidSize" class="image-size">Please upload image below 2MB</span>
    </a>
  </div>
</div>
<div class="col-lg-12 col-sm-12 col-12 px-0" *ngIf="bannerImage">
  <div class="uploaded-img" [style.background-image]="'url(' + bannerImage + ')'"></div>
</div>
<div class="col px-0">
  <button
    mat-flat-button
    color="primary"
    [pmx-progress-button]="isLoading"
    [disabled]="disableButton"
    (click)="selectImage(selectedFile)"
  >
    Apply
  </button>
</div>

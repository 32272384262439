import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { TermsPolicyDirective } from 'src/app/_shared/directive/terms-policy.directive';
import { UserState } from 'src/app/_shared/state/user.state';
import { AppState } from 'src/app/app.state';
import { ProfileService } from 'src/app/profile/profile.service';
import { SignupService } from 'src/app/signup/signup.service';
import { AgentContactState } from 'src/app/workspace/state/agent-contact.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import constants from 'src/constant.json';

interface DialogContent {
  proceedAction?: any;
  proceedCancelAction?: any;
  schedulePropertyVisitData?: any;
}
interface CompanyPolicy {
  content: string;
  title: string;
  type: string;
}

@Component({
  selector: 'app-agent-contact-schedule',
  templateUrl: './agent-contact-schedule.component.html',
  styleUrls: ['./agent-contact-schedule.component.scss'],
})
export class AgentContactScheduleComponent implements OnInit, OnDestroy {
  @ViewChild('ctaPolicy') ctaPolicy: ElementRef;
  @ViewChildren(TermsPolicyDirective) termsAndPolicyDir;
  onDestroyNotifier$ = new Subject();
  currentDate = new Date();
  preferredTimeSlots = [
    'Early Morning (8AM - 10 AM)',
    'Mid Morning (10AM - 12 noon)',
    'Early Afternoon (12 noon - 3PM)',
    'Late Afternoon (3 PM - 5 PM)',
    'Evening (5PM - 8PM)',
  ];
  enableSubmit = true;
  type: string;
  checkBox: boolean;
  propertyVisitScheduleForm: FormGroup;
  policyAgreement: Partial<CompanyPolicy> = {};
  companyPolicy: [];
  termsAndConditions: Partial<CompanyPolicy> = {};
  policy: Partial<CompanyPolicy> = {};
  schedulePropertyVisitInprogress: boolean;
  userProfile: any;
  showPassword: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AgentContactScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogContent,
    private profileService: ProfileService,
    private renderer: Renderer2,
    private formBuilder: FormBuilder,
    private agentContactState: AgentContactState,
    private appState: AppState,
    private propertyState: PropertyState,
    private signupService: SignupService,
    private snackBar: MatSnackBar,
    private userState: UserState
  ) { }

  ngAfterViewInit() {
    if (this.policyAgreement['content'] != null) {
      this.renderer.setProperty(this.ctaPolicy.nativeElement, 'innerHTML', this.policyAgreement['content']);
      this.renderer.listen(this.ctaPolicy.nativeElement.querySelector('#terms'), 'click', (event) => {
        this.termsAndPolicyDir.first.triggerPolicyView('terms');
      });
      this.renderer.listen(this.ctaPolicy.nativeElement.querySelector('#policy'), 'click', (event) => {
        this.termsAndPolicyDir.first.triggerPolicyView('policy');
      });
    }
  }

  ngOnInit(): void {
    this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res) this.userProfile = res;
      this.userProfile = Object.assign({}, this.userProfile?.user_profile);
    });
    this.propertyVisitScheduleForm = this.formBuilder.group({
      firstName: [
        { value: this.userProfile?.firstName || '', disabled: false },
        [Validators.required, Validators.pattern('[a-zA-Z ]*')],
      ],
      lastName: [
        { value: this.userProfile?.lastName || '', disabled: false },
        [Validators.required, Validators.pattern('[a-zA-Z ]*')],
      ],
      email: [
        { value: this.userProfile?.email || '', disabled: false },
        [Validators.required, Validators.pattern(constants.regex.validEmail)],
      ],
      phone: [
        { value: this.userProfile?.contact || '', disabled: false },
        [
          Validators.required,
          Validators.pattern('^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'),
        ],
      ],
      preferredDate: ['', [Validators.required]],
      preferredTime: ['', [Validators.required]],
      notes: ['', []],
    });
    if (this.appState.authTokenValue?.idToken) {
      this.propertyVisitScheduleForm.controls['email'].disable();
    }
    this.agentContactState.contactAgentRequestActionStatus$
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        this.schedulePropertyVisitInprogress = res;
      });
    this.appState.companyInfo$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      if (res) {
        this.companyPolicy = res['companyPolicy'];
        this.companyPolicy.forEach((data: any) => {
          if (data['type'] === 'ctaPolicy') {
            this.policyAgreement.content = data.content;
            this.checkBox = true;
          } else {
            this.checkBox = false;
          }
        });
      }
    });
  }

  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  sendPropertyVisitRequest() {
    if (this.propertyVisitScheduleForm.invalid) {
      return;
    }
    this.data.schedulePropertyVisitData = this.propertyVisitScheduleForm.value;
    if (this.appState.authTokenValue?.idToken) {
      (this.userProfile.email = this.propertyVisitScheduleForm.value.email),
        (this.userProfile.firstName = this.propertyVisitScheduleForm.value.firstName),
        (this.userProfile.lastName = this.propertyVisitScheduleForm.value.lastName),
        (this.userProfile.contact = this.propertyVisitScheduleForm.value.phone);
      if (this.propertyVisitScheduleForm.invalid) return;
      this.profileService
        .updateUserBasicInfo(this.userProfile)
        .pipe(takeUntil(this.onDestroyNotifier$))
        .subscribe((res) => {
          if (res.message) {
            this.data.proceedAction(this.data.schedulePropertyVisitData, () => { });
          }
        });
    } else {
      let signUpParams = {
        email: this.propertyVisitScheduleForm.value.email.toLowerCase(),
        firstName: this.propertyVisitScheduleForm.value.firstName,
        lastName: this.propertyVisitScheduleForm.value.lastName,
        phone: this.propertyVisitScheduleForm.value.phone,
        role: 'employee',
        userId: this.agentContactState.propertyClientDataValue['agent'].shortId,
        domain: this.appState.appBaseUrlValue,
        interestedAreas: [
          {
            locality: this.propertyState.activePropertyDetailsValue['basic'].City,
            state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
          },
        ],
        createdBy: 'system',
        source: constants.SIGN_UP.source.scheduleShowingCta,
      };
      console.log('signup', signUpParams)
      this.agentContactState.contactAgentRequestActionStatusValue = true;
      this.signupService
        .createUser(signUpParams)
        .pipe(
          takeUntil(this.onDestroyNotifier$),
          map(() => {
            this.data.proceedAction(this.data.schedulePropertyVisitData, () => { });
          }),
          catchError(() => {
            this.agentContactState.contactAgentRequestActionStatusValue = false;
            this.openSnackBar('Failed to schedule property visit', 'snackbar-error');
            this.close();
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  get propertyVisitScheduleFormControls() {
    return this.propertyVisitScheduleForm.controls;
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }

  // phoneValidator = (control) => {
  //   if (control.value != '') {
  //     const formatedValue = this.toNumberPipe.transform(control.value);
  //     if (formatedValue.toString().length == 10 || formatedValue.toString().length == 11) {
  //       return null;
  //     } else return { phoneNumberInvalid: true };
  //   }
  // };
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { PropertyService } from 'src/app/workspace/service/property.service';

interface CompanyFooter {
  data: any;
  uniqueMlsList: any;
  currentYear: string;
}
@Component({
  selector: 'app-homehunt',
  templateUrl: './homehunt.component.html',
  styleUrls: ['./homehunt.component.scss'],
})
export class HomehuntComponent implements CompanyFooter, OnInit, OnDestroy {
  constructor(public appState: AppState, private propertyService: PropertyService) {}

  @Input() data;
  @Input() currentYear;
  @Input() uniqueMlsList;
  companyInfo: any = {};
  mlsLogo: any = null;
  // Subscriptions to unsubscribe on component destroy
  onDestroyNotifier$ = new Subject();
  eventMessage: any = '';

  ngOnInit() {
    this.appState.companyInfo$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res && Object.keys(res).length) {
        this.companyInfo = res;
      }
    });
    this.propertyService.getEvent().subscribe((event: any) => {
      this.eventMessage = event.loadImage;
      if (this.eventMessage) {
        this.isAnyListingFromMaine();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  isAnyListingFromMaine(): boolean {
    if (this.uniqueMlsList && this.uniqueMlsList.length > 0) {
      // this.propertyService.getMlsImageInfo('MREIS');
      // this.propertyState.mlsImage$.subscribe((res: any) => {
      // this.mlsLogo = res.logoUrl || '';
      // });
      return this.uniqueMlsList.includes('MREIS');
    }
    return false;
  }
}

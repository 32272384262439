import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FormControl, Validators } from '@angular/forms';
import { EMPTY, Observable, merge } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { PartialProperty } from '../../interface/property.interface';
import { FavoriteService } from '../../service/favorite.service';
import { NotificationService } from '../../service/notification.service';
import { FavoriteState } from '../../state/favorite.state';
import { FavoriteFacade } from './favorite-modal.facade';

type ActionType = 'add' | 'move' | 'copy';
type DataType = 'property' | 'favorite' | 'recommendation';
type DataList = Array<PartialProperty | string>;

interface DialogContent {
  action: ActionType;
  dataType: DataType;
  dataList: DataList;
}

@Component({
  selector: 'app-favorite-modal',
  templateUrl: './favorite-modal.component.html',
  styleUrls: ['./favorite-modal.component.scss'],
})
export class FavoriteModalComponent implements OnInit {
  @ViewChild('inputField') inputField: ElementRef;

  showNewGroupForm: boolean = false;
  newGroupCtrl: FormControl = new FormControl(null, Validators.minLength(3));
  groupListCtrl: FormControl = new FormControl(null);
  formErrorText: string;
  isInvalidForm: boolean = false;
  isGroupCreationInProgress: boolean = false;
  isFavoriteInProgress: boolean = false;

  actionTitle: string = 'Add to Watchlist';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogContent,
    public dialogRef: MatDialogRef<FavoriteModalComponent>,
    public favoriteFacade: FavoriteFacade,
    public favoriteState: FavoriteState,
    private favoriteService: FavoriteService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.favoriteState.followGroupList$.subscribe((res) => {
      if (res?.length) this.groupListCtrl.setValue(res[0]);
    });

    // Subscribes to RadioButton and New Group Input Field and sets the form error state
    merge(this.newGroupCtrl.valueChanges, this.groupListCtrl.valueChanges).subscribe(() => {
      this.formErrorText = null;

      if (!this.newGroupCtrl.valid && this.newGroupCtrl.value) return (this.isInvalidForm = true);
      if (!this.newGroupCtrl.value && !this.groupListCtrl.value) {
        this.formErrorText = 'Please select a group or create a new group';
        this.isInvalidForm = true;
        return;
      }
      this.isInvalidForm = false;
    });
  }

  formSubmit() {
    if (
      (!this.newGroupCtrl.valid && this.newGroupCtrl.value) ||
      (!this.newGroupCtrl.value && !this.groupListCtrl.value)
    )
      return;

    this.isFavoriteInProgress = true;

    // Create a new group and then update favorite
    if (this.newGroupCtrl.value) {
      this.favoriteService
        .createGroup(this.newGroupCtrl.value)
        .pipe(
          tap(() => {
            this.showNewGroupForm = false;
          }),
          concatMap((newGroup) => {
            return this.addToWatchList$(
              this.data.action,
              this.data.dataType,
              this.data.dataList as Array<PartialProperty>,
              [newGroup.id]
            );
          }),
          finalize(() => {
            this.isFavoriteInProgress = false;
          })
        )
        .subscribe(() => {
          this.notificationService.triggerSnackBar('success', 'Properties added to Watchlist');
          this.dialogRef.close();
        });
    }

    // Use existing group and update favorite
    if (this.groupListCtrl.value) {
      this.addToWatchList$(this.data.action, this.data.dataType, this.data.dataList as Array<PartialProperty>, [
        this.groupListCtrl.value,
      ])
        .pipe(
          finalize(() => {
            this.isFavoriteInProgress = false;
          })
        )
        .subscribe((res) => {
          const messageType = res.data ? 'success' : 'warning';
          this.notificationService.triggerSnackBar(messageType, res.Message);
          this.dialogRef.close();
        });
    }
  }

  addToWatchList$(
    action: ActionType,
    dataType: DataType,
    dataList: DataList,
    groupIdList: Array<string>
  ): Observable<any> {
    switch (action + '-' + dataType) {
      case 'add-property':
        return this.favoriteService.addPropertiesToFavoriteGroup(dataList as Array<PartialProperty>, groupIdList);

      case 'copy-favorite':
        console.log('COPY');
        return this.favoriteService.copyFavoritesToFavoriteGroup(dataList as Array<string>, groupIdList);

      case 'move-favorite':
        console.log('MOVE');
        return this.favoriteService.moveFavoritesToFavoriteGroup(dataList as Array<string>, groupIdList);

      case 'add-recommendation':
        return this.favoriteService.addRecommendationsToFavoriteGroup(dataList as Array<string>, groupIdList);

      default:
        return EMPTY;
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { IsValueExistPipe } from './is-value-exist.pipe';

@Pipe({
  name: 'noListingInformation',
})
export class NoListingInformationPipe implements PipeTransform {
  constructor(private isValueExistPipe: IsValueExistPipe) {}

  transform(listingData: any): any {
    if (listingData && Object.keys(listingData).length) {
      if (
        this.isValueExistPipe.transform(listingData.Lst_ListPrice) === '--' &&
        this.isValueExistPipe.transform(listingData.Lst_OriginalListPrice) === '--' &&
        this.isValueExistPipe.transform(listingData.Lst_ClosePrice) === '--' &&
        this.isValueExistPipe.transform(listingData.Lst_ListingContractDate) === '--' &&
        this.isValueExistPipe.transform(listingData.Lst_StandardStatus) === '--' &&
        this.isValueExistPipe.transform(listingData.Lst_ListAgentFullName) === '--' &&
        this.isValueExistPipe.transform(listingData.Lst_ListAgentPhone) === '--' &&
        this.isValueExistPipe.transform(listingData.Lst_ListAgentEmail) === '--' &&
        this.isValueExistPipe.transform(listingData.Lst_ListAgentOfficeName) === '--' &&
        this.isValueExistPipe.transform(listingData.Lst_FlipDuration) === '--'
      ) {
        return null;
      } else {
        return listingData;
      }
    } else {
      return null;
    }
  }
}

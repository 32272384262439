<nav
  class="navbar navbar-expand-md navbar-light bg-light homehunt_nav"
  *ngIf="appState.companyInfo$ | async as companyInfo"
>
  <div class="container-fluid justify-content-start">
    <button
      class="navbar-toggler border-0"
      type="button"
      data-toggle="collapse"
      data-target="#navbarTogglerDemo01"
      aria-controls="navbarTogglerDemo01"
      aria-expanded="false"
      aria-label="Toggle navigation"
      (click)="toggleNavbar()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand ml-3 ml-md-0" *ngIf="companyInfo.websiteUrl" [href]="companyInfo.websiteUrl">
      <img [src]="companyInfo?.logo?.dark" width="130px"
    /></a>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01" [ngClass]="{ show: navbarOpen }">
      <!-- <a class="navbar-brand" *ngIf="companyInfo.websiteUrl" [href]="companyInfo.websiteUrl">
        <img [src]="companyInfo?.logo?.dark" width="130px"></a> -->
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 ml-3 ml-md-0" *ngIf="currentView == 'desktop'">
        <li
          class="nav-item dropdown"
          *ngFor="let menuItem of companyInfo?.customizationConfig?.companyNavBar[0]?.config?.menuItems; let i = index"
        >
          <ng-container *ngIf="i == 0">
            <button
              mat-flat-button
              class="nav-link dropdown-toggle"
              [matMenuTriggerFor]="menu1"
              id="navbardrop"
              data-toggle="dropdown"
              #menuTrigger1="matMenuTrigger"
              (mouseenter)="menu1Timer$.next('open')"
              (mouseleave)="menu1Timer$.next('close')"
            >
              {{ menuItem.linkText }}
              <mat-icon>expand_more</mat-icon>
            </button>
            <mat-menu [hasBackdrop]="false" #menu1="matMenu">
              <div (mouseenter)="menu1Timer$.next('open')" (mouseleave)="menu1Timer$.next('close')">
                <button class="homeH" mat-menu-item *ngFor="let subMenu of menuItem.subMenuItems">
                  <a [href]="subMenu.link" [attr.target]="subMenu.openInNewTab ? '_blank' : null">
                    {{ subMenu.linkText }}
                  </a>
                </button>
              </div>
            </mat-menu>
          </ng-container>
          <ng-container *ngIf="i == 1">
            <button
              mat-flat-button
              class="nav-link dropdown-toggle"
              [matMenuTriggerFor]="menu2"
              id="navbardrop"
              data-toggle="dropdown"
              #menuTrigger2="matMenuTrigger"
              (mouseenter)="menu2Timer$.next('open')"
              (mouseleave)="menu2Timer$.next('close')"
            >
              {{ menuItem.linkText }}
              <mat-icon>expand_more</mat-icon>
            </button>
            <mat-menu hasBackdrop="false" #menu2="matMenu">
              <div (mouseenter)="menu2Timer$.next('open')" (mouseleave)="menu2Timer$.next('close')">
                <button class="homeH" mat-menu-item *ngFor="let subMenu of menuItem.subMenuItems">
                  <a [href]="subMenu.link" [attr.target]="subMenu.openInNewTab ? '_blank' : null">
                    {{ subMenu.linkText }}
                  </a>
                </button>
              </div>
            </mat-menu>
          </ng-container>
          <ng-container *ngIf="i == 2">
            <button mat-flat-button class="nav-link" [routerLink]="menuItem.link">
              {{ menuItem.linkText }}
            </button>
          </ng-container>
        </li>
      </ul>
      <!-- navbar for mobile -->
      <ul class="navbar-nav me-auto mb-2 mb-lg-0 ml-3 ml-md-0" *ngIf="currentView == 'mobile'">
        <li
          class="nav-item dropdown"
          *ngFor="let menuItem of companyInfo?.customizationConfig?.companyNavBar[0]?.config?.menuItems; let i = index"
        >
          <ng-container *ngIf="i == 0">
            <button
              mat-flat-button
              class="nav-link dropdown-toggle"
              id="navbardrop"
              data-toggle="dropdown"
              (click)="menuToggleClass1 = !menuToggleClass1; menuToggleClass2 = false"
            >
              {{ menuItem.linkText }}
              <mat-icon>expand_more</mat-icon>
            </button>
            <div class="dropdown-menu" [ngClass]="menuToggleClass1 ? 'd-block' : ''">
              <button class="dropdown-item" *ngFor="let subMenu of menuItem.subMenuItems">
                <a [href]="subMenu.link" [attr.target]="subMenu.openInNewTab ? '_blank' : null">
                  {{ subMenu.linkText }}
                </a>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="i == 1">
            <button
              mat-flat-button
              class="nav-link dropdown-toggle"
              id="navbardrop"
              data-toggle="dropdown"
              (click)="menuToggleClass2 = !menuToggleClass2; menuToggleClass1 = false"
            >
              {{ menuItem.linkText }}
              <mat-icon>expand_more</mat-icon>
            </button>
            <div class="dropdown-menu" [ngClass]="menuToggleClass2 ? 'd-block' : ''">
              <button class="dropdown-item" *ngFor="let subMenu of menuItem.subMenuItems">
                <a [href]="subMenu.link" [attr.target]="subMenu.openInNewTab ? '_blank' : null">
                  {{ subMenu.linkText }}
                </a>
              </button>
            </div>
          </ng-container>
          <ng-container *ngIf="i == 2">
            <button
              mat-flat-button
              class="nav-link dropdown-toggle"
              id="navbardrop"
              data-toggle="dropdown"
              [routerLink]="menuItem.link"
            >
              {{ menuItem.linkText }}
            </button>
          </ng-container>
        </li>
      </ul>
    </div>

    <div class="form-inline my-2 my-lg-0 d-none d-md-none d-lg-block">
      <button type="button" class="btn mr-3" *ngIf="showLogIn" (click)="openLogInDialog()" style="font-weight: 500">
        Log In
      </button>
      <a [href]="companyInfo?.siteUrls?.getStarted?.link">
        <button class="btn my-2 my-sm-0" type="submit" style="color: #fff; font-weight: 500">
          {{ companyInfo?.siteUrls?.getStarted?.displayText }}
        </button>
      </a>
    </div>
  </div>
</nav>

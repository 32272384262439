import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireState {
  private questionnaireListSub$ = new BehaviorSubject<Array<any>>(null);
  questionsList$ = this.questionnaireListSub$.asObservable();

  public get questionsListValue(): Array<any> {
    return this.questionnaireListSub$.value;
  }

  public set questionsListValue(array: Array<any>) {
    this.questionnaireListSub$.next(array);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NgbCarouselModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SWIPER_CONFIG, SwiperModule } from 'ngx-swiper-wrapper';
import { SharedModule } from '../_shared/shared.module';
import { CompanyNavBarComponent } from './company-nav-bar/company-nav-bar.component';
import { CompanyNavBarDirective } from './company-nav-bar/company-nav-bar.directive';
import { DefaultComponent } from './company-nav-bar/component/default/default.component';
import { HomehuntComponent } from './company-nav-bar/component/homehunt/homehunt.component';
import { RubyporchComponent } from './company-nav-bar/component/rubyporch/rubyporch.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NavigationRoutingModule } from './navigation-routing.module';

@NgModule({
  declarations: [
    NavBarComponent,
    MainMenuComponent,
    CompanyNavBarComponent,
    DefaultComponent,
    RubyporchComponent,
    HomehuntComponent,
    CompanyNavBarDirective,
  ],
  imports: [
    CommonModule,
    NavigationRoutingModule,
    MatMenuModule,
    MatSidenavModule,
    MatButtonModule,
    NgbCarouselModule,
    NgbTooltipModule,
    SwiperModule,
    MatIconModule,
    SharedModule,
  ],
  exports: [NavBarComponent, MainMenuComponent, HomehuntComponent],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: null,
    },
  ],
})
export class NavigationModule {}

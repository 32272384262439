import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailSubscribeService } from '../../service/email-subscribe.service';

export interface DialogContent {
  type: string;
  message: string;
  title: string;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  proceedAction?: any;
  proceedCancelAction?: any;
}

@Component({
  selector: 'app-email-subscribe-dialog',
  templateUrl: './email-subscribe-dialog.component.html',
  styleUrls: ['./email-subscribe-dialog.component.scss'],
})
export class EmailSubscribeDialogComponent implements OnInit {
  isLoading = false;
  constructor(
    public dialogRef: MatDialogRef<EmailSubscribeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogContent,
    public emailSubscribeService: EmailSubscribeService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {}
  onNoClick() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {},
    });
    this.dialogRef.close();
  }
  onProceed() {
    this.emailSubscribeService
      .unsubscribe(this.data['params'])
      .pipe()
      .subscribe((res: any) => {
        this.isLoading = res.isLoading;
        if (res.isLoading == false) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {},
          });
          this.dialogRef.close();
        }
      });
  }
}

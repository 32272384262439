import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import constants from 'src/constant.json';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppApi {
  prospektrBackendBaseURL = environment.prospektrBackendBaseURL;

  constructor(private http: HttpClient) {}

  getCompany(): Observable<any> {
    return this.http.get(`${this.prospektrBackendBaseURL}${constants.API.getCompany}`);
  }
}

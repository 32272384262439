import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ListPaginationParams, ListSortParams } from '../../shared/interface/list-interface';

interface Pagination {
  pageSize: number;
  pageNumber: number;
  sortFields: string;
  sortOrder: keyof { ASC: 'ASC'; DESC: 'DESC' };
}

@Injectable({
  providedIn: 'root',
})
export class ListState {
  private paginationSub$ = new BehaviorSubject<{ emit: boolean; data: Pagination }>({
    emit: true,
    data: {
      pageSize: 20,
      pageNumber: 1,
      sortFields: 'Lst_ListPrice',
      sortOrder: 'DESC',
    },
  });
  pagination$: Observable<Pagination> = this.paginationSub$.pipe(
    filter((res) => res.emit),
    map((res) => res.data)
  );
  public getPaginationValue(): Pagination {
    return this.paginationSub$.value.data;
  }
  public setPaginationValue(data: Pagination, emit: boolean = true) {
    this.paginationSub$.next({ emit, data });
  }

  private isPaginatedResultSub$ = new BehaviorSubject<boolean>(true);
  isPaginatedResult$ = this.isPaginatedResultSub$.asObservable();
  public get isPaginatedResultValue(): boolean {
    return this.isPaginatedResultSub$.value;
  }
  public set isPaginatedResultValue(map: boolean) {
    this.isPaginatedResultSub$.next(map);
  }

  $appliedSort = new BehaviorSubject<ListSortParams | {}>({});
  appliedSort = this.$appliedSort.asObservable();
  $listPageIndex = new BehaviorSubject<ListPaginationParams | {}>({});
  listPageIndex = this.$listPageIndex.asObservable();

  public get appliedSortValue(): ListSortParams | {} {
    return this.$appliedSort.value;
  }

  public set appliedSortValue(sortParams: ListSortParams | {}) {
    this.$appliedSort.next(sortParams);
  }

  public set listPageIndexValue(index: ListPaginationParams | {}) {
    this.$listPageIndex.next(index);
  }

  public get listPageIndexValue(): ListPaginationParams | {} {
    return this.$listPageIndex.value;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WorkspaceMode } from 'src/app/_shared/interface/workspace.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceState {
  constructor() {}

  private workspaceViewSub$ = new BehaviorSubject<string>(null);
  workspaceView$ = this.workspaceViewSub$.asObservable();
  private $propertyDetailsSheetLoader = new BehaviorSubject<boolean>(false);
  propertyDetailsSheetLoader$ = this.$propertyDetailsSheetLoader.asObservable();
  private isSearchProcessingSub$ = new BehaviorSubject<boolean>(false);
  isSearchProcessing$ = this.isSearchProcessingSub$.asObservable();
  private openedViewSearchSub$ = new BehaviorSubject<boolean>(false);
  openedViewSearch$ = this.openedViewSearchSub$.asObservable();
  private isSwitchedSub$ = new BehaviorSubject<boolean>(false);
  isSwitched$ = this.isSwitchedSub$.asObservable();
  private multiplePropertyDetailsSheetLoaderSub$ = new BehaviorSubject<boolean>(false);
  multiplePropertyDetailsSheetLoader$ = this.multiplePropertyDetailsSheetLoaderSub$.asObservable();
  private openLocalTrendsSheetSub$ = new BehaviorSubject<boolean>(false);
  openLocalTrendsSheet$ = this.openLocalTrendsSheetSub$.asObservable();
  private workspaceModeSub$ = new BehaviorSubject<Array<WorkspaceMode>>(null);
  workspaceMode$ = this.workspaceModeSub$.asObservable().pipe(debounceTime(300));
  private activeWorkspaceModeSub$ = new BehaviorSubject<WorkspaceMode>(null);
  activeWorkspaceMode$ = this.activeWorkspaceModeSub$.asObservable().pipe(debounceTime(300));

  private initiateSubjectPropertySub$ = new BehaviorSubject<boolean>(false);
  initiateSubjectProperty$ = this.initiateSubjectPropertySub$.asObservable();
  public set initiateSubjectPropertyValue(status: boolean) {
    this.initiateSubjectPropertySub$.next(status);
  }
  public get initiateSubjectPropertyValue(): boolean {
    return this.initiateSubjectPropertySub$.value;
  }

  public set workspaceViewValue(view: string) {
    this.workspaceViewSub$.next(view);
  }

  public get workspaceViewValue(): string {
    return this.workspaceViewSub$.value;
  }

  public set propertyDetailsSheetLoaderValue(status: boolean) {
    this.$propertyDetailsSheetLoader.next(status);
  }

  public get isSearchProcessingValue(): boolean {
    return this.isSearchProcessingSub$.value;
  }

  public set isSearchProcessingValue(status) {
    this.isSearchProcessingSub$.next(status);
  }

  public set openedViewSearchValue(status: boolean) {
    this.openedViewSearchSub$.next(status);
  }

  public set isSwitchedValue(status: boolean) {
    this.isSwitchedSub$.next(status);
  }

  public get isSwitchedValue(): boolean {
    return this.isSwitchedSub$.value;
  }

  public set multiplePropertyDetailsSheetLoaderValue(status: boolean) {
    this.multiplePropertyDetailsSheetLoaderSub$.next(status);
  }

  public get openLocalTrendsSheetValue(): boolean {
    return this.openLocalTrendsSheetSub$.value;
  }

  public set openLocalTrendsSheetValue(status: boolean) {
    this.openLocalTrendsSheetSub$.next(status);
  }

  public get activeWorkspaceModeValue(): WorkspaceMode {
    return this.activeWorkspaceModeSub$.value;
  }

  public set activeWorkspaceModeValue(mode: WorkspaceMode) {
    this.activeWorkspaceModeSub$.next(mode);
  }

  public get workspaceModeValue(): WorkspaceMode[] {
    return this.workspaceModeSub$.value;
  }

  public set workspaceModeValue(mode: WorkspaceMode[]) {
    this.workspaceModeSub$.next(mode);
  }
}

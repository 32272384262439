import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, retry } from 'rxjs/operators';
import constants from 'src/constant.json';
import { environment } from '../../../environments/environment';
import { ProspektrApi } from '../api/prospektr.api';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;
  private s3Upload = 'uploadToS3';
  imgURL: any;
  uploadObj: any = {};
  response: any;

  constructor(private http: HttpClient, private prospektrApi: ProspektrApi) { }

  upload(file, type): Observable<any> {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        this.imgURL = reader.result;
        this.uploadObj.project = environment.s3Upload.project;
        this.uploadObj.env = environment.s3Upload.env;
        this.uploadObj.type = environment.s3Upload.type;
        this.uploadObj.url = this.imgURL;
        const tmpName = file.name.split('.');
        this.uploadObj.ext = tmpName[1];
        this.http.post(`${this.prospektrBackendBaseURL}${this.s3Upload}`, this.uploadObj).subscribe(
          (res) => {
            this.response = res;
            if (this.response) {
              if (this.response && this.response.Location) {
                const url =
                  environment.mediaUrl +
                  environment.s3Upload.project +
                  '&ImageID=' +
                  this.response.key.split('PROSPECTOR/')[1];
                observer.next({ status: 'Success', uploadedUrl: url });
              } else {
                observer.next({ status: 'Failed', uploadedUrl: constants.userAvatar });
              }
            } else {
              observer.next({ status: 'Failed', uploadedUrl: constants.userAvatar });
            }
          },
          (err) => {
            observer.next({ status: 'Failed', uploadedUrl: constants.userAvatar });
          }
        );
      };
    });
  }

  uploadImage(file): Observable<any> {
    return new Observable((observer) => {
      let uploadData: any;
      observer.next({ inProgress: true });
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        uploadData = {
          project: environment.s3Upload.project,
          env: environment.s3Upload.env,
          type: environment.s3Upload.type,
          url: reader.result,
          ext: file.name.split('.'),
        };
        this.prospektrApi
          .uploadToS3(uploadData)
          .pipe(
            finalize(() => {
              observer.next({ inProgress: false });
              observer.complete();
            })
          )
          .subscribe((res) => {
            if (res && res.key) {
              const imageUrl =
                environment.mediaUrl + environment.s3Upload.project + '&ImageID=' + res.key.split('PROSPECTOR/')[1];
              observer.next({ message: 'Upload Successful', status: true, data: imageUrl });
            } else {
              observer.next({ message: 'Upload Failed', status: false });
            }
          });
      };
      reader.onerror = () => {
        observer.next({ inProgress: false, message: 'Upload Failed', status: false });
        return observer.complete();
      };
    });
  }

  uploadMultipleImage(file): Observable<any> {
    const base64Image = file.url;
    const uploadData = {
      project: environment.s3Upload.project,
      env: environment.s3Upload.env,
      type: environment.s3Upload.type,
      url: base64Image, // This is already in base64 format
      ext: file.ext,
      uniqueId: file?.uniqueId,
    };
    return this.http
      .post<any>(`${this.prospektrBackendBaseURL}${this.s3Upload}`, uploadData)
      .pipe(retry(1));
  }

  uploadDocument(file, name): Observable<any> {
    const reqData = new FormData();
    reqData.append('new', file, file.name); //document
    reqData.append('name', name);

    return new Observable((observer) => {
      observer.next({ isLoading: true });

      this.prospektrApi
        .uploadDocumentToS3(reqData)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {
            observer.next(res);
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }
}

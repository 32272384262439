import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MapBounds, MapCenter } from 'src/app/shared/interface/map.interface';

@Injectable({
  providedIn: 'root',
})
export class MapState {
  // To store the current Workspace Google Map instance
  workspaceMap: google.maps.Map = null;
  // private workspaceMapSub$ = new BehaviorSubject<google.maps.Map>(null);
  // workspaceMap$ = this.workspaceMapSub$.asObservable();
  // public get workspaceMapValue(): google.maps.Map {
  //   return this.workspaceMapSub$.value;
  // }
  // public set workspaceMapValue(map: google.maps.Map) {
  //   this.workspaceMapSub$.next(map);
  // }

  private activeInfoWindowSub$ = new BehaviorSubject<google.maps.InfoWindow>(null);
  activeInfoWindow$ = this.activeInfoWindowSub$.asObservable();
  public get activeInfoWindowValue(): google.maps.InfoWindow {
    return this.activeInfoWindowSub$.value;
  }
  public set activeInfoWindowValue(infoWindow: google.maps.InfoWindow) {
    this.activeInfoWindowSub$.next(infoWindow);
  }

  private mapCenterSub$ = new BehaviorSubject<MapCenter>(null);
  mapCenter$ = this.mapCenterSub$.asObservable();
  private mapBoundsSub$ = new BehaviorSubject<MapBounds>(null);
  mapBounds$ = this.mapBoundsSub$.asObservable();
  private mapZoomSub$ = new BehaviorSubject<number>(13);
  mapZoom$ = this.mapZoomSub$.asObservable();
  private isClusteredViewSub$ = new BehaviorSubject<boolean>(false);
  isClusteredView$ = this.isClusteredViewSub$.asObservable();
  private activePropensityListSub$ = new BehaviorSubject<Array<string>>([]);
  activePropensityList$ = this.activePropensityListSub$.asObservable();
  private showMapControlsSub$ = new BehaviorSubject<boolean>(true);
  showMapControls$ = this.showMapControlsSub$.asObservable();
  private mapStatusBarNotificationSub$ = new BehaviorSubject<boolean>(false);
  mapStatusBarNotification$ = this.mapStatusBarNotificationSub$.asObservable();
  private searchLimitExceededSub$ = new BehaviorSubject<boolean>(false);
  searchLimitExceeded$ = this.searchLimitExceededSub$.asObservable();
  private boundingBoxLimitExceededSub$ = new BehaviorSubject<boolean>(false);
  boundingBoxLimitExceeded$ = this.boundingBoxLimitExceededSub$.asObservable();
  private searchSuccessMessageSub$ = new BehaviorSubject<any>(null);
  searchSuccessMessage$ = this.searchSuccessMessageSub$.asObservable();
  private isShapeFilterActiveSub$ = new BehaviorSubject<boolean>(true);
  isShapeFilterActive$ = this.isShapeFilterActiveSub$.asObservable();

  public set isShapeFilterActiveValue(status) {
    this.isShapeFilterActiveSub$.next(status);
  }

  public get isShapeFilterActiveValue() {
    return this.isShapeFilterActiveSub$.value;
  }

  public set mapCenterValue(center) {
    this.mapCenterSub$.next(center);
  }

  public get mapCenterValue() {
    return this.mapCenterSub$.value;
  }

  public get mapBoundsValue(): MapBounds {
    return this.mapBoundsSub$.value;
  }

  public set mapBoundsValue(bounds: MapBounds) {
    this.mapBoundsSub$.next(bounds);
  }

  public get mapZoomValue(): number {
    return this.mapZoomSub$.value;
  }

  public set mapZoomValue(zoom: number) {
    if (this.mapZoomSub$.value != zoom) this.mapZoomSub$.next(zoom);
  }

  set isClusteredViewValue(markerList) {
    this.isClusteredViewSub$.next(markerList);
  }

  get isClusteredViewValue() {
    return this.isClusteredViewSub$.value;
  }

  set activePropensityListValue(propensityList) {
    this.activePropensityListSub$.next(propensityList);
  }

  get activePropensityListValue() {
    return this.activePropensityListSub$.value;
  }

  public set showMapControlsValue(status: boolean) {
    this.showMapControlsSub$.next(status);
  }

  public set mapStatusBarNotificationStatus(status: boolean) {
    this.mapStatusBarNotificationSub$.next(status);
  }

  public set searchLimitExceededValue(status: boolean) {
    this.searchLimitExceededSub$.next(status);
  }

  public get searchLimitExceededValue(): boolean {
    return this.searchLimitExceededSub$.value;
  }

  public set boundingBoxLimitExceededValue(status: boolean) {
    this.boundingBoxLimitExceededSub$.next(status);
  }

  public get boundingBoxLimitExceededValue(): boolean {
    return this.boundingBoxLimitExceededSub$.value;
  }

  public get searchSuccessMessageValue(): object {
    return this.searchSuccessMessageSub$.value;
  }

  public set searchSuccessMessageValue(message: object) {
    this.searchSuccessMessageSub$.next(message);
  }
}

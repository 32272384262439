import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationApi {
  propertyNotificationsApi = 'notifications ';
  notificationStatusChange = 'notifications/status';

  constructor(private http: HttpClient) {}

  getNotificationProperty(params) {
    return new Observable((observer) => {
      this.http.post(environment.prospektrBackendBaseURL + this.propertyNotificationsApi, params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err);
        }
      );
    }).pipe(take(1));
  }

  UpdateNotificationStatusChange(params) {
    return new Observable((observer) => {
      this.http.patch(environment.prospektrBackendBaseURL + this.notificationStatusChange, params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err);
        }
      );
    }).pipe(take(1));
  }
}

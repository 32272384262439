import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../shared/service/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TrialAccountValidatorGuard implements CanLoad {
  constructor(private storage: LocalStorageService, private router: Router) {}
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (route.path === 'accountExpired') {
      if (!this.storage.isTrialAccountExpired) {
        this.router.navigateByUrl('');
      }
      return this.storage.isTrialAccountExpired;
    } else {
      if (this.storage.isTrialAccountExpired) {
        this.router.navigateByUrl('accountExpired');
      }
      return !this.storage.isTrialAccountExpired;
    }
  }
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighchartsChartModule } from 'highcharts-angular';
import { LightgalleryModule } from 'lightgallery/angular';
import { NouisliderModule } from 'ng2-nouislider';
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { ShareModule } from 'ngx-sharebuttons';
import { SimpleDatePipe } from 'src/app/shared/pipe/simple-date.pipe';
import { SelectSearchDropdownComponent } from '../client-dashboard/component/select-search-dropdown/select-search-dropdown.component';
import { CurrencyFormatterPipe } from '../shared/pipe/currency-formatter.pipe';
import { IsValueExistPipe } from '../shared/pipe/is-value-exist.pipe';
import { NoListingInformationPipe } from '../shared/pipe/no-listing-information.pipe';
import { NumberToTextConverterPipe } from '../shared/pipe/number-to-text-converter.pipe';
import { OwnedForStatusPipe } from '../shared/pipe/owned-for-status.pipe';
import { ShortNumberPipe } from '../shared/pipe/short-number.pipe';
import { SqftToAcresConverterPipe } from '../shared/pipe/sqft-to-acres-converter.pipe';
import { YesOrNoConverterPipe } from '../shared/pipe/yes-or-no-converter.pipe';
import { ActivateModalComponent } from './component/activate-account/activate-modal.component';
import { AddContactComponent } from './component/add-contact/add-contact.component';
import { ArticleSlidingSheetComponent } from './component/article-sliding-sheet/article-sliding-sheet.component';
import { BarChartComponent } from './component/bar-chart/bar-chart.component';
import { CampaignModalComponent } from './component/campaign-modal/campaign-modal.component';
import { CompanyFooterComponent } from './component/company-footer/company-footer.component';
import { CompanyFooterDirective } from './component/company-footer/company-footer.directive';
import { DefaultComponent } from './component/company-footer/component/default/default.component';
import { HomehuntComponent } from './component/company-footer/component/homehunt/homehunt.component';
import { HomeriseComponent } from './component/company-footer/component/homerise/homerise.component';
import { RubyporchComponent } from './component/company-footer/component/rubyporch/rubyporch.component';
import { ContactTableComponent } from './component/contact-table/contact-table.component';
import { CopyFieldComponent } from './component/copy-field/copy-field.component';
import { CustomEmailComponent } from './component/custom-email/custom-email.component';
import { DealAnalysisSheetComponent } from './component/deal-analysis-sheet/deal-analysis-sheet.component';
import { EmailSubscribeDialogComponent } from './component/email-subscribe-dialog/email-subscribe-dialog.component';
import { EmailTemplateComponent } from './component/email-template/email-template.component';
import { FavoriteModalComponent } from './component/favorite-modal/favorite-modal.component';
import { FileUploadComponent } from './component/file-upload/file-upload.component';
import { ImageGalleryComponent } from './component/image-gallery- modal/image-gallery.component';
import { LargeModalComponent } from './component/large-modal/large-modal.component';
import { LineChartComponent } from './component/line-chart/line-chart.component';
import { LinkShareComponent } from './component/link-share/link-share.component';
import { ListViewComponent } from './component/list copy/list-view/list-view.component';
import { SignupDialogComponent } from './component/login-signup/signup-dialog.component';
import { MultipleFileUploadComponent } from './component/multiple-file-upload/multiple-file-upload.component';
import { ListingsCardComponent } from './component/mylistings-card/listings-card.component';
import { PieChartComponent } from './component/pie-chart/pie-chart.component';
import { PlanInformationComponent } from './component/plan-information/plan-information.component';
import { PrimaryFiltersComponent } from './component/primary-filters/primary-filters.component';
import { ProgressIndicatorComponent } from './component/progress-indicator/progress-indicator.component';
import { PropertyCardComponent } from './component/property-card/property-card.component';
import { AgentContactOfferComponent } from './component/property-details-sheet/component/agent-contact-offer/agent-contact-offer.component';
import { AgentContactScheduleComponent } from './component/property-details-sheet/component/agent-contact-schedule/agent-contact-schedule.component';
import { AgentContactSuggestionComponent } from './component/property-details-sheet/component/agent-contact-suggestion/agent-contact-suggestion.component';
import { AgentContactComponent } from './component/property-details-sheet/component/agent-contact/agent-contact.component';
import { AreaChartComponent } from './component/property-details-sheet/component/area-chart/area-chart.component';
import { BirdsEyeViewComponent } from './component/property-details-sheet/component/birds-eye-view/birds-eye-view.component';
import { ContactSellingConsultantComponent } from './component/property-details-sheet/component/contact-selling-consultant/contact-selling-consultant/contact-selling-consultant.component';
import { ListingInfoComponent } from './component/property-details-sheet/component/listing-info/listing-info.component';
import { LoanApplicationUrlRequestComponent } from './component/property-details-sheet/component/loan-application-url-request/loan-application-url-request.component';
import { PaymentCalculatorComponent } from './component/property-details-sheet/component/payment-calculator/payment-calculator.component';
import { StreetViewComponent } from './component/property-details-sheet/component/street-view/street-view.component';
import { PropertyDetailsSheetComponent } from './component/property-details-sheet/property-details-sheet.component';
import { PropertyComponent } from './component/property/property.component';
import { QuestionnaireWidgetComponent } from './component/questionnaire-widget/questionnaire-widget.component';
import { SaveSearchComponent } from './component/save-search/save-search.component';
import { SavedSearchCardComponent } from './component/saved-search-card/saved-search-card.component';
import { SavedSearchDialogComponent } from './component/saved-search-dialog/saved-search-dialog.component';
import { StatusBarComponent } from './component/status-bar/status-bar.component';
import { StripeFormComponent } from './component/stripe-form/stripe-form.component';
import { ToggleSwitchComponent } from './component/toggle-switch/toggle-switch.component';
import { UploadBackgroundImageComponent } from './component/upload-background-image/upload-background-image/upload-background-image.component';
import { WarningMsgComponent } from './component/warning-msg/warning-msg.component';
import { ConnectedAgentDetailsComponent } from './connected-agent-details/connected-agent-details.component';
import { AddressDirective } from './directive/address.directive';
import { DecimalInputDirective } from './directive/decimal-input.directive';
import { GoogleAutocompleteDirective } from './directive/google-autocomplete.directive';
import { PmxProgressButtonDirective } from './directive/pmx-progress-button.directive';
import { TermsPolicyDirective } from './directive/terms-policy.directive';
import { TrackChangeDirective } from './directive/track-change.directive';
import { ToNumberPipe } from './pipe/to-number.pipe';
import { ToStringPipe } from './pipe/to-string.pipe';
import { SharedDealDetailsComponent } from './shared-deal-details/shared-deal-details.component';

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  inputMode: CurrencyMaskInputMode.NATURAL,
  max: null,
  min: 0,
  nullable: true,
  precision: 0,
  prefix: '',
  suffix: '',
  thousands: ',',
};
export const options: Partial<IConfig> = {
  thousandSeparator: ',',
};

@NgModule({
  declarations: [
    ActivateModalComponent,
    AddressDirective,
    AgentContactComponent,
    AgentContactOfferComponent,
    AgentContactScheduleComponent,
    AgentContactSuggestionComponent,
    AreaChartComponent,
    ArticleSlidingSheetComponent,
    BarChartComponent,
    BirdsEyeViewComponent,
    CampaignModalComponent,
    CompanyFooterComponent,
    CompanyFooterDirective,
    ContactTableComponent,
    CopyFieldComponent,
    CurrencyFormatterPipe,
    DealAnalysisSheetComponent,
    DefaultComponent,
    FavoriteModalComponent,
    FileUploadComponent,
    GoogleAutocompleteDirective,
    HomehuntComponent,
    HomeriseComponent,
    ImageGalleryComponent,
    IsValueExistPipe,
    ShortNumberPipe,
    LargeModalComponent,
    LineChartComponent,
    LinkShareComponent,
    ListingInfoComponent,
    ListViewComponent,
    LoanApplicationUrlRequestComponent,
    NoListingInformationPipe,
    NumberToTextConverterPipe,
    OwnedForStatusPipe,
    PaymentCalculatorComponent,
    PieChartComponent,
    PmxProgressButtonDirective,
    ProgressIndicatorComponent,
    PropertyCardComponent,
    ListingsCardComponent,
    PropertyDetailsSheetComponent,
    QuestionnaireWidgetComponent,
    RubyporchComponent,
    SavedSearchCardComponent,
    SavedSearchDialogComponent,
    SaveSearchComponent,
    SelectSearchDropdownComponent,
    SharedDealDetailsComponent,
    SignupDialogComponent,
    SimpleDatePipe,
    SqftToAcresConverterPipe,
    StatusBarComponent,
    StreetViewComponent,
    TermsPolicyDirective,
    ToggleSwitchComponent,
    ToNumberPipe,
    ToStringPipe,
    TrackChangeDirective,
    YesOrNoConverterPipe,
    UploadBackgroundImageComponent,
    EmailSubscribeDialogComponent,
    StripeFormComponent,
    AddContactComponent,
    PlanInformationComponent,
    WarningMsgComponent,
    ContactSellingConsultantComponent,
    DecimalInputDirective,
    PrimaryFiltersComponent,
    ConnectedAgentDetailsComponent,
    EmailTemplateComponent,
    MultipleFileUploadComponent,
    PropertyComponent,
    CustomEmailComponent,
  ],
  imports: [
    CommonModule,
    QuillModule.forRoot(),
    FormsModule,
    HighchartsChartModule,
    LightgalleryModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    NgbModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    ShareModule,
    OverlayModule,
    NouisliderModule,
    DragDropModule,

    NgxMaskModule.forRoot(options),
    NgxGalleryModule,
  ],
  exports: [
    CustomEmailComponent,
    ActivateModalComponent,
    AddressDirective,
    AgentContactOfferComponent,
    AgentContactScheduleComponent,
    AgentContactSuggestionComponent,
    AreaChartComponent,
    ArticleSlidingSheetComponent,
    BarChartComponent,
    BirdsEyeViewComponent,
    CompanyFooterComponent,
    ContactTableComponent,
    CopyFieldComponent,
    CurrencyFormatterPipe,
    DealAnalysisSheetComponent,
    FileUploadComponent,
    GoogleAutocompleteDirective,
    ImageGalleryComponent,
    IsValueExistPipe,
    ShortNumberPipe,
    LineChartComponent,
    ListingInfoComponent,
    ListViewComponent,
    LoanApplicationUrlRequestComponent,
    OwnedForStatusPipe,
    PaymentCalculatorComponent,
    PieChartComponent,
    PmxProgressButtonDirective,
    ProgressIndicatorComponent,
    PropertyCardComponent,
    ListingsCardComponent,
    PropertyDetailsSheetComponent,
    QuestionnaireWidgetComponent,
    SavedSearchCardComponent,
    SavedSearchDialogComponent,
    SaveSearchComponent,
    SelectSearchDropdownComponent,
    SignupDialogComponent,
    SimpleDatePipe,
    SqftToAcresConverterPipe,
    StatusBarComponent,
    StreetViewComponent,
    TermsPolicyDirective,
    ToggleSwitchComponent,
    ToNumberPipe,
    ToStringPipe,
    UploadBackgroundImageComponent,
    StripeFormComponent,
    PlanInformationComponent,
    DecimalInputDirective,
    PrimaryFiltersComponent,
    ConnectedAgentDetailsComponent,
    EmailTemplateComponent,
    MultipleFileUploadComponent,
  ],
  entryComponents: [],
  providers: [
    CurrencyFormatterPipe,
    IsValueExistPipe,
    OwnedForStatusPipe,
    SimpleDatePipe,
    SqftToAcresConverterPipe,
    ToNumberPipe,
    ToStringPipe,
    DecimalPipe,
    ShortNumberPipe,
    { provide: MatDialogRef, useValue: {} },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        disableClose: false,
        hasBackdrop: true,
        panelClass: 'custom-modalbox-follow',
        minWidth: '400px',
      },
    },
  ],
})
export class SharedModule {}

import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserState } from 'src/app/_shared/state/user.state';
import { AppState } from 'src/app/app.state';
import { CompanyNavBarDirective } from './company-nav-bar.directive';
import { CompanyNavBarService } from './company-nav-bar.service';

interface CompanyNavBar {
  data: any;
}

@Component({
  selector: 'app-company-nav-bar',
  template: '<ng-template appCompanyNavBar></ng-template>',
})
export class CompanyNavBarComponent implements OnInit, AfterViewInit {
  @ViewChild(CompanyNavBarDirective, { static: false }) appCompanyNavBar!: CompanyNavBarDirective;
  activeView: string;
  viewCompanyNavbar = true;
  aliasName: any;
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;

  // Subscription to unsubscribe on component destroy
  onDestroyNotifier$ = new Subject();

  constructor(
    media: MediaMatcher,
    private companyNavBarService: CompanyNavBarService,
    private appState: AppState,
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    private userState: UserState
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 767.98px)');
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  ngOnInit() {}

  ngAfterViewInit() {
    const currentView = this.mobileQuery.matches ? 'mobile' : 'desktop';
    this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      const userSession = res ? 'identified' : 'guest';
      this.setCompanyNavBar(this.appState.companyInfoValue, userSession, currentView);
    });
    this.appState.companyInfo$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      if (res && Object.keys(res).length) {
        const userSession = this.appState.authTokenValue?.idToken ? 'identified' : 'guest';
        this.setCompanyNavBar(res, userSession, currentView);
      }
    });
  }

  setCompanyNavBar(companyInfo, userSession, currentView) {
    if (
      companyInfo?.alias &&
      companyInfo?.customizationConfig?.companyNavBar[0]?.activeView.includes(currentView) &&
      companyInfo?.customizationConfig?.companyNavBar[0]?.sessionStatus.includes(userSession) &&
      companyInfo?.customizationConfig?.companyNavBar[0]?.config?.active
    ) {
      const companyComponent = this.companyNavBarService.getCompanyComponent(companyInfo?.alias);
      const viewContainerRef = this.appCompanyNavBar.viewContainerRef;
      viewContainerRef.clear();
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(companyComponent.component);
      const componentRef = viewContainerRef.createComponent<CompanyNavBar>(componentFactory);
      componentRef.instance.data = companyComponent.data;
    } else {
      const viewContainerRef = this.appCompanyNavBar.viewContainerRef;
      viewContainerRef.clear();
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}

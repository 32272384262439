<div class="confirmDialogWrap">
  <div *ngIf="data">
    <mat-tab-group (selectedTabChange)="tabChanged($event)" [selectedIndex]="0">
      <mat-tab label="New">
        <div mat-dialog-content class="mb-2 mt-2">
          <form [formGroup]="newCampaignForm">
            <mat-form-field>
              <mat-label>Campaign name</mat-label>
              <input
                formControlName="campaignName"
                matInput
                name="createNewCampaign"
                autocomplete="off"
                name="campaign name"
              />
              <mat-error *ngIf="newCampaignForm.get('campaignName').hasError('required')">
                Campaign name is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="newCampaignForm.get('campaignName').hasError('pattern')">
                Special characters are <strong>not allowed</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="mt-4" [ngClass]="{ 'select-progress': isLoadingPartnerList }" [attr.name]="'foods'">
              <mat-label>{{ partnerType == 'agent' ? 'Agent' : 'Loan Officer' }}</mat-label>
              <mat-select [disabled]="isLoadingPartnerList" formControlName="partnerId">
                <mat-option *ngFor="let partner of partnerList" [value]="partner._id">
                  {{ partner.firstName + ' ' + partner.lastName }}
                </mat-option>
                <mat-option *ngIf="!isLoadingPartnerList && !partnerList.length">No {{ partnerType }}</mat-option>
              </mat-select>
              <mat-spinner [diameter]="20" strokeWidth="3" *ngIf="isLoadingPartnerList"></mat-spinner>
              <mat-error *ngIf="newCampaignForm.get('partnerId').hasError('required')">
                <strong>Select</strong> a partner
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </mat-tab>
      <mat-tab label="Existing">
        <div mat-dialog-content>
          <form [formGroup]="existingCampaignForm">
            <mat-form-field [ngClass]="{ 'select-progress': campaignState.isLoadingCampaignList$ | async }">
              <mat-label>Campaigns</mat-label>
              <mat-select [disabled]="campaignState.isLoadingCampaignList$ | async" formControlName="campaign">
                <mat-option *ngFor="let campaign of campaignList" [value]="campaign.id">
                  {{ campaign.campaignName }}
                </mat-option>
                <mat-option *ngIf="!(campaignState.isLoadingCampaignList$ | async) && !campaignList.length"
                  >No New Campaigns</mat-option
                >
              </mat-select>
              <mat-spinner
                [diameter]="20"
                strokeWidth="3"
                *ngIf="campaignState.isLoadingCampaignList$ | async"
              ></mat-spinner>
              <mat-error *ngIf="existingCampaignForm.get('campaign').hasError('required')">
                <strong>Select</strong> a Campaign
              </mat-error>
            </mat-form-field>
          </form>
        </div>
      </mat-tab>
    </mat-tab-group>
    <div mat-dialog-actions class="mb-0">
      <div class="float-right">
        <button mat-button mat-dialog-close (click)="proceedCancelAction()">Cancel</button>
        <button
          class="ml-2"
          mat-flat-button
          [pmx-progress-button]="inProgressCreateCampaign"
          color="primary"
          cdkFocusInitial
          (click)="proceedSelectedTabAction()"
        >
          <span *ngIf="!inProgressCreateCampaign">Proceed</span>
        </button>
      </div>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { AppState } from 'src/app/app.state';

interface CompanyFooter {
  data: any;
  uniqueMlsList: any;
  currentYear: string;
}
@Component({
  selector: 'app-rubyporch',
  templateUrl: './rubyporch.component.html',
  styleUrls: ['./rubyporch.component.scss'],
})
export class RubyporchComponent implements CompanyFooter, OnInit {
  constructor(public appState: AppState) {}

  @Input() data;
  @Input() uniqueMlsList;
  @Input() currentYear;
  ngOnInit(): void {}
}

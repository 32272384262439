<div class="md-block mx-3 mb-3 filterbar">
  <button
    id="savepop_btn"
    [matMenuTriggerFor]="forSale"
    #forSaleMenuTrigger="matMenuTrigger"
    tabindex="8"
    name="save search"
    aria-label="save search"
    class="search-agent dropdown-toggle"
  >
    <span *ngIf="appliedFilter?.soldWithin"> Sold</span>
    <span *ngIf="!appliedFilter?.soldWithin"> For Sale </span>
  </button>
  <button
    id="savepop_btn"
    [matMenuTriggerFor]="maxMinPrice"
    #maxMinPriceMenuTrigger="matMenuTrigger"
    tabindex="8"
    name="save search"
    aria-label="save search"
    class="search-agent dropdown-toggle"
  >
    <span *ngIf="appliedFilter?.lPrice">
      {{ appliedFilter?.lPrice | shortNumber }}
      <ng-container *ngIf="appliedFilter?.lPrice"> - </ng-container>
    </span>
    <span *ngIf="appliedFilter?.hPrice"> ${{ appliedFilter?.hPrice | shortNumber }} </span>
    <span *ngIf="!appliedFilter?.lPrice && !appliedFilter?.hPrice">Price</span>
  </button>
  <button
    id="savepop_btn"
    [matMenuTriggerFor]="maxMinBedBath"
    #maxMinBedBathMenuTrigger="matMenuTrigger"
    tabindex="8"
    name="save search"
    aria-label="save search"
    class="search-agent dropdown-toggle"
  >
    <span *ngIf="appliedFilter?.lBed"> {{ appliedFilter?.lBed }}</span>
    <ng-container *ngIf="appliedFilter?.lBed && appliedFilter?.hBed"> - </ng-container>
    <span *ngIf="appliedFilter?.hBed"> {{ appliedFilter?.hBed }}</span>
    <ng-container *ngIf="appliedFilter?.lBed || appliedFilter?.hBed"> bd </ng-container>
    <ng-container *ngIf="appliedFilter?.lBath || appliedFilter?.hBath"> / </ng-container>
    <span *ngIf="appliedFilter?.lBath"> {{ appliedFilter?.lBath }}</span>
    <ng-container *ngIf="appliedFilter?.lBath && appliedFilter?.hBath"> - </ng-container>
    <span *ngIf="appliedFilter?.hBath"> {{ appliedFilter?.hBath }}</span>
    <ng-container *ngIf="appliedFilter?.lBath || appliedFilter?.hBath"> ba </ng-container>

    <span *ngIf="!appliedFilter?.lBed && !appliedFilter?.hBed">Beds/Baths</span>
  </button>
  <button
    id="savepop_btn"
    [matMenuTriggerFor]="homeType"
    tabindex="8"
    name="save search"
    aria-label="save search"
    class="search-agent dropdown-toggle"
    #homeTypeMenuTrigger="matMenuTrigger"
  >
    <span *ngIf="appliedFilter?.homeType">
      {{
        appliedFilter?.homeType?.length > 2 ? (appliedFilter?.homeType | slice: 0:2) + '...' : appliedFilter?.homeType
      }}
    </span>
    <span *ngIf="!appliedFilter?.homeType"> Home Type </span>
  </button>
  <button class="all-filter dropdown-toggle" (click)="propertyFilterService.toggleFilterSheet()">
    All Filters <span class="filter-bubble">{{ selectedFiltersCount }}</span>
  </button>
  <app-save-search class="d-inline-block"></app-save-search>
</div>

<mat-menu class="for-sale" #forSale="matMenu">
  <div [formGroup]="filterForm" class="side_submenu" (click)="$event.stopPropagation()">
    <div (keydown.tab)="$event.stopPropagation()">
      <div class="row mb-3">
        <div class="col-md-10 col-sm-10 col-10 f-label">
          <h5>&nbsp;</h5>
        </div>
        <div class="col-md-2 col-sm-2 col-2 field">
          <button class="submenu_close" (click)="forSaleMenuTrigger.closeMenu()"></button>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <mat-button-toggle-group class="tab-grp" [formControl]="statusToggleBtnCtrl">
          <mat-button-toggle value="forSale">For Sale</mat-button-toggle>
          <mat-button-toggle value="sold">Sold</mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      <div class="savedpop_base">
        <div class="clearfix"></div>
        <div class="savesearch_base">
          <div class="filter-group-container" *ngIf="statusToggleBtnCtrl.value == 'forSale'">
            <div class="row">
              <div class="col-md-4 col-12 mt-4">
                <div class="d-flex">
                  <mat-icon
                    role="img"
                    matprefix=""
                    inline=""
                    class="legend-icon listing mr-2 pt-1"
                    aria-hidden="true"
                    data-mat-icon-type="font"
                    >circle</mat-icon
                  >
                  <mat-checkbox formControlName="isForSale" color="primary"> For Sale on MLS (Standard) </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4 col-12 mb-2">
                <div class="row">
                  <div class="col-md-12 col-12">
                    <div class="d-flex">
                      <mat-icon
                        role="img"
                        matprefix=""
                        inline=""
                        class="legend-icon distressed mr-2 pt-1"
                        aria-hidden="true"
                        data-mat-icon-type="font"
                        >circle</mat-icon
                      >
                      <mat-checkbox
                        color="primary"
                        [checked]="isChecked()"
                        [indeterminate]="isAllSelected()"
                        (change)="selectAll($event)"
                        class="pb-2"
                      >
                        Foreclosure
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-12">
                    <mat-checkbox
                      color="primary"
                      formControlName="isPreFcl"
                      class="pl-5 pb-2"
                      (change)="updateDistressStatus($event)"
                    >
                      Pre-Foreclosure
                    </mat-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-12">
                    <mat-checkbox
                      color="primary"
                      formControlName="isAuction"
                      class="pl-5 pb-2"
                      (change)="updateDistressStatus($event)"
                    >
                      Auction
                    </mat-checkbox>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-12">
                    <mat-checkbox
                      color="primary"
                      formControlName="isReo"
                      class="pl-5 pb-2"
                      (change)="updateDistressStatus($event)"
                    >
                      REO / Bank Owned
                    </mat-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-12 mb-2">
                <div class="row">
                  <div class="col-md-12 col-12">
                    <div class="d-flex">
                      <mat-icon
                        role="img"
                        matprefix=""
                        inline=""
                        class="legend-icon hud mr-2 pt-1"
                        aria-hidden="true"
                        data-mat-icon-type="font"
                        >circle</mat-icon
                      >
                      <mat-checkbox color="primary" formControlName="isHud" (change)="updateDistressStatus($event)">
                        Hud
                      </mat-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-12 mb-2">
                <div class="row">
                  <div class="col-md-12 col-12">
                    <mat-slide-toggle color="primary" formControlName="includePending"
                      >Include Pending</mat-slide-toggle
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="filter-group-container" *ngIf="statusToggleBtnCtrl.value == 'sold'">
            <div class="row my-3">
              <div class="col-md-1 col-1">
                <mat-icon
                  role="img"
                  matprefix=""
                  inline=""
                  class="legend-icon sold mr-2 pt-3"
                  aria-hidden="true"
                  data-mat-icon-type="font"
                  >circle</mat-icon
                >
              </div>
              <div class="col-md-7 col-8">
                <mat-form-field class="w-100">
                  <mat-select formControlName="soldWithin">
                    <mat-option *ngFor="let option of PropertyFilterConfig.soldWithinOptions" [value]="option.value">
                      {{ option.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="filter-sheet-footer">
        <button mat-flat-button class="float-right my-3" color="primary" (click)="_applyFilter()">Apply Filter</button>
        <button mat-stroked-button class="float-right my-3 mr-2" color="primary" (click)="_resetFilter('listing')">
          Reset
        </button>
      </footer>
    </div>
  </div>
</mat-menu>

<!-- *Filter for Price*  -->
<mat-menu class="price-filter" #maxMinPrice="matMenu">
  <div [formGroup]="filterForm" class="side_submenu" (click)="$event.stopPropagation()">
    <div (keydown.tab)="$event.stopPropagation()">
      <div class="filter-group-container">
        <div class="row mb-4">
          <div class="col-md-10 col-sm-10 col-10 f-label">
            <h5>Price</h5>
          </div>
          <div class="col-md-2 col-sm-2 col-2 field">
            <button class="submenu_close" (click)="maxMinPriceMenuTrigger.closeMenu()"></button>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-6">
            <mat-form-field class="w-100">
              <mat-label>Min Price</mat-label>
              <input
                matInput
                type="text"
                formControlName="lPrice"
                mask="separator.2"
                class="small-form-field"
                prefix="$"
                list="options1"
              />
              <datalist id="options1">
                <option *ngFor="let minPrice of PropertyFilterConfig.minPriceList" [value]="minPrice.value">
                  {{ minPrice.label }}
                </option>
              </datalist>
            </mat-form-field>
          </div>
          <div class="col-md-6 col-6">
            <mat-form-field class="w-100">
              <mat-label> Max Price</mat-label>
              <input
                matInput
                type="text"
                id="inputField"
                formControlName="hPrice"
                mask="separator.2"
                class="small-form-field"
                prefix="$"
                list="options2"
              />
              <datalist id="options2">
                <option *ngFor="let maxPrice of PropertyFilterConfig.maxPriceList" [value]="maxPrice.value">
                  {{ maxPrice.label }}
                </option>
              </datalist>
            </mat-form-field>
          </div>
        </div>
      </div>
      <mat-error
        *ngIf="filterForm.get('lPrice').hasError('minMaxError') || filterForm.get('hPrice').hasError('minMaxError')"
        class="text-danger"
      >
        Minimum listing price should not be greater than maximum listing price
      </mat-error>
    </div>
    <footer class="filter-sheet-footer">
      <button mat-flat-button class="my-3 float-right" color="primary" (click)="_applyFilter()">Apply Filter</button>
      <button mat-stroked-button class="float-right my-3 mr-2" color="primary" (click)="_resetFilter('price')">
        Reset
      </button>
    </footer>
  </div>
</mat-menu>

<mat-menu class="bed-bath" #maxMinBedBath="matMenu" (click)="$event.stopPropagation()">
  <div [formGroup]="filterForm" class="side_submenu" (click)="$event.stopPropagation()">
    <div class="filter-group-container">
      <div class="row mb-3">
        <div class="col-md-10 col-sm-10 col-10 f-label">
          <h5>Beds/Baths</h5>
        </div>
        <div class="col-md-2 col-sm-2 col-2 field">
          <button class="submenu_close" (click)="maxMinBedBathMenuTrigger.closeMenu()"></button>
        </div>
      </div>
      <h5 class="mb-3">Bedrooms</h5>
      <section class="mt-2">
        <div class="row">
          <div class="col-md-10 col-10 lt-40">
            <mat-button-toggle-group class="small-form-field" [formControl]="bedToggleBtnCtrl" color="secondary">
              <mat-button-toggle [value]="null"> Any </mat-button-toggle>
              <mat-button-toggle *ngFor="let value of PropertyFilterConfig.bedBathToggleValues" [value]="value.value">
                {{ value.label }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-2 col-2">
            <span class="or-seperator">OR</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4 col-6">
            <mat-form-field class="small-form-field w-100">
              <mat-label>Min</mat-label>
              <mat-select formControlName="lBed">
                <mat-option *ngFor="let value of PropertyFilterConfig.bedBathDropdownValues" [value]="value">
                  {{ value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4 col-6">
            <mat-form-field class="small-form-field w-100">
              <mat-label>Max</mat-label>
              <mat-select formControlName="hBed">
                <mat-option *ngFor="let value of PropertyFilterConfig.bedBathDropdownValues" [value]="value">
                  {{ value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <mat-error
          *ngIf="filterForm.get('lBed').hasError('minMaxError') || filterForm.get('hBed').hasError('minMaxError')"
          class="text-danger"
        >
          Minimum Bed should not be greater than maximum Bed
        </mat-error>
      </section>

      <h5 class="mt-3 mb-3">Bathrooms</h5>
      <section class="mt-2">
        <div class="row">
          <div class="col-md-10 col-10 lt-40">
            <mat-button-toggle-group class="small-form-field" [formControl]="bathToggleBtnCtrl" color="secondary">
              <mat-button-toggle [value]="null"> Any </mat-button-toggle>
              <mat-button-toggle *ngFor="let value of PropertyFilterConfig.bedBathToggleValues" [value]="value.value">
                {{ value.label }}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
          <div class="col-md-2 col-2">
            <span class="or-seperator">OR</span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4 col-6">
            <mat-form-field class="small-form-field w-100">
              <mat-label>Min</mat-label>
              <mat-select formControlName="lBath">
                <mat-option *ngFor="let value of PropertyFilterConfig.bedBathDropdownValues" [value]="value">
                  {{ value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4 col-6">
            <mat-form-field class="small-form-field w-100">
              <mat-label>Max</mat-label>
              <mat-select formControlName="hBath">
                <mat-option *ngFor="let value of PropertyFilterConfig.bedBathDropdownValues" [value]="value">
                  {{ value }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-error
          *ngIf="filterForm.get('lBath').hasError('minMaxError') || filterForm.get('hBath').hasError('minMaxError')"
          class="text-danger"
        >
          Minimum Bed should not be greater than maximum Bed
        </mat-error>
      </section>
    </div>

    <footer class="filter-sheet-footer">
      <button mat-flat-button class="float-right my-3" color="primary" (click)="_applyFilter()">Apply Filter</button>
      <button mat-stroked-button class="float-right my-3 mr-2" color="primary" (click)="_resetFilter('bedBath')">
        Reset
      </button>
    </footer>
  </div>
</mat-menu>

<mat-menu class="home-type" #homeType="matMenu" (click)="$event.stopPropagation()">
  <div class="side_submenu" [formGroup]="filterForm">
    <div class="filter-group-container" (click)="$event.stopPropagation()">
      <div class="row mb-3">
        <div class="col-md-10 col-sm-10 col-10 f-label">
          <h5>Home Type</h5>
        </div>
        <div class="col-md-2 col-sm-2 col-2 field">
          <button class="submenu_close" (click)="homeTypeMenuTrigger.closeMenu()"></button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="home_type_card_wrap col-md-12">
          <div *ngFor="let value of PropertyFilterConfig.HomeTypeArray; let i = index">
            <div class="cards" (click)="toggleSelection(value)" [ngClass]="{ selected: isSelected(value) }">
              <img [src]="'../../assets/images/home-type/' + value['icon']" />
              <p class="card_title">{{ value['label'] }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button mat-flat-button class="float-right my-3" color="primary" (click)="_applyFilter()">
            Apply Filter
          </button>
          <button mat-stroked-button class="float-right my-3 mr-2" color="primary" (click)="_resetFilter('homeType')">
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-menu>

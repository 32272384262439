import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { AppState } from 'src/app/app.state';
@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private stripePromise;
  constructor(public appState: AppState) {
    this.stripePromise = loadStripe(this.appState.companyInfoValue.stripePublishableAPIKey);
  }
  async getStripe() {
    return this.stripePromise;
  }
}

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { VerifyLoginService } from 'src/app/verify-login/verify-login.service';
import { UserState } from '../state/user.state';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationResolver implements Resolve<boolean> {
  constructor(
    private appState: AppState,
    private userState: UserState,
    private verifyLoginService: VerifyLoginService
  ) { }

  resolve(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!this.userState.userDetails && this.appState.authTokenValue?.idToken && !this.appState.getAppLoadingState('verifyLogin')) {
        this.verifyLoginService.verifyLogin().subscribe(
          () => resolve(true),
          () => resolve(false)
        );
      } else resolve(false);
    });
  }
}

import { AfterViewInit, Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { PropertyState } from 'src/app/workspace/state/property.state';

@Directive({
  selector: '[propertyAddress]',
})
export class AddressDirective implements AfterViewInit {
  constructor(private _el: ElementRef, private propertyState: PropertyState, private _renderer: Renderer2) { }

  @Input() propertyAddress: string;

  @HostListener('click') onClick() {

    let addressComponent = this.getAddressComponents();
    if (!addressComponent) return;
    this.propertyState.propertyDetailsSheetInput = addressComponent;
  }

  ngAfterViewInit() {
    let addressComponent = this.getAddressComponents();
    if (!addressComponent) return;
    this._renderer.addClass(this._el.nativeElement, 'property-address-link');
  }

  getAddressComponents() {
    let addressComponentList = this.propertyAddress?.split(',');
    if (!addressComponentList || addressComponentList?.length < 3) return null;
    let stateAndZip = addressComponentList[2];
    let stateAndZipList = stateAndZip?.split(' ');
    let addressComponent: any = {
      StreetAddress: addressComponentList[0],
      City: addressComponentList[1],
      State: stateAndZipList[1],
      PostalCode: stateAndZipList[2],
    };
    return addressComponent;
  }
}

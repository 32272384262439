import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ArticleService } from '../../service/article.service';

@Component({
  selector: 'app-article-sliding-sheet',
  templateUrl: './article-sliding-sheet.component.html',
  styleUrls: ['./article-sliding-sheet.component.scss'],
})
export class ArticleSlidingSheetComponent {
  @Input() protected article: string;

  @ViewChild('articleContainer') protected articleContainer: ElementRef;
  @ViewChild('sidenav') protected sidenav: MatSidenav;

  isArticleLoadingInProgress: boolean = true;
  articleTitle: string = null;
  articleContent: string = null;

  constructor(private _renderer: Renderer2, public articleService: ArticleService) {}

  public open(): void {
    this.articleService.getArticle(this.article).subscribe(({ isLoading, data }) => {
      this.isArticleLoadingInProgress = isLoading;
      if (!data) return;
      this.articleTitle = data?.title || '';
      this.articleContent = data?.content;
      this._renderer.setProperty(this.articleContainer.nativeElement, 'innerHTML', data?.content);
    });

    this.sidenav.open();
  }

  public close(): void {
    this.sidenav.close();
  }
}

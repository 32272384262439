import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogInGuard } from '../_shared/guard/log-in.guard';
import { PlanExpiryGuard } from '../_shared/guard/plan-expiry.guard';
import { ProfileSetupGuard } from '../_shared/guard/profile-setup.guard';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';

const routes: Routes = [
  {
    path: 'payment-failed',
    component: PaymentFailedComponent,
    canActivate: [LogInGuard, ProfileSetupGuard, PlanExpiryGuard],
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
    canActivate: [LogInGuard, ProfileSetupGuard],
  },
  {
    path: 'account-upgrade',
    loadChildren: () =>
      import('../account-upgrading-reminder/account-upgrading-reminder.module').then(
        (m) => m.AccountUpgradingReminderModule
      ),
    canActivate: [LogInGuard, ProfileSetupGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SubscriptionPlanRoutingModule {}

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CashOutResult, LoanComputedResult } from 'src/app/_shared/interface/loan-computation.interface';

@Injectable({
  providedIn: 'root',
})
export class LoanComputationService {
  constructor() {}

  /**
   * Computes all the values for a new or existing loan
   *
   * @param {number} principal Initial loan principal
   * @param {number} interestRate Annual interest rate
   * @param {number} term Total term for the loan
   * @param {Date} [startDate] Start date of the loan as a new Date() object
   * @returns LoanComputedResult
   */
  computeTotalLoan(principal: number, interestRate: number, term: number, startDate: Date = null): LoanComputedResult {
    if (
      !isFinite(principal) ||
      principal < 0 ||
      !isFinite(interestRate) ||
      interestRate < 0 ||
      interestRate > 1 ||
      (!isFinite(term) && term < 0) ||
      !moment.isDate(startDate) ||
      moment().isBefore(startDate)
    )
      return null;
    const emi = this.computeAmortizedLoanPayment(principal, interestRate, term);

    const currentTerm = moment().diff(startDate, 'months', false);
    let paidPrincipal = 0;
    let duePrincipal = 0;
    let totalInterest = 0;
    let paidInterest = 0;
    let dueInterest = 0;
    let totalPayment = 0;
    let paidPayment = 0;
    let duePayment = 0;
    let paidTerm = 0;
    let dueTerm = 0;

    const monthStartPrincipalList = [];
    const monthlyPaymentList = [];
    const interestPaidList = [];
    const principalPaidList = [];
    const interestDueList = [];
    const principalDueList = [];

    // const loanResultTable = [
    //   ['Loan Balance', 'Monthly Payment', 'Principal Paid', 'Interest Paid', 'Principal Due', 'Interest Due'],
    // ];
    let currentMonth = 1;
    for (let balancePrincipal = principal; Math.round(balancePrincipal) > 0; ) {
      const startPrincipal = balancePrincipal;
      monthStartPrincipalList.push(startPrincipal);
      const interestPaid = startPrincipal * (interestRate / 12);
      const monthlyPayment = startPrincipal + interestPaid > emi ? emi : startPrincipal + interestPaid;
      const principalPaid = monthlyPayment + interestPaid > emi ? emi - interestPaid : startPrincipal;
      monthlyPaymentList.push(monthlyPayment);
      totalInterest += interestPaid;
      totalPayment += monthlyPayment;
      if (currentMonth < currentTerm && startDate) {
        paidInterest += interestPaid;
        paidPrincipal += principalPaid;
        paidPayment += monthlyPayment;
        paidTerm++;
        interestPaidList.push(interestPaid);
        interestDueList.push(0);
        principalPaidList.push(principalPaid);
        principalDueList.push(0);
        // loanResultTable.push([
        //   Math.round(startPrincipal).toString(),
        //   Math.round(monthlyPayment).toString(),
        //   Math.round(principalPaid).toString(),
        //   Math.round(interestPaid).toString(),
        //   '0',
        //   '0',
        // ]);
      } else {
        dueInterest += interestPaid;
        duePrincipal += principalPaid;
        duePayment += monthlyPayment;
        dueTerm++;
        interestPaidList.push(0);
        interestDueList.push(interestPaid);
        principalPaidList.push(0);
        principalDueList.push(principalPaid);
        // loanResultTable.push([
        //   Math.round(startPrincipal).toString(),
        //   Math.round(monthlyPayment).toString(),
        //   '0',
        //   '0',
        //   Math.round(principalPaid).toString(),
        //   Math.round(interestPaid).toString(),
        // ]);
      }
      balancePrincipal = startPrincipal - principalPaid;
      currentMonth++;
    }

    // console.table(loanResultTable);
    return {
      paymentMonthly: Math.round(emi),
      principalPaid: Math.round(paidPrincipal),
      principalBalance: Math.round(duePrincipal),
      interestPaid: Math.round(paidInterest),
      interestBalance: Math.round(dueInterest),
      totalPaid: Math.round(paidPrincipal + paidInterest),
      totalBalance: Math.round(duePrincipal + dueInterest),
      termBalance: Math.round(dueTerm),
    };
  }

  /**
   * Computes the values for Refinanced loans and/or Extra payment loans
   *
   * @param {number} principalBalance Balance loan principal
   * @param {number} interestRate Annual interest rate
   * @param {number} termBalance Remaining or refinanced loan term
   * @param {number} [monthlyPayment] Monthly payment in case of existing loan
   * @param {number} [extraPayment] Extra monthly payment
   * @returns LoanComputedResult
   */
  computeBalanceLoan(
    principalBalance: number,
    interestRate: number,
    termBalance: number,
    monthlyPayment?: number,
    extraPayment: number = 0
  ): LoanComputedResult {
    if (
      !isFinite(principalBalance) ||
      principalBalance < 0 ||
      !isFinite(interestRate) ||
      interestRate < 0 ||
      interestRate > 1 ||
      (!isFinite(termBalance) && termBalance < 0)
    )
      return null;
    const emi =
      monthlyPayment || this.computeAmortizedLoanPayment(principalBalance, interestRate, termBalance) + extraPayment;
    let totalInterest = 0;
    let totalPayment = 0;
    let totalTerm = 0;

    const monthStartPrincipalList = [];
    const monthlyPaymentList = [];
    const interestDueList = [];
    const principalDueList = [];

    // const loanResultTable = [['Loan Balance', 'Monthly Payment', 'Principal Due', 'Interest Due']];

    for (let balancePrincipal = principalBalance; Math.round(balancePrincipal) > 0; ) {
      const startPrincipal = balancePrincipal;
      monthStartPrincipalList.push(startPrincipal);
      const interestPaid = startPrincipal * (interestRate / 12);
      const monthlyPayment = startPrincipal + interestPaid > emi ? emi : startPrincipal + interestPaid;
      const principalPaid = monthlyPayment + interestPaid > emi ? emi - interestPaid : startPrincipal;
      monthlyPaymentList.push(monthlyPayment);
      totalInterest += interestPaid;
      totalPayment += monthlyPayment;
      totalTerm++;
      interestDueList.push(interestPaid);
      principalDueList.push(principalPaid);
      // loanResultTable.push([
      //   Math.round(startPrincipal).toString(),
      //   Math.round(monthlyPayment).toString(),
      //   Math.round(principalPaid).toString(),
      //   Math.round(interestPaid).toString(),
      // ]);
      balancePrincipal = startPrincipal - principalPaid;
    }
    // console.table(loanResultTable);
    return {
      paymentMonthly: Math.round(emi),
      interestBalance: Math.round(totalInterest),
      totalBalance: Math.round(principalBalance + totalInterest),
      termBalance: Math.round(totalTerm),
    };
  }

  /**
   * @param principal Loan principal or balance principal
   * @param interestRate Annual interest rate
   * @param term Loan term or the balance term
   * @returns Monthly payment for amortized loans
   */
  computeAmortizedLoanPayment(principal: number, interestRate: number, term: number) {
    const p = principal;
    const i = interestRate;
    const t = term;

    return (p * (i / 12) * Math.pow(1 + i / 12, t)) / (Math.pow(1 + i / 12, t) - 1);
  }

  /**
   * Computes the cash that can be used to buy another property
   *
   * @param {number} propertyValue Current value of the property
   * @param {number} loanBalance Current loan principal balance
   * @param {number} ltvRatio Loan-to-Value ratio of the property
   * @param {number} equity Optional current equity of the property if it is custom set by user
   * @returns CashOutResult
   */
  computeTradeLimit(
    propertyValue: number,
    loanBalance: number,
    ltvRatio: number,
    equity: number = null
  ): CashOutResult {
    const currentEquity = equity || propertyValue - loanBalance;
    const cashOut = currentEquity - propertyValue * (1 - ltvRatio);
    const tradeUpLimit = currentEquity / (1 - ltvRatio);
    const addOnLimit = cashOut / (1 - ltvRatio);
    return {
      currentEquity,
      cashOut,
      tradeUpLimit,
      addOnLimit,
    };
  }
}

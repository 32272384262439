import { Injectable } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Injectable({
  providedIn: 'root',
})
export class SwiperConfig {
  navBrandSwiper: SwiperConfigInterface = {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 10,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true,
    },
  };
}

<h2 mat-dialog-title>{{ this.title }}</h2>
<div>
  <p class="address">{{ this.address }}</p>
</div>
<mat-dialog-content class="mat-typography">
  <div class="wrapper-xs">
    <div #templateName></div>
    <button class="close" mat-icon-button mat-dialog-close (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container *ngFor="let filter of filters">
    <div class="filter-items">
      <span
        ><b>
          {{ filter.key }}
        </b>
        <span matTooltip="{{ filter.value }}" matTooltipPosition="above">
          {{ filter.value }}
        </span>
      </span>
    </div>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    mat-dialog-close
    cdkFocusInitial
    color="primary"
    *ngIf="showPrimaryButton"
    (click)="primaryAction()"
  >
    {{ primaryButtonText }}
  </button>
</mat-dialog-actions>

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-birds-eye-view',
  templateUrl: './birds-eye-view.component.html',
  styleUrls: ['./birds-eye-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BirdsEyeViewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() defaultPlace;
  @Output() birdsEyeViewEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild('birdsEyeView', { static: true }) birdsEyeView: ElementRef;
  place: any;
  birdsEyeViewInitVal: ElementRef;

  constructor(private _zone: NgZone) {}

  ngOnInit() {}

  ngOnChanges() {
    this.birdsEyeViewInitVal = this.birdsEyeView;
    let birdsEyeViewMap: google.maps.Map = null;
    if (this.defaultPlace) {
      birdsEyeViewMap = this._zone.runOutsideAngular(() => {
        return new google.maps.Map(this.birdsEyeView.nativeElement, {
          center: this.defaultPlace,
          zoom: 20,
          tilt: 45,
          mapTypeId: google.maps.MapTypeId.HYBRID,
          zoomControl: true,
          gestureHandling: 'cooperative',
          zoomControlOptions: {
            // style: google.maps.ZoomControlStyle.LARGE,
            position: google.maps.ControlPosition.RIGHT_CENTER,
          } as google.maps.ZoomControlOptions,
          rotateControl: true,
          rotateControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
          panControl: true,
          disableDefaultUI: true,
        } as google.maps.MapOptions);
      });
      this.birdsEyeViewEvent.next(birdsEyeViewMap);
    } else {
      this.birdsEyeView = this.birdsEyeViewInitVal;
    }
  }

  ngOnDestroy() {
    this.defaultPlace = null;
  }
}

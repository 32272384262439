import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { WorkspaceState } from 'src/app/_shared/state/workspace.state';
import { AppState } from 'src/app/app.state';
import { PropertyFilterState } from 'src/app/property-filter/state/property-filter.state';
import { SearchApi } from 'src/app/workspace/api/search.api';
import { MapState } from 'src/app/workspace/state/map.state';
import { SearchState } from '../../../app/workspace/state/search.state';
import { FiltersConfig } from '../config/filter.config';
import { FiltersState } from '../state/filter.state';
import { GTMService } from './gtm.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  previousEventData;
  newEventData = {};
  saleTypePriority: any = ['Auction', 'Bankowned', 'Pre-Foreclosure', 'Regular sale'];

  constructor(
    private filtersConfig: FiltersConfig,
    private filtersState: FiltersState,
    private searchState: SearchState,
    private searchApi: SearchApi,
    private mapState: MapState,
    private workspaceState: WorkspaceState,
    private gtmService: GTMService,
    private activatedRoute: ActivatedRoute,
    private appState: AppState,
    private propertyFilterState: PropertyFilterState
  ) {
    this.initSubscription();
  }

  initSubscription() {
    this.filtersState.detailedFilterList$.subscribe((res) => {
      this.workspaceState.isSwitchedValue = false;
      if (res) {
        const filterSet = this.getFilterSet(res);
        const basicFilterParams = this.getFilterQuery(filterSet);
        this.filtersState.basicFilterListValue = basicFilterParams;
      }
    });
  }

  resetFilterList() {
    const defaultFilterList = this.filtersConfig.filterSchema;
    this.setDetailedFilterForBaseFilter(this.workspaceState.activeWorkspaceModeValue?.defaultFilters || {});
    //this.filtersState.detailedFilterListValue = defaultFilterList;
    this.mapState.activePropensityListValue = [];
    this.saveFilterList();
  }

  setFilter(updatedFilterList, type) {
    if (type == 'Mortgage') {
      updatedFilterList.map((el) => {
        const tempMortgage = el.mortgageType ? el.mortgageType : el.subType;
        if (el.mortgageType) {
          el.mortgageType.map((subType) => {
            let status = subType.subType.find((sub) => {
              return sub.status === true;
            });
            if (status) {
              subType.status = true;
              el.status = true;
            } else {
              el.status = false;
              subType.status = false;
            }
            return subType;
          });
        }

        const status = tempMortgage.find((sub) => {
          return sub.status === true;
        });
        if (status) {
          el.status = true;
        } else {
          el.status = false;
        }
        return el;
      });
    } else {
      updatedFilterList.map((el) => {
        if (el.subType) {
          let status = el.subType.find((sub) => sub.status === true);
          if (status) {
            el.status = true;
          } else {
            el.status = false;
          }
        }
        return el;
      });
    }
    const currentFilterList: any = {};
    const lastUpdatedFilterList: any = this.filtersState.detailedFilterListValue;
    if (type === 'PropertyType') {
      currentFilterList.currentPropertytypeFilter = updatedFilterList;
    } else {
      currentFilterList.currentPropertytypeFilter = lastUpdatedFilterList.currentPropertytypeFilter;
    }

    if (type === 'Insights') {
      currentFilterList.currentInsightFilter = updatedFilterList;
    } else {
      currentFilterList.currentInsightFilter = lastUpdatedFilterList.currentInsightFilter;
    }

    if (type === 'Mortgage') {
      currentFilterList.currentMortgageFilter = updatedFilterList;
    } else {
      currentFilterList.currentMortgageFilter = lastUpdatedFilterList.currentMortgageFilter;
    }

    if (type === 'Value') {
      currentFilterList.currentValueFilter = updatedFilterList;
    } else {
      currentFilterList.currentValueFilter = lastUpdatedFilterList.currentValueFilter;
    }

    if (type === 'TaxAndAssessment') {
      currentFilterList.currentTaxAndAssessmentsFilter = updatedFilterList;
    } else {
      currentFilterList.currentTaxAndAssessmentsFilter = lastUpdatedFilterList.currentTaxAndAssessmentsFilter;
    }

    if (type === 'Ownership') {
      currentFilterList.currentOwnershipFilter = updatedFilterList;
    } else {
      currentFilterList.currentOwnershipFilter = lastUpdatedFilterList.currentOwnershipFilter;
    }

    if (type === 'PropertyCharacteristics') {
      currentFilterList.currentPropertyCharacteristicsFilter = updatedFilterList;
    } else {
      currentFilterList.currentPropertyCharacteristicsFilter =
        lastUpdatedFilterList.currentPropertyCharacteristicsFilter;
    }

    if (type === 'Listing') {
      currentFilterList.currentListingFilter = updatedFilterList;
    } else {
      currentFilterList.currentListingFilter = lastUpdatedFilterList.currentListingFilter;
    }

    if (type === 'Distress') {
      currentFilterList.currentDistressFilter = updatedFilterList;
    } else {
      currentFilterList.currentDistressFilter = lastUpdatedFilterList.currentDistressFilter;
    }
    this.filtersState.detailedFilterListValue = currentFilterList;
    this.saveFilterList();
  }

  saveFilterList() {
    delete this.searchState.searchAddressValue.id;
    const userLog = {
      ...this.searchState.searchAddressValue,
      filters: this.propertyFilterState.appliedFilter,
      type: 'filter',
    };
    this.searchApi.setSearch(userLog);
  }

  getFilterSet(filters) {
    const filterList = [];

    // Listing Status
    if (Array.isArray(filters.currentListingFilter)) {
      filters.currentListingFilter.forEach((spec) => {
        if (spec.status && spec.value != null) {
          let value = {};
          value[`${spec.key}`] = spec.value;
          filterList.push(value);
          if (spec.key === 'DaysOffMarket') {
            const DaysOffMarket = { OffMarket: true };
            const OffMarketStatus = { OffMarketStatus: 'Cancel/Withdrawn,Delisted' };
            filterList.push(DaysOffMarket);
            filterList.push(OffMarketStatus);
          } else if (
            spec.key === 'MaxDaysOnMarket' ||
            spec.key === 'MinDaysOnMarket' ||
            spec.key === 'UnderpricePercentage'
          ) {
            const DaysOnMarket = { OnMarket: true };
            filterList.push(DaysOnMarket);
          } else if (spec.key === 'SoldWithin') {
            let OffMarketStatus;
            if (filters.currentListingFilter[0].status == true) {
              OffMarketStatus = { OffMarketStatus: 'Sold, Cancel/Withdrawn,Delisted' };
              filterList.push(OffMarketStatus);
            } else {
              OffMarketStatus = { OffMarketStatus: 'Sold' };
              filterList.push(OffMarketStatus);
            }
          }
        } else if (spec.status) {
          let minSpec = {};
          let maxSpec = {};
          minSpec[`min${spec.key}`] = spec.subType[0].minVal;
          maxSpec[`max${spec.key}`] = spec.subType[0].maxVal;
          filterList.push(minSpec);
          filterList.push(maxSpec);
        }
      });
    }

    // Distress Status
    if (Array.isArray(filters.currentDistressFilter)) {
      filters.currentDistressFilter.forEach((spec) => {
        if (spec.status) {
          let value = {};
          if (spec.subType) {
            const filterValue = [];
            spec.subType.forEach((propertySubType) => {
              if (propertySubType.status) {
                filterValue.push(propertySubType.key);
              }
            });
            value[`${spec.key}`] = filterValue;
          } else {
            value[`${spec.key}`] = spec.value;
          }
          filterList.push(value);
        }
      });

      const includeClosedDistress = filterList.find((category) => {
        return category.DistressStartDateWithin ? true : false;
      });
      if (includeClosedDistress) {
        filterList.push({ IncludeClosedDistress: true });
      }
    }

    // Property Sub Type
    if (
      Array.isArray(filters.currentPropertytypeFilter) &&
      filters.currentPropertytypeFilter[0].key == 'residential' &&
      filters.currentPropertytypeFilter[0].status
    ) {
      const filterValue = [];
      filters.currentPropertytypeFilter[0].subType.forEach((propertySubType) => {
        if (propertySubType.status) {
          filterValue.push(propertySubType.key);
        }
      });
      filterList.push({ PropertyType: ['residential'] }, { PropertySubType: filterValue });
    }

    // Property Characteristics
    if (Array.isArray(filters.currentPropertyCharacteristicsFilter)) {
      filters.currentPropertyCharacteristicsFilter.forEach((spec) => {
        if (spec.status) {
          let minSpec = {};
          let maxSpec = {};
          minSpec[`min${spec.key}`] = spec.subType[0].minVal;
          maxSpec[`max${spec.key}`] = spec.subType[0].maxVal;
          filterList.push(minSpec);
          filterList.push(maxSpec);
        }
      });
    }

    // Ownership Specs
    if (Array.isArray(filters.currentOwnershipFilter)) {
      filters.currentOwnershipFilter.forEach((spec) => {
        if (spec.status && (spec.key == 'OwnerIsCorporation' || spec.key == 'OwnerOccupied')) {
          let filterValue = [];
          spec.subType.forEach((conf) => {
            if (conf.status) {
              filterValue.push(conf.value);
            }
          });
          filterList.push({ [spec.key]: filterValue });
        } else if (spec.status && (spec.key == 'OwnershipDuration' || spec.key == 'LastSaleContractDate')) {
          let minSpec = {};
          let maxSpec = {};
          minSpec[`min${spec.key}`] = spec.subType[0].minVal;
          maxSpec[`max${spec.key}`] = spec.subType[0].maxVal;
          filterList.push(minSpec);
          filterList.push(maxSpec);
        }
      });
    }

    // Tax and Assessments
    if (Array.isArray(filters.currentTaxAndAssessmentsFilter)) {
      filters.currentTaxAndAssessmentsFilter.forEach((spec) => {
        if (spec.status) {
          let minSpec = {};
          let maxSpec = {};
          minSpec[`min${spec.key}`] = spec.subType[0].minVal;
          maxSpec[`max${spec.key}`] = spec.subType[0].maxVal;
          filterList.push(minSpec);
          filterList.push(maxSpec);
        }
      });
    }

    // Sales Price
    if (Array.isArray(filters.currentValueFilter)) {
      filters.currentValueFilter.forEach((spec) => {
        if (spec.status) {
          let minSpec = {};
          let maxSpec = {};
          minSpec[`min${spec.key}`] = spec.subType[0].minVal;
          maxSpec[`max${spec.key}`] = spec.subType[0].maxVal;
          filterList.push(minSpec);
          filterList.push(maxSpec);
        }
      });
    }

    // Mortgages
    if (Array.isArray(filters.currentMortgageFilter)) {
      filters.currentMortgageFilter.forEach((spec) => {
        if (spec.status && (spec.key == 'FirstMortgage' || spec.key == 'SecondMortgage') && spec.mortgageType) {
          spec.mortgageType.forEach((mortgageFilter) => {
            if (mortgageFilter.key == 'FirstMortgageRateType' || mortgageFilter.key == 'SecondMortgageRateType') {
              let rateFilterList = [];
              mortgageFilter.subType.forEach((rateType) => {
                if (rateType.status) {
                  rateFilterList.push(rateType.key);
                }
              });
              filterList.push({ [mortgageFilter.key]: rateFilterList });
            } else {
              let minSpec = {};
              let maxSpec = {};
              minSpec[`min${[mortgageFilter.key]}`] = mortgageFilter.subType[0].minVal;
              maxSpec[`max${[mortgageFilter.key]}`] = mortgageFilter.subType[0].maxVal;
              filterList.push(minSpec);
              filterList.push(maxSpec);
            }
          });
        } else {
          if (spec.status) {
            let minSpec = {};
            let maxSpec = {};
            minSpec[`min${spec.key}`] = spec.subType[0].minVal;
            maxSpec[`max${spec.key}`] = spec.subType[0].maxVal;
            filterList.push(minSpec);
            filterList.push(maxSpec);
          }
        }
      });
    }

    // Insights
    if (Array.isArray(filters.currentInsightFilter)) {
      filters.currentInsightFilter.forEach((spec) => {
        if (spec.status) {
          let minSpec = {};
          let maxSpec = {};
          minSpec[`min${spec.key}`] = spec.subType[0].minVal;
          maxSpec[`max${spec.key}`] = spec.subType[0].maxVal;
          filterList.push(minSpec);
          filterList.push(maxSpec);
        }
      });
    }
    return filterList;
  }

  getFilterQuery(filterList) {
    const filterParamsList: any = {};
    let isSoldWithIn = filterList.find((filterParams) => filterParams.SoldWithin);
    if (isSoldWithIn) {
      this.filtersState.isSoldWithInValue = true;
    } else {
      this.filtersState.isSoldWithInValue = false;
    }

    filterList.forEach((filter) => {
      let key = Object.keys(filter)[0];
      let filterKey = '';
      let filterValue;
      switch (key) {
        case 'minSaleValue':
          filterKey = 'MinAVM';
          filterValue = filter[key];
          break;
        case 'maxSaleValue':
          filterKey = 'MaxAVM';
          filterValue = filter[key];
          break;
        case 'minRentValue':
          filterKey = 'MinRentValue';
          filterValue = filter[key];
          break;
        case 'maxRentValue':
          filterKey = 'MaxRentValue';
          filterValue = filter[key];
          break;
        case 'minCapRate':
          filterKey = 'MinCapRate';
          filterValue = filter[key];
          break;
        case 'maxCapRate':
          filterKey = 'MaxCapRate';
          filterValue = filter[key];
          break;
        case 'DistressStatus':
          filterKey = 'DistressStatus';
          filterValue = filter[key].join();
          break;
        case 'DistressStartDateWithin':
          filterKey = 'DistressStartDateWithin';
          filterValue = filter[key];
          break;
        case 'IncludeClosedDistress':
          filterKey = 'IncludeClosedDistress';
          filterValue = filter[key];
          break;
        case 'DaysOffMarket':
          filterKey = 'DaysOffMarket';
          filterValue = filter[key];
          break;
        case 'OffMarket':
          filterKey = 'OffMarket';
          filterValue = filter[key];
          break;
        case 'OffMarketStatus':
          filterKey = 'OffMarketStatus';
          filterValue = filter[key];
          break;
        case 'MinDaysOnMarket':
          filterKey = 'MinDaysOnMarket';
          filterValue = filter[key];
          break;
        case 'OnMarket':
          filterKey = 'OnMarket';
          filterValue = filter[key];
          break;
        case 'UnderpricePercentage':
          filterKey = 'UnderpricePercentage';
          filterValue = filter[key];
          break;
        case 'FlipDuration':
          filterKey = 'FlipDuration';
          filterValue = filter[key];
          break;
        case 'MaxDaysOnMarket':
          filterKey = 'MaxDaysOnMarket';
          filterValue = filter[key];
          break;
        case 'maxOwnershipDuration':
          filterKey = 'MaxOwnershipDuration';
          filterValue = filter[key];
          break;
        case 'SoldWithin':
          filterKey = 'MinLastSaleDate';
          filterValue = filter[key];
          break;
        case 'minListPrice':
          filterKey = 'MinListPrice';
          filterValue = filter[key];
          break;
        case 'maxListPrice':
          filterKey = 'MaxListPrice';
          filterValue = filter[key];
          break;
        case 'PropertyType':
          filterKey = 'PropertyType';
          filterValue = filter[key].join();
          break;
        case 'PropertySubType':
          filterKey = 'PropertySubType';
          filterValue = filter[key].join();
          break;
        case 'minBedroomsTotal':
          filterKey = 'MinBed';
          filterValue = filter[key];
          break;
        case 'maxBedroomsTotal':
          filterKey = 'MaxBed';
          filterValue = filter[key];
          break;
        case 'minBathroomsTotalInteger':
          filterKey = 'MinBath';
          filterValue = filter[key];
          break;
        case 'maxBathroomsTotalInteger':
          filterKey = 'MaxBath';
          filterValue = filter[key];
          break;
        case 'minLivingArea':
          filterKey = 'MinLivingArea';
          filterValue = filter[key];
          break;
        case 'maxLivingArea':
          filterKey = 'MaxLivingArea';
          filterValue = filter[key];
          break;
        case 'minLotSizeSquareFeet':
          filterKey = 'MinLotSize';
          filterValue = filter[key];
          break;
        case 'maxLotSizeSquareFeet':
          filterKey = 'MaxLotSize';
          filterValue = filter[key];
          break;
        case 'minParkingSpaces':
          filterKey = 'MinParkingSpaces';
          filterValue = filter[key];
          break;
        case 'maxParkingSpaces':
          filterKey = 'MaxParkingSpaces';
          filterValue = filter[key];
          break;
        case 'minStoriesTotal':
          filterKey = 'MinStories';
          filterValue = filter[key];
          break;
        case 'maxStoriesTotal':
          filterKey = 'MaxStories';
          filterValue = filter[key];
          break;
        case 'minLastSaleContractDate':
          if (filter[key]) {
            filterKey = 'MinLastSaleDate';
            filterValue = moment(filter[key]).format('YYYYMMDD');
          }
          break;
        case 'maxLastSaleContractDate':
          if (filter[key]) {
            filterKey = 'MaxLastSaleDate';
            filterValue = moment(filter[key]).format('YYYYMMDD');
          }
          break;
        case 'OwnerIsCorporation':
          filterKey = 'OwnerIsCorporation';
          filterValue = filter[key].includes('y') ? true : false;
          break;
        case 'OwnerOccupied':
          filterKey = 'OwnerOccupied';
          filterValue = filter[key].includes('y') ? true : false;
          break;
        case 'minClosePrice':
          filterKey = 'MinClosePrice';
          filterValue = filter[key];
          break;
        case 'maxClosePrice':
          filterKey = 'MaxClosePrice';
          filterValue = filter[key];
          break;
        case 'minOwnershipDuration':
          filterKey = 'MinOwnershipDuration';
          filterValue = filter[key];
          break;
        case 'minTaxAnnualAmount':
          filterKey = 'MinTax';
          filterValue = filter[key];
          break;
        case 'maxTaxAnnualAmount':
          filterKey = 'MaxTax';
          filterValue = filter[key];
          break;
        case 'minAssessedValue':
          filterKey = 'MinAssessedValue';
          filterValue = filter[key];
          break;
        case 'maxAssessedValue':
          filterKey = 'MaxAssessedValue';
          filterValue = filter[key];
          break;
        case 'minAssessedLandValue':
          filterKey = 'MinAssessedLandValue';
          filterValue = filter[key];
          break;
        case 'maxAssessedLandValue':
          filterKey = 'MaxAssessedLandValue';
          filterValue = filter[key];
          break;
        case 'minAssessedImprovementValue':
          filterKey = 'MinAssessedImprovementValue';
          filterValue = filter[key];
          break;
        case 'maxAssessedImprovementValue':
          filterKey = 'MaxAssessedImprovementValue';
          filterValue = filter[key];
          break;
        case 'minFirstMortgageTerm':
          filterKey = 'MinFirstMortgageTerm';
          filterValue = filter[key];
          break;
        case 'maxFirstMortgageTerm':
          filterKey = 'MaxFirstMortgageTerm';
          filterValue = filter[key];
          break;
        case 'minFirstMortgagePrincipal':
          filterKey = 'MinFirstMortgagePrincipal';
          filterValue = filter[key];
          break;
        case 'maxFirstMortgagePrincipal':
          filterKey = 'MaxFirstMortgagePrincipal';
          filterValue = filter[key];
          break;
        case 'minFirstMortgageRate':
          filterKey = 'MinFirstMortgageRate';
          filterValue = filter[key];
          break;
        case 'maxFirstMortgageRate':
          filterKey = 'MaxFirstMortgageRate';
          filterValue = filter[key];
          break;
        case 'FirstMortgageRateType':
          filterKey = 'FirstMortgageRateType';
          filterValue = filter[key][0];
          break;
        case 'minFirstMortgageRecordingDate':
          if (filter[key]) {
            filterKey = 'MinFirstMortgageRecordingDate';
            filterValue = moment(filter[key]).format('YYYYMMDD');
          }
          break;
        case 'maxFirstMortgageRecordingDate':
          if (filter[key]) {
            filterKey = 'MaxFirstMortgageRecordingDate';
            filterValue = moment(filter[key]).format('YYYYMMDD');
          }
          break;
        case 'minSecondMortgageTerm':
          filterKey = 'MinSecondMortgageTerm';
          filterValue = filter[key];
          break;
        case 'maxSecondMortgageTerm':
          filterKey = 'MaxSecondMortgageTerm';
          filterValue = filter[key];
          break;
        case 'minSecondMortgagePrincipal':
          filterKey = 'MinSecondMortgagePrincipal';
          filterValue = filter[key];
          break;
        case 'maxSecondMortgagePrincipal':
          filterKey = 'MaxSecondMortgagePrincipal';
          filterValue = filter[key];
          break;
        case 'minSecondMortgageRate':
          filterKey = 'MinSecondMortgageRate';
          filterValue = filter[key];
          break;
        case 'maxSecondMortgageRate':
          filterKey = 'MaxSecondMortgageRate';
          filterValue = filter[key];
          break;
        case 'SecondMortgageRateType':
          filterKey = 'SecondMortgageRateType';
          filterValue = filter[key][0];
          break;
        case 'minSecondMortgageRecordingDate':
          if (filter[key]) {
            filterKey = 'MinSecondMortgageRecordingDate';
            filterValue = moment(filter[key]).format('YYYYMMDD');
          }
          break;
        case 'maxSecondMortgageRecordingDate':
          if (filter[key]) {
            filterKey = 'MaxSecondMortgageRecordingDate';
            filterValue = moment(filter[key]).format('YYYYMMDD');
          }
          break;
        case 'minMortgageCount':
          filterKey = 'MinMortgageCount';
          filterValue = filter[key];
          break;
        case 'maxMortgageCount':
          filterKey = 'MaxMortgageCount';
          filterValue = filter[key];
          break;
        case 'minEstimatedLoanBalance':
          filterKey = 'MinEstimatedLoanBalance';
          filterValue = filter[key];
          break;
        case 'maxEstimatedLoanBalance':
          filterKey = 'MaxEstimatedLoanBalance';
          filterValue = filter[key];
          break;
        case 'minTotEstMthlyMtgPymt':
          filterKey = 'MinTotEstMthlyMtgPymt';
          filterValue = filter[key];
          break;
        case 'maxTotEstMthlyMtgPymt':
          filterKey = 'MaxTotEstMthlyMtgPymt';
          filterValue = filter[key];
          break;
        case 'minCurrEstLTV':
          filterKey = 'MinCurrEstLTV';
          filterValue = filter[key];
          break;
        case 'maxCurrEstLTV':
          filterKey = 'MaxCurrEstLTV';
          filterValue = filter[key];
          break;
        case 'minPropensityToSell':
          filterKey = 'MinPropensityToSell';
          filterValue = filter[key];
          break;
        case 'maxPropensityToSell':
          filterKey = 'MaxPropensityToSell';
          filterValue = filter[key];
          break;
        case 'minYearBuilt':
          filterKey = 'MinYearBuilt';
          filterValue = filter[key];
          break;
        case 'maxYearBuilt':
          filterKey = 'MaxYearBuilt';
          filterValue = filter[key];
          break;
      }
      if (filterKey && filterValue != null) {
        filterParamsList[filterKey] = filterValue;
      }
    });
    if (filterParamsList.MinDaysOnMarket != null)
      filterParamsList.OnMarketStatus = this.filtersState.appliedListingStatus.join(',');

    if (
      filterParamsList.MinDaysOnMarket != null ||
      filterParamsList.DistressStatus != null ||
      this.workspaceState.activeWorkspaceModeValue.defaultFilters?.listingEnabled == true
    ) {
      filterParamsList.listingEnabled = true;
    }
    this.filtersState.appliedFilterSetValue = filterParamsList;
    return filterParamsList;
  }

  isBasePropertyFiltered(property) {
    let appliedListingStatus = ['active', 'active under contract', 'pending'];
    let isPreFclListingStatus = ['notice of default', 'lis pendens', 'short sale'];
    let isReoListingStatus = ['bank owned', 'real estate owned'];
    let isHudStatus = ['fannie mae owned', 'hud owned'];

    const filterList: any = this.filtersState.basicFilterListValue;
    const appliedFilters: any = this.propertyFilterState.appliedFilter;

    let status = false;
    let validFilterList = [];

    if (
      property.Lst_StandardStatus &&
      appliedListingStatus.includes(property.Lst_StandardStatus.toLowerCase()) &&
      property.saletype == 'Sale'
    ) {
      validFilterList.push('listing');
      status = true;
    }

    if (property.Lst_StandardStatus && property.Lst_StandardStatus.toLowerCase() == 'sold' && !property.saletype) {
      validFilterList.push('sold');
      status = true;
    }

    if (
      property.Lst_speciallistingconditions &&
      property.Lst_StandardStatus &&
      property.saletype == 'Hudlisting' &&
      this.filtersConfig.listingStatus.includes(property.Lst_StandardStatus.toLowerCase())
    ) {
      let isHud = false;

      isHudStatus.forEach((status) => {
        if (property.Lst_speciallistingconditions.toLowerCase().includes(status)) {
          isHud = true;
          return;
        }
      });

      if (isHud) {
        status = true;
        validFilterList.push('hud');
      }
    }

    if (property.Lst_StandardStatus && property.saletype == 'Pre-Foreclosure') {
      let isPreFcl = false;
      isPreFclListingStatus.forEach((status) => {
        if (property.Lst_speciallistingconditions.toLowerCase().includes(status)) {
          isPreFcl = true;
        }
      });

      if (isPreFcl) {
        validFilterList.push('distressed');
        status = true;
      }
    }

    if (property.Lst_StandardStatus && property.saletype == 'Bankowned') {
      let isReo = false;
      isReoListingStatus.forEach((status) => {
        if (property.Lst_speciallistingconditions.toLowerCase().includes(status)) {
          isReo = true;
        }
      });

      if (isReo) {
        validFilterList.push('distressed');
        status = true;
      }
    }

    if (property.Lst_StandardStatus && property.saletype == 'Auction') {
      if (
        !property.Fcl_lastauctionDate ||
        (property.Fcl_lastauctionDate && moment().isBefore(property.Fcl_lastauctionDate, 'day'))
      ) {
        validFilterList.push('distressed');
        status = true;
      } else {
      }
    }

    if (property.saletype && typeof property.saletype === 'string' && property.saletype?.includes(',')) {
      let saletypeArray = property.saletype.split(',');
      saletypeArray.sort((a, b) => this.getSaleTypePriority(a) - this.getSaleTypePriority(b));
      let prioritizedSaletype = saletypeArray[0];

      if (prioritizedSaletype.toLowerCase() == 'auction') {
        if (property.Fcl_lastauctionDate && moment().isBefore(property.Fcl_lastauctionDate, 'day')) {
          validFilterList.push('distressed');
          property.addUpdatedBadge = 'auction';
          status = true;
        } else if (saletypeArray[1]) {
          validFilterList.push('distressed');
          status = true;
        }
      }
      if (prioritizedSaletype == 'Hudlisting') {
        isHudStatus.forEach((statusHud) => {
          if (property.Lst_speciallistingconditions.toLowerCase().includes(statusHud)) {
            validFilterList.push('hud');
            property.addUpdatedBadge = statusHud;
            status = true;
          }
        });
      }

      if (prioritizedSaletype == 'Bankowned') {
        isReoListingStatus.forEach((statusReo) => {
          if (property.Lst_speciallistingconditions.toLowerCase().includes(statusReo)) {
            property.addUpdatedBadge = statusReo;
            validFilterList.push('distressed');
            status = true;
          }
        });
      }

      // for (const filter in filterList) {
      //   if (Object.prototype.hasOwnProperty.call(filterList, filter)) {
      //     const filterValue = filterList[filter];
      //     switch (filter) {
      //       case 'DistressStatus':
      //         if (
      //           property.Lst_speciallistingconditions &&
      //           property.Lst_StandardStatus &&
      //           this.filtersConfig.listingStatus.includes(property.Lst_StandardStatus.toLowerCase())
      //         ) {
      //           let isHud = false;
      //           this.filtersConfig.hudCondition.specialListingStatus.forEach((status) => {
      //             if (property.Lst_speciallistingconditions.toLowerCase().includes(status)) {
      //               isHud = true;
      //               return;
      //             }
      //           });

      //           if (isHud) {
      //             status = true;
      //             validFilterList.push('hud');
      //             break;
      //           }
      //           let isDistressed = false;
      //           this.filtersConfig.distressCondition.specialListingStatus.forEach((status) => {
      //             if (property.Lst_speciallistingconditions.toLowerCase().includes(status)) {
      //               isDistressed = true;
      //               return;
      //             }
      //           });

      //           if (isDistressed) {
      //             status = true;
      //             validFilterList.push('distressed');
      //             break;
      //           }
      //         }
      //         if (
      //           property.Fcl_Status &&
      //           filterValue?.toLowerCase()?.split(',').includes(property.Fcl_Status.toLowerCase()) &&
      //           (!property.Fcl_EndDate || (property.Fcl_EndDate && moment().isBefore(property.Fcl_EndDate, 'day')))
      //         ) {
      //           status = true;
      //           validFilterList.push('distressed');
      //         } else {
      //           status = false;
      //         }
      //         break;

      //       case 'MinDaysOnMarket':
      //         if (property.Lst_speciallistingconditions) {
      //           let isDistressed = false;
      //           [
      //             ...this.filtersConfig.distressCondition.specialListingStatus,
      //             ...this.filtersConfig.hudCondition.specialListingStatus,
      //           ].forEach((status) => {
      //             if (property.Lst_speciallistingconditions.toLowerCase().includes(status)) {
      //               isDistressed = true;
      //               return;
      //             }
      //           });
      //           if (isDistressed) {
      //             status = false;
      //             break;
      //           }
      //         }
      //         if (property.Fcl_Status) {
      //           let isDistressed = false;
      //           this.filtersConfig.distressCondition.fclStatus.forEach((status) => {
      //             if (property.Fcl_Status.toLowerCase().includes(status)) {
      //               isDistressed = true;
      //               return;
      //             }
      //           });
      //           if (isDistressed) {
      //             status = false;
      //             break;
      //           }
      //         }
      //         if (
      //           property.Lst_StandardStatus &&
      //           this.filtersState.appliedListingStatus.includes(property.Lst_StandardStatus.toLowerCase()) &&
      //           filterList.listingEnabled == true
      //         ) {
      //           validFilterList.push('listing');
      //           status = true;
      //         } else {
      //           status = false;
      //         }
      //         break;

      //       // case 'MinLastSaleDate':
      //       //   if (property.Lst_StandardStatus && property.Lst_StandardStatus.toLowerCase() == 'sold') {
      //       //     status = true;
      //       //     validFilterList.push('sold');
      //       //   } else {
      //       //     status = false;
      //       //   }
      //       //   break;

      //       case 'DaysOffMarket':
      //         if (
      //           property.Lst_StandardStatus &&
      //           this.filtersConfig.offMarketStatus.includes(property.Lst_StandardStatus.toLowerCase())
      //         ) {
      //           if (property.Lst_StandardStatus && property.Lst_StandardStatus == 'Cancel/Withdrawn') {
      //             status = true;
      //           } else {
      //             status = false;
      //           }
      //         } else {
      //           status = false;
      //         }
      //         break;
      //     }
      //     if (status) {
      //       break;
      //     }
      //   }
      // }

      // if (appliedFilters.soldWithin) {
      //   if (property.Lst_StandardStatus && property.Lst_StandardStatus.toLowerCase() == 'sold') {
      //     status = true;
      //     validFilterList.push('sold');
      //   } else {
      //     status = false;
      //   }
      // }
    }
    return { status, filter: validFilterList };
  }

  getSaleTypePriority(saletype) {
    return this.saleTypePriority.indexOf(saletype);
  }

  setDetailedFilterForBaseFilter(filterSet) {
    const detailedFilterSet: any = this.filtersConfig.filterSchema;
    // const detailedFilterSet: any = this.filtersState.detailedFilterListValue || this.filtersConfig.filterSchema;
    filterSet = filterSet || this.workspaceState.activeWorkspaceModeValue?.defaultFilters || {};
    const filterKeys = Object.keys(filterSet);

    let appQueryParams = JSON.parse(localStorage.getItem('appQueryParams'));
    let forSaleQueryParam =
      this.activatedRoute.snapshot.queryParamMap.get('regularSales') == 'true'
        ? 'active'
        : this.activatedRoute.snapshot.queryParamMap.get('regularSales') == 'false'
        ? this.activatedRoute.snapshot.queryParamMap.get('regularSales')
        : appQueryParams?.regularSales == 'true'
        ? 'active'
        : appQueryParams?.regularSales == 'false'
        ? appQueryParams.regularSales
        : 'active';

    filterKeys.forEach((filterKey) => {
      switch (filterKey) {
        case 'minDaysOnMarket':
          if (forSaleQueryParam === 'active') {
            detailedFilterSet.currentListingFilter = detailedFilterSet.currentListingFilter.map((res) => {
              if (res.key == 'MinDaysOnMarket') {
                res.status = true;
                res.value = 0;
              }
              return res;
            });
          }
          break;

        case 'DistressStatus':
          if (this.appState.authTokenValue?.idToken) {
            if (this.workspaceState.activeWorkspaceModeValue?.defaultFilters) {
              detailedFilterSet.currentDistressFilter = detailedFilterSet.currentDistressFilter.map((element) => {
                if (element.key == 'DistressStatus') {
                  element.status = true;
                  element.subType.map((subType) => {
                    let DistressStatus =
                      this.workspaceState.activeWorkspaceModeValue?.defaultFilters?.DistressStatus?.split(',');
                    DistressStatus.forEach((keyValues) => {
                      if (subType.key == keyValues && subType.key != 'HUD' && subType.status == false) {
                        subType.status = true;
                      } else {
                        subType.status = true;
                      }
                    });

                    return subType;
                  });
                }
                return element;
              });
            }
          }
          break;
      }
    });

    let priceRangeQueryParam = this.activatedRoute.snapshot.queryParamMap.get('priceRange')
      ? this.activatedRoute.snapshot.queryParamMap.get('priceRange')
      : appQueryParams?.priceRange;
    if (priceRangeQueryParam && this.appState.authTokenValue?.idToken) {
      const values = priceRangeQueryParam?.split('-');
      const [minValue, maxValue] = values.map((val) => parseInt(val, 10));
      if ((minValue < maxValue && minValue && maxValue) || (!minValue && maxValue) || (minValue && !maxValue)) {
        detailedFilterSet.currentListingFilter = detailedFilterSet.currentListingFilter.map((element) => {
          if (element.key == 'ListPrice') {
            element.status = priceRangeQueryParam ? true : false;
            element.subType.map((subType) => {
              subType.maxVal = priceRangeQueryParam ? maxValue : null;
              subType.minVal = priceRangeQueryParam ? minValue : null;
              subType.status = priceRangeQueryParam ? true : false;
            });
          }
          return element;
        });
        this.saveFilterList();
      }
    }

    this.filtersState.detailedFilterListValue = detailedFilterSet;
    // const queryParamsToRemove: string[] = ['priceRange', 'regularSales']; // Query parameters remove for filters
    // const updatedQueryParams = { ...this.activatedRoute.snapshot.queryParams };
    // queryParamsToRemove.forEach((param) => delete updatedQueryParams[param]);
    // this.router.navigate([], {
    //   relativeTo: this.activatedRoute,
    //   queryParams: updatedQueryParams,
    //   // queryParamsHandling: 'merge',
    // });
    this.setSoldWithinFilter(detailedFilterSet);
  }

  setSoldWithinFilter(detailedFilterSet) {
    let filteredData;
    detailedFilterSet.currentDistressFilter.map((element) => {
      if (element.key == 'DistressStatus') {
        filteredData = element.subType.filter((obj) => {
          if (obj.status == true) {
            return obj;
          }
        });
      }
    });
    if (filteredData.length == 0 && detailedFilterSet.currentListingFilter[1].status == false) {
      let soldWithinDate = moment().subtract(30, 'days').format('YYYYMMDD');
      detailedFilterSet.currentListingFilter[5].value = soldWithinDate;
      detailedFilterSet.currentListingFilter[5].status = true;
      detailedFilterSet.currentListingFilter[0].value = 30; // Set to effect DaysOffMarket filter with the same values as SoldWithin Filter
      detailedFilterSet.currentListingFilter[0].status = true; // Set to effect DaysOffMarket filter with the same values as SoldWithin Filter
      this.filtersState.detailedFilterListValue = detailedFilterSet;
    }
  }

  dataLayerFilterValue(eventType, EventData) {
    if (eventType == 'clearAllFilter') {
      Object.keys(this.newEventData).forEach((newKey) => {
        if (['address', 'city', 'state'].includes(newKey)) return;
        this.newEventData[newKey] = undefined;
      });
    } else if (eventType == 'navigation') {
      Object.keys(this.newEventData).forEach((newKey) => {
        this.newEventData[newKey] = undefined;
      });
      this.newEventData = { ...this.newEventData, EventData };
    } else if (eventType == 'propertySearch') {
      const filteredEventData = Object.assign({}, this.previousEventData, EventData);
      this.newEventData = filteredEventData;
      this.previousEventData = this.newEventData;
    }
    this.gtmService.updateEvent(eventType, this.newEventData);
  }
}

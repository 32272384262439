import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { QuestionnaireResponseComponent } from 'src/app/questionnaire-response/questionnaire-response.component';
import { QuestionnaireState } from '../../_shared/state/questionnaire.state';
import { QuestionnaireApi } from '../api/questionnaire.api';

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {
  constructor(
    private getQuestionsApi: QuestionnaireApi,
    private snackBar: MatSnackBar,
    private questionnaireState: QuestionnaireState,
    private matDialog: MatDialog,
    public appState: AppState,
    private location: Location,
  ) {
    this.triggerQuestionnaire();
  }

  getQuestionnaireList() {
    this.getQuestionsApi
      .getQuestionnaire()
      .pipe(finalize(() => { }))
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            this.questionnaireState.questionsListValue = res.data;
          }
        },
        (err) => {
          this.questionnaireState.questionsListValue = [];
        }
      );
  }

  QuestionnaireResponse(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.getQuestionsApi
        .questionsResponse(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => { },
          (err) => {
            let message = err && err.error && err.error.message ? err.error.message : `'Some error occurred '`;
            this.openSnackBar(message, 'snackbar-error');
          }
        );
    });
  }
  private triggerQuestionnaire() {
    this.questionnaireState.questionsList$.subscribe((questionsArray: any) => {
      const path = this.location.path();
      let lPath = path ? path.split('/') : [];
      let route = lPath.length > 1 ? lPath[1] : '';

      if (questionsArray && questionsArray.length && route != 'verifyAccount') {
        const dialogRef = this.matDialog.open(QuestionnaireResponseComponent, {
          width: '385px',
          disableClose: false,
          hasBackdrop: false,
          data: questionsArray,
        });
      }
    });
  }

  private openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

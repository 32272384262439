<div class="agnt_cnct shadow-1">
  <div class="row agnt_img">
    <div class="col-sm-12">
      <div class="pr-2">
        <img
          height="70px"
          [src]="
            agentInfo['profileImage'] ||
            'https://media.propmix.io/mlslite/media/GetImageURL?fromApp=PROSPECTOR&ImageID=COMMON/userAvatar.png'
          "
        />
      </div>
      <div class="float-left agnt_dtls">
        <h5>
          {{
            agentInfo['firstName'] && agentInfo['lastName']
              ? agentInfo['firstName'] + ' ' + agentInfo['lastName']
              : agentInfo['firstName']
              ? agentInfo['firstName']
              : agentInfo['lastName']
              ? agentInfo['lastName']
              : '--'
          }}
        </h5>

        <p class="mb-1">
          <img src="../../../../assets/images/phone_line.svg" width="15px" /> :
          <a class="link" [href]="'tel:' + agentInfo['phone']">
            {{ agentInfo['phone'] || '--' }}
          </a>
        </p>
        <p class="mb-0">
          <img src="../../../../assets/images/email.svg" width="15px" /> :
          <a class="link" [href]="'mailto:' + agentInfo['email']">
            {{ agentInfo['email'] || '--' }}
          </a>
        </p>
      </div>
    </div>
  </div>
  <div class="border-top mt-3 pt-3">
    <h4 class="" style="padding-bottom: 15px">
      {{ alterNativeName }}
    </h4>
    <button
      *ngIf="appState.companyInfoValue?.ctaConfig?.getPreApproval?.isActive; else makeOfferSection"
      mat-flat-button
      color="primary"
      class="btn-block"
      (click)="preApprovedAction()"
      [pmx-progress-button]="loanApplicationUrlRequestInProgress"
    >
      {{ appState.companyInfoValue?.ctaConfig?.getPreApproval?.agentCtaButtonLabel || 'Get Pre-Approved' }}
    </button>
    <ng-template #makeOfferSection>
      <button mat-flat-button color="primary" class="btn-block" (click)="makeOffer()">
        {{ appState.companyInfoValue?.ctaConfig?.makeOffer?.agentCtaButtonLabel || 'Make an Offer' }}
      </button>
    </ng-template>
    <div class="d-flex mt-2 btn-container">
      <span class="schedule-visit" *ngIf="appState.companyInfoValue?.ctaConfig?.scheduleVisit?.isActive">
        <button mat-stroked-button class="w-100" (click)="schedulePropertyVisit()">
          {{ appState.companyInfoValue?.ctaConfig?.scheduleVisit?.agentCtaButtonLabel || 'Schedule Showing' }}
        </button>
      </span>
      <span class="suggested-value" *ngIf="appState.companyInfoValue?.ctaConfig?.cmaReport?.isActive">
        <button mat-stroked-button class="w-100" (click)="getHomeValueReport()">
          {{ appState.companyInfoValue?.ctaConfig?.cmaReport?.agentCtaButtonLabel || 'Get a Suggested Value' }}
        </button>
      </span>
    </div>
  </div>
  <div class="section-in-progress" *ngIf="isDataLoading">
    <mat-spinner [diameter]="30" class="mr-3"></mat-spinner>
    Please wait while we load
  </div>
</div>

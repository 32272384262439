import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { QuestionnaireService } from '../_shared/service/questionnaire.service';

@Component({
  selector: 'app-questionnaire-response',
  templateUrl: './questionnaire-response.component.html',
  styleUrls: ['./questionnaire-response.component.scss'],
})
export class QuestionnaireResponseComponent implements OnInit {
  questionObj: any;
  array: any;
  question: Object = {};
  questionOptions: any;
  isLoggedInUser: boolean;
  options: any;
  questionId: string;
  keys: Object = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QuestionnaireResponseComponent,
    public dialogRef: MatDialogRef<QuestionnaireResponseComponent>,
    private questionnaireResponseService: QuestionnaireService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.questionObj = this.data;
    this.question = {
      question: this.questionObj[0].question,
      index: 0,
    };
    this.questionId = this.questionObj[0].id;
    this.questionOptions = this.questionObj[0].responseOption;
    this.options = Object.values(this.questionOptions);
  }

  Next(index, value) {
    let data = {
      questionId: this.questionId,
      question: this.question['question'],
      answer: value,
    };
    this.postQuestionnaireResponse(data);
    let length = this.questionObj.length;
    let i = index;
    if (i < length - 1) {
      this.question['index'] = i + 1;

      this.question['question'] = this.questionObj[i + 1].question;
      this.options = Object.values(this.questionObj[i + 1].responseOption);
      this.questionId = this.questionObj[i + 1].id;
    } else if (this.question['index'] + 1 == this.questionObj.length) {
      this.skipQuestions();
    }
  }
  postQuestionnaireResponse(data) {
    this.questionnaireResponseService.QuestionnaireResponse(data).subscribe((res: any) => {});
  }
  goToPreviousQuestion(index) {
    let length = this.questionObj.length;
    let i = index;
    if (i > 0) {
      this.question['index'] = i - 1;

      this.question['question'] = this.questionObj[i - 1].question;
      this.options = Object.values(this.questionObj[i - 1].responseOption);
      this.questionId = this.questionObj[i - 1].id;
    }
  }
  skipQuestions() {
    this.dialogRef.close();
  }
}

<!DOCTYPE html>
<html lang="en">
<head>
    <style>
        body { font-family: Arial, sans-serif; line-height: 1.6; }
        header, footer { background: #f4f4f4; padding: 1em; text-align: center; }
        article { margin: 20px; padding: 20px; border: 1px solid #ccc; }
    </style>
</head>
<body>

<header>
    <h1>3930 10TH ST N E, Washington, DC 20017</h1>
    <img src="https://dev-media.propmix.io/mls/media/image?ListingId=458959951&ImageName=1&height=500"/>
    <nav>
      <ul>
          <li>Quadruplex Property Type</li>
          <li>73 Days on Market</li>
          <li>$196 Price Per Sqft</li>
          <li>2 stories</li>
      </ul>
  </nav>
</header>

<main>
    <article id="about">
        <h2>About Us</h2>
        <p>Calling all investors! Four self contained 1-bedroom apartments with closed in back porches. Great Brookland location which is directly across the street from Brookland Middle school, Turkey Thicket Community Center & about a half mile walk from Catholic University & Metro. Properties are being sold in "AS IS condition. Zoned RA-1</p>
    </article>

    <article id="services">
        <h2>Our Services</h2>
        <p>We offer a range of services including web development, SEO consultation, and digital marketing strategies.</p>
    </article>

    <article id="contact">
        <h2>Contact Us</h2>
        <p>For inquiries, please reach out via <a href="mailto:info@example.com">email</a>.</p>
    </article>
</main>

<footer>
    <p>&copy; 2024 Homehunt. All rights reserved.</p>
    <p>Privacy Policy</p>
</footer>

</body>
</html>

import { FilterDisplayConfig } from '../interface/property-filter.interface';

export class PropertyFilterConfig {
  static primaryFilterParamList = [
    'status',
    'lPrice',
    'hPrice',
    'lBed',
    'hBed',
    'lBath',
    'hBath',
    'homeType',
    'isForSale',
    'isPreFcl',
    'isAuction',
    'isReo',
    'isHud',
    'includePending',
    'soldWithin',
    'lLiving',
    'hLiving',
    'lLot',
    'hLot',
    'lParking',
    'lYearBuilt',
    'hYearBuilt',
    'lStories',
    'hStories',
    'lMortgage',
    'hMortgage',
    'lLoanBalance',
    'hLoanBalance',
    'lMonthlyPayment',
    'hMonthlyPayment',
    'lLtv',
    'hLtv',
    'mortgLTerm',
    'mortgHTerm',
    'mortgLPrincipal',
    'mortgHPrincipal',
    'mortgLRate',
    'mortgHRate',
    'mortgRateType',
    'mortgLDate',
    'mortgHDate',
    'mortgLTerm2',
    'mortgHTerm2',
    'mortgLPrincipal2',
    'mortgHPrincipal2',
    'mortgLRate2',
    'mortgHRate2',
    'mortgRateType2',
    'mortgLDate2',
    'mortgHDate2',
    'corpOwned',
    'occupied',
    'lOwnDuration',
    'hOwnDuration',
    'lTax',
    'hTax',
    'lValue',
    'hValue',
    'lLandValue',
    'hLandValue',
    'lBldgValue',
    'hBldgValue',
    'lSalePrice',
    'hSalePrice',
    'lEstValue',
    'hEstValue',
    'lRent',
    'hRent',
    'lCapRate',
    'hCapRate',
    'underprice',
    // 'hOffMktDays',
    'lOnMktDays',
    'hOnMktDays',
    'flipWithin',
    'distressWithin',
  ];

  static primaryFilterDisplayConfig: FilterDisplayConfig[] = [
    {
      name: 'For Sale',
      type: 'VALUE',
      keys: [
        {
          key: 'isForSale',
          name: '',
          dataType: 'boolean',
          condition: true,
        },
      ],
    },
    {
      name: 'Foreclosure',
      type: 'VALUE',
      keys: [
        {
          key: 'isPreFcl',
          name: 'Pre-Foreclosure',
          dataType: 'boolean',
          condition: true,
        },
        {
          key: 'isAuction',
          name: 'Auction',
          dataType: 'boolean',
          condition: true,
        },
        {
          key: 'isReo',
          name: 'Bank-Owned',
          dataType: 'boolean',
          condition: true,
        },
      ],
    },
    {
      name: 'HUD',
      type: 'VALUE',
      keys: [
        {
          key: 'isHud',
          name: '',
          dataType: 'boolean',
          condition: true,
        },
      ],
    },
    {
      name: 'Sold Within',
      type: 'VALUE',
      keys: [
        {
          key: 'soldWithin',
          name: '',
          dataType: 'number',
        },
      ],
    },
    {
      name: 'Include Pending',
      type: 'VALUE',
      keys: [
        {
          key: 'includePending',
          name: '',
          dataType: 'boolean',
          condition: true,
        },
      ],
    },
    {
      name: 'Price',
      type: 'RANGE',
      keys: [
        {
          key: 'lPrice',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'hPrice',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Beds',
      type: 'RANGE',
      keys: [
        {
          key: 'lBed',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hBed',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Baths',
      type: 'RANGE',
      keys: [
        {
          key: 'lBath',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hBath',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Home Type',
      type: 'VALUE',
      keys: [
        {
          key: 'homeType',
          name: 'Home Type',
          dataType: 'string',
          enum: 'propertySubTypeEnum',
        },
      ],
    },
    {
      name: 'Living Area',
      type: 'RANGE',
      keys: [
        {
          key: 'lLiving',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hLiving',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Lot Size',
      type: 'RANGE',
      keys: [
        {
          key: 'lLot',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hLot',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Parking',
      type: 'RANGE',
      keys: [
        {
          key: 'lParking',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hParking',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Year Built',
      type: 'RANGE',
      keys: [
        {
          key: 'lYearBuilt',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hYearBuilt',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Stories',
      type: 'RANGE',
      keys: [
        {
          key: 'lStories',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hStories',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Mortgage #',
      type: 'RANGE',
      keys: [
        {
          key: 'lMortgage',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hMortgage',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Loan Balance',
      type: 'RANGE',
      keys: [
        {
          key: 'lLoanBalance',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'hLoanBalance',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Monthly Mortgage Payment',
      type: 'RANGE',
      keys: [
        {
          key: 'lMonthlyPayment',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'hMonthlyPayment',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Estimated LTV',
      type: 'RANGE',
      keys: [
        {
          key: 'lLtv',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hLtv',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Mortgage Term',
      type: 'RANGE',
      keys: [
        {
          key: 'mortgLTerm',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'mortgHTerm',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Mortgage Principal',
      type: 'RANGE',
      keys: [
        {
          key: 'mortgLPrincipal',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'mortgLPrincipal',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Mortgage Rate',
      type: 'RANGE',
      keys: [
        {
          key: 'mortgLRate',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'mortgHRate',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Mortgage Type',
      type: 'VALUE',
      keys: [
        {
          key: 'mortgRateType',
          name: '',
          dataType: 'string',
          enum: 'mortgageRateTypeEnum',
        },
      ],
    },
    {
      name: 'Mortgage Date',
      type: 'RANGE',
      keys: [
        {
          key: 'mortgLDate',
          name: '',
          dataType: 'date',
          keyType: 'min',
        },
        {
          key: 'mortgHDate',
          name: '',
          dataType: 'date',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Owned By',
      type: 'VALUE',
      keys: [
        {
          key: 'corpOwned',
          name: 'Corporation',
          dataType: 'boolean',
          condition: true,
        },
        {
          key: 'corpOwned',
          name: 'Individual',
          dataType: 'boolean',
          condition: false,
        },
      ],
    },
    {
      name: '',
      type: 'VALUE',
      keys: [
        {
          key: 'occupied',
          name: 'Occupied',
          dataType: 'boolean',
          condition: true,
        },
        {
          key: 'occupied',
          name: 'Unoccupied',
          dataType: 'boolean',
          condition: false,
        },
      ],
    },
    {
      name: 'Ownership Duration',
      type: 'RANGE',
      keys: [
        {
          key: 'lOwnDuration',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hOwnDuration',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Tax',
      type: 'RANGE',
      keys: [
        {
          key: 'lTax',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'hTax',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Assessed Value',
      type: 'RANGE',
      keys: [
        {
          key: 'lValue',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'hValue',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Land Value',
      type: 'RANGE',
      keys: [
        {
          key: 'lLandValue',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'hLandValue',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Building Value',
      type: 'RANGE',
      keys: [
        {
          key: 'lBldgValue',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'hBldgValue',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Sale Price',
      type: 'RANGE',
      keys: [
        {
          key: 'lSalePrice',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'hSalePrice',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Estimated Value',
      type: 'RANGE',
      keys: [
        {
          key: 'lEstValue',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'hEstValue',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Rent',
      type: 'RANGE',
      keys: [
        {
          key: 'lRent',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'min',
        },
        {
          key: 'hRent',
          name: '',
          dataType: 'number',
          prefix: '$',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Cap Rate',
      type: 'RANGE',
      keys: [
        {
          key: 'lCapRate',
          name: '',
          dataType: 'number',
          keyType: 'min',
        },
        {
          key: 'hCapRate',
          name: '',
          dataType: 'number',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Underpriced By',
      type: 'VALUE',
      keys: [
        {
          key: 'underprice',
          name: '',
          dataType: 'number',
          suffix: '%',
        },
      ],
    },
    {
      name: 'Off-Market',
      type: 'VALUE',
      keys: [
        {
          key: 'hOffMktDays',
          name: '',
          dataType: 'number',
          suffix: 'days',
        },
      ],
    },
    {
      name: 'On-Market',
      type: 'RANGE',
      keys: [
        {
          key: 'lOnMktDays',
          name: '',
          dataType: 'number',
          suffix: 'days',
          keyType: 'min',
        },
        {
          key: 'hOnMktDays',
          name: '',
          dataType: 'number',
          suffix: 'days',
          keyType: 'max',
        },
      ],
    },
    {
      name: 'Flipped Within',
      type: 'VALUE',
      keys: [
        {
          key: 'flipWithin',
          name: '',
          dataType: 'number',
          suffix: 'days',
        },
      ],
    },
    {
      name: 'Distressed Within',
      type: 'VALUE',
      keys: [
        {
          key: 'distressWithin',
          name: '',
          dataType: 'number',
          suffix: 'days',
        },
      ],
    },
  ];

  static propertySubTypeEnum: Record<string, string> = {
    apt: 'Apartment',
    condo: 'Condominium',
    landMfd: 'Manufactured On Land',
    mfd: 'Manufactured Home',
    mobile: 'Mobile Home',
    multi: 'Multi Family',
    multiLarge: 'Multi Family > 4',
    quad: 'Quadruplex',
    single: 'Single Family Residence',
    town: 'Townhouse',
  };

  static mortgageRateTypeEnum: Record<string, string> = {
    adjustable: 'Adjustable',
    fixed: 'Fixed',
    multiple: 'Multiple',
    other: 'Other',
  };

  static minPriceList: Record<string, string | number>[] = [
    { label: '$50K', value: 50000 },
    { label: '$100K', value: 100000 },
    { label: '$200K', value: 200000 },
    { label: '$300K', value: 300000 },
    { label: '$400K', value: 400000 },
    { label: '$500K', value: 500000 },
    { label: '$600K', value: 600000 },
    { label: '$700K', value: 700000 },
    { label: '$800K', value: 800000 },
    { label: '$1M', value: 1000000 },
    { label: '$1.5M', value: 1500000 },
    { label: '$2M', value: 2000000 },
  ];

  static maxPriceList: Record<string, string | number>[] = [
    { label: '$100K', value: 100000 },
    { label: '$200K', value: 200000 },
    { label: '$300K', value: 300000 },
    { label: '$400K', value: 400000 },
    { label: '$500K', value: 500000 },
    { label: '$600K', value: 600000 },
    { label: '$700K', value: 700000 },
    { label: '$800K', value: 800000 },
    { label: '$1M', value: 1000000 },
    { label: '$1.5M', value: 1500000 },
    { label: '$2M', value: 2000000 },
    { label: '$3M', value: 3000000 },
  ];

  static minLivingAreaOptions = [
    { label: '500 sqft', value: 500 },
    { label: '750 sqft', value: 750 },
    { label: '1,000 sqft', value: 1000 },
    { label: '1,250 sqft', value: 1250 },
    { label: '1,500 sqft', value: 1500 },
    { label: '1,750 sqft', value: 1750 },
    { label: '2,000 sqft', value: 2000 },
    { label: '2,250 sqft', value: 2250 },
    { label: '2,500 sqft', value: 2500 },
    { label: '2,750 sqft', value: 2750 },
    { label: '3,000 sqft', value: 3000 },
    { label: '3,500 sqft', value: 3500 },
    { label: '4,000 sqft', value: 4000 },
    { label: '5,000 sqft', value: 5000 },
    { label: '6,000 sqft', value: 6000 },
  ];
  static maxLivingAreaOptions = [
    { label: '750 sqft', value: 750 },
    { label: '1,000 sqft', value: 1000 },
    { label: '1,250 sqft', value: 1250 },
    { label: '1,500 sqft', value: 1500 },
    { label: '1,750 sqft', value: 1750 },
    { label: '2,000 sqft', value: 2000 },
    { label: '2,250 sqft', value: 2250 },
    { label: '2,500 sqft', value: 2500 },
    { label: '2,750 sqft', value: 2750 },
    { label: '3,000 sqft', value: 3000 },
    { label: '3,500 sqft', value: 3500 },
    { label: '4,000 sqft', value: 4000 },
    { label: '5,000 sqft', value: 5000 },
    { label: '6,000 sqft', value: 6000 },
    { label: '7,000 sqft', value: 7000 },
  ];
  static minLotSizeOptions = [
    { label: '1,000 sqft', value: 1000 },
    { label: '2,000 sqft', value: 2000 },
    { label: '3,000 sqft', value: 3000 },
    { label: '4,000 sqft', value: 4000 },
    { label: '5,000 sqft', value: 5000 },
    { label: '7,500 sqft', value: 7500 },
    { label: '10,000 sqft', value: 10000 },
    { label: '1/4 acre', value: 43560 / 4 },
    { label: '1/2 acre', value: 43560 / 2 },
    { label: '1 acre', value: 43560 * 1 },
    { label: '2 acre', value: 43560 * 2 },
    { label: '3 acre', value: 43560 * 3 },
    { label: '5 acre', value: 43560 * 5 },
    { label: '10 acre', value: 43560 * 10 },
    { label: '20 acre', value: 43560 * 20 },
    { label: '50 acre', value: 43560 * 50 },
  ];
  static maxLotSizeOptions = [
    { label: '2,000 sqft', value: 2000 },
    { label: '3,000 sqft', value: 3000 },
    { label: '4,000 sqft', value: 4000 },
    { label: '5,000 sqft', value: 5000 },
    { label: '7,500 sqft', value: 7500 },
    { label: '10,000 sqft', value: 10000 },
    { label: '1/4 acre', value: 43560 / 4 },
    { label: '1/2 acre', value: 43560 / 2 },
    { label: '1 acre', value: 43560 * 1 },
    { label: '2 acre', value: 43560 * 2 },
    { label: '3 acre', value: 43560 * 3 },
    { label: '5 acre', value: 43560 * 5 },
    { label: '10 acre', value: 43560 * 10 },
    { label: '20 acre', value: 43560 * 20 },
    { label: '50 acre', value: 43560 * 50 },
    { label: '100 acre', value: 43560 * 100 },
  ];

  static minLastSaleAndEstimatedPriceOptions = [
    { label: '50K', value: 50000 },
    { label: '100K', value: 100000 },
    { label: '150K', value: 150000 },
    { label: '200K', value: 200000 },
    { label: '250K', value: 250000 },
    { label: '300K', value: 300000 },
    { label: '350K', value: 350000 },
    { label: '400K', value: 400000 },
    { label: '450K', value: 450000 },
    { label: '500K', value: 500000 },
    { label: '600K', value: 600000 },
    { label: '700K', value: 700000 },
    { label: '800K', value: 800000 },
    { label: '900K', value: 900000 },
    { label: '1M', value: 1000000 },
    { label: '1.5M', value: 1500000 },
    { label: '2M', value: 2000000 },
    { label: '2.5M', value: 2500000 },
  ];
  static maxLastSaleAndEstimatedPriceOptions = [
    { label: '100K', value: 100000 },
    { label: '150K', value: 150000 },
    { label: '200K', value: 200000 },
    { label: '250K', value: 250000 },
    { label: '300K', value: 300000 },
    { label: '350K', value: 350000 },
    { label: '400K', value: 400000 },
    { label: '450K', value: 450000 },
    { label: '500K', value: 500000 },
    { label: '600K', value: 600000 },
    { label: '700K', value: 700000 },
    { label: '800K', value: 800000 },
    { label: '900K', value: 900000 },
    { label: '1M', value: 1000000 },
    { label: '1.5M', value: 1500000 },
    { label: '2M', value: 2000000 },
    { label: '2.5M', value: 2500000 },
    { label: '3M', value: 3000000 },
  ];
  static minRentValueOptions = [
    { label: '500', value: 500 },
    { label: '750', value: 750 },
    { label: '1,000', value: 1000 },
    { label: '1,250', value: 1250 },
    { label: '1,500', value: 1500 },
    { label: '1,750', value: 1750 },
    { label: '2,000', value: 2000 },
    { label: '2,500', value: 2500 },
    { label: '3,000', value: 3000 },
    { label: '4,000', value: 4000 },
  ];
  static maxRentValueOptions = [
    { label: '750', value: 750 },
    { label: '1,000', value: 1000 },
    { label: '1,250', value: 1250 },
    { label: '1,500', value: 1500 },
    { label: '1,750', value: 1750 },
    { label: '2,000', value: 2000 },
    { label: '2,500', value: 2500 },
    { label: '3,000', value: 3000 },
    { label: '4,000', value: 4000 },
    { label: '5,000', value: 5000 },
  ];

  static parkingSpaceOptions = [
    { label: '1+', value: 1 },
    { label: '2+', value: 2 },
    { label: '3+', value: 3 },
    { label: '4+', value: 4 },
    { label: '5+', value: 5 },
  ];

  static bedBathToggleValues = [
    { label: '1+', value: 1 },
    { label: '2+', value: 2 },
    { label: '3+', value: 3 },
    { label: '4+', value: 4 },
    { label: '5+', value: 5 },
  ];

  static HomeTypeArray = [
    {
      label: 'Single Family Residence',
      value: 'single',
      icon: 'SingleFamilyResidence.svg',
    },
    {
      label: 'Condominium',
      value: 'condo',
      icon: 'Condominium.svg',
    },

    {
      label: 'Mobile Home',
      value: 'mobile',
      icon: 'MobileHome.svg',
    },
    {
      label: 'Quadruplex',
      value: 'quad',
      icon: 'Quadruplex.svg',
    },
    {
      label: 'Townhouse',
      value: 'town',
      icon: 'Townhouse.svg',
    },

    {
      label: 'Manufactured Home',
      value: 'mfd',
      icon: 'ManufacturedHome.svg',
    },
    {
      label: 'Multi Family > 4',
      value: 'multiLarge',
      icon: 'MultiFamily4.svg',
    },
    {
      label: 'MultiFamily',
      value: 'multi',
      icon: 'MultiFamily.svg',
    },
    {
      label: 'Manufactured on Land',
      value: 'landMfd',
      icon: 'ManufacturedLand.svg',
    },
    {
      label: 'Apartment',
      value: 'apt',
      icon: 'Apartment.svg',
    },

    // multiLarge = 'multiLarge',
  ];

  static bedBathDropdownValues = [1, 2, 3, 4, 5, 6];
  static OwnerShipDropdownValues = [
    {
      label: 'Individual ',
      value: false,
    },
    {
      label: 'Corporation',
      value: true,
    },
  ];
  static OccupancyDropdownValues = [
    {
      label: 'Owner Occupied',
      value: true,
    },
    {
      label: 'Not Owner Occupied',
      value: false,
    },
  ];
  static soldWithinOptions = [
    { label: '1 day', value: 1 },
    { label: '1 week', value: 7 },
    { label: '2 weeks', value: 14 },
    { label: '1 month', value: 30 },
    { label: '3 months', value: 91 },
    { label: '6 months', value: 182 },
    { label: '1 year', value: 365 },
    { label: '2 years', value: 730 },
    { label: '3 years', value: 1095 },
  ];
  static MortgageRateType = [
    { label: 'Adjustable', value: 'adjustable' },
    { label: 'Fixed', value: 'fixed' },
    { label: 'Multiple', value: 'multiple' },
    { label: 'Other', value: 'other' },
  ];
}

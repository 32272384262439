import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-msg',
  templateUrl: './warning-msg.component.html',
  styleUrls: ['./warning-msg.component.scss'],
})
export class WarningMsgComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private dialogRef: MatDialogRef<WarningMsgComponent>) {}
  ngOnInit() {}

  onClick(val): void {
    this.dialogRef.close(val);
  }
}

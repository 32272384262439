import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { ProspektrApi } from 'src/app/_shared/api/prospektr.api';
import { TermsPolicyDirective } from 'src/app/_shared/directive/terms-policy.directive';
import { ToNumberPipe } from 'src/app/_shared/pipe/to-number.pipe';
import { UserState } from 'src/app/_shared/state/user.state';
import { AppState } from 'src/app/app.state';
import { SignupService } from 'src/app/signup/signup.service';
import { AgentContactState } from 'src/app/workspace/state/agent-contact.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import constants from 'src/constant.json';

interface DialogContent {
  proceedAction?: any;
  proceedCancelAction?: any;
  loanApplicationUrlRequestData?: any;
}
interface CompanyPolicy {
  content: string;
  title: string;
  type: string;
}

@Component({
  selector: 'app-loan-application-url-request',
  templateUrl: './loan-application-url-request.component.html',
  styleUrls: ['./loan-application-url-request.component.scss'],
})
export class LoanApplicationUrlRequestComponent implements OnInit, OnDestroy {
  @ViewChild('ctaPolicy') ctaPolicy: ElementRef;
  @ViewChildren(TermsPolicyDirective) termsAndPolicyDir;

  loanApplicationUrlRequestForm: FormGroup;
  loanApplicationUrlRequestInProgress: boolean;
  enableSubmit = true;
  type: string;
  content: string;
  policy: Partial<CompanyPolicy> = {};
  checkBox: boolean;
  offerRequestForm: FormGroup;
  initial: boolean = true;
  termsAndConditions: Partial<CompanyPolicy> = {};
  makeOfferInProgress: boolean;
  companyPolicy: [];
  policyAgreement: Partial<CompanyPolicy> = {};
  contactAgentRequestActionStatusSub$ = new Subscription();
  onDestroyNotifier$ = new Subject();
  showPassword: boolean = false;
  propertyListingPrice: any;
  downPaymentList: any = [];
  propertyAddress: any;
  userProfile: any;

  constructor(
    public dialogRef: MatDialogRef<LoanApplicationUrlRequestComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogContent,
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private toNumberPipe: ToNumberPipe,
    private agentContactState: AgentContactState,
    public appState: AppState,
    private propertyState: PropertyState,
    private signupService: SignupService,
    private userState: UserState,
    private prospektrApi: ProspektrApi
  ) {}

  ngAfterViewInit() {
    if (this.policyAgreement.content != null) {
      this.renderer.setProperty(this.ctaPolicy.nativeElement, 'innerHTML', this.policyAgreement.content);
      this.renderer.listen(this.ctaPolicy.nativeElement.querySelector('#terms'), 'click', (event) => {
        this.termsAndPolicyDir.first.triggerPolicyView('terms');
      });
      this.renderer.listen(this.ctaPolicy.nativeElement.querySelector('#policy'), 'click', (event) => {
        this.termsAndPolicyDir.first.triggerPolicyView('policy');
      });
    }
  }

  ngOnInit(): void {
    this.propertyState.activePropertyDetails$.subscribe((res: any) => {
      this.propertyListingPrice = res.basic?.Lst_ListPrice;
      this.propertyAddress = res.basic?.Address;
    });
    this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res) this.userProfile = res;
      this.userProfile = Object.assign({}, this.userProfile?.user_profile);
    });
    for (let i = 0.1; i <= 0.55; i = i + 0.05) {
      this.downPaymentList.push({
        min: Math.floor(i * this.propertyListingPrice),
        max: Math.floor(this.propertyListingPrice * (i + 0.05)),
      });
    }
    this.loanApplicationUrlRequestForm = this.formBuilder.group({
      firstName: [
        { value: this.userProfile?.firstName || '', disabled: false },
        [Validators.required, Validators.pattern('[a-zA-Z ]*')],
      ],
      lastName: [
        { value: this.userProfile?.lastName || '', disabled: false },
        [Validators.required, Validators.pattern('[a-zA-Z ]*')],
      ],
      email: [
        { value: this.userProfile?.email || '', disabled: false },
        [Validators.required, Validators.pattern(constants.regex.validEmail)],
      ],
      phone: [
        { value: this.userProfile?.contact || '', disabled: false },
        [
          Validators.required,
          Validators.pattern('^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'),
        ],
      ],
      estimatedCreditScore: [{ value: this.userProfile?.estimatedCreditScore || '', disabled: false }],
      downPayment: [''],
    });
    this.agentContactState.loanApplicationUrlRequestFormStatus$
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        this.loanApplicationUrlRequestInProgress = res;
      });
    this.appState.companyInfo$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      if (res) {
        this.companyPolicy = res['companyPolicy'];
        this.companyPolicy.forEach((data: any) => {
          if (data['type'] === 'ctaPolicy') {
            this.policyAgreement.content = data.content;
            this.checkBox = true;
          } else {
            this.checkBox = false;
          }
        });
      }
    });
  }

  get loanApplicationUrlRequestFormControls() {
    return this.loanApplicationUrlRequestForm.controls;
  }

  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  requestLoanApplicationUrl() {
    if (this.loanApplicationUrlRequestForm.invalid) return;
    // if (!this.appState.authTokenValue?.idToken && this.loanApplicationUrlRequestForm.invalid) return;
    let signUpParams = {
      email: this.loanApplicationUrlRequestForm.value.email.toLowerCase(),
      loanOfficerMailId: this.agentContactState.propertyClientDataValue['loanOfficer'].email,
      propertyAddress: this.propertyAddress,
      firstName: this.loanApplicationUrlRequestForm.value.firstName,
      lastName: this.loanApplicationUrlRequestForm.value.lastName,
      phone: this.loanApplicationUrlRequestForm.value.phone,
      downPayment: this.loanApplicationUrlRequestForm.value.downPayment
        ? this.downPaymentList[this.loanApplicationUrlRequestForm.value.downPayment]
        : '',
      estimatedCreditScore: this.loanApplicationUrlRequestForm.value.estimatedCreditScore,
      role: 'employee',
      userId: this.agentContactState.propertyClientDataValue['agent'].shortId,
      domain: this.appState.appBaseUrlValue,
      interestedAreas: [
        {
          locality: this.propertyState.activePropertyDetailsValue['basic'].City,
          state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
        },
      ],
      createdBy: 'system',
      source: constants.SIGN_UP.source.getPreApprovedCta,
    };
    if (!this.appState.authTokenValue?.idToken) {
      this.enableSubmit = false;
      this.data.loanApplicationUrlRequestData = this.loanApplicationUrlRequestForm.value;
      this.agentContactState.loanApplicationUrlRequestFormStatusValue = true;
      this.signupService
        .createUser(signUpParams)
        .pipe(
          takeUntil(this.onDestroyNotifier$),
          map(() => {
            this.data.proceedAction(this.data.loanApplicationUrlRequestData, () => {});
            this.enableSubmit = true;
          }),
          catchError(() => {
            this.enableSubmit = true;
            this.agentContactState.loanApplicationUrlRequestFormStatusValue = false;
            this.close();
            return EMPTY;
          })
        )
        .subscribe();
    } else {
      this.enableSubmit = false;
      this.prospektrApi
        .preapprovalAction(signUpParams)
        .pipe(
          takeUntil(this.onDestroyNotifier$),
          map(() => {
            // this.loanApplicationUrlRequestInProgress = res;
            this.data.proceedAction(this.data.loanApplicationUrlRequestData, () => {});
            this.enableSubmit = true;
          }),
          catchError(() => {
            this.enableSubmit = true;
            this.agentContactState.loanApplicationUrlRequestFormStatusValue = false;
            this.close();
            return EMPTY;
          })
        )
        .subscribe();
      // this.prospektrApi
      //   .preapprovalAction(signUpParams)
      //   .pipe(takeUntil(this.onDestroyNotifier$))
      //   .subscribe((res) => {
      //     console.log(res);
      //     this.loanApplicationUrlRequestInProgress = res;
      //   });
    }
  }

  close() {
    this.dialogRef.close();
  }

  phoneValidator = (control) => {
    if (control.value != '') {
      const formatedValue = this.toNumberPipe.transform(control.value);
      if (formatedValue.toString().length == 10 || formatedValue.toString().length == 11) {
        return null;
      } else return { phoneNumberInvalid: true };
    }
  };
}

import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { ProgressIndicatorComponent } from '../component/progress-indicator/progress-indicator.component';

@Directive({
  selector: 'button[pmx-progress-button]',
})
export class PmxProgressButtonDirective implements OnInit, OnChanges {
  @Input('pmx-progress-button') pmxBtnProgress: boolean;

  componentFactory: ComponentFactory<ProgressIndicatorComponent>;
  componentRef: ComponentRef<ProgressIndicatorComponent>;
  host: HTMLElement;
  newProgressElement: HTMLElement;
  btnWrapper: HTMLElement;

  constructor(
    private element: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.host = this.element.nativeElement;
  }

  ngOnInit(): void {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(ProgressIndicatorComponent);
    this.btnWrapper = this.host.querySelector('.mat-button-wrapper');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pmxBtnProgress.currentValue === true && this.btnWrapper) {
      this.componentRef = this.viewContainerRef.createComponent(this.componentFactory);
      this.componentRef.instance.size = 20;
      this.componentRef.instance.transparency = 0.8;
      this.newProgressElement = this.componentRef?.location.nativeElement;
      this.host.setAttribute('disabled', '');
      this.btnWrapper.style.visibility = 'hidden';
      this.host.insertBefore(this.newProgressElement, this.host.firstChild);
    } else if (changes.pmxBtnProgress.currentValue === false && this.newProgressElement && this.btnWrapper) {
      this.componentRef?.destroy();
      this.btnWrapper.style.visibility = 'visible';
      this.host.removeAttribute('disabled');
    }
  }
}

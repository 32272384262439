<div class="body_wrap dashboard_body">
  <div class="sticky-top sticky-offset">
    <div class="top_menu">
      <button mat-button class="d-md-none d-none"><img src="../../assets/images/menu.svg" /></button>
      <app-nav></app-nav>
    </div>
  </div>
  <div class="dash_base">
    <div class="container-fluid mt-40">
      <div class="container">
        <div class="paymentWrap">
          <div class="w-50 mw-100 m-auto whiteWrap">
            <div class="text-center mb-4 badgeWrap">
              <img src="../../../assets/images/alert-red.svg" width="80" />
              <h3 class="mt-2 text-danger">Payment Failed!</h3>
              <p class="text-secondary mt-2">
                Something went wrong during your payment process!<br />
                Please try again after some time.
              </p>
            </div>
            <div class="w-100 text-center">
              <button color="primary" mat-flat-button class="ml-2" (click)="goToSubscriptionPlan()">
                Choose your plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { ContactState } from 'src/app/_shared/state/contact.state';
import MESSAGE from 'src/assets/resource/strings.json';
import constants from 'src/constant.json';
import { ConfirmDialogComponent } from '../../../shared/modules/alerts/confirm-dialog/confirm-dialog.component';
import { ContactService } from '../../service/contact.service';

@Component({
  selector: 'app-contact-table',
  templateUrl: './contact-table.component.html',
  styleUrls: ['./contact-table.component.scss'],
})
export class ContactTableComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('contactGroupPaginator', { static: false }) set contactGroupPaginator(paginator: MatPaginator) {
    this.contactGroupDataSource.paginator = paginator;
  }

  @ViewChild('contactPaginator', { static: false }) set contactPaginator(paginator: MatPaginator) {
    this.contactDataSource.paginator = paginator;
  }

  @ViewChild('addNewFile') addNewFile: any;
  @ViewChild('file') file;

  @Input() csvFileColumnList;
  @Input() csvRecordColumnList;
  @Input() filter;
  @Input() externalSelectedId = [];

  @Output() hideOptionsEvent = new EventEmitter<any>();
  @Output() selectedRecordsEvent = new EventEmitter<any>();
  champaignType: string;
  contactGroupDataSource = new MatTableDataSource([]);
  contactDataSource = new MatTableDataSource([]);
  csvFileListCount = 0;
  csvRecordListCount = 0;
  pageIndex = 0;
  pageSize = 10;
  selection = new SelectionModel<any>(true, []);
  selectedRecordIdList = [];
  isDataLoading: boolean = false;
  searchFormCtrl: FormControl = new FormControl();
  stringRes: any = MESSAGE;
  filterResponseData: any;
  isContactFileListVisible = true;
  uniqueRecord = 0;
  selectFileInfo = {
    id: null,
    name: '',
  };
  constant: any = constants;
  onDestroyNotifier$ = new Subject();

  constructor(
    private contactState: ContactState,
    private contactService: ContactService,
    private authorizeService: AuthorizeService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.contactService
      .getContactGroupList()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(({ isLoading, data }) => {
        this.isDataLoading = isLoading;
      });
    this.listenToSearch();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.externalSelectedId?.length) {
      this.selectedRecordIdList = this.externalSelectedId;
    }
    this.contactState.contactGroupList$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((response) => {
      let filterResponse = this.filterContactGroups(response, this.filter);
      this.contactGroupDataSource.data = filterResponse;
      this.csvFileListCount = response?.length;
    });
    this.contactState.contactList$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((response) => {
      this.filterResponseData = this.filterContacts(response, this.filter);
      this.contactDataSource.data = this.filterResponseData;
      this.csvRecordListCount = response?.length;
      this.preserveSelected();
    });
    if (this.filter == 'contact') this.champaignType = 'Below are contacts wIth verified email ID';
    if (this.filter == 'address-contact') this.champaignType = 'Below are contacts wIth verified property & email ID';
  }

  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  preserveSelected() {
    this.contactDataSource.data.forEach((row: any) => {
      if (
        this.selectedRecordIdList.includes(row._id) ||
        (this.externalSelectedId && this.externalSelectedId.includes(row._id))
      ) {
        this.selection.select(row);
      }
    });
  }

  toggleSelect(row, checked) {
    const selectedIdSet = new Set(this.selectedRecordIdList);
    if (checked) {
      selectedIdSet.add(row);
    } else {
      for (const item of selectedIdSet.values()) {
        if (item._id == row._id) selectedIdSet.delete(item);
      }
    }
    this.selectedRecordIdList = [...selectedIdSet];
    this.externalSelectedId = [...selectedIdSet];
    this.selectedRecordsEvent.emit([...selectedIdSet]);
    if (this.selectedRecordIdList.length > 1) {
      this.uniqueRecordCount();
    }
  }

  isAllSelected() {
    return this.selectedRecordIdList.length === this.filterResponseData.length;
  }

  masterToggle(event) {
    const selectedIdSet = new Set(this.selectedRecordIdList);
    if (event.checked) {
      this.contactDataSource.data.forEach((row) => this.selection.select(row));
      this.filterResponseData.forEach((record) => selectedIdSet.add(record));
    } else {
      this.contactDataSource.data.forEach((row) => {
        this.selection.deselect(row);
        for (const item of selectedIdSet.values()) {
          if (item._id == row._id) console.log(row._id, 'dddddddd', item._id);
          selectedIdSet.delete(item);
        }
      });
    }
    this.selectedRecordIdList = [...selectedIdSet];
    this.externalSelectedId = [...selectedIdSet];
    this.selectedRecordsEvent.emit([...selectedIdSet]);
    this.uniqueRecordCount();
  }

  uniqueRecordCount() {
    let records = this.selectedRecordIdList;
    this.uniqueRecord = 0;
    if (this.filter == 'address') {
      let uniqueRecords = [...new Map(records?.map((item) => [item['pmxPropertyId'], item])).values()];
      this.uniqueRecord = uniqueRecords.length;
    }
    if (this.filter == 'contact') {
      let uniqueRecords = [...new Map(records?.map((item) => [item['email'], item])).values()];
      this.uniqueRecord = uniqueRecords.length;
    }
    if (this.filter == 'address-contact') {
      let uniqueRecords = [...new Map(records?.map((item) => [item['email']] && [item['contact'], item])).values()];
      this.uniqueRecord = uniqueRecords.length;
    }
  }

  getContactFileList() {
    this.contactService
      .getContactFileList()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res: any) => {
        this.isDataLoading = res.isLoading;
      });
  }

  getContactList() {
    this.contactService
      .getContactRecordList(this.selectFileInfo.id)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res: any) => {
        this.isDataLoading = res.isLoading;
      });
  }

  goBack() {
    this.hideOptionsEvent.emit({ isCSVFileVisible: true });
    this.isContactFileListVisible = true;
    this.selectFileInfo = {
      id: null,
      name: '',
    };
  }

  open(row) {
    if (row.status == 'pending' || row.isBeingDeleted) return;
    this.hideOptionsEvent.emit({ isCSVFileVisible: false });
    this.isContactFileListVisible = false;
    this.selectFileInfo.name = row.name;
    this.selectFileInfo.id = row._id;
    this.getContactList();
  }

  deleteFile(id: string) {
    if (!this.authorizeService.canAct('favoriteEdit')) {
      return false;
    }
    const _this = this;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '385px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        type: 'deleteGroup',
        message: 'Are you sure you want to delete?',
        title: '',
        proceedAction(status, callBackFunction) {
          const contactDeleteIndex = _this.contactGroupDataSource.data.findIndex(
            (contactGroup) => contactGroup._id == id
          );
          if (contactDeleteIndex >= 0) {
            _this.contactGroupDataSource.data[contactDeleteIndex].isBeingDeleted = true;
          }
          if (status) {
            _this.contactService.deleteAddressCSV(id).subscribe((res: any) => {
              if (res.response) {
                _this.getContactFileList();
              }
              dialogRef.close();
            });
          } else {
            return false;
          }
        },
        proceedCancelAction(cancelCallBack) {},
      },
    });
  }

  listenToSearch() {
    this.searchFormCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((searchValue) => {
      if (this.isContactFileListVisible)
        return (this.contactGroupDataSource.filter = searchValue?.trim()?.toLowerCase());

      return (this.contactDataSource.filter = searchValue?.trim()?.toLowerCase());
    });
  }

  filterContacts(response, filter) {
    if (response) {
      switch (filter) {
        case 'address':
          return response.filter((record) => {
            return record.hasValidAddress == true;
          });

        case 'contact':
          return response.filter((record) => {
            return record.hasValidEmail == true;
          });

        case 'address-contact':
          return response.filter((record) => {
            return record.hasValidEmail == true && record.hasValidAddress == true;
          });

        default:
          return response;
      }
    }
    return [];
  }

  filterContactGroups(response, filter) {
    if (response) {
      switch (filter) {
        case 'address':
          return response.filter((record) => {
            return record.validAddressCount > 0;
          });

        case 'contact':
          return response.filter((record) => {
            return record.validEmailCount > 0;
          });

        case 'address-contact':
          return response.filter((record) => {
            return record.validEmailCount > 0 && record.validAddressCount > 0;
          });

        default:
          return response;
      }
    }
    return [];
  }
}

<footer class="rp_footerwrap" *ngIf="appState.companyInfo$ | async as companyInfo">
  <div class="rp_footer_sec1">
    <p class="text-justify mb-0">
      RubyPorch.com was founded in 2018 by a group of online real estate veterans in order to streamline the home buying
      and selling process in order to make it easy, digital and cost effective. The company is focused exclusively on
      helping Americans find affordable homes to buy and helping Americans save money when it's time to sell.
    </p>
  </div>
  <div class="rp_footer_sec2">
    <div class="row">
      <div class="col-lg-9 col-12">
        <ul>
          <li>
            <a [href]="companyInfo?.siteUrls?.sell.link" [target]="'_blank'">{{
              companyInfo?.siteUrls?.sell.displayText
            }}</a>
          </li>
          <li>
            <a [routerLink]="companyInfo?.siteUrls?.buy.link"> {{ companyInfo?.siteUrls?.buy.displayText }}</a>
          </li>
          <li>
            <a [href]="companyInfo?.siteUrls?.loan.link" [target]="'_blank'">{{
              companyInfo?.siteUrls?.loan.displayText
            }}</a>
          </li>
          <li>
            <a [href]="companyInfo?.siteUrls?.agent.link" [target]="'_blank'">{{
              companyInfo?.siteUrls?.agent.displayText
            }}</a>
          </li>
          <li>
            <a [href]="companyInfo?.siteUrls?.loanOfficer.link" [target]="'_blank'">{{
              companyInfo?.siteUrls?.loanOfficer.displayText
            }}</a>
          </li>
          <li>
            <a [href]="companyInfo?.siteUrls?.about.link" [target]="'_blank'">{{
              companyInfo?.siteUrls?.about.displayText
            }}</a>
          </li>
          <li>
            <a [href]="companyInfo?.siteUrls?.help.link" [target]="'_blank'">{{
              companyInfo?.siteUrls?.help.displayText
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col-lg-3 col-12 text-center">
        <a [href]="companyInfo?.siteUrls?.home.link"
          ><img [src]="companyInfo?.logo?.dark" class="img-fluid" width="120px"
        /></a>
      </div>
    </div>
    <div class="row mt-4 rp_footer_policy">
      <div class="col-lg-6 text-left">
        <span>© {{ currentYear }} RubyPorch.com LLC. All Rights Reserved.</span>
      </div>
      <div class="col-lg-6 d-flex justify-content-md-center justify-content-lg-end">
        <ul>
          <li>
            <a [href]="companyInfo?.siteUrls?.policy.link" [target]="'_blank'">{{
              companyInfo?.siteUrls?.policy.displayText
            }}</a>
          </li>
          <li>
            <a [href]="companyInfo?.siteUrls?.terms.link" [target]="'_blank'">{{
              companyInfo?.siteUrls?.terms.displayText
            }}</a>
          </li>
        </ul>
      </div>
      <div class="col-12 mt-2 pt-2">
        <span>
          Continental Real Estate Group, Inc. and Pleasant Realty, Inc. hold real estate brokerage licenses in multiple
          states. A list of our real estate licenses is available
          <a [target]="'_blank'" href="http://sell.rubyporch.com/real-estate-licenses/">here</a>
        </span>
      </div>
    </div>
  </div>
</footer>

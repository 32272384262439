import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityLoggerService } from 'src/app/_shared/service/activity-logger.service';
import { FavoriteService } from 'src/app/_shared/service/favorite.service';
import { DealAnalysisState } from 'src/app/_shared/state/deal-analysis.state';
import { FavoriteState } from 'src/app/_shared/state/favorite.state';
import { FiltersState } from 'src/app/_shared/state/filter.state';
import { WorkspaceState } from 'src/app/_shared/state/workspace.state';
import { WorkspaceService } from 'src/app/workspace/service/workspace.service';
import { MapState } from 'src/app/workspace/state/map.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import constant from 'src/constant.json';

@Injectable({
  providedIn: 'root',
})
export class PropertySheetFacade {
  constructor(
    private workspaceState: WorkspaceState,
    private propertyState: PropertyState,
    private workspaceService: WorkspaceService,
    private favoriteState: FavoriteState,
    private favoriteService: FavoriteService,
    private filtersState: FiltersState,
    private dealAnalysisState: DealAnalysisState,
    private mapState: MapState,
    private activityLoggerService: ActivityLoggerService
  ) {}

  getActiveProperty$(): Observable<any> {
    return this.propertyState.activeProperty$;
  }

  getActivePropertyDetails$(): Observable<any> {
    return this.propertyState.activePropertyDetails$;
  }

  getActiveProperty(): object {
    return this.propertyState.activePropertyValue;
  }

  // getActiveContactInfo$(): Observable<any> {
  //   return this.propertyState.activeContactInfo$;
  // }

  getPropertyDetailsSheetLoaderStatus(): Observable<any> {
    return this.workspaceState.propertyDetailsSheetLoader$;
  }

  getContactInfoLoaderStatus$(): Observable<any> {
    return this.propertyState.contactInfoLoader$;
  }

  setContactInfoLoaderStatus(status) {
    this.propertyState.contactInfoLoaderValue = status;
  }

  setSchoolInformationForProperty(property) {
    this.propertyState.schoolsForPropertyLoaderValue = true;
    this.propertyState.schoolsForPropertyParamValue = {
      StreetAddress: property.StreetAddress,
      City: property.City,
      StateOrProvince: property.StateOrProvince,
      PostalCode: property.PostalCode,
    };
  }

  getActiveSchoolDistrictsForProperty$(): Observable<any> {
    return this.propertyState.activeSchoolDistrictsForProperty$;
  }

  getActiveSchoolsForProperty$(): Observable<any> {
    return this.propertyState.activeSchoolsForProperty$;
  }

  getSchoolsForPropertyLoaderStatus$(): Observable<any> {
    return this.propertyState.schoolsForPropertyLoader$;
  }

  setSchoolsForPropertyLoaderStatus(status) {
    this.propertyState.schoolsForPropertyLoaderValue = status;
  }

  getActiveMultiplePropertyDetails$(): Observable<any> {
    return this.propertyState.activeMultiplePropertyDetails$;
  }

  openPropertyDetailsSheet(property) {
    this.workspaceService.openPropertyDetailsSheet(property);
  }

  setPropertyDetailsSheetLoaderStatus(status) {
    this.workspaceState.propertyDetailsSheetLoaderValue = status;
  }

  openDealAnalysisSheet(propertyDetails) {
    this.dealAnalysisState.openDealAnalysisSheetValue = true;
    let loggerParams = {
      category: constant.LOGGER.dealAnalysis.category,
      action: constant.LOGGER.dealAnalysis.action.init.key,
    };
    let inputData = {
      PMXPropertyId: propertyDetails.PMXPropertyId,
      address: {
        street: propertyDetails.StreetAddress,
        city: propertyDetails.City,
        state: propertyDetails.StateOrProvince,
        zip: propertyDetails.PostalCode,
      },
      fullAddress: propertyDetails.Address,
    };
    this.activityLoggerService.logActivity(loggerParams, inputData);
  }

  get defaultFollowGroup() {
    const followGroups = this.favoriteState.followGroupListValue;
    if (followGroups) {
      const defaultGroup = followGroups.find((el) => el.mode === 'Default');
      return defaultGroup && defaultGroup.id ? [defaultGroup.id] : null;
    } else {
      return null;
    }
  }

  addFavorite(input) {
    const inputParams = {
      PMXPropertyIdList: [input.PMXPropertyId],
      GroupIds: this.defaultFollowGroup,
      stateList: [input.state],
    };
    return new Observable((observer) => {
      this.favoriteService.addFavorites(inputParams).subscribe((response: any) => {
        observer.next(response);
      });
    });
  }

  unFollowFavorite(inputParams) {
    return new Observable((observer) => {
      this.favoriteService.unFollowFavorite(inputParams).subscribe((response: any) => {
        observer.next(response);
      });
    });
  }

  // setInitiateSubjectPropertyStatus(status) {
  //   this.workspaceState.initiateSubjectPropertyValue = status;
  // }

  getAppliedFilterStatus(): Observable<boolean> {
    return new Observable((observer) => {
      if (this.isEmptyObject(this.filtersState.basicFilterListValue)) {
        observer.next(false);
      } else {
        observer.next(true);
      }
    });
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  setMultiplePropertyDetailsSheetLoaderStatus(status) {
    this.workspaceState.multiplePropertyDetailsSheetLoaderValue = status;
  }

  getMultiplePropertyDetailsSheetLoaderStatus(): Observable<any> {
    return this.workspaceState.multiplePropertyDetailsSheetLoader$;
  }

  getMapZoomValue$(): Observable<number> {
    return this.mapState.mapZoom$;
  }
}

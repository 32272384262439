<!-- <h4 class="mb-3">Payment Calculator</h4> -->
<div class="row mb-3">
  <div class="col-lg-6 col-md-12 col-12 pie_wrap">
    <app-pie-chart [pieChartConfig]="chartConfig" [data]="chartData"></app-pie-chart>
  </div>
  <div class="col-lg-6 col-md-12 col-12 extra-summary">
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-8 col-8 px-md-3 px-lg-0">Principal & Interest</div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-4 px-md-3 px-lg-0 font-weight-bold">
        $ {{ mortgagePaymentMonthly | number: '1.0-0' }}
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-8 col-8 px-md-3 px-lg-0">Property Taxes</div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-4 px-md-3 px-lg-0 font-weight-bold">
        $ {{ propertyTaxValue | toNumber | number: '1.0-0' }}
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-8 col-8 px-md-3 px-lg-0">Home Insurance</div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-4 px-md-3 px-lg-0 font-weight-bold">
        $ {{ insuranceValue | toNumber | number: '1.0-0' }}
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-8 col-8 px-md-3 px-lg-0">HOA Fees</div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-4 px-md-3 px-lg-0 font-weight-bold">
        $ {{ hoaFeeValue | toNumber | number: '1.0-0' }}
      </div>
    </div>
    <hr class="row separator" />
    <div class="row">
      <div class="col-lg-8 col-md-8 col-sm-8 col-8 px-md-3 px-lg-0 font-weight-bold">Monthly Payment</div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-4 px-md-3 px-lg-0 font-weight-bold">
        $ {{ totalPaymentMonthly | toNumber | number: '1.0-0' }}
      </div>
    </div>
    <div class="row pull-right edit-summary">
      <button class="myHedit_btn" mat-button color="primary" [matMenuTriggerFor]="editSummaryMenu">
        <mat-icon inline color="primary">edit</mat-icon>
      </button>
    </div>
  </div>
</div>

<div class="form-full">
  <form [formGroup]="paymentForm">
    <div class="row mt-4">
      <div class="col-lg-6 col-md-12 col-6">
        <mat-form-field>
          <mat-label>Home Value</mat-label>
          <input type="text" matInput placeholder="$100" autocomplete="off" currencyMask formControlName="homePrice" />
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12 col-6">
        <div class="row">
          <div class="col-8 pr-0">
            <mat-form-field>
              <mat-label>Down Payment($)</mat-label>
              <input
                type="text"
                matInput
                placeholder="$100"
                autocomplete="off"
                currencyMask
                formControlName="downPayment"
              />
            </mat-form-field>
          </div>
          <div class="col-4 pl-1">
            <mat-form-field>
              <mat-label>(%)</mat-label>
              <input
                type="number"
                matInput
                placeholder="20%"
                autocomplete="off"
                formControlName="downPaymentRate"
                style="text-align: right"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-6 col-md-12 col-6">
        <mat-form-field>
          <mat-label>Mortgage Type</mat-label>
          <mat-select formControlName="mortgageType">
            <mat-option *ngFor="let mortgageType of mortgageTypes" [value]="mortgageType.value">
              {{ mortgageType.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-12 col-6">
        <mat-form-field>
          <mat-label>Interest Rate</mat-label>
          <input type="text" matInput placeholder="2.8%" autocomplete="off" formControlName="interestRate" />
          <!-- <mat-hint align="end" class="menu-link" [matMenuTriggerFor]="creditScoreMenu">Check Rates</mat-hint> -->
        </mat-form-field>
      </div>
    </div>
    <div class="text-right mt-2 mb-2">
      <!-- <button mat-flat-button type="button" color="primary"
        (click)="Window.open(appState.companyInfoValue && appState.companyInfoValue.externalURL && appState.companyInfoValue.externalURL.lenderAppUrl)">Get
        Pre-Approved</button> -->
      <button
        mat-flat-button
        type="button"
        color="primary"
        (click)="preApprovedAction()"
        [pmx-progress-button]="loanApplicationUrlRequestInProgress"
      >
        Get Pre-Approved
      </button>
    </div>
  </form>
</div>

<!-- SUMMARY EDIT MENU TEMPLATE -->
<mat-menu class="edit-payment" #editSummaryMenu="matMenu" overlapTrigger="true" backdropClass="payment-calc-backdrop">
  <div class="container">
    <div class="row close-icon" style="padding-left: 230px; padding-bottom: 0px; padding-top: 5px">
      <button class="pull-right close-summary px-0 mx-auto" style="min-width: unset" mat-button>
        <mat-icon inline>close</mat-icon>
      </button>
    </div>
    <div class="clearfix"></div>
    <div class="row">
      <div class="pay-summary-container" style="padding-top: 0px" (click)="$event.stopPropagation()">
        <form class="edit-payment" [formGroup]="paymentForm" (keydown.tab)="$event.stopPropagation()">
          <div class="row edit-payment">
            <div class="col-sm-8 col-12 mb-2">
              <mat-form-field>
                <mat-label>Property Taxes($)</mat-label>
                <input
                  type="text"
                  matInput
                  placeholder="$100"
                  autocomplete="off"
                  currencyMask
                  formControlName="propertyTax"
                />
                <mat-icon matPrefix inline class="color-2">circle</mat-icon>
                <span matSuffix>/month</span>
              </mat-form-field>
            </div>
            <div class="col-sm-4 col-12 mb-2">
              <mat-form-field>
                <mat-label>(%)</mat-label>
                <input type="number" matInput placeholder="20%" autocomplete="off" formControlName="propertyTaxRate" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-8 col-12 mb-2">
              <mat-form-field>
                <mat-label>Home Insurance($)</mat-label>
                <input
                  type="text"
                  matInput
                  placeholder="$100"
                  autocomplete="off"
                  currencyMask
                  formControlName="insurance"
                />
                <mat-icon matPrefix inline class="color-3">circle</mat-icon>
                <span matSuffix>/month</span>
              </mat-form-field>
            </div>
            <div class="col-sm-4 col-12 mb-2">
              <mat-form-field>
                <mat-label>(%)</mat-label>
                <input type="number" matInput placeholder="20%" autocomplete="off" formControlName="insuranceRate" />
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-8 col-12 mb-2">
              <mat-form-field>
                <mat-label>HOA Fee($)</mat-label>
                <input
                  type="text"
                  matInput
                  placeholder="$100"
                  autocomplete="off"
                  currencyMask
                  formControlName="hoaFee"
                />
                <mat-icon matPrefix inline class="color-4">circle</mat-icon>
                <span matSuffix>/month</span>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</mat-menu>

<!-- CREDIT SCORE MENU TEMPLATE -->
<mat-menu #creditScoreMenu="matMenu" overlapTrigger="true" backdropClass="credit-score-backdrop">
  <div class="credit-score-container" (click)="$event.stopPropagation()">
    <p class="mb-2"><strong>Credit Score</strong></p>
    <div class="btn-group btn-group-sm btn-group-toggle w-100" role="group" data-toggle="buttons">
      <button type="button" class="btn btn-outline-primary active">Excellent</button>
      <button type="button" class="btn btn-outline-primary">Good</button>
      <button type="button" class="btn btn-outline-primary">Fair</button>
      <button type="button" class="btn btn-outline-primary">Poor</button>
    </div>
    <div class="row score-range ml-3">
      <div class="col-3 p-0 pl-2">&gt;740</div>
      <div class="col-3 p-0 pl-3">&gt;700</div>
      <div class="col-3 p-0 pl-2">&gt;660</div>
      <div class="col-3 p-0">660&lt;</div>
    </div>
    <p class="text-center mt-3 mb-1">Today's rate in <strong>Hinsdale, IL</strong></p>
    <table>
      <tr>
        <th>Mortgage</th>
        <th>Rate</th>
        <th>Change</th>
      </tr>
      <tr>
        <td>30-year Fixed</td>
        <td>2.789%</td>
        <td>0.00%</td>
      </tr>
      <tr>
        <td>20-year Fixed</td>
        <td>2.789%</td>
        <td>0.00%</td>
      </tr>
      <tr>
        <td>15-year Fixed</td>
        <td>2.789%</td>
        <td>0.00%</td>
      </tr>
      <tr>
        <td>10-year Fixed</td>
        <td>2.789%</td>
        <td>0.00%</td>
      </tr>
    </table>
  </div>
</mat-menu>

import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import constants from 'src/constant.json';

@Directive({
  selector: '[googleAutocomplete]',
})
export class GoogleAutocompleteDirective implements AfterViewInit, OnDestroy {
  @Output() addressComponents = new EventEmitter();

  @Input() set googleAutocomplete(value: '(cities)' | 'geocode' | '(regions)' | '') {
    if (value) {
      this.autocompleteType = value;
    }
  }

  private autocompleteType: '(cities)' | '(regions)' | 'geocode' = 'geocode';
  private constant: any = constants;
  private autocomplete: any;
  private placeChangeListener: any = null;

  constructor(private element: ElementRef) {}

  ngAfterViewInit(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.element.nativeElement, {
      componentRestrictions: { country: 'US' },
      types: [this.autocompleteType], // 'establishment' / 'address' / 'geocode'
    });
    this.placeChangeListener = google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  ngOnDestroy(): void {
    if (this.placeChangeListener) {
      google.maps.event.removeListener(this.placeChangeListener);
    }
  }

  invokeEvent(place: google.maps.places.PlaceResult) {
    if (place && place.address_components) {
      this.getPlaceFromGeocoder(place.formatted_address);
    } else {
      var googleAutoCompleteContainer = document.getElementsByClassName('pac-container');
      if (googleAutoCompleteContainer && googleAutoCompleteContainer[0]) {
        var firstAutoCompleteItem = googleAutoCompleteContainer[0].getElementsByClassName('pac-item');
        if (firstAutoCompleteItem && firstAutoCompleteItem[0]) {
          this.getPlaceFromGeocoder(firstAutoCompleteItem[0].textContent);
        }
      }
    }
  }

  getPlaceFromGeocoder(autoCompleteInput: string) {
    new google.maps.Geocoder().geocode({ address: autoCompleteInput }, (results, status) => {
      if (!results || !results[0]) return this.addressComponents.emit(null);

      let locality: string = null;
      let subPremise: string = null;
      let streetNumber: string = null;
      let city: string = null;
      let state: string = null;
      let zip: string = null;
      results[0].address_components.forEach((result) => {
        if (result.types[0] == 'route') {
          locality = result.short_name;
        } else if (result.types[0] == 'subpremise') {
          subPremise = result.short_name;
        } else if (result.types[0] == 'street_number') {
          streetNumber = result.short_name;
        } else if (result.types[0] == 'locality') {
          city = result.short_name;
        } else if (result.types[0] == 'administrative_area_level_1') {
          state = result.short_name;
        } else if (result.types[0] == 'postal_code') {
          zip = result.short_name;
        }
      });
      let addressObj: any = {
        StreetAddress: streetNumber && locality ? `${streetNumber} ${locality}` : null,
        City: city,
        State: state,
        PostalCode: zip,
        logInput: true,
      };
      if (subPremise) {
        let subPreFix = subPremise.toLowerCase();
        let isPrefixIncluded = this.constant.UNIT_PREFIX.some((prefix) => subPreFix.includes(prefix.toLowerCase()));
        if (isPrefixIncluded == true) {
          addressObj.StreetAddress += ' ' + subPremise;
        } else {
          addressObj.StreetAddress += ' # ' + subPremise;
        }
      }

      addressObj['fullAddress'] = results[0].formatted_address && results[0].formatted_address.replace(/, USA/i, '');
      addressObj['center'] = results[0].geometry.location;

      this.addressComponents.emit(addressObj);
    });
  }
}

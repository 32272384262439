import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import constants from 'src/constant.json';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlanListingService {
  constructor(private http: HttpClient) {}

  getPaymentStatus(): Observable<any> {
    return new Observable((observer) => {
      this.http.get(environment.prospektrBackendBaseURL + constants.API.getPaymentStatus).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  listSubscriptionPlan(): Observable<any> {
    return new Observable((observer) => {
      this.http.get(environment.prospektrBackendBaseURL + constants.API.listSubscriptionPlan).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  getStripePublishableKey(): Observable<any> {
    return new Observable((observer) => {
      this.http.get(environment.prospektrBackendBaseURL + constants.API.getStripePublishableKey).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  getStripeSession(params): Observable<any> {
    return new Observable((observer) => {
      this.http.post(environment.prospektrBackendBaseURL + constants.API.getStripeSession, params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }
}

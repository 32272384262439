import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'IsValueExist',
})
export class IsValueExistPipe implements PipeTransform {
  transform(value: any): any {
    return (
      !Number.isNaN(parseFloat(value))
        ? parseFloat(value) === 0 || parseFloat(value) < 1
          ? true
          : false
        : value === null || value === undefined || value === '' || value === 0
        ? true
        : false
    )
      ? '--'
      : value;
  }
}

<mat-sidenav-container>
  <mat-sidenav class="main row upload_background" #sidenav mode="over" position="end" [opened]="openSheet" disableClose>
    <div class="sliding-sheet-header">
      <h4>Choose or Upload Image</h4>
      <button type="button" (click)="onClose()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="w-100 upload-bg-sheet">
      <!-- <div class="section-in-progress" *ngIf="isDataLoading">
        <mat-spinner [diameter]="30" class="mr-3"></mat-spinner>
        Please wait while we load
      </div> -->
      <mat-tab-group>
        <mat-tab label="Choose Image" *ngIf="imageUrlList.length">
          <br />
          <mat-expansion-panel class="category-section" [expanded]="true" *ngIf="userImage?.length">
            <mat-expansion-panel-header class="pl-0">
              <mat-panel-title><b>Selected Image</b> </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row" *ngIf="imageType == 'bannerImage'">
              <div class="col-lg-4 col-md-4 col-sm-6 col-6">
                <div
                  class="select-img"
                  [style.width.%]="100"
                  [style.paddingBottom.%]="aspectRatio"
                  (click)="selectImage(userImage)"
                  [ngClass]="{ 'selected-exterior-image': userImage === selectedImage }"
                  [style.background-image]="'url(' + userImage + '&height=200' + ')'"
                ></div>
              </div>
            </div>
            <div class="row" *ngIf="imageType == 'profile'">
              <div class="col-lg-3 col-md-3 col-sm-4 col-4">
                <div
                  class="select-profile"
                  [style.width.%]="100"
                  [style.paddingBottom.%]="aspectRatio"
                  (click)="selectImage(userImage)"
                  [ngClass]="{ 'selected-exterior-image': userImage === selectedImage }"
                  [style.background-image]="'url(' + userImage + '&height=120' + ')'"
                ></div>
              </div>
            </div>
            <div class="row" *ngIf="imageType == 'logoImage'">
              <div class="col-lg-3 col-md-3 col-sm-4 col-4">
                <div
                  class="select-logo"
                  [style.width.%]="100"
                  (click)="selectImage(userImage)"
                  [ngClass]="{ 'selected-exterior-image': userImage === selectedImage }"
                  [style.background-image]="'url(' + userImage + '&height=100' + ')'"
                ></div>
              </div>
            </div>
          </mat-expansion-panel>
          <div>
            <mat-expansion-panel class="category-section" *ngFor="let image of imageUrlList" [expanded]="true">
              <mat-expansion-panel-header class="pl-0">
                <mat-panel-title
                  ><b>{{ image.category }}</b
                  ><b>({{ image.images.length }})</b>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row mb-3" *ngIf="imageType == 'bannerImage'">
                <div class="col-lg-4 col-md-4 col-sm-6 col-6" *ngFor="let image of image.images">
                  <div
                    class="select-img"
                    [style.width.%]="100"
                    [style.paddingBottom.%]="aspectRatio"
                    (click)="selectImage(image)"
                    [ngClass]="{ 'selected-exterior-image': image === selectedImage }"
                    [style.background-image]="'url(' + image + '&height=200' + ')'"
                  ></div>
                </div>
              </div>
              <div class="row mb-3" *ngIf="imageType == 'profile'">
                <div class="col-lg-3 col-md-3 col-sm-4 col-4" *ngFor="let image of image.images">
                  <div
                    class="select-profile"
                    [style.width.%]="100"
                    (click)="selectImage(image)"
                    [style.paddingBottom.%]="aspectRatio"
                    [ngClass]="{ 'selected-exterior-image': image === selectedImage }"
                    [style.background-image]="'url(' + image + '&height=120' + ')'"
                  ></div>
                </div>
              </div>
              <div class="row mb-3" *ngIf="imageType == 'logoImage'">
                <div class="col-lg-3 col-md-3 col-sm-4 col-4" *ngFor="let image of image.images">
                  <div
                    class="select-logo"
                    [style.width.%]="100"
                    (click)="selectImage(image)"
                    [ngClass]="{ 'selected-exterior-image': image === selectedImage }"
                    [style.background-image]="'url(' + image + '&height=100' + ')'"
                  ></div>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
          <div class="col-lg-12 mt-5 px-0 apply-btn">
            <button
              [pmx-progress-button]="isDataLoading"
              [disabled]="disableButton"
              mat-flat-button
              color="primary"
              (click)="setImage()"
            >
              Apply
            </button>
          </div>
          <!-- {{ percentageWidth }}{{ percentageHeight }} -->
        </mat-tab>

        <mat-tab label="Upload Image">
          <app-file-upload [imageType]="imageType" (uploadedFile)="uploadFile($event)"></app-file-upload>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

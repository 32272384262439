<div class="prpty_visit">
  <button class="close" mat-icon-button (click)="close()" autofocus="false">
    <mat-icon>close</mat-icon>
  </button>
  <h4>Get Pre-Approved</h4>
  <p class="text-secondary">Get Pre-Approved by a Loan Officer</p>
  <div class="wrap_body mt-4">
    <form [formGroup]="loanApplicationUrlRequestForm">
      <ng-container>
        <!-- <ng-container *ngIf="!appState.authTokenValue?.idToken"> -->
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="firstName" autocomplete="off" />
              <mat-error
                *ngIf="
                  loanApplicationUrlRequestFormControls.firstName.errors &&
                  loanApplicationUrlRequestFormControls.firstName.errors.required
                "
                class="invalid-feedback"
              >
                First Name is required</mat-error
              >
              <mat-error
                *ngIf="
                  loanApplicationUrlRequestFormControls.firstName.errors &&
                  loanApplicationUrlRequestFormControls.firstName.errors.pattern
                "
                class="invalid-feedback"
              >
                Enter valid names</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="lastName" autocomplete="off" />
              <mat-error
                *ngIf="
                  loanApplicationUrlRequestFormControls.lastName.errors &&
                  loanApplicationUrlRequestFormControls.lastName.errors.required
                "
                class="invalid-feedback"
              >
                Last Name is required</mat-error
              >
              <mat-error
                *ngIf="
                  loanApplicationUrlRequestFormControls.lastName.errors &&
                  loanApplicationUrlRequestFormControls.lastName.errors.pattern
                "
                class="invalid-feedback"
              >
                Enter valid names</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Email</mat-label>
              <input
                matInput
                formControlName="email"
                autocomplete="off"
                [readonly]="appState.authTokenValue?.idToken"
              />
              <mat-error
                *ngIf="
                  loanApplicationUrlRequestFormControls.email.errors &&
                  loanApplicationUrlRequestFormControls.email.errors.required
                "
                class="invalid-feedback"
              >
                Email is required</mat-error
              >
              <mat-error
                *ngIf="
                  loanApplicationUrlRequestFormControls.email.errors &&
                  loanApplicationUrlRequestFormControls.email.errors.pattern
                "
                class="invalid-feedback"
              >
                Invalid email format</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Phone</mat-label>
              <input matInput formControlName="phone" autocomplete="off" />
              <mat-error
                *ngIf="
                  loanApplicationUrlRequestFormControls.phone.errors &&
                  loanApplicationUrlRequestFormControls.phone.errors.required
                "
                class="invalid-feedback"
              >
                Contact number is required</mat-error
              >
              <mat-error
                *ngIf="
                  loanApplicationUrlRequestFormControls.phone.errors &&
                  loanApplicationUrlRequestFormControls.phone.errors.pattern
                "
                class="invalid-feedback"
              >
                Enter valid contact number</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Estimated Credit Score</mat-label>
              <input matInput formControlName="estimatedCreditScore" autocomplete="off" type="number" />
              <mat-error
                *ngIf="
                  loanApplicationUrlRequestFormControls.estimatedCreditScore.errors &&
                  loanApplicationUrlRequestFormControls.estimatedCreditScore.errors.required
                "
                class="invalid-feedback"
              >
                Estimated Credit Score is required</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="w-100">
              <mat-label>Down Payment</mat-label>
              <mat-select formControlName="downPayment">
                <mat-option *ngFor="let paymentOption of downPaymentList; let i = index" value="{{ i }}"
                  >${{ paymentOption.min | number }} - ${{ paymentOption.max | number }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  loanApplicationUrlRequestFormControls.downPayment.errors &&
                  loanApplicationUrlRequestFormControls.downPayment.errors.required
                "
                class="invalid-feedback"
              >
                Down Payment is required</mat-error
              >
            </mat-form-field>
          </div>
        </div>
      </ng-container>
      <div class="row mt-2">
        <div *ngIf="checkBox" class="col">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              [checked]="enableSubmit"
              (change)="enableSubmit = !enableSubmit"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label" style="font-style: italic" for="flexCheckDefault">
              I agree to the below terms.
            </label>
          </div>
        </div>
        <div class="col text-right">
          <button
            mat-flat-button
            [pmx-progress-button]="loanApplicationUrlRequestInProgress"
            color="primary"
            [disabled]="!enableSubmit"
            (click)="requestLoanApplicationUrl()"
          >
            Continue
          </button>
          <br />
        </div>
      </div>
      <div #ctaPolicy class="policyDetails" [termsPolicy]></div>
    </form>
  </div>
</div>

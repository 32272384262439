import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { ClientDashboardState } from 'src/app/client-dashboard/state/client-dashboard.state';
import { PropertyFilterService } from 'src/app/property-filter/service/property-filter.service';
import { PropertyFilterState } from 'src/app/property-filter/state/property-filter.state';
import { SearchState } from 'src/app/workspace/state/search.state';
import constants from 'src/constant.json';
import { environment } from '../../../environments/environment';
import { ProspektrApi } from '../api/prospektr.api';
import { SavedSearchApi } from '../api/saved-search.api';
import { FiltersState } from '../state/filter.state';
import { SavedSearchState } from '../state/saved-search.state';
import { Utility } from '../utility/utility';
import { FilterService } from './filter.service';

@Injectable({
  providedIn: 'root',
})
export class SavedSearchService {
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;

  utility = new Utility();
  savedSearchFilterDetails: Array<any> = [];

  constructor(
    private savedSearchApi: SavedSearchApi,
    private savedSearchState: SavedSearchState,
    private snackBar: MatSnackBar,
    private filtersState: FiltersState,
    private searchState: SearchState,
    private appState: AppState,
    private http: HttpClient,
    private filterService: FilterService,
    private currencyPipe: CurrencyPipe,
    private clientDashboardState: ClientDashboardState,
    private router: Router,
    private prospektrApi: ProspektrApi,
    private propertyFilterState: PropertyFilterState,
    public propertyFilterService: PropertyFilterService
  ) {
    this.savedSearchState.$newSaveSearch.subscribe((res) => {
      this.setNewSaveSearchParams(res);
    });
  }

  getSavedSearches() {
    const params = {
      limit: '',
    };
    this.savedSearchApi.loadSavedDetails(params).subscribe(
      (res) => {
        if (res && res.status === 'OK' && res.data) {
          this.savedSearchState.activeSaveSearchesValue = this.formatSavedSearches(res.data);
        } else {
          this.savedSearchState.activeSaveSearchesValue = [];
        }
      },
      (err) => {
        this.savedSearchState.activeSaveSearchesValue = [];
      }
    );
  }

  formatSavedSearches(savedSearches) {
    let appliedFilter = [];
    let filteredSavedSearches = [];
    this.savedSearchFilterDetails = [];
    savedSearches.forEach((savedItem) => {
      if (savedItem && savedItem.type && savedItem.type === 'location' && !appliedFilter.includes(savedItem.filterId)) {
        if (savedItem.filterId && savedItem.filterId !== '') {
          const tmpArray = savedSearches.filter((search) => {
            return savedItem.filterId === search.id;
          });
          const tmpObj = savedItem;
          if (tmpArray.length) {
            savedItem.appliedFilter = tmpArray[0];
            appliedFilter.push(savedItem.filterId);
          }
          filteredSavedSearches.push(tmpObj);
        } else {
          const tmpObj = savedItem;
          filteredSavedSearches.push(tmpObj);
        }
      } else if (savedItem.type === 'filter' && !appliedFilter.includes(savedItem.id)) {
        const tmpArray = savedSearches.filter((search) => {
          return savedItem.id === search.filterId;
        });
        let tmpObj = savedItem;
        if (tmpArray.length) {
          tmpObj = tmpArray[0];
          tmpObj.appliedFilter = savedItem;
          appliedFilter.push(savedItem.id);
        }
        filteredSavedSearches.push(tmpObj);
      }
    });
    for (const item of filteredSavedSearches) {
      item.fromNow =
        moment(new Date()).diff(moment(item.updatedDate), 'hours') < 24
          ? moment(item.updatedDate).fromNow()
          : moment(item.updatedDate).format('MMMM D, YYYY');
    }
    this.savedSearchFilterDetails = filteredSavedSearches;
    return this.savedSearchFilterDetails;
  }

  setNewSaveSearchParams(params) {
    if (params && !this.isEmptyObject(params)) {
      if (!params.isSearchStatus && !params.isFilterStatus) {
        this.utility.openSnackBar(this.snackBar, 'Location or filter option is mandatory', 'snackbar-warning');
      } else if (
        (params.isSearchStatus || params.isFilterStatus) &&
        (!params.saveSearchName ||
          params.saveSearchName === undefined ||
          params.saveSearchName.trim() === '' ||
          params.saveSearchName === null)
      ) {
        this.utility.openSnackBar(this.snackBar, 'Save search name is mandatory', 'snackbar-warning');
      } else if (
        params.isFilterStatus &&
        this.propertyFilterState.appliedFilter &&
        !Object.keys(this.propertyFilterState.appliedFilter).length
      ) {
        this.utility.openSnackBar(this.snackBar, 'Filter should be added before save', 'snackbar-warning');
      } else {
        const newSaveSearchParams = {
          name: params.saveSearchName,
        };
        if (params.isFilterStatus) {
          newSaveSearchParams['filter'] = this.propertyFilterState.appliedFilter;
        }
        if (params.isSearchStatus) {
          newSaveSearchParams['location'] = this.searchState.searchAddressValue;
        }
        this.saveSearchDetails(newSaveSearchParams);
      }
    }
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  saveSearchDetails(params) {
    this.savedSearchApi.saveSearchDetails(params).subscribe(
      (res) => {
        if (res && res.status && res.status === 'success' && res.Message) {
          if (res.data) {
            this.savedSearchState.activeSaveSearchesValue = this.formatSavedSearchResponse(res.data);
            this.utility.openSnackBar(this.snackBar, res.Message, 'snackbar-success');
          } else {
            this.utility.openSnackBar(this.snackBar, res.Message, 'snackbar-warning');
          }
        }
      },
      (err) => {
        if (err && err.status && err.status === 'error' && err.Message) {
          this.utility.openSnackBar(this.snackBar, err.Message, 'snackbar-error');
        }
      }
    );
  }

  addSavedSearchForClients(params): Observable<{ isLoading: boolean; status?: boolean; cause?: 'duplicate' }> {
    let searchData = {
      investorId: params.investorId,
      name: params.name,
      filter: this.propertyFilterState.savedFiltersValue,

      location: params.location,
    };

    let status: boolean = true;
    let cause: 'duplicate' = null;
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .addSavedSearch(searchData)
        .pipe(
          finalize(() => {
            observer.next({ status, cause, isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            this.openSnackBar('Search Saved Successfully', 'snackbar-success');
            this.getSavedSearchForClients(searchData.investorId).subscribe();
          },
          (err) => {
            status = false;
            if (err.error.message == 'Search name already exists') cause = 'duplicate';
            else
              this.openSnackBar(
                err.error && err.error.message ? err.error.message : 'Some error occurred',
                'snackbar-error'
              );
          }
        );
    });
  }

  formatSavedSearchResponse(savedSearchResponse) {
    let savedSearchFilterDetails = this.savedSearchState.activeSaveSearchesValue;
    if (savedSearchResponse.location) {
      if (savedSearchResponse.filter) {
        savedSearchResponse.location.appliedFilter = savedSearchResponse.filter;
      }
      savedSearchResponse.location.fromNow = moment(new Date(savedSearchResponse.location.updatedDate)).fromNow();
      if (savedSearchFilterDetails && savedSearchFilterDetails.length > 1) {
        savedSearchFilterDetails.unshift(savedSearchResponse.location);
      } else {
        savedSearchFilterDetails = savedSearchFilterDetails ? savedSearchFilterDetails : [];
        savedSearchFilterDetails.push(savedSearchResponse.location);
      }
    } else if (savedSearchResponse.filter) {
      savedSearchResponse.filter.fromNow = moment(new Date(savedSearchResponse.filter.updatedDate)).fromNow();
      if (savedSearchFilterDetails && savedSearchFilterDetails.length > 1) {
        savedSearchFilterDetails.unshift(savedSearchResponse.filter);
      } else {
        savedSearchFilterDetails = savedSearchFilterDetails ? savedSearchFilterDetails : [];
        savedSearchFilterDetails.push(savedSearchResponse.filter);
      }
    }
    return savedSearchFilterDetails;
  }

  deleteSavedSearch(params) {
    return new Observable((observer) => {
      const savedDocIds: string[] = [params.id];
      if (params.type === 'location' && params.filterId) {
        savedDocIds.push(params.filterId);
      }
      const saveSearchParams = {
        savedDocIds,
      };
      this.savedSearchApi.deleteSavedDetails(saveSearchParams).subscribe(
        (res) => {
          observer.next(res);
          this.savedSearchState.activeSaveSearchesValue = this.savedSearchState.activeSaveSearchesValue.filter(
            (savedSearch) => ![params.id, params.filterId].includes(savedSearch.id)
          );
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  deleteSavedSearchForClients(params) {
    return new Observable((observer) => {
      const savedDocIds: string[] = [params.id];
      if (params.type === 'location' && params.filterId) {
        savedDocIds.push(params.filterId);
      }
      const saveSearchParams = {
        savedDocIds,
      };
      this.savedSearchApi.deleteSavedDetails(saveSearchParams).subscribe(
        (res) => {
          observer.next(res);
          this.clientDashboardState.savedSearchValue = this.clientDashboardState.savedSearchValue.filter(
            (savedSearch) => ![params.id, params.filterId].includes(savedSearch.id)
          );
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  rePopulateSavedSearch(savedSearch) {
    if (savedSearch && savedSearch.type && savedSearch.type === 'location') {
      if (savedSearch.filterId) {
        if (savedSearch.appliedFilter && savedSearch.appliedFilter.filters) {
          this.propertyFilterService.setPrimaryFilterToUrl(savedSearch.appliedFilter.filters);
        }
      }
      if (savedSearch.placeDetails) {
        savedSearch.placeDetails.isFormatted = true;
        this.searchState.searchAddressValue = savedSearch.placeDetails;
      }
    } else if (savedSearch && savedSearch.type && savedSearch.type === 'filter') {
      if (savedSearch.filters) {
        this.propertyFilterService.setPrimaryFilterToUrl(savedSearch.appliedFilter.filters);
      }
    }
  }

  getSavedItems(): Observable<any> {
    let allSavedSearchFilterDetails: any = [];
    return new Observable((observer) => {
      const params = {
        limit: '',
      };
      let response: any = [];
      this.http.post(environment.prospektrBackendBaseURL + constants.API.loadSavedDetails, params).subscribe(
        (res) => {
          if (res) {
            response = res;
            if (response.data) {
              let filterTaken = [];
              let fnlResult = [];
              response.data.forEach((x) => {
                if (x.type === 'location' && !filterTaken.includes(x.filterId)) {
                  if (x.filterId && x.filterId !== '') {
                    const tmpArray = response.data.filter((e) => {
                      return x.filterId === e.id;
                    });
                    const tmpObj = x;
                    if (tmpArray.length) {
                      x.appliedFilter = tmpArray[0];
                      filterTaken.push(x.filterId);
                    }
                    fnlResult.push(tmpObj);
                  } else {
                    const tmpObj = x;
                    fnlResult.push(tmpObj);
                  }
                } else if (x.type === 'filter' && !filterTaken.includes(x.id)) {
                  const tmpArray = response.data.filter((e) => {
                    return x.id === e.filterId;
                  });
                  let tmpObj = x;
                  if (tmpArray.length) {
                    tmpObj = tmpArray[0];
                    tmpObj.appliedFilter = x;
                    filterTaken.push(x.id);
                  }
                  fnlResult.push(tmpObj);
                }
              });
              allSavedSearchFilterDetails = JSON.parse(JSON.stringify(fnlResult));
              this.savedSearchState.activeSaveSearchesValue = allSavedSearchFilterDetails;
              fnlResult = null;
              filterTaken = null;
            }
          }
          observer.next(allSavedSearchFilterDetails);
        },
        (err) => {
          observer.next(allSavedSearchFilterDetails);
        }
      );
    });
  }

  getFormattedSavedSearchList(savedSearchList: Array<any>) {
    for (let i = 0; i < savedSearchList.length; i++) {
      const simplifiedFilterList = [];
      if (savedSearchList[i].appliedFilter?.filters) {
        const filterList = savedSearchList[i].appliedFilter.filters;
        const filterSet = this.filterService.getFilterSet(filterList);
        const simplifiedFilter = this.filterService.getFilterQuery(filterSet);
        savedSearchList[i]['filter'] = simplifiedFilter;
      }
      savedSearchList[i]['filterList'] = this.getFilters(savedSearchList[i]['filter']);
    }
    return savedSearchList;
  }

  getFilters(filters) {
    let filterList = [];
    for (let key in filters) {
      if (key == 'MaxBath' || key == 'MinBath' || key == 'MaxBed' || key == 'MinBed') {
        let filterIndex = filterList.findIndex(({ type }) => type == 'bedBath');
        if (filterIndex == -1) {
          filterList.push({
            value: [],
            icon: 'bed_filter.png',
            type: 'bedBath',
          });
          filterIndex = filterList.length - 1;
        }

        if (key == 'MaxBath' || key == 'MinBath') {
          if (filters.MaxBath && filters.MinBath) {
            filterList[filterIndex].value.push(`${filters.MinBath} - ${filters.MaxBath} Bath`);
            delete filters.MinBath;
            delete filters.MaxBath;
            continue;
          }
          if (filters.MaxBath) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxBath} Bath`);
            delete filters.MaxBath;
            continue;
          }
          if (filters.MinBath) {
            filterList[filterIndex].value.push(`${filters.MinBath} + Bath`);
            delete filters.MinBath;
            continue;
          }
        }

        if (key == 'MaxBed' || key == 'MinBed') {
          if (filters.MaxBed && filters.MinBed) {
            filterList[filterIndex].value.push(`${filters.MinBed} - ${filters.MaxBed} Bed`);
            delete filters.MinBed;
            delete filters.MaxBed;
            continue;
          }
          if (filters.MaxBed) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxBed} Bed`);
            delete filters.MaxBed;
            continue;
          }
          if (filters.MinBed) {
            filterList[filterIndex].value.push(`${filters.MinBed} + Bed`);
            delete filters.MinBed;
            continue;
          }
        }
      }

      if (key == 'MaxListPrice' || key == 'MinListPrice') {
        let filterIndex = filterList.findIndex(({ type }) => type == 'price');
        if (filterIndex == -1) {
          filterList.push({
            value: [],
            icon: 'valueDark.png',
            type: 'price',
          });
          filterIndex = filterList.length - 1;
        }
        if (filters.MaxListPrice && filters.MinListPrice) {
          filterList[filterIndex].value.push(
            `${this.currencyPipe.transform(filters.MinListPrice, 'USD')} - ${this.currencyPipe.transform(
              filters.MaxListPrice,
              'USD'
            )}`
          );
          delete filters.MaxListPrice;
          delete filters.MinListPrice;
          continue;
        }
        if (filters.MaxListPrice) {
          filterList[filterIndex].value.push(`Upto ${this.currencyPipe.transform(filters.MaxListPrice, 'USD')}`);
          delete filters.MaxListPrice;
          continue;
        }
        if (filters.MinListPrice) {
          filterList[filterIndex].value.push(`${this.currencyPipe.transform(filters.MinListPrice, 'USD')} +`);
          delete filters.MinListPrice;
          continue;
        }
      }

      if (key == 'PropertySubType') {
        let filterIndex = filterList.findIndex(({ type }) => type == 'propertyType');
        if (filterIndex == -1) {
          filterList.push({
            value: [],
            icon: 'propertyTypeDark.svg',
            type: 'propertyType',
          });
          filterIndex = filterList.length - 1;
        }
        if (filters.PropertySubType) {
          filterList[filterIndex].value.push(`${filters.PropertySubType}`);
        }
        continue;
      }

      if (key == 'DistressStatus') {
        let filterIndex = filterList.findIndex(({ type }) => type == 'listing');
        if (filterIndex == -1) {
          filterList.push({
            value: [],
            icon: 'listingDataDark.png',
            type: 'listing',
          });
          filterIndex = filterList.length - 1;
        }
        if (filters.DistressStatus) {
          filterList[filterIndex].value.push(`${filters.DistressStatus}`);
        }
        continue;
      }

      if (
        key == 'MinTax' ||
        key == 'MaxTax' ||
        key == 'MaxOwnershipDuration' ||
        key == 'MinOwnershipDuration' ||
        key == 'OwnerIsCorporation' ||
        key == 'OwnerOccupied' ||
        key == 'MaxAssessedLandValue' ||
        key == 'MinAssessedLandValue' ||
        key == 'MaxAssessedValue' ||
        key == 'MinAssessedValue' ||
        key == 'MaxAssessedImprovementValue' ||
        key == 'MinAssessedImprovementValue' ||
        key == 'MaxAVM' ||
        key == 'MinAVM' ||
        key == 'MaxCapRate' ||
        key == 'MinCapRate' ||
        key == 'MaxClosePrice' ||
        key == 'MinClosePrice' ||
        key == 'MaxRentValue' ||
        key == 'MinRentValue' ||
        key == 'UnderpricePercentage' ||
        key == 'MaxDaysOnMarket' ||
        key == 'DistressStartDateWithin' ||
        (key == 'MinDaysOnMarket' && filters[key]) ||
        key == 'FlipDuration'
      ) {
        let filterIndex = filterList.findIndex(({ type }) => type == 'moreFilters');
        if (filterIndex == -1) {
          filterList.push({
            value: [],
            icon: 'more_filter.png',
            type: 'moreFilters',
          });
          filterIndex = filterList.length - 1;
        }
        if (key == 'MaxTax' || key == 'MinTax') {
          if (filters.MaxTax && filters.MinTax) {
            filterList[filterIndex].value.push(`${filters.MinTax} - ${filters.MaxTax} Tax`);
            delete filters.MinTax;
            delete filters.MaxTax;
            continue;
          }
          if (filters.MaxTax) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxTax} Tax`);
            delete filters.MaxTax;
            continue;
          }
          if (filters.MinTax) {
            filterList[filterIndex].value.push(`${filters.MinTax} + Tax`);
            delete filters.MinTax;
            continue;
          }
        }

        if (key == 'MaxRentValue' || key == 'MinRentValue') {
          if (filters.MaxRentValue && filters.MinRentValue) {
            filterList[filterIndex].value.push(`${filters.MinRentValue} - ${filters.MaxRentValue} Rent`);
            delete filters.MinRentValue;
            delete filters.MaxRentValue;
            continue;
          }
          if (filters.MaxRentValue) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxRentValue} Rent`);
            delete filters.MaxRentValue;
            continue;
          }
          if (filters.MinRentValue) {
            filterList[filterIndex].value.push(`${filters.MinRentValue} + Rent`);
            delete filters.MinRentValue;
            continue;
          }
        }

        if (key == 'MaxClosePrice' || key == 'MinClosePrice') {
          if (filters.MaxClosePrice && filters.MinClosePrice) {
            filterList[filterIndex].value.push(`${filters.MinClosePrice} - ${filters.MaxClosePrice} Last Sale Price`);
            delete filters.MinClosePrice;
            delete filters.MaxClosePrice;
            continue;
          }
          if (filters.MaxClosePrice) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxClosePrice} Last Sale Price`);
            delete filters.MaxClosePrice;
            continue;
          }
          if (filters.MinClosePrice) {
            filterList[filterIndex].value.push(`${filters.MinClosePrice} + Last Sale Price`);
            delete filters.MinClosePrice;
            continue;
          }
        }

        if (key == 'MaxCapRate' || key == 'MinCapRate') {
          if (filters.MaxCapRate && filters.MinCapRate) {
            filterList[filterIndex].value.push(`${filters.MinCapRate} - ${filters.MaxCapRate} % Cap Rate`);
            delete filters.MinCapRate;
            delete filters.MaxCapRate;
            continue;
          }
          if (filters.MaxCapRate) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxCapRate} % Cap Rate`);
            delete filters.MaxCapRate;
            continue;
          }
          if (filters.MinCapRate) {
            filterList[filterIndex].value.push(`${filters.MinCapRate} + % Cap Rate`);
            delete filters.MinCapRate;
            continue;
          }
        }

        if (key == 'MaxAVM' || key == 'MinAVM') {
          if (filters.MaxAVM && filters.MinAVM) {
            filterList[filterIndex].value.push(`${filters.MinAVM} - ${filters.MaxAVM} AVM`);
            delete filters.MinAVM;
            delete filters.MaxAVM;
            continue;
          }
          if (filters.MaxAVM) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxAVM} AVM`);
            delete filters.MaxAVM;
            continue;
          }
          if (filters.MinAVM) {
            filterList[filterIndex].value.push(`${filters.MinAVM} + AVM`);
            delete filters.MinAVM;
            continue;
          }
        }

        if (key == 'MaxAssessedImprovementValue' || key == 'MinAssessedImprovementValue') {
          if (filters.MaxAssessedImprovementValue && filters.MinAssessedImprovementValue) {
            filterList[filterIndex].value.push(
              `${filters.MinAssessedImprovementValue} - ${filters.MaxAssessedImprovementValue} Assessed Bldg Value`
            );
            delete filters.MinAssessedImprovementValue;
            delete filters.MaxAssessedImprovementValue;
            continue;
          }
          if (filters.MaxAssessedImprovementValue) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxAssessedImprovementValue} Assessed Bldg Value`);
            delete filters.MaxAssessedImprovementValue;
            continue;
          }
          if (filters.MinAssessedImprovementValue) {
            filterList[filterIndex].value.push(`${filters.MinAssessedImprovementValue} + Assessed Bldg Value`);
            delete filters.MinAssessedImprovementValue;
            continue;
          }
        }

        if (key == 'MaxAssessedValue' || key == 'MinAssessedValue') {
          if (filters.MaxAssessedValue && filters.MinAssessedValue) {
            filterList[filterIndex].value.push(
              `${filters.MinAssessedValue} - ${filters.MaxAssessedValue} Assessed Value`
            );
            delete filters.MinAssessedValue;
            delete filters.MaxAssessedValue;
            continue;
          }
          if (filters.MaxAssessedValue) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxAssessedValue} Assessed Value`);
            delete filters.MaxAssessedValue;
            continue;
          }
          if (filters.MinAssessedValue) {
            filterList[filterIndex].value.push(`${filters.MinAssessedValue} + Assessed Value`);
            delete filters.MinAssessedValue;
            continue;
          }
        }

        if (key == 'MaxAssessedLandValue' || key == 'MinAssessedLandValue') {
          if (filters.MaxAssessedLandValue && filters.MinAssessedLandValue) {
            filterList[filterIndex].value.push(
              `${filters.MinAssessedLandValue} - ${filters.MaxAssessedLandValue} Assessed Land Value`
            );
            delete filters.MinAssessedLandValue;
            delete filters.MaxAssessedLandValue;
            continue;
          }
          if (filters.MaxAssessedLandValue) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxAssessedLandValue} Assessed Land Value`);
            delete filters.MaxAssessedLandValue;
            continue;
          }
          if (filters.MinAssessedLandValue) {
            filterList[filterIndex].value.push(`${filters.MinAssessedLandValue} + Assessed Land Value`);
            delete filters.MinAssessedLandValue;
            continue;
          }
        }

        if (key == 'MaxOwnershipDuration' || key == 'MinOwnershipDuration') {
          if (filters.MaxOwnershipDuration && filters.MinOwnershipDuration) {
            filterList[filterIndex].value.push(
              `${filters.MinOwnershipDuration} - ${filters.MaxOwnershipDuration} Ownership`
            );
            delete filters.MinOwnershipDuration;
            delete filters.MaxOwnershipDuration;
            continue;
          }
          if (filters.MaxOwnershipDuration) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxOwnershipDuration} Ownership`);
            delete filters.MaxOwnershipDuration;
            continue;
          }
          if (filters.MinOwnershipDuration) {
            filterList[filterIndex].value.push(`${filters.MinOwnershipDuration} + Ownership`);
            delete filters.MinOwnershipDuration;
            continue;
          }
        }

        if (key == 'OwnerIsCorporation') {
          if (filters.OwnerIsCorporation == false) {
            filterList[filterIndex].value.push(`Individual`);
          } else {
            filterList[filterIndex].value.push(`Corporation`);
          }
          continue;
        }

        if (key == 'FlipDuration') {
          if (filters.FlipDuration) {
            filterList[filterIndex].value.push(`Flipped within ${filters['FlipDuration']} days`);
          }
          continue;
        }

        if (key == 'OwnerOccupied') {
          if (filters.OwnerOccupied == false) {
            filterList[filterIndex].value.push(`Not Owner Occupied`);
          } else {
            filterList[filterIndex].value.push(` Owner Occupied`);
          }
          continue;
        }

        if (key == 'UnderpricePercentage') {
          if (filters.UnderpricePercentage) {
            filterList[filterIndex].value.push(`${filters.UnderpricePercentage} % + Under priced by`);
          }
          continue;
        }

        if (key == 'MaxDaysOnMarket') {
          if (filters.MaxDaysOnMarket) {
            filterList[filterIndex].value.push(`Listed within ${filters.MaxDaysOnMarket} days`);
          }
          continue;
        }

        if (key == 'MinDaysOnMarket') {
          if (filters.MinDaysOnMarket != '') {
            filterList[filterIndex].value.push(`In the market for more than ${filters.MinDaysOnMarket} days`);
          }
          continue;
        }

        if (key == 'DistressStartDateWithin') {
          if (filters.DistressStartDateWithin) {
            filterList[filterIndex].value.push(`Distress Started within ${filters.DistressStartDateWithin} days`);
          }
          continue;
        }
      }

      if (
        key == 'MaxLivingArea' ||
        key == 'MinLivingArea' ||
        key == 'MaxLotSize' ||
        key == 'MinLotSize' ||
        key == 'MinParkingSpaces' ||
        key == 'MaxYearBuilt' ||
        key == 'MinYearBuilt' ||
        key == 'MaxStories' ||
        key == 'MinStories'
      ) {
        let filterIndex = filterList.findIndex(({ type }) => type == 'propertyFeature');
        if (filterIndex == -1) {
          filterList.push({
            value: [],
            icon: 'prop_specDark.png',
            type: 'propertyFeature',
          });
          filterIndex = filterList.length - 1;
        }
        if (key == 'MaxLivingArea' || key == 'MinLivingArea') {
          if (filters.MaxLivingArea && filters.MinLivingArea) {
            filterList[filterIndex].value.push(`${filters.MinLivingArea} - ${filters.MaxLivingArea} sq.ft`);
            delete filters.MinLivingArea;
            delete filters.MaxLivingArea;
            continue;
          }
          if (filters.MaxLivingArea) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxLivingArea} sq.ft`);
            delete filters.MaxLivingArea;
            continue;
          }
          if (filters.MinLivingArea) {
            filterList[filterIndex].value.push(`${filters.MinLivingArea} + sq.ft`);
            delete filters.MinLivingArea;
            continue;
          }
        }

        if (key == 'MaxLotSize' || key == 'MinLotSize') {
          if (filters.MaxLotSize && filters.MinLotSize) {
            filterList[filterIndex].value.push(`${filters.MinLotSize} - ${filters.MaxLotSize} Lot sq.ft`);
            delete filters.MinLotSize;
            delete filters.MaxLotSize;
            continue;
          }
          if (filters.MaxLotSize) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxLotSize} Lot sq.ft`);
            delete filters.MaxLotSize;
            continue;
          }
          if (filters.MinLotSize) {
            filterList[filterIndex].value.push(`${filters.MinLotSize} + Lot sq.ft`);
            delete filters.MinLotSize;
            continue;
          }
        }

        if (key == 'MaxYearBuilt' || key == 'MinYearBuilt') {
          if (filters.MaxYearBuilt && filters.MinYearBuilt) {
            filterList[filterIndex].value.push(`${filters.MinYearBuilt} - ${filters.MaxYearBuilt} Year`);
            delete filters.MinYearBuilt;
            delete filters.MaxYearBuilt;
            continue;
          }
          if (filters.MaxYearBuilt) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxYearBuilt} Year`);
            delete filters.MaxYearBuilt;
            continue;
          }
          if (filters.MinYearBuilt) {
            filterList[filterIndex].value.push(`${filters.MinYearBuilt} + Year`);
            delete filters.MinYearBuilt;
            continue;
          }
        }

        if (key == 'MaxStories' || key == 'MinStories') {
          if (filters.MaxStories && filters.MinStories) {
            filterList[filterIndex].value.push(`${filters.MinStories} - ${filters.MaxStories} Stories`);
            delete filters.MinStories;
            delete filters.MaxStories;
            continue;
          }
          if (filters.MaxStories) {
            filterList[filterIndex].value.push(`Upto ${filters.MaxStories} Stories`);
            delete filters.MaxStories;
            continue;
          }
          if (filters.MinStories) {
            filterList[filterIndex].value.push(`${filters.MinStories} + Stories`);
            delete filters.MinStories;
            continue;
          }
        }

        if (key == 'MinParkingSpaces') {
          if (filters.MinParkingSpaces) {
            filterList[filterIndex].value.push(`${filters.MinParkingSpaces} + Parking`);
          }
          continue;
        }
      }
    }
    return filterList;
  }

  getSavedSearchForClients(investorId: string): Observable<{ isLoading: boolean }> {
    let allSavedSearchFilterDetails: any = [];
    return new Observable((observer) => {
      let response: any = [];
      observer.next({ isLoading: true });
      this.http
        .get(`${this.prospektrBackendBaseURL}investors/${investorId}/saved-searches`)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res) => {
            if (res) {
              response = res;
              if (response.data) {
                let filterTaken = [];
                let fnlResult = [];
                response.data.forEach((x) => {
                  if (x.type === 'location' && !filterTaken.includes(x.filterId)) {
                    if (x.filterId && x.filterId !== '') {
                      const tmpArray = response.data.filter((e) => {
                        return x.filterId === e.id;
                      });
                      const tmpObj = x;
                      if (tmpArray.length) {
                        x.appliedFilter = tmpArray[0];
                        filterTaken.push(x.filterId);
                      }
                      fnlResult.push(tmpObj);
                    } else {
                      const tmpObj = x;
                      fnlResult.push(tmpObj);
                    }
                  } else if (x.type === 'filter' && !filterTaken.includes(x.id)) {
                    const tmpArray = response.data.filter((e) => {
                      return x.id === e.filterId;
                    });
                    let tmpObj = x;
                    if (tmpArray.length) {
                      tmpObj = tmpArray[0];
                      tmpObj.appliedFilter = x;
                      filterTaken.push(x.id);
                    }
                    fnlResult.push(tmpObj);
                  }
                });
                allSavedSearchFilterDetails = fnlResult;
                this.clientDashboardState.savedSearchValue = allSavedSearchFilterDetails;
                fnlResult = null;
                filterTaken = null;
              }
            }
            observer.next(allSavedSearchFilterDetails);
          },
          (err) => {
            observer.next(allSavedSearchFilterDetails);
          }
        );
    });
  }

  loadSearch(search) {
    let selectedSearch = {
      type: search.type,
      filterId: search.filterId || null,
      appliedFilter:
        search.appliedFilter && search.appliedFilter.filters
          ? {
              filters: search.appliedFilter.filters,
            }
          : {},
      placeDetails: {
        formatted_address: search.placeDetails.formatted_address,
        geometry: {
          curMapCenter:
            search.placeDetails.geometry && search.placeDetails.geometry.curMapCenter
              ? search.placeDetails.geometry.curMapCenter
              : null,
        },
        currentZoom: search.placeDetails.currentZoom,
      },
    };

    this.appState.selectedSearchToLoad = selectedSearch;
    this.router.navigate(['workspace']);
    selectedSearch = null;
  }

  private openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

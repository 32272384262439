import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CorporateLoginDeactivationGuard implements CanDeactivate<any> {
  constructor(private appState: AppState) {}

  async canDeactivate(): Promise<boolean> {
    return await new Promise((resolve, reject) => {
      this.appState.companyInfo$.subscribe((result) => {
        if (result) {
          if (result?.alias !== environment.defaultCompany) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        }
      });
    });
  }
}

import { Injectable } from '@angular/core';
// import { CanDeactivate } from '@angular/router';
import { CanDeactivate } from '@angular/router';
import { AppState } from 'src/app/app.state';
import { PageNotFoundComponent } from 'src/app/page-not-found/component/page-not-found.component';

@Injectable({
  providedIn: 'root',
})
export class NotFoundDeactivationGuard implements CanDeactivate<PageNotFoundComponent> {
  constructor(private appState: AppState) {}

  canDeactivate() {
    const companyInfo = this.appState.companyInfoValue;
    if (companyInfo && Object.keys(companyInfo).length) {
      return true;
    } else {
      return false;
    }
  }
}

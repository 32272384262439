import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { ActivityLoggerService } from 'src/app/_shared/service/activity-logger.service';
import { UserState } from 'src/app/_shared/state/user.state';
import { AppState } from 'src/app/app.state';
import { AgentContactService } from 'src/app/workspace/service/agent-contact.service';
import { AgentContactState } from 'src/app/workspace/state/agent-contact.state';
import { PropertyState } from 'src/app/workspace/state/property.state';
import constant from 'src/constant.json';
import { AgentContactOfferComponent } from '../agent-contact-offer/agent-contact-offer.component';
import { AgentContactScheduleComponent } from '../agent-contact-schedule/agent-contact-schedule.component';
import { AgentContactSuggestionComponent } from '../agent-contact-suggestion/agent-contact-suggestion.component';

@Component({
  selector: 'app-agent-contact',
  templateUrl: './agent-contact.component.html',
  styleUrls: ['./agent-contact.component.scss'],
})
export class AgentContactComponent implements OnInit, OnDestroy {
  agentInfo: object = {};
  listingData = null;
  propertyDetails$;
  loanApplicationUrlRequestInProgress = false;
  isDataLoading = false;
  address = {};
  fullAddress: string;
  alterNativeName: string;
  onDestroyNotifier$ = new Subject();

  constructor(
    public appState: AppState,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    public agentContactService: AgentContactService,
    public agentContactState: AgentContactState,
    private propertyState: PropertyState,
    private authorizeService: AuthorizeService,
    private activityLoggerService: ActivityLoggerService,
    private _ngZone: NgZone,
    private changeDetect: ChangeDetectorRef,
    private userState: UserState
  ) {}

  ngOnInit(): void {
    this.propertyDetails$ = this.propertyState.activePropertyDetails$.subscribe((res: any) => {
      if (Object.keys(res).length == 0) {
        return;
      }
      if (res.listing && res.basic) {
        this.listingData = res.listing;
        let propertyData = (Object.keys(res.basic).length && res.basic) || this.listingData;

        let params: any = {};
        this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
          if (res) {
            params.email = encodeURIComponent(res?.user_profile?.email);
          }
        });
        if (propertyData?.PostalCode) {
          params.zip = propertyData?.PostalCode;
        }
        if (propertyData?.City && propertyData?.StateOrProvince) {
          params.city = propertyData?.City + ', ' + propertyData?.StateOrProvince;
        }
        if (propertyData?.FIPS) {
          params.county = propertyData?.FIPS;
        }
        if (Object.keys(params).length) {
          this.agentContactService
            .getPropertyClient(params)
            .pipe(takeUntil(this.onDestroyNotifier$))
            .subscribe(({ isLoading }) => {
              this.isDataLoading = isLoading;
              this.changeDetect.detectChanges();
            });
          this.fullAddress = propertyData.Address;
          this.address = {
            street: propertyData.StreetAddress,
            city: propertyData.City,
            State: propertyData.StateOrProvince,
            zip: propertyData.PostalCode,
          };
        }
      }
    });

    this.agentContactState.propertyClientData$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      this.agentInfo = res.agent ? res.agent : {};
      this.changeDetect.detectChanges();
    });
    this.appState.companyInfo$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res: any) => {
      if (res) {
        this.alterNativeName = res && res.alterNativeName ? res.alterNativeName : '';
      }
    });
  }

  ngOnDestroy() {
    this.agentInfo = {};
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
    this.propertyDetails$.unsubscribe();
  }

  schedulePropertyVisit() {
    if (this.appState.authTokenValue?.idToken) {
      if (!this.authorizeService.canAct('agentContact')) {
        return false;
      }
    }
    this._ngZone.run(() => {
      const matDialogRef = this.matDialog.open(AgentContactScheduleComponent, {
        panelClass: 'Contact-Schedule',
        width: '500px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          proceedAction: (schedulePropertyVisitData) => {
            this.agentContactState.contactAgentRequestActionStatusValue = true;
            let params = {
              investor: schedulePropertyVisitData,
              agentId: this.agentInfo['id'],
              property: {
                PMXPropertyId: this.propertyState.activePropertyDetailsValue['basic'].PMXPropertyId,
                fullAddress: this.fullAddress,
                streetAddress: this.propertyState.activePropertyDetailsValue['basic'].StreetAddress,
                city: this.propertyState.activePropertyDetailsValue['basic'].City,
                state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
                zipCode: this.propertyState.activePropertyDetailsValue['basic'].PostalCode,
                mlsName: this.listingData.MlsBoard,
                mlsNumber: this.listingData.MlsListingNumber,
                listPrice: this.listingData.ListPrice,
              },
              address: this.address,
            };
            this.logUserActivity(
              constant.LOGGER.propertyDetails.category,
              constant.LOGGER.propertyDetails.action.showingInit.key,
              params.property.PMXPropertyId,
              params.agentId,
              params.address,
              this.fullAddress
            );

            this.agentContactService
              .schedulePropertyVisit(params)
              .pipe(takeUntil(this.onDestroyNotifier$))
              .subscribe(
                (response: any) => {
                  this.agentContactState.contactAgentRequestActionStatusValue = false;
                  matDialogRef.close();
                  this.openSnackBar(response.message, 'snackbar-success');
                  if (response.data && response.data.redirectUrl) {
                    window.open(response.data.redirectUrl);
                  }
                },
                (error) => {
                  this.agentContactState.contactAgentRequestActionStatusValue = false;
                  this._ngZone.run(() => {
                    matDialogRef.close();
                  });
                }
              );
          },
        },
      });
    });
  }

  makeOffer() {
    if (this.appState.authTokenValue?.idToken) {
      if (!this.authorizeService.canAct('agentContact')) {
        return false;
      }
    }
    this._ngZone.run(() => {
      const matDialogRef = this.matDialog.open(AgentContactOfferComponent, {
        panelClass: 'Contact-Offer',
        width: '500px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          proceedAction: (offerRequestData) => {
            this.agentContactState.contactAgentRequestActionStatusValue = true;
            let params = {
              investor: offerRequestData,
              agentId: this.agentInfo['id'],
              property: {
                PMXPropertyId: this.propertyState.activePropertyDetailsValue['PMXPropertyId'],
                fullAddress: this.fullAddress,
                streetAddress: this.propertyState.activePropertyDetailsValue['StreetAddress'],
                city: this.propertyState.activePropertyDetailsValue['City'],
                state: this.propertyState.activePropertyDetailsValue['StateOrProvince'],
                zipCode: this.propertyState.activePropertyDetailsValue['PostalCode'],
                mlsName: this.listingData.MlsBoard,
                mlsNumber: this.listingData.MlsListingNumber,
                listPrice: this.listingData.ListPrice,
              },
              address: this.address,
            };

            this.logUserActivity(
              constant.LOGGER.propertyDetails.category,
              constant.LOGGER.propertyDetails.action.offerSubmit.key,
              params.property.PMXPropertyId,
              params.agentId,
              params.address,
              this.fullAddress
            );

            this.agentContactService
              .makeAnOfferForAProperty(params)
              .pipe(takeUntil(this.onDestroyNotifier$))
              .subscribe(
                (response: any) => {
                  matDialogRef.close();
                  this.openSnackBar(response.message, 'snackbar-success');
                  this.agentContactState.contactAgentRequestActionStatusValue = false;
                  if (response.data && response.data.redirectUrl) {
                    window.open(response.data.redirectUrl);
                  }
                },
                (error) => {
                  this.agentContactState.contactAgentRequestActionStatusValue = false;
                  matDialogRef.close();
                }
              );
          },
        },
      });
    });
  }

  getHomeValueReport() {
    if (this.appState.authTokenValue?.idToken) {
      if (!this.authorizeService.canAct('agentContact')) {
        return false;
      }
    }
    this._ngZone.run(() => {
      const matDialogRef = this.matDialog.open(AgentContactSuggestionComponent, {
        panelClass: 'Contact-Suggestion',
        width: '500px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          proceedAction: (homeValueReportRequestData) => {
            this.agentContactState.contactAgentRequestActionStatusValue = true;
            let params = {
              investor: homeValueReportRequestData,
              agentId: this.agentInfo['id'],
              property: {
                PMXPropertyId: this.propertyState.activePropertyDetailsValue['basic']['PMXPropertyId'],
                fullAddress: this.fullAddress,
                streetAddress: this.propertyState.activePropertyDetailsValue['basic'].StreetAddress,
                city: this.propertyState.activePropertyDetailsValue['basic'].City,
                state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
                zipCode: this.propertyState.activePropertyDetailsValue['basic'].PostalCode,
                mlsName: this.listingData.MlsBoard,
                mlsNumber: this.listingData.MlsListingNumber,
                listPrice: this.listingData.ListPrice,
              },
              address: this.address,
            };

            this.logUserActivity(
              constant.LOGGER.propertyDetails.category,
              constant.LOGGER.propertyDetails.action.valueReportSubmit.key,
              params.property.PMXPropertyId,
              params.agentId,
              params.address,
              this.fullAddress
            );

            this.agentContactService
              .homeValueReportRequest(params)
              .pipe(takeUntil(this.onDestroyNotifier$))
              .subscribe(
                (response: any) => {
                  this.agentContactState.contactAgentRequestActionStatusValue = false;
                  matDialogRef.close();
                  this.openSnackBar(response.message, 'snackbar-success');
                  if (response.data && response.data.redirectUrl) {
                    window.open(response.data.redirectUrl);
                  }
                },
                (error) => {
                  this.agentContactState.contactAgentRequestActionStatusValue = false;
                  matDialogRef.close();
                }
              );
          },
        },
      });
    });
  }

  preApprovedAction() {
    this.agentContactService.preApprovedAction();
    this.agentContactState.loanApplicationUrlRequestStatus$
      .pipe(take(2), takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        this.loanApplicationUrlRequestInProgress = res;
      });
    this.loanApplicationUrlRequestInProgress = false;
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }

  logUserActivity(category, action, PMXPropertyId, agentId, address, fullAddress) {
    let loggerParams = {
      category: category,
      action: action,
    };
    let inputData = {
      PMXPropertyId: PMXPropertyId,
      agentId: agentId,
      address: address,
      fullAddress: fullAddress,
    };
    this.activityLoggerService.logActivity(loggerParams, inputData);
  }
}

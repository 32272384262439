import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { SaveSearchFacade } from '../../../workspace/component/_shared/save-search.facade';

@Component({
  selector: 'app-save-search',
  templateUrl: './save-search.component.html',
  styleUrls: ['./save-search.component.scss'],
})
export class SaveSearchComponent implements OnInit {
  @ViewChild('saveSearchDropDown', { static: false }) saveSearchDropDown: MatMenuTrigger;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  saveFilterForm: FormGroup;

  savedSearchesAndFilters: any[] = [];
  saveSearchFilterInput: string;
  onDestroyNotifier$ = new Subject();
  alternateCompanyName: string = '';

  constructor(
    private saveSearchFacade: SaveSearchFacade,
    private authorizeService: AuthorizeService,
    public appState: AppState,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.saveFilterForm = this.formBuilder.group({
      savedSearch: ['', Validators.required],
    });
    this.saveSearchFacade
      .getOpenedViewSearchStatus()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res) => {
        if (res) {
          this.cancelSaveSearch();
        }
      });
    this.alternateCompanyName = this.appState && this.appState.companyInfoValue.alterNativeName;
  }

  openSaveSearch() {
    if (this.authorizeService.canAct('savedFilter')) {
      this.saveSearchDropDown.openMenu();
      this.saveSearchFacade
        .getActiveSaveSearches()
        .pipe(takeUntil(this.onDestroyNotifier$))
        .subscribe((res) => {
          this.savedSearchesAndFilters = res;
          this.resetSaveSearch();
        });
      this.saveSearchFacade.setOpenedViewSearchStatus(false);
    }
  }

  rePopulateSavedData(property) {
    this.saveSearchFacade.rePopulateSavedSearch(property);
    this.cancelSaveSearch();
  }

  saveSearch() {
    if (!this.saveFilterForm.valid) return;
    let input = this.saveFilterForm.value.savedSearch;
    let searchFlag = true;
    let filterFlag = true;
    this.saveSearchFacade.saveNewSearchAndFilters(input, searchFlag, filterFlag);
    this.trigger.closeMenu();
    this.saveSearchFilterInput = null;
  }

  cancelSaveSearch() {
    this.trigger.closeMenu();
    this.resetSaveSearch();
  }

  resetSaveSearch() {
    this.saveSearchFilterInput = null;
  }

  trackByFn(index, item) {
    return index;
  }
  ngOnDestroy() {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}

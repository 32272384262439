import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Json } from '../interface/common.interface';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionState {
  $planUsage = new BehaviorSubject<Json>({});
  planUsage = this.$planUsage.asObservable();

  public get planUsageValue(): Json {
    return this.$planUsage.value;
  }

  public set planUsageValue(planUsages: Json) {
    this.$planUsage.next(planUsages);
  }
}

import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog-with-controls',
  templateUrl: './confirm-dialog-with-controls.component.html',
  styleUrls: ['./confirm-dialog-with-controls.component.scss'],
})
export class ConfirmDialogWithControlsComponent implements OnInit {
  @ViewChild('inputValue', { static: true }) inputValue;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmDialogWithControlsComponent>
  ) {}

  ngOnInit() {}

  proceed() {
    if (
      (this.inputValue.errors && (this.inputValue.errors.pattern || this.inputValue.errors.required)) ||
      !this.data.inputValue
    )
      return;
    this.data.proceedAction(this.data.inputValue, () => {});
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageGalleryState {
  private imgGalleryListSub$ = new BehaviorSubject<Array<any>>(null);
  imgGalleryList$ = this.imgGalleryListSub$.asObservable();

  private imageGalleryShowStatusSub$ = new BehaviorSubject<boolean>(false);
  imageGalleryShowStatus$ = this.imageGalleryShowStatusSub$.asObservable();

  public get imgGalleryListValue(): Array<any> {
    return this.imgGalleryListSub$.value;
  }

  public set imgGalleryListValue(array: Array<any>) {
    this.imgGalleryListSub$.next(array);
  }

  public get imageGalleryShowStatus() {
    return this.imageGalleryShowStatusSub$.value;
  }

  public set imageGalleryShowStatus(status) {
    this.imageGalleryShowStatusSub$.next(status);
  }
}

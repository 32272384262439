import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/app.state';
import { LargeModalComponent } from '../../_shared/component/large-modal/large-modal.component';
type PolicyType = 'terms' | 'policy' | 'accountCreationPolicy';
type UserRole = 'agent' | 'loanOfficer';

@Directive({
  selector: '[termsPolicy]',
})
export class TermsPolicyDirective {
  @Output() termsAndConditionComponents = new EventEmitter();
  companyDetails: any = {};
  companyPolicy: any;
  constructor(private appState: AppState, private dialog: MatDialog) {}

  @Input() termsPolicy: PolicyType;
  @Input() role: UserRole;

  @HostListener('click') onClick() {
    if (!this.termsPolicy) return;
    this.triggerPolicyView(this.termsPolicy, this.role);
  }

  triggerPolicyView(type: PolicyType, role?: UserRole) {
    let companyPolicy = this.getTermsAndConditions(type, role);
    this.viewPolicyConditions(companyPolicy);
  }

  getTermsAndConditions(type: PolicyType, role?: UserRole) {
    let company = this.appState.companyInfoValue.companyPolicy.find((companyPolicy) => {
      if (companyPolicy.type == type && companyPolicy.role == role) {
        return companyPolicy;
      }
      if (companyPolicy.type == type && companyPolicy.role == undefined) {
        return companyPolicy;
      }
    });
    return company;
  }

  viewPolicyConditions(companyPolicy) {
    let content: string;
    let title: string;
    content = companyPolicy.content;
    title = companyPolicy.title;
    if (companyPolicy.inAppDisplay) window.open(companyPolicy.externalUrl);
    else {
      this.dialog.open(LargeModalComponent, {
        width: '60%',
        disableClose: true,
        data: {
          title,
          content,
          showPrimaryButton: true,
          primaryButtonText: 'OK',
        },
      });
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SavedSearchApi {
  private prospektrBackendBaseURL = environment.prospektrBackendBaseURL;
  private loadSavedDetailsApi = 'saved-searches';
  private saveSearchDetailsApi = 'saveSearchDetails';
  private deleteSavedDetailsApi = 'deleteSavedDetails';

  constructor(private http: HttpClient) {}

  loadSavedDetails(params): Observable<any> {
    return this.http.post(`${this.prospektrBackendBaseURL}${this.loadSavedDetailsApi}`, params);
  }

  saveSearchDetails(params): Observable<any> {
    return new Observable((observer) => {
      this.http.post(`${this.prospektrBackendBaseURL}${this.saveSearchDetailsApi}`, params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  saveSearchDetailsforInvestor(params): Observable<any> {
    return new Observable((observer) => {
      this.http.post(`${this.prospektrBackendBaseURL}investors/${params.investorId}/saved-searches`, params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }

  deleteSavedDetails(params): Observable<any> {
    return new Observable((observer) => {
      this.http.post(`${this.prospektrBackendBaseURL}${this.deleteSavedDetailsApi}`, params).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => {
          observer.error(err.error);
        }
      );
    });
  }
}

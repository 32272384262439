import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() barChartConfig;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  updateFlag = false;
  plotOptions = {};
  tooltip = {};

  constructor() {}

  ngOnInit() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
      },
    });
  }

  ngOnChanges(): void {
    if (!this.barChartConfig.data || !this.barChartConfig.data.length) {
      return;
    }
    if (this.barChartConfig.type === 'bar') {
      if (this.barChartConfig.stacked) {
        this.plotOptions = {
          bar: {
            stacking: 'percent',
            dataLabels: {
              enabled: true,
            },
          },
        };
      } else {
        this.plotOptions = {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        };
      }
      this.tooltip = {
        valuePrefix: '$',
      };
    } else if (this.barChartConfig.type === 'column') {
      if (this.barChartConfig.stacked) {
        this.plotOptions = {
          column: {
            stacking: 'normal',
          },
        };
      } else {
        this.plotOptions = {
          column: {
            dataLabels: {
              enabled: true,
            },
          },
        };
      }
      this.tooltip = {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
      };
    }
    this.createChart();
  }

  private createChart(): void {
    this.chartOptions = {
      chart: {
        type: this.barChartConfig.type,
      },
      title: {
        text: this.barChartConfig.title,
        verticalAlign: 'bottom',
        style: {
          fontSize: '12px',
        },
      },
      subtitle: {
        text: null,
      },
      xAxis: {
        categories: this.barChartConfig.categories,
      },
      yAxis: {
        title: {
          text: this.barChartConfig.yAxisTitle,
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: 'bold',
            color:
              // theme
              (Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || 'gray',
          },
        },
      },
      // tooltip: this.barChartConfig && this.barChartConfig.tooltip ? this.tooltip : null,
      plotOptions: this.plotOptions,
      credits: {
        enabled: false,
      },
      series: this.barChartConfig.data,
    };
    this.updateFlag = true;
  }

  cbChart: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      chart.reflow();
    }, 0);
  };
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'NumberFormatter',
})
export class NumberFormatterPipe implements PipeTransform {
  transform(value: any, trim: boolean = true, separate: boolean = true): any {
    let numValue: any = Number(value);
    if (!numValue || isNaN(numValue)) {
      return value;
    }
    let numberSuffix = '';
    if (trim) {
      const numberLength = Math.ceil(Math.log(value + 1) / Math.LN10);
      if (numberLength >= 7) {
        numberSuffix = 'M';
        numValue = Math.round(value / 100000) / 10;
      } else if (numberLength >= 4) {
        numberSuffix = 'k';
        numValue = Math.floor(value / 1000);
      }
    }
    if (separate) {
      numValue = numValue.toLocaleString();
    }
    return numValue + numberSuffix;
  }
}

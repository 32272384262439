<mat-progress-bar class="mt-4" mode="determinate" [value]="(questionObj.activeStage / questionObj.totalStage) * 100">
</mat-progress-bar>
<h5 class="text-center my-2">{{ question['question'] }}</h5>
<div class="option-group">
  <button
    mat-button
    type="button"
    *ngFor="let option of options"
    class="option"
    (click)="Next(question['index'], option)"
  >
    {{ option }}
  </button>
</div>
<div class="row">
  <div class="form-group col-md-6 col-6 text-center">
    <mat-dialog-actions align="start">
      <button
        mat-button
        class="continue-back"
        type="button"
        *ngIf="question['index'] !== 0"
        (click)="goToPreviousQuestion(question['index'])"
      >
        <span class="mr-2">&#8592;</span>Back
      </button>
    </mat-dialog-actions>
  </div>
  <div class="form-group col-md-6 col-6 text-center">
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close cdkFocusInitial class="skip_btn">Skip Now</button>
    </mat-dialog-actions>
  </div>
</div>

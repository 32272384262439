import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { difference as _difference, union as _union } from 'lodash';
import { BehaviorSubject, Subject, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MultiSelectOption } from 'src/app/_shared/interface/multi-select.interface';

@Component({
  selector: 'app-select-search-dropdown',
  templateUrl: './select-search-dropdown.component.html',
  styleUrls: ['./select-search-dropdown.component.scss'],
})
export class SelectSearchDropdownComponent implements AfterViewChecked, OnDestroy, OnInit, OnChanges, AfterViewInit {
  @ViewChild('allOption') allOption: MatOption;
  @ViewChild(MatSelect) selectView: MatSelect;

  @Input() label!: string;
  @Input() options: MultiSelectOption[];
  @Input() selected: MultiSelectOption[];
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() multiple: boolean = false;
  @Input() canSelectAll: boolean = false;
  @Input() canSearch: boolean = true;
  @Input() panelClass: string = '';
  @Input() lazyLoadComplete: boolean = true;
  @Input() isHideSearch: string;

  @Output() selectedEvent = new EventEmitter<any>();
  @Output() formValidEvent = new EventEmitter<boolean>();
  @Output() scrollEndEvent = new EventEmitter<null>();
  @Output() searchEvent = new EventEmitter<string>();

  allOptionValue: MultiSelectOption = { displayText: 'All', value: '__all__' };
  selectSearchForm: FormGroup;
  selectElement: Element;
  isLoadingMoreOptions: boolean = false;
  selectedValue: any;
  isFilterExists;
  filteredOptions$ = new BehaviorSubject<MultiSelectOption[]>([]);
  selectedOptions$ = new BehaviorSubject<MultiSelectOption[]>([]);
  // Subscription to unsubscribe on component destroy
  onDestroyNotifier$ = new Subject();

  constructor(private _formBuilder: FormBuilder, private _cd: ChangeDetectorRef, private _ngZone: NgZone) {}

  ngOnInit(): void {
    this.selectSearchForm = this._formBuilder.group({
      selectCtrl: [{ value: this.selected, disabled: this.disabled }, this._getValidatorFns()],
      searchCtrl: [],
    });

    this.selectedOptions$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      this.selectSearchForm?.get('selectCtrl').setValue(res);

      let selectedValues = res?.filter((option) => option.value != this.allOptionValue.value);
      this.selectedEvent.emit(selectedValues);

      this.selectedValue = selectedValues[0];
    });

    this.filteredOptions$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res.length == 0) {
        this.isFilterExists = true;
      } else {
        this.isFilterExists = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.options?.currentValue != changes?.options?.previousValue) {
      this.filteredOptions$.next(changes?.options?.currentValue);
      this.isLoadingMoreOptions = false;
    }
    if (changes?.selected?.currentValue != changes?.selected?.previousValue) {
      let selectedOptions = changes?.selected?.currentValue;
      if (selectedOptions?.length == this.options?.length) {
        selectedOptions = changes?.selected?.currentValue?.concat(this.allOptionValue);
      }
      this.selectedOptions$.next(selectedOptions);
    }
  }

  reset() {
    this.filteredOptions$.next(this.filterOptions(null));
    this.searchEvent.emit(null);
    this.searchCtrl.reset();
  }

  ngAfterViewInit(): void {
    this.selectView.openedChange.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((opened) => {
      if (opened) {
        this.selectElement = this.selectView.panel.nativeElement;
        this.registerScrollListener();
      }
    });

    this.selectSearchForm.controls.searchCtrl.valueChanges
      .pipe(debounceTime(300), takeUntil(this.onDestroyNotifier$))
      .subscribe((value) => {
        if (value) {
          this.filteredOptions$.next(this.filterOptions(value));
          this.searchEvent.emit(value);
        }
      });

    this.selectSearchForm.statusChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res === 'VALID') {
        this.formValidEvent.emit(true);
      }
      if (res === 'INVALID') {
        this.formValidEvent.emit(false);
      }
    });
  }

  ngAfterViewChecked(): void {
    if (this._cd && !(this._cd as ViewRef).destroyed) {
      this._cd.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  toggleAllSelection(): void {
    // this.searchCtrl.reset();
    if (this.allOption.selected) {
      this.selectedOptions$.next(this.options.concat(this.allOptionValue));
    } else {
      this.selectedOptions$.next([]);
    }
  }
  toggleOneSelection(): void {
    this.isHideSearch = 'show';
    const existingValidSelectCtrlValue = this.selectedOptions$.value.filter(
      (option) => option.value != this.allOptionValue.value
    );
    const existingSelection = _difference(existingValidSelectCtrlValue, this.filteredOptions$.value);
    if (this.selectSearchForm.controls.selectCtrl.value.length) {
      let newValidSelectCtrlValue = this.selectSearchForm.controls.selectCtrl.value.filter(
        (option) => option.value != this.allOptionValue.value
      );
      this.selectedOptions$.next(_union(existingSelection, newValidSelectCtrlValue));

      if (this.selectedOptions$?.value?.length == this.options.length) {
        this.allOption?.select();
      } else {
        this.allOption?.deselect();
      }
    } else {
      let newValidSelectCtrlValue = [this.selectSearchForm.controls.selectCtrl.value];
      this.selectedOptions$.next(_union(newValidSelectCtrlValue));
    }
  }

  private _getValidatorFns(): Validators[] {
    if (this.required) {
      return [Validators.required];
    }
    return null;
  }

  private filterOptions(value: string): MultiSelectOption[] {
    if (!value) {
      return this.options;
    }
    return this.options.filter((option) => {
      return option.displayText.toLowerCase().includes(value.toLowerCase());
    });
  }

  private registerScrollListener() {
    fromEvent(this.selectElement, 'scroll')
      .pipe(debounceTime(500), takeUntil(this.onDestroyNotifier$))
      .subscribe(() => {
        this._ngZone.runOutsideAngular(() => {
          if (this.lazyLoadComplete || this.isLoadingMoreOptions) return;

          if (this.selectElement.scrollTop >= this.selectElement.scrollHeight - 400) {
            this.isLoadingMoreOptions = true;
            this._ngZone.run(() => this.scrollEndEvent.emit());
          }
        });
      });
  }

  public get selectCtrl(): FormControl {
    return this.selectSearchForm.controls.selectCtrl as FormControl;
  }

  public get searchCtrl(): FormControl {
    return this.selectSearchForm.controls.searchCtrl as FormControl;
  }
}

import { Injectable } from '@angular/core';
import { isEqual } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import {
  ClusteredProperty,
  MapCluster,
  MapProperty,
  PartialProperty,
  PropertyCount,
} from 'src/app/_shared/interface/property.interface';
import { ActiveProperty } from '../../shared/interface/activeProperty-interface';

@Injectable({
  providedIn: 'root',
})
export class PropertyState {
  // ------------------------------------------- NEW STATES ------------------------------------

  // To store the formatted property list received from /GetClustersInBoundingBoxWithFilters API
  private boundingBoxClustersSub$ = new BehaviorSubject<ClusteredProperty[]>(null);
  boundingBoxClusters$ = this.boundingBoxClustersSub$.asObservable();
  public get boundingBoxClustersValue(): ClusteredProperty[] {
    return this.boundingBoxClustersSub$.value;
  }
  public set boundingBoxClustersValue(propertySet: ClusteredProperty[]) {
    this.boundingBoxClustersSub$.next(propertySet);
  }

  // To store the formatted property list received from /GetListingsInBoundingBoxWithFilters API
  private boundingBoxListingsSub$ = new BehaviorSubject<PartialProperty[]>(null);
  boundingBoxListings$ = this.boundingBoxListingsSub$.asObservable();

  public get boundingBoxListingsValue(): PartialProperty[] {
    return this.boundingBoxListingsSub$.value;
  }
  public set boundingBoxListingsValue(propertySet: PartialProperty[]) {
    this.boundingBoxListingsSub$.next(propertySet);
  }

  // To store the formatted property list received from /GetPropertiesInBoundingBoxWithFilters API
  private boundingBoxPropertiesSub$ = new BehaviorSubject<PartialProperty[]>(null);
  boundingBoxProperties$ = this.boundingBoxPropertiesSub$.asObservable();
  public get boundingBoxPropertiesValue(): PartialProperty[] {
    return this.boundingBoxPropertiesSub$.value;
  }
  public set boundingBoxPropertiesValue(propertySet: PartialProperty[]) {
    this.boundingBoxPropertiesSub$.next(propertySet);
  }

  // To store the formatted property list received from /GetPropertiesByGeo API
  private geoPropertiesSub$ = new BehaviorSubject<PartialProperty[]>(null);
  geoProperties$ = this.geoPropertiesSub$.asObservable();
  public get geoPropertiesValue(): PartialProperty[] {
    return this.geoPropertiesSub$.value;
  }
  public set geoPropertiesValue(propertySet: PartialProperty[]) {
    this.geoPropertiesSub$.next(propertySet);
  }

  // To store the cluster list for marker rendering
  private mapClusterSetSub$ = new BehaviorSubject<MapCluster>(null);
  mapClusterSet$ = this.mapClusterSetSub$.asObservable();
  public get mapClusterSetValue(): MapCluster {
    return this.mapClusterSetSub$.value;
  }
  public set mapClusterSetValue(propertySet: MapCluster) {
    this.mapClusterSetSub$.next(propertySet);
  }

  // To store the property list for marker rendering
  private mapPropertySetSub$ = new BehaviorSubject<MapProperty>(null);
  mapPropertySet$ = this.mapPropertySetSub$.asObservable();
  public get mapPropertySetValue(): MapProperty {
    return this.mapPropertySetSub$.value;
  }
  public set mapPropertySetValue(propertySet: MapProperty) {
    this.mapPropertySetSub$.next(propertySet);
  }

  // To store the property list for card and list rendering
  private listPropertySetSub$ = new BehaviorSubject<PartialProperty[]>(null);
  listPropertySet$ = this.listPropertySetSub$.asObservable();
  public get listPropertySetValue(): PartialProperty[] {
    return this.listPropertySetSub$.value;
  }
  public set listPropertySetValue(propertyList: PartialProperty[]) {
    this.listPropertySetSub$.next(propertyList);
  }

  // To store the total number of valid properties and total number of paginated records received by
  // the API matching the filter
  private propertyCountSub$ = new BehaviorSubject<PropertyCount>({
    totalRecord: 0,
    receivedRecord: 0,
  });
  propertyCount$ = this.propertyCountSub$.asObservable();
  public get propertyCountValue(): PropertyCount {
    return this.propertyCountSub$.value;
  }
  public set propertyCountValue(count: PropertyCount) {
    this.propertyCountSub$.next(count);
  }

  // To store the progress status of the search APIs.
  private searchApiProgressSub$ = new BehaviorSubject<boolean>(null);
  searchApiProgress$ = this.searchApiProgressSub$.asObservable();
  public get searchApiProgressValue(): boolean {
    return this.searchApiProgressSub$.value;
  }
  public set searchApiProgressValue(inProgress: boolean) {
    this.searchApiProgressSub$.next(inProgress);
  }

  // To store the progress status of the pagination APIs.
  private paginationApiProgressSub$ = new BehaviorSubject<boolean>(null);
  paginationApiProgress$ = this.paginationApiProgressSub$.asObservable();
  public get paginationApiProgressValue(): boolean {
    return this.paginationApiProgressSub$.value;
  }
  public set paginationApiProgressValue(inProgress: boolean) {
    this.paginationApiProgressSub$.next(inProgress);
  }

  // To store the PMXPropertyId of the property that was shared via sharable URL
  private sharedPMXPropertyIdSub$ = new BehaviorSubject<number>(null);
  sharedPMXPropertyId$ = this.sharedPMXPropertyIdSub$.asObservable();
  public get sharedPMXPropertyValue(): number {
    return this.sharedPMXPropertyIdSub$.value;
  }
  public set sharedPMXPropertyValue(id: number) {
    this.sharedPMXPropertyIdSub$.next(id);
  }

  // ------------------------------------------- NEW STATES END ------------------------------------

  private noPropertyViewSub = new BehaviorSubject<string>(null);
  noPropertyView$ = this.noPropertyViewSub.asObservable();
  public get noPropertyViewValue(): string {
    return this.noPropertyViewSub.value;
  }
  public set noPropertyViewValue(status: string) {
    this.noPropertyViewSub.next(status);
  }

  private filteredPropertyCountSub = new BehaviorSubject<number>(null);
  filteredPropertyCount$ = this.filteredPropertyCountSub.asObservable();
  private propensityCountSub = new BehaviorSubject<any>(null);
  propensityCount$ = this.propensityCountSub.asObservable();
  private activePropertyDetailsSub = new BehaviorSubject<any>({});
  activePropertyDetails$ = this.activePropertyDetailsSub.asObservable();
  private propertyDetailsSheetInputSub$ = new BehaviorSubject<PartialProperty[]>(null);
  propertyDetailsSheetInput$ = this.propertyDetailsSheetInputSub$.asObservable();
  private activePropertySub = new BehaviorSubject<ActiveProperty | {}>({});
  activeProperty$ = this.activePropertySub.asObservable();
  private activeSchoolDistrictsForPropertySub = new BehaviorSubject<any>({});
  activeSchoolDistrictsForProperty$ = this.activeSchoolDistrictsForPropertySub.asObservable();
  private activeSchoolsForPropertySub = new BehaviorSubject<any>({});
  activeSchoolsForProperty$ = this.activeSchoolsForPropertySub.asObservable();

  private contactInfoLoaderSub = new BehaviorSubject<boolean>(false);
  contactInfoLoader$ = this.contactInfoLoaderSub.asObservable();
  private avoidListingSub = new BehaviorSubject<boolean>(false);
  avoidListing$ = this.avoidListingSub.asObservable();
  private schoolsForPropertyLoaderSub = new BehaviorSubject<boolean>(false);
  schoolsForPropertyLoader$ = this.schoolsForPropertyLoaderSub.asObservable();
  private schoolsForPropertyParamSub = new BehaviorSubject<any>({});
  schoolsForPropertyParam$ = this.schoolsForPropertyParamSub.asObservable();
  private activeMultiplePropertyDetailsSub = new BehaviorSubject<Array<any>>([]);
  activeMultiplePropertyDetails$ = this.activeMultiplePropertyDetailsSub.asObservable();
  private noDataAvailableMessageSub = new BehaviorSubject<string>(null);
  noDataAvailableMessage$ = this.noDataAvailableMessageSub.asObservable();
  isFullPropertyListAvailable = false;
  private initiateRenderSub = new BehaviorSubject<any>(false);
  initiateRender$ = this.initiateRenderSub.asObservable();
  private zipCodeListSub = new BehaviorSubject<Array<any>>([]);
  zipCodeList$ = this.zipCodeListSub.asObservable();

  private listingInformationSub = new BehaviorSubject<any>({});
  listingInformation$ = this.listingInformationSub.asObservable();
  private mlsImageSub$ = new BehaviorSubject<any>({});
  mlsImage$ = this.mlsImageSub$.asObservable();

  public get listingInformationValue(): object {
    return this.listingInformationSub.value;
  }

  public set listingInformationValue(count) {
    this.listingInformationSub.next(count);
  }

  public get initiateRenderValue() {
    return this.initiateRenderSub.value;
  }

  public set initiateRenderValue(status) {
    this.initiateRenderSub.next(status);
  }

  public get filteredPropertyCountValue(): number {
    return this.filteredPropertyCountSub.value;
  }

  public set filteredPropertyCountValue(count: number) {
    this.filteredPropertyCountSub.next(count);
  }

  public get propensityCountValue() {
    return this.propensityCountSub.value;
  }

  public set propensityCountValue(count) {
    this.propensityCountSub.next(count);
  }

  public get activePropertyDetailsValue(): object {
    return this.activePropertyDetailsSub.value;
  }

  public set activePropertyDetailsValue(property: object) {
    this.activePropertyDetailsSub.next(property);
  }

  public get propertyDetailsSheetInput(): PartialProperty[] {
    return this.propertyDetailsSheetInputSub$.value;
  }

  public set propertyDetailsSheetInput(propertyInfo: PartialProperty[]) {
    this.propertyDetailsSheetInputSub$.next(propertyInfo);
  }

  public get activePropertyValue(): ActiveProperty | {} {
    return this.activePropertySub.value;
  }

  public set activePropertyValue(propertyValue: ActiveProperty | {}) {
    this.activePropertySub.next(propertyValue);
  }

  public get activeSchoolDistrictsForPropertyValue(): object {
    return this.activeSchoolDistrictsForPropertySub.value;
  }

  public set activeSchoolDistrictsForPropertyValue(schoolDistrictsForProperty: object) {
    this.activeSchoolDistrictsForPropertySub.next(schoolDistrictsForProperty);
  }

  public get activeSchoolsForPropertyValue(): object {
    return this.activeSchoolsForPropertySub.value;
  }

  public set activeSchoolsForPropertyValue(schoolsForProperty: object) {
    this.activeSchoolsForPropertySub.next(schoolsForProperty);
  }

  public get contactInfoLoaderValue() {
    return this.contactInfoLoaderSub.value;
  }

  public set contactInfoLoaderValue(status: boolean) {
    this.contactInfoLoaderSub.next(status);
  }

  public get avoidListingValue() {
    return this.avoidListingSub.value;
  }

  public set avoidListingValue(status: boolean) {
    this.avoidListingSub.next(status);
  }

  public get schoolsForPropertyLoaderValue() {
    return this.schoolsForPropertyLoaderSub.value;
  }

  public set schoolsForPropertyLoaderValue(status: boolean) {
    this.schoolsForPropertyLoaderSub.next(status);
  }

  public get schoolsForPropertyParamValue(): object {
    return this.schoolsForPropertyParamSub.value;
  }

  public set schoolsForPropertyParamValue(address: object) {
    this.schoolsForPropertyParamSub.next(address);
  }

  public get activeMultiplePropertyDetailsValue() {
    return this.activeMultiplePropertyDetailsSub.value;
  }

  public set activeMultiplePropertyDetailsValue(propertyList: Array<any>) {
    this.activeMultiplePropertyDetailsSub.next(propertyList);
  }

  public get noDataAvailableValue() {
    return this.noDataAvailableMessageSub.value;
  }

  public set noDataAvailableValue(message: string) {
    this.noDataAvailableMessageSub.next(message);
  }

  public get zipCodeListValue(): Array<any> {
    return this.zipCodeListSub.value;
  }

  public set zipCodeListValue(zipCodeArray: Array<any>) {
    if (!isEqual(this.zipCodeListSub.value, zipCodeArray)) {
      this.zipCodeListSub.next(zipCodeArray);
    }
  }

  public get mlsImageValue() {
    return this.mlsImageSub$.value;
  }

  public set mlsImageValue(mlsImage: any) {
    this.mlsImageSub$.next(mlsImage);
  }

  private activeHomeValueSub = new BehaviorSubject<any>({});
  activeHomeValue$ = this.activeHomeValueSub.asObservable();

  public get activeHomeValueData(): object {
    return this.activeHomeValueSub.value;
  }
  public set activeHomeValueData(homeValue: object) {
    this.activeHomeValueSub.next(homeValue);
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { GeoIpApi } from '../api/geo-ip.api';

@Injectable({
  providedIn: 'root',
})
export class GeoIpService {
  constructor(private geoIpApi: GeoIpApi, private snackBar: MatSnackBar) {}

  getGeoIpInfo() {
    return new Observable((observer) => {
      this.geoIpApi.getGeoIpInfo().subscribe((res) => {
        if (res && res.status && res.status === 'success' && res.data) {
          observer.next(res.data);
          observer.complete();
        } else {
          this.openSnackBar('Failed to fetch user location', 'snackbar-error');
        }
      });
    });
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogContent {
  type: string;
  message: string;
  title: string;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  proceedAction?: any;
  proceedCancelAction?: any;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  isProceedActionProceeding: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogContent
  ) {
    this.isProceedActionProceeding = false;
  }

  ngOnInit() {}

  continueAction() {
    this.isProceedActionProceeding = true;
    if (this.data.proceedAction) {
      this.data.proceedAction(true, (endResult) => {
        if (endResult && endResult.status) {
          this.dialogRef.close(true);
        }
        this.isProceedActionProceeding = false;
      });
    } else {
      this.isProceedActionProceeding = false;
      this.dialogRef.close(true);
    }
  }
  proceedCancelAction() {
    this.data.proceedCancelAction(true, (endResult) => {
      if (endResult && endResult.status) {
        this.dialogRef.close(true);
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportsService } from 'src/app/reports/reports.service';
import { UserState } from '../state/user.state';

@Component({
  selector: 'app-connected-agent-details',
  templateUrl: './connected-agent-details.component.html',
  styleUrls: ['./connected-agent-details.component.scss'],
})
export class ConnectedAgentDetailsComponent implements OnInit {
  displayedColumns: string[] = ['agent_name', 'plan_type', 'status', 'date', 'details', 'payment_status'];
  onDestroyNotifier$ = new Subject();
  connectedAgentDetails: any;

  constructor(private reportsService: ReportsService, private userState: UserState) {}

  ngOnInit(): void {
    this.reportsService.getConnectedAgents();
    this.userState.connectedAgents.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((data) => {
      this.connectedAgentDetails = data;
    });
  }
}

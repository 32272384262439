import { Injectable } from '@angular/core';
import { AppState } from 'src/app/app.state';
import { WorkspaceState } from '../../_shared/state/workspace.state';
import { ListState } from '../state/list.state';
import { PropertyState } from '../state/property.state';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  constructor(
    private workspaceState: WorkspaceState,
    private propertyState: PropertyState,
    private listState: ListState,
    private appState: AppState
  ) {
    this.initSubscription();
  }

  initSubscription() {
    this.workspaceState.workspaceViewValue = this.appState.workspaceView || 'map';

    this.workspaceState.activeWorkspaceMode$.subscribe((res) => {
      this.listState.setPaginationValue({
        pageSize: 20,
        pageNumber: 1,
        sortFields: res?.defaultPropertySort?.sortFields || 'Lst_ListingContractDate',
        sortOrder: res?.defaultPropertySort?.sortOrder || 'DESC',
      });
    });
  }

  openPropertyDetailsSheet(property) {
    const listingInformation: any = this.propertyState.listingInformationValue;
    if (Object.keys(listingInformation).length && listingInformation.PMXPropertyId == property.PMXPropertyId) {
      property.Lst_StandardStatus = listingInformation.Lst_StandardStatus;
      property.Lst_ListPrice = listingInformation.Lst_ListPrice;
      property.Lst_OriginalListPrice = listingInformation.Lst_OriginalListPrice;
      property.Lst_ClosePrice = listingInformation.Lst_ClosePrice;
      property.Lst_ListingContractDate = listingInformation.Lst_ListingContractDate;
      property.Lst_ListAgentFullName = listingInformation.Lst_ListAgentFullName;
      property.Lst_ListAgentPhone = listingInformation.Lst_ListAgentPhone;
      property.Lst_ListAgentEmail = listingInformation.Lst_ListAgentEmail;
      property.Lst_ListAgentOfficeName = listingInformation.Lst_ListAgentOfficeName;
      property.Lst_FlipDuration = listingInformation.Lst_FlipDuration;
      property.Lst_LastStatusChangeEventTimestamp = listingInformation.Lst_LastStatusChangeEventTimestamp;
    }

    if (property && property.PMXPropertyId) {
      property.logInput = true;
      this.propertyState.propertyDetailsSheetInput = property;
    }
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDecimalInput]'
})
export class DecimalInputDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value;
    const regex = /^\d+(\.\d{0,2})?$/;
    if (!regex.test(value)) {
      // If not, remove the last character from the input
      value = value.slice(0, -1);
    }

    if (input.value !== value) {
      input.value = value;

      // Dispatch the 'input' event
      const event2 = new Event('input', { bubbles: true });
      input.dispatchEvent(event2);
    }
  }

}

import { Component, HostBinding, Input } from '@angular/core';

interface IndicatorType {
  spinner: 'spinner';
}

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent {
  /**
   * Test to be displayed along with the spinner.
   * No text is displayed by default.
   */
  @Input() text: string = null;

  /**
   * Whether the spinner should be placed inline or as a block
   * Default value is false.
   */
  @Input() inline: boolean = false;

  /**
   * Type of progress indicator. Only 'spinner' is supported.
   */
  @Input() type: keyof IndicatorType = 'spinner';

  /**
   * Size of the spinner. Default value is 30.
   */
  @Input() size: number = 30;

  /**
   * Controls the transparency/opacity of the background.
   * Accepts a value from 0 to 1. 0 being fully transparent and 1 being fully opaque.
   * Default value is 0.
   */
  @Input() transparency: number = 1;

  @HostBinding('class.block') get blockClass() {
    return !this.inline;
  }

  @HostBinding('style.background-color') get transparencyStyle() {
    return `rgb(255, 255, 255, ${this.inline ? 0 : this.transparency})`;
  }

  constructor() {}

  hex2rgba(hex, alpha = 1) {
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { FavoriteState } from 'src/app/_shared/state/favorite.state';
import MESSAGE from 'src/assets/resource/strings.json';
import { FavoriteModalComponent } from '../../../_shared/component/favorite-modal/favorite-modal.component';
import { WorkspaceState } from '../../../_shared/state/workspace.state';
import { PropertyService } from '../../../workspace/service/property.service';
import { WorkspaceService } from '../../../workspace/service/workspace.service';
import { ListState } from '../../../workspace/state/list.state';
import { MapState } from '../../../workspace/state/map.state';
import { PropertyState } from '../../../workspace/state/property.state';
import { SearchState } from '../../../workspace/state/search.state';

@Injectable({
  providedIn: 'root',
})
export class ListFacade {
  isFullPropertyListAvailable = true;
  stringRes: any = MESSAGE;

  constructor(
    private propertyState: PropertyState,
    private searchState: SearchState,
    private listState: ListState,
    private workspaceService: WorkspaceService,
    private workspaceState: WorkspaceState,
    private dialog: MatDialog,
    private favoriteState: FavoriteState,
    private mapState: MapState,
    private propertyService: PropertyService
  ) {}

  getListPropertyCount(): Observable<any> {
    return this.propertyState.filteredPropertyCount$;
  }

  getFilteredPropertyCount() {
    return this.propertyState.filteredPropertyCountValue;
  }

  isDataLoading$(): Observable<any> {
    return this.searchState.searchInitiated$;
  }

  isLoading(): Observable<boolean> {
    return this.workspaceState.isSearchProcessing$;
  }

  pagination(index) {
    this.listState.listPageIndexValue = { PageNumber: index + 1 };
  }

  listSort(sortingParams) {
    if (sortingParams) {
      this.listState.appliedSortValue = { SortFields: sortingParams.active, SortOrder: sortingParams.direction };
    }
  }

  getIsFullPropertyListAvailable() {
    return this.propertyState.isFullPropertyListAvailable;
  }

  openPropertyDetailsSheet(property) {
    if (this.isClusteredViewActive()) {
      if (property.Lst_ListingId && property.PMXPropertyId) {
        this.propertyService.getListingData(property.PMXPropertyId);
      }
      this.workspaceService.openPropertyDetailsSheet(property);
    } else {
      this.workspaceService.openPropertyDetailsSheet(property);
    }
  }

  isClusteredViewActive() {
    return this.mapState.isClusteredViewValue;
  }

  openFavoriteModal(pmxPropertyIdList) {
    const PMXPropertyIdList = pmxPropertyIdList;
    const dialogRef = this.dialog.open(FavoriteModalComponent, {
      data: {
        action: 'add',
        dataType: 'property',
        dataList: PMXPropertyIdList,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.setFavoriteActionCompletedStatus(true);
    });
  }

  formatPropertyList(propertyGroupList) {
    let formattedPropertyList = [];
    if (propertyGroupList && propertyGroupList.length) {
      propertyGroupList.forEach((propertyGroup) => {
        if (propertyGroup && propertyGroup.propertyList && propertyGroup.propertyList.length) {
          // formattedPropertyList = formattedPropertyList.concat(propertyGroup.propertyList);
          propertyGroup.propertyList.forEach((property) => {
            if (
              moment(property.LastSaleContractDate, 'DD/MM/YYYY').isBefore(moment(property.Lst_CloseDate, 'DD/MM/YYYY'))
            ) {
              property.lastSaleDate = property.LastSaleContractDate
                ? property.LastSaleContractDate
                : property.Lst_CloseDate;
            } else {
              property.lastSaleDate = property.Lst_CloseDate ? property.Lst_CloseDate : property.LastSaleContractDate;
            }
            if (property && property.isFiltered) {
              formattedPropertyList.push(property);
            }
          });
        }
      });
    }
    return formattedPropertyList;
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  getFavoriteActionCompletedStatus(): Observable<any> {
    return this.favoriteState.favoriteActionCompleted$;
  }

  setFavoriteActionCompletedStatus(status) {
    this.favoriteState.favoriteActionCompletedValue = status;
  }

  setSearchInitiatedStatus(status) {
    this.searchState.searchInitiatedValue = status;
  }

  getIsSearchProcessingValue$(): Observable<boolean> {
    return this.workspaceState.isSearchProcessing$;
  }

  isViewSwitched() {
    return this.workspaceState.isSwitchedValue;
  }

  setOpenLocalTrendsStatus(status) {
    this.workspaceState.openLocalTrendsSheetValue = status;
  }

  getLocalTrendsAvailabilityStatus(): Observable<boolean> {
    return new Observable((observer) => {
      this.propertyState.zipCodeList$.subscribe((res) => {
        if (res && res.length) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }
}

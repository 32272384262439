import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppState } from '../app.state';
import { NotificationService } from '../notifications/service/notification.service';
import { ProfileService } from '../profile/profile.service';
import { ReportsService } from '../reports/reports.service';
import { SubscriptionPlanService } from '../shared/service/subscription-plan.service';
import { SearchService } from '../workspace/service/search.service';
import { AuthorizeService } from '../_core/access-control/authorize.service';
import { FavoriteService } from '../_shared/service/favorite.service';
import { FilterService } from '../_shared/service/filter.service';
import { QuestionnaireService } from '../_shared/service/questionnaire.service';
import { SavedSearchService } from '../_shared/service/saved-search.service';
import { UserState } from '../_shared/state/user.state';

@Injectable({
  providedIn: 'root',
})
export class VerifyLoginService {
  timeoutExecuted: boolean = false;
  constructor(
    public router: Router,
    private http: HttpClient,
    private appState: AppState,
    private userState: UserState,
    private favoriteService: FavoriteService,
    private savedSearchService: SavedSearchService,
    public notificationService: NotificationService,
    private questionnaireService: QuestionnaireService,
    private reportsService: ReportsService,
    private ngZone: NgZone,
    public authorizeService: AuthorizeService,
    private profileService: ProfileService,
    private subscriptionService: SubscriptionPlanService,
    private searchService: SearchService,
    private filterService: FilterService
  ) {}

  verifyLogin(): Observable<any> {
    return new Observable((observer) => {
      this.appState.setAppLoadingState('verifyLogin', true);
      this.http
        .post<any>(environment.prospektrBackendBaseURL + 'validateLogin', null)
        .pipe(
          catchError(() => {
            this.appState.authTokenValue = null;
            return EMPTY;
          }),
          finalize(() => this.appState.setAppLoadingState('verifyLogin', false))
        )
        .subscribe(
          (res: any) => {
            if (res && 'user_profile' in res) {
              let navigatedTo = 'workspace';
              if (moment().isAfter(res.renewalDate, 'day')) {
                res.userRole = 'trial';
                navigatedTo = 'account-upgrade';
              } else {
                res.userRole = 'client';
                if (!res.workPlace || !res.workPlace.addressComponents) {
                  navigatedTo = 'profile';
                } else if (sessionStorage.getItem('redirectUrl')) {
                  navigatedTo = sessionStorage.getItem('redirectUrl').replace('/', '');
                }
              }
              this.userState.userDetails = res;

              // if (res && res.roles && res.roles.indexOf('companyManager') < 0) {
              //   this.subscriptionService.getSubscriptionPlan();
              // }

              this.userState.userLocation = res?.workPlace?.userLocation || '';
              this.favoriteService.fetchFollowGroupList();
              this.favoriteService.getAllFavorites();
              this.savedSearchService.getSavedSearches();
              this.filterService.setDetailedFilterForBaseFilter(null);
              this.subscriptionService.getSubscriptionPlan();
              this.searchService.initSubscription();
              if (
                this.appState.companyInfoValue.appConfig?.valueReport?.isEnabled &&
                res.roles &&
                (res.roles.indexOf('agent') > -1 ||
                  res.roles.indexOf('loanOfficer') > -1 ||
                  res.roles.indexOf('appraiser') > -1)
              ) {
                this.reportsService.getPlanStatus();
                if (this.authorizeService.canView('mls')) {
                  this.profileService.getAgentMLSList().subscribe((resp) => {});
                }
              }
              let notificationInput = {
                limit: 10,
                offset: 0,
                sortBy: 'newNotificationDate',
                sortOrder: 'desc',
                sortType: 'all',
              };
              this.notificationService.getPropertyNotifications(notificationInput);
              // if (!res.user_profile.contact && companyName == 'homehunt') {
              if (!res.user_profile.contact) {
                localStorage.setItem('redirect', 'true');
                this.router.navigate(['profile']);
              } else {
                // let queryParams = localStorage.getItem('appQueryParams') ? JSON.parse(localStorage.getItem('appQueryParams')) : {};
                // console.log("verifyLogin queryParams : ", queryParams);
                // this.router.navigate([], {
                //   queryParams: queryParams,
                //   replaceUrl: true,
                //   state: { skipUpdate: true }, // TODO: check skipUpdate initially on this subscription
                // });
                localStorage.setItem('redirect', 'false');
              }
              if (!this.timeoutExecuted) {
                this.timeoutExecuted = true;
                this.ngZone.runOutsideAngular(() => {
                  setTimeout(() => {
                    if (this.userState.userDetails?.roles?.includes('investor')) {
                      this.ngZone.run(() => {
                        this.questionnaireService.getQuestionnaireList();
                      });
                    }
                  }, 30000);
                });
              }

              observer.next({ status: true, path: navigatedTo });
            } else {
              observer.next({ status: true, message: 'Error occurred' });
            }
          },
          (err) => {
            observer.next({
              status: true,
              message: err.error && err.error.Message ? err.error.Message : 'Error occurred',
            });
          }
        );
    });
  }
}

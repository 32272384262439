import { Directive, HostListener, OnChanges, OnInit, Self, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trackChange]',
})
export class TrackChangeDirective implements OnInit, OnChanges {
  // @Input() input: any;

  constructor(@Self() private ngControl: NgControl) { }

  @HostListener('document:keyup', ['$event.target']) onChange(event) {
    // console.log(event);
    // console.log(this.ngControl);
    // return this.ng;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.input) {
      console.log(changes.input);
    }
  }
}

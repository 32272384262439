import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationState {
  private notificationPropertyListSub$ = new BehaviorSubject<Array<any>>([]);
  notificationPropertyList = this.notificationPropertyListSub$.asObservable();

  public get notificationPropertyListValue(): Array<any> {
    return this.notificationPropertyListSub$.value;
  }

  public set notificationPropertyListValue(recommendedData: Array<any>) {
    this.notificationPropertyListSub$.next(recommendedData);
  }

  private notificationPropertyCountSub$ = new BehaviorSubject<number>(0);
  notificationPropertyCount = this.notificationPropertyCountSub$.asObservable();

  public get notificationPropertyCountValue(): number {
    return this.notificationPropertyCountSub$.value;
  }

  public set notificationPropertyCountValue(count: number) {
    this.notificationPropertyCountSub$.next(count);
  }
}

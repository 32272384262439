import { MediaMatcher } from '@angular/cdk/layout';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { AppState } from 'src/app/app.state';

interface CompanyNavBar {
  data: any;
}
@Component({
  selector: 'app-homehunt',
  templateUrl: './homehunt.component.html',
  styleUrls: ['./homehunt.component.scss'],
  providers: [{ provide: MAT_MENU_DEFAULT_OPTIONS, useValue: { overlayPanelClass: 'hh-menu' } }],
})
export class HomehuntComponent implements CompanyNavBar, OnInit, OnDestroy {
  constructor(media: MediaMatcher, private authorizeService: AuthorizeService, public appState: AppState) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this.mobileQuery.addListener(this.mobileQueryListener);
  }

  @Input() data;
  @Input() showLogIn = true;
  @ViewChild('menuTrigger1') menuTrigger1: MatMenuTrigger;
  @ViewChild('menuTrigger2') menuTrigger2: MatMenuTrigger;
  menu1Timer$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  menu2Timer$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  mobileQuery: MediaQueryList;
  currentView = 'desktop';
  private mobileQueryListener: () => void;
  menuToggleClass1 = false;
  menuToggleClass2 = false;
  navbarOpen = false;

  // Subscriptions to unsubscribe on component destroy
  onDestroyNotifier$ = new Subject();

  ngOnInit(): void {
    this.currentView = this.mobileQuery.matches ? 'mobile' : 'desktop';
    this.menu1Timer$.pipe(debounceTime(100), takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res == 'open') this.menuTrigger1.openMenu();
      if (res == 'close') this.menuTrigger1.closeMenu();
    });
    this.menu2Timer$.pipe(debounceTime(100), takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res == 'open') this.menuTrigger2.openMenu();
      if (res == 'close') this.menuTrigger2.closeMenu();
    });
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  openLogInDialog() {
    this.authorizeService.openLogInDialog();
  }
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

type SnackBarType = 'success' | 'info' | 'warning' | 'error';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackBar: MatSnackBar) {}

  triggerSnackBar(type: SnackBarType = 'info', message: string): void {
    this.snackBar.open(message, '', {
      duration: 3000,
      panelClass: type,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
  }
}

<h4 mat-dialog-title class="mb-2">{{ data.title }}</h4>
<button class="close" mat-button color="primary" (click)="data.closeDialog()">
  <mat-icon>close</mat-icon>
</button>
<div mat-dialog-actions>
  <mat-form-field class="form-field w-100">
    <input
      matInput
      type="text"
      pattern="^[ A-Za-z0-9-_]*$"
      required
      [(ngModel)]="data.inputValue"
      #inputValue="ngModel"
    />
    <button
      mat-button
      class="form_btn"
      *ngIf="data.inputValue"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="data.inputValue = ''"
    >
      <mat-icon>create</mat-icon>
    </button>
    <mat-error *ngIf="inputValue.errors && inputValue.errors.pattern" class="invalid-feedback">
      Special Characters are not allowed</mat-error
    >
    <mat-error *ngIf="inputValue.errors && inputValue.errors.required" class="invalid-feedback">
      Name is required
    </mat-error>
  </mat-form-field>
  <div class="text-right w-100">
    <!-- <button class="btn-outline mb-3" mat-button color="primary" (click)="data.closeDialog()">Cancel</button> -->
    <button class="btn-primary text-light mb-3" mat-button color="primary" (click)="proceed()">Save</button>
  </div>
</div>

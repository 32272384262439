import { Component, Input } from '@angular/core';

interface CompanyFooter {
  data: any;
  uniqueMlsList: any;
}
@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements CompanyFooter {
  constructor() {}
  @Input() data;
  @Input() uniqueMlsList;
  @Input() currentYear;

  ngOnInit(): void {}
}

import { ApplicationRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MatSnackBar } from '@angular/material/snack-bar';
import { Event, NavigationEnd, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import constants from 'src/constant.json';
import { FavoriteApi } from '../api/favorite.api';
import { CreateFavorite, FavoriteGroup } from '../interface/favorite.interface';
import { PartialProperty } from '../interface/property.interface';
import { FavoriteState } from '../state/favorite.state';
import { SubscriptionState } from '../state/subscription.state';
import { Utility } from '../utility/utility';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService {
  GLOBAL = constants;
  utility = new Utility();
  subscriptionPlanDetails;
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private favoriteApi: FavoriteApi,
    private favoriteState: FavoriteState,
    private router: Router,
    private snackBar: MatSnackBar,
    private subscriptionState: SubscriptionState,
    private appRef: ApplicationRef,
    private appState: AppState
  ) {
    this.currentUrl = this.router.url;
  }

  initService() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
        if (
          this.currentUrl.startsWith('/workspace') &&
          !this.previousUrl.startsWith('/workspace') &&
          this.appState.authTokenValue?.idToken
        ) {
          this.getAllFavorites();
        }
      }
    });
  }

  getAllFavorites() {
    const favoriteList = [];
    this.favoriteApi.getAllFavorites().subscribe((response: any) => {
      if (response && response.favResult) {
        response.favResult.map((data) => {
          if (data.PMXPropertyId) {
            favoriteList.push(data.PMXPropertyId);
          }
        });
        this.favoriteState.favoritePropertyIdListValue = favoriteList;
      }
    });
  }

  fetchFollowGroupList() {
    this.favoriteApi.fetchFollowGroups().subscribe((response: any) => {
      if (response && response.status === 'success' && response.groupsList) {
        this.favoriteState.followGroupListValue = response.groupsList;
      } else {
        this.favoriteState.followGroupListValue = [];
      }
    });
  }

  createGroup(name): Observable<FavoriteGroup> {
    return new Observable((observer) => {
      this.favoriteApi
        .createGroup({ name })
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (response: any) => {
            if (response?.groupResult) {
              let followGroup = this.favoriteState.followGroupListValue || [];
              let newGroup = {
                id: response.groupResult.id,
                mode: response.groupResult.mode,
                createdDate: response.groupResult.createdDate,
                name: response.groupResult.name,
                favCount: 0,
              };
              followGroup.push(newGroup);
              this.favoriteState.followGroupListValue = followGroup;
              this.fetchFollowGroupList();
              this.openSnackBar('Watchlist created successfully', 'snackbar-success');
              observer.next(newGroup);
            } else {
              this.openSnackBar(response.Message, 'snackbar-error');
            }
          },
          () => {
            this.openSnackBar('Failed to create Watchlist', 'snackbar-warning');
          }
        );
    });
  }

  deleteGroup(groupRecord): Observable<any> {
    return new Observable((observer) => {
      this.favoriteApi
        .deleteGroup(groupRecord.id)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe((response: any) => {
          if (response) {
            let followGroup = this.favoriteState.followGroupListValue || [];
            this.favoriteState.followGroupListValue = followGroup.filter((group) => group.id != groupRecord.id);
            observer.next(groupRecord.id);
            let planUsageValue: any = this.subscriptionState.planUsageValue;
            planUsageValue.favoriteUsage = planUsageValue.favoriteUsage - groupRecord.favCount;
            this.subscriptionState.planUsageValue = planUsageValue;
          }
        });
    });
  }

  addFavorites(inputParams: CreateFavorite): Observable<any> {
    return new Observable((observer) => {
      let IsFavoriteUsageLimit = this.checkFavoriteUsage(inputParams.PMXPropertyIdList.length);

      if (!IsFavoriteUsageLimit) {
        observer.complete();
        this.appRef.tick();
      }

      this.favoriteApi.addFavorites(inputParams).subscribe(
        (response: any) => {
          this.favoriteState.favoritePropertyIdListValue = this.favoriteState.favoritePropertyIdListValue.concat(
            inputParams.PMXPropertyIdList.map((id) => {
              if (typeof id == 'string') {
                id = parseInt(id, 10);
              }
              return id;
            })
          );
          let planUsageValue: any = this.subscriptionState.planUsageValue;
          planUsageValue.favoriteUsage = planUsageValue.favoriteUsage + inputParams.PMXPropertyIdList.length;
          this.subscriptionState.planUsageValue = planUsageValue;
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  addPropertiesToFavoriteGroup(propertyList: Array<PartialProperty>, groupIdList: Array<string>): Observable<any> {
    let requestData = { ...this.formatPropertyToFavorite(propertyList), GroupIds: groupIdList };
    return new Observable((observer) => {
      let IsFavoriteUsageLimit = this.checkFavoriteUsage(propertyList.length);
      if (IsFavoriteUsageLimit) {
        this.favoriteApi
          .addFavorites(requestData)
          .pipe(
            finalize(() => {
              observer.complete();
            })
          )
          .subscribe(
            (response: any) => {
              if (response && response.status && response.status === 'success') {
                this.favoriteState.favoritePropertyIdListValue = this.favoriteState.favoritePropertyIdListValue.concat(
                  requestData.PMXPropertyIdList
                );
                let planUsageValue: any = this.subscriptionState.planUsageValue;
                planUsageValue.favoriteUsage = planUsageValue.favoriteUsage + propertyList.length;
                this.subscriptionState.planUsageValue = planUsageValue;
              }
              observer.next(response);
            },
            (error) => {
              observer.error(error);
            }
          );
      } else {
        observer.complete();
      }
    });
  }

  addRecommendationsToFavoriteGroup(recommendationIdList: Array<string>, groupIdList: Array<string>): Observable<any> {
    let requestData = { recommendationIdList, groupId: groupIdList };
    return new Observable((observer) => {
      this.favoriteApi
        .createFavoritesByRecommendation(requestData)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe(
          (response: any) => {
            observer.next(response);
          },
          (error) => {
            observer.error(error);
          }
        );
    });
  }

  copyFavoritesToFavoriteGroup(favoriteIdList: Array<string>, groupIdList: Array<string>): Observable<any> {
    let requestData = { favoriteList: favoriteIdList, followGroupList: groupIdList };
    return new Observable((observer) => {
      let IsFavoriteUsageLimit = this.checkFavoriteUsage(favoriteIdList.length);
      if (IsFavoriteUsageLimit) {
        this.favoriteApi
          .copyFavorites(requestData)
          .pipe(
            finalize(() => {
              observer.complete();
            })
          )
          .subscribe((response) => {
            if (response.data) {
              let planUsageValue: any = this.subscriptionState.planUsageValue;
              planUsageValue.favoriteUsage = planUsageValue.favoriteUsage + favoriteIdList.length;
              this.subscriptionState.planUsageValue = planUsageValue;
            }
            observer.next(response);
          });
      } else {
        observer.complete();
      }
    });
  }

  moveFavoritesToFavoriteGroup(favoriteIdList: Array<string>, groupIdList: Array<string>): Observable<any> {
    let requestData = { favoriteList: favoriteIdList, followGroupList: groupIdList };
    return new Observable((observer) => {
      this.favoriteApi
        .moveFavorite(requestData)
        .pipe(
          finalize(() => {
            observer.complete();
          })
        )
        .subscribe((response) => {
          observer.next(response);
        });
    });
  }

  getDefaultGroup() {
    let defaultGroupId = null;
    if (this.favoriteState.followGroupListValue && this.favoriteState.followGroupListValue.length) {
      const defaultGroup = this.favoriteState.followGroupListValue.find((el) => el.mode === 'Default');
      if (defaultGroup) defaultGroupId = defaultGroup.id;
      else defaultGroupId = this.favoriteState.followGroupListValue[0].id;
    }
    return defaultGroupId;
  }

  createFavoritesByRecommendation(inputParams) {
    return new Observable((observer) => {
      this.favoriteApi.createFavoritesByRecommendation(inputParams).subscribe((response: any) => {
        observer.next(response);
      });
    });
  }

  unFollowFavorite(inputParams): Observable<any> {
    return new Observable((observer) => {
      this.favoriteApi.unFollowFavorite(inputParams).subscribe(
        (response: any) => {
          if (response && response.status && response.status === 'success') {
            this.favoriteState.favoritePropertyIdListValue = this.favoriteState.favoritePropertyIdListValue.filter(
              (res) => {
                return res != inputParams.PMXPropertyId;
              }
            );
            let planUsageValue: any = this.subscriptionState.planUsageValue;
            planUsageValue.favoriteUsage = planUsageValue.favoriteUsage--;
            this.subscriptionState.planUsageValue = planUsageValue;
          }
          observer.next(response);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  exportFavoriteToExternal(type, idList) {
    return new Observable((observer) => {
      this.favoriteApi.exportFavoriteToExternal({ type, idList }).subscribe(
        (res: any) => {
          this.openSnackBar(res.Message, 'snackbar-success');
          observer.next();
        },
        (err) => {
          this.openSnackBar(err.Message, 'snackbar-error');
          observer.next();
        }
      );
    });
  }

  formatPropertyToFavorite(propertyList: Array<PartialProperty>) {
    const propertyIdList = [];
    const stateList = [];
    propertyList.forEach((property) => {
      propertyIdList.push(property.PMXPropertyId);
      stateList.push(property.StateOrProvince);
    });
    const propertyIdSet: Set<string> = new Set(propertyIdList);
    const stateSet: Set<string> = new Set(stateList);

    return {
      PMXPropertyIdList: Array.from(propertyIdSet),
      stateList: Array.from(stateSet),
    };
  }

  openFavoriteModal(pmxPropertyIdList: Array<string>, stateList: Array<string>): void { }

  checkFavoriteUsage(params) {
    this.subscriptionPlanDetails = this.subscriptionState.planUsageValue;
    if (this.subscriptionPlanDetails && this.subscriptionPlanDetails.favoriteUsage + params <= this.subscriptionPlanDetails.favoriteLimit) {
      return true;
    } else {
      this.openSnackBar('Limit exceeded. Please upgrade to increase the limit.', 'snackbar-error');
      return false;
    }
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import * as moment from 'moment';
import { UserState } from '../state/user.state';

@Injectable({
  providedIn: 'root',
})
export class PlanExpiryGuard implements CanActivate {
  constructor(private router: Router, private userState: UserState) {}

  canActivate() {
    if (this.userState.userDetails?.renewalDate) {
      if (moment().isAfter(this.userState.userDetails?.renewalDate, 'day')) {
        this.router.navigate(['/subscription/account-upgrade']);
      } else {
        return true;
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NotificationApi } from 'src/app/_shared/api/notification.api';
import { NotificationState } from '../state/notification.state';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private notificationState: NotificationState,
    private NotificationApi: NotificationApi
  ) {}

  getPropertyNotifications(params) {
    this.NotificationApi.getNotificationProperty(params).subscribe(
      (res: any) => {
        if (res && res.status && res.status === 'OK' && res.data) {
          this.notificationState.notificationPropertyListValue = res.data;
          this.notificationState.notificationPropertyCountValue = res.totalCount;
        } else {
          this.notificationState.notificationPropertyListValue = [];
          this.notificationState.notificationPropertyCountValue = 0;
        }
      },
      (err: any) => {
        this.notificationState.notificationPropertyListValue = [];
      }
    );
  }

  formatAlerts(data) {
    data.forEach((alert, index) => {
      switch (alert.referenceType) {
        case 'L_NEW':
          data[index].icon = 'newListed.svg';
          break;
        case 'L_PC':
          data[index].icon = 'priceChange.svg';
          break;
        case 'L_SC':
          data[index].icon = 'statusChanges.svg';
          break;
        case 'M_C':
          data[index].icon = 'mortgageClose.svg';
          break;
        case 'M_NEW':
          data[index].icon = 'mortgageOpen.svg';
          break;
        case 'M_LTV':
          // const thresholdValue = alert.rawAlertType.substring(6);
          //data[index].type = `LTV fell below ${thresholdValue}`;
          data[index].icon = 'LTV.svg';
          break;
        case 'P_OD':
          data[index].icon = 'ownershipDuration.svg';
          break;
        case 'P_OSC':
          data[index].icon = 'occupancy.svg';
          break;
        case 'D_ON':
          data[index].icon = 'distressStatus.svg';
          break;
        case 'recommendation':
          data[index].icon = 'recommendation.svg';
          break;
      }
    });
    return data;
  }
  changeNotificationStatus(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.NotificationApi.UpdateNotificationStatusChange(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          })
        )
        .subscribe(
          (res: any) => {},
          (err: any) => {
            //  this.openSnackBar(err.error.message || 'Failed to fetch  Notification', 'snackbar-error');
          }
        );
    });
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit, OnChanges {
  @Input() pieChartConfig;
  @Input('data') series?;
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'test',
    },
    series: [
      {
        type: 'pie',
        name: 'Value',
        colorByPoint: true,
        data: [],
      },
    ],
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    tooltip: {},
    credits: {
      enabled: false,
    },
  };
  updateFlag = false;
  plotOptions = {};
  tooltipContent;

  constructor() {}

  ngOnInit() {
    Highcharts.setOptions({
      lang: {
        thousandsSep: ',',
      },
      legend: { enabled: this.pieChartConfig.showLegend ?? true },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pieChartConfig.currentValue) {
      let chartConfig = changes.pieChartConfig.currentValue;
      // if (!chartConfig.data || !chartConfig.data.length) {
      //   return;
      //}

      if (chartConfig.type === 'pie') {
        this.plotOptions = {
          size: '100%',
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        };
      } else if (chartConfig.type === 'donut') {
        this.plotOptions = {
          pie: {
            size: '100%',
            center: ['50%', '50%'],
            dataLabels: {
              enabled: false,
              format: '<b>{point.percentage:.1f}%</b>',
              distance: -30,
            },
            showInLegend: true,
          },
        };
      }
      this.chartOptions.plotOptions = this.plotOptions;
      this.chartOptions.legend = { enabled: chartConfig.showLegend ?? true };
      this.chartOptions.title = { text: chartConfig.title, verticalAlign: 'bottom' };
      this.updateFlag = true;
      this.createChart(chartConfig);
    }
    if (changes.series && changes.series.currentValue) {
      this.chartOptions.series = changes.series.currentValue;
      this.updateFlag = true;
    }
  }

  private createChart(chartConfig): void {
    this.chartOptions = {
      chart: {
        type: 'pie',
      },
      title: {
        text: null,
      },
      tooltip: {
        formatter: function () {
          return (
            '<span style="color:{point.color}">\u25CF</span> <b>' +
            this.point.name +
            '</b><br/> Value: <b>' +
            this.y +
            '</b><br/>Percent : <b>' +
            this.point.percentage.toFixed() +
            '% </b>'
          );
        },
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: this.plotOptions,
      },
      series: chartConfig.series,
      credits: {
        enabled: false,
      },
      legend: { enabled: chartConfig.showLegend ?? true },
    };
    this.updateFlag = true;
  }

  cbChart: Highcharts.ChartCallbackFunction = (chart): void => {
    setTimeout(() => {
      chart.reflow();
    }, 0);
  };
}

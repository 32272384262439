export class Utility {
  openSnackBar(snackBar, message, style) {
    if (message && style) {
      snackBar.open(message, '', {
        duration: 3000,
        panelClass: style,
        verticalPosition: 'top',
      });
    }
  }
}

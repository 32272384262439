import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WorkspaceState } from '../../../_shared/state/workspace.state';
import { MapState } from '../../state/map.state';
import { PropertyState } from '../../state/property.state';

@Injectable({
  providedIn: 'root',
})
export class StatusBarFacade {
  totalPropertyCount = 0;
  favoriteCount = 0;
  isVisible = true;
  countBar = null;
  switchBarStatus = false;
  switchMsg = null;
  propertyInBoundsCount = -1;

  constructor(
    private mapState: MapState,
    private propertyState: PropertyState,
    private workspaceState: WorkspaceState
  ) {}

  getIsSearchProcessingValue(): Observable<any> {
    return this.workspaceState.isSearchProcessing$;
  }

  getPropensityCount$(): Observable<any> {
    return this.propertyState.propensityCount$;
  }

  getActivePropensityList$(): Observable<any> {
    return this.mapState.activePropensityList$;
  }

  setActivePropensityList(type: string, status: boolean) {
    let propensitySet = new Set(this.mapState.activePropensityListValue);
    if (status) {
      propensitySet = new Set();
      propensitySet.add(type);
    } else {
      propensitySet.delete(type);
    }
    this.mapState.activePropensityListValue = Array.from(propensitySet);
  }

  isDefaultZoomLevel(): Observable<number> {
    return this.mapState.mapZoom$;
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  getNoDataAvailableMessage(): Observable<any> {
    return this.propertyState.noDataAvailableMessage$;
  }

  getFilteredPropertyCount(propertyGroupList) {
    let totalPropertyCount = 0;
    propertyGroupList.forEach((propertyGroup) => {
      if (propertyGroup && propertyGroup.propertyList && propertyGroup.propertyList.length) {
        propertyGroup.propertyList.forEach((property) => {
          if (property.isFiltered) {
            totalPropertyCount++;
          }
        });
      }
    });
    this.mapState.searchSuccessMessageValue = {
      propertyExist: totalPropertyCount ? true : false,
      message: totalPropertyCount ? `${totalPropertyCount} properties found` : 'No properties found',
    };
    setTimeout(() => {
      this.mapState.searchSuccessMessageValue = null;
    }, 2000);
  }

  setOpenLocalTrendsStatus(status) {
    this.workspaceState.openLocalTrendsSheetValue = status;
  }

  getLocalTrendsAvailabilityStatus(): Observable<boolean> {
    return new Observable((observer) => {
      this.propertyState.zipCodeList$.subscribe((res) => {
        if (res && res.length) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }
}

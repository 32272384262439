<h2 mat-dialog-title>{{ this.title }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="wrapper-xs">
    <div #templateRefName></div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    mat-dialog-close
    cdkFocusInitial
    color="primary"
    *ngIf="showPrimaryButton"
    (click)="primaryAction()"
  >
    {{ primaryButtonText }}
  </button>
  <button mat-stroked-button mat-dialog-close cdkFocusInitial *ngIf="showSecondaryButton" (click)="secondaryAction()">
    {{ secondaryButtonText }}
  </button>
</mat-dialog-actions>

<div class="prpty_visit">
  <button class="close" mat-icon-button (click)="close()" autofocus="false">
    <mat-icon>close</mat-icon>
  </button>
  <!-- <div class="pull-right" ><mat-icon>close</mat-icon></div> -->
  <div>
    <h4 class="font-12">Request a selling Agent</h4>
    <!-- <p class="text-secondary">Agent will contact you with a time-slot for visit</p> -->
  </div>
  <div class="wrap_body mt-4">
    <form [formGroup]="sellingConsultantForm">
      <ng-container>
        <div class="row">
          <div class="col">
            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input matInput formControlName="firstName" autocomplete="off" />
              <mat-error
                *ngIf="
                  sellingConsultantFormControls.firstName.errors &&
                  sellingConsultantFormControls.firstName.errors.required
                "
                class="invalid-feedback"
              >
                First Name is required</mat-error
              >
              <mat-error
                *ngIf="
                  sellingConsultantFormControls.firstName.errors &&
                  sellingConsultantFormControls.firstName.errors.pattern
                "
                class="invalid-feedback"
              >
                Enter valid names</mat-error
              >
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="lastName" autocomplete="off" />
              <mat-error
                *ngIf="
                  sellingConsultantFormControls.lastName.errors &&
                  sellingConsultantFormControls.lastName.errors.required
                "
                class="invalid-feedback"
              >
                Last Name is required</mat-error
              >
              <mat-error
                *ngIf="
                  sellingConsultantFormControls.lastName.errors && sellingConsultantFormControls.lastName.errors.pattern
                "
                class="invalid-feedback"
              >
                Enter valid names</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" autocomplete="off" />
              <mat-error
                *ngIf="
                  sellingConsultantFormControls.email.errors && sellingConsultantFormControls.email.errors.required
                "
                class="invalid-feedback"
              >
                Email is required</mat-error
              >
              <mat-error
                *ngIf="sellingConsultantFormControls.email.errors && sellingConsultantFormControls.email.errors.pattern"
                class="invalid-feedback"
              >
                Invalid email format</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <mat-label>Phone</mat-label>
              <input matInput formControlName="phone" autocomplete="off" />
              <mat-error
                *ngIf="
                  sellingConsultantFormControls.phone.errors && sellingConsultantFormControls.phone.errors.required
                "
                class="invalid-feedback"
              >
                Contact number is required</mat-error
              >
              <mat-error
                *ngIf="sellingConsultantFormControls.phone.errors && sellingConsultantFormControls.phone.errors.pattern"
                class="invalid-feedback"
              >
                Enter valid contact number</mat-error
              >
            </mat-form-field>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <mat-form-field>
              <mat-label>How soon do you want to sell?</mat-label>
              <mat-select formControlName="sellDuration">
                <mat-option *ngFor="let sellDuration of sellDuration" [value]="sellDuration.value">
                  {{ sellDuration.viewValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <mat-label>Why are you selling your home?</mat-label>
              <mat-select formControlName="sellOptions">
                <mat-option *ngFor="let sellOptions of sellOptions" [value]="sellOptions.value">
                  {{ sellOptions.viewValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <mat-form-field>
              <mat-label>Are you also looking to buy?</mat-label>
              <mat-select formControlName="buyOptions">
                <mat-option *ngFor="let buyOptions of buyOptions" [value]="buyOptions.value">
                  {{ buyOptions.viewValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <div class="row mt-2">
        <div class="col text-right">
          <button
            mat-flat-button
            [pmx-progress-button]="requestSellingAgentInprogress"
            [disabled]="!enableSubmit"
            color="primary"
            (click)="sendSellingConsultantRequest()"
          >
            Request</button
          ><br />
        </div>
      </div>
    </form>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(value: any, precision = 'day', nextDate?: any): any {
    let formattedDate = value;
    let duration;
    // if (moment(nextDate).isValid()) {
    //   duration = moment.duration(moment(nextDate).diff(value));
    // } else {
    //   duration = moment.duration(moment().diff(value));
    // }

    if (moment(value).isValid()) {
      formattedDate = '';
      let dateDiff = moment().diff(value, 'days');
      if (Math.abs(dateDiff)) {
        formattedDate = `${Math.abs(dateDiff)} days`;
      }
      // const years = duration.years() ? `${Math.abs(duration.years())}Y` : '';
      // const months = duration.months() ? `${Math.abs(duration.months())}M` : '';
      // const days = duration.days() ? `${Math.abs(duration.days())}D` : '';
      // const hours = duration.hours() ? `${Math.abs(duration.hours())}h` : '';
      // const minutes = duration.minutes() ? `${Math.abs(duration.minutes())}m` : '';
      // const seconds = duration.seconds() ? `${Math.abs(duration.seconds())}s` : '';
      // const years = duration.years() ? `${Math.abs(duration.years())} ${duration.years() > 1 ? 'years' : 'year'}` : '';
      // const months = duration.months()
      //   ? `${Math.abs(duration.months())} ${duration.months() > 1 ? 'months' : 'month'}`
      //   : '';
      // const days = duration.days() ? `${Math.abs(duration.days())} ${duration.days() > 1 ? 'days' : 'day'}` : '';
      // const hours = duration.hours() ? `${Math.abs(duration.hours())} ${duration.hours() > 1 ? 'hours' : 'hour'}` : '';
      // const minutes = duration.minutes()
      //   ? `${Math.abs(duration.minutes())} ${duration.minutes() > 1 ? 'minutes' : 'minute'}`
      //   : '';
      // const seconds = duration.seconds()
      //   ? `${Math.abs(duration.seconds())} ${duration.seconds() > 1 ? 'seconds' : 'second'}`
      //   : '';

      // switch (precision) {
      //   case 'year':
      //     formattedDate = years;
      //     break;
      //   case 'month':
      //     formattedDate = `${years}${months ? ' ' + months : ''}`.trim();
      //     break;
      //   case 'day':
      //     formattedDate = `${years}${months ? ' ' + months : ''}${days ? ' ' + days : ''}`.trim();
      //     break;
      //   case 'hour':
      //     formattedDate = `${years}${months ? ' ' + months : ''}${days ? ' ' + days : ''}${
      //       hours ? ' ' + hours : ''
      //     }`.trim();
      //     break;
      //   case 'minute':
      //     formattedDate = `${years}${months ? ' ' + months : ''}${days ? ' ' + days : ''}${hours ? ' ' + hours : ''}${
      //       minutes ? ' ' + minutes : ''
      //     }`.trim();
      //     break;
      //   case 'second':
      //     formattedDate = `${years}${months ? ' ' + months : ''}${days ? ' ' + days : ''}${hours ? ' ' + hours : ''}${
      //       minutes ? ' ' + minutes : ''
      //     }${seconds ? ' ' + seconds : ''}`.trim();
      //     break;
      // }
    } else {
      formattedDate = '--';
    }
    return formattedDate;
  }
}

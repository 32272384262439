import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ProspektrApi } from '../api/prospektr.api';

@Injectable({
  providedIn: 'root',
})
export class EmailSubscribeService {
  constructor(private prospektrApi: ProspektrApi, private snackBar: MatSnackBar) {}

  unsubscribe(params) {
    return new Observable((observer) => {
      observer.next({ isLoading: true });
      this.prospektrApi
        .updateUnsubscribeRecommendation(params)
        .pipe(
          finalize(() => {
            observer.next({ isLoading: false });
            observer.complete();
          }),
          catchError(() => {
            observer.complete();
            return EMPTY;
          })
        )
        .subscribe(
          (res: any) => {
            this.openSnackBar(res.message, 'snackbar-success');
          },
          (err) => {
            let message = err && err.error && err.error.message ? err.error.message : `'Some error occurred '`;
            this.openSnackBar(message, 'snackbar-error');
          }
        );
    });
  }

  private openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoanApplicationUrlRequestComponent } from 'src/app/_shared/component/property-details-sheet/component/loan-application-url-request/loan-application-url-request.component';
import { AppState } from 'src/app/app.state';
import { AgentContactApi } from '../api/agent-contact.api';
import { AgentContactState } from '../state/agent-contact.state';
import { PaymentCalculatorState } from '../state/payment-calculator.state';
import { PropertyState } from '../state/property.state';

@Injectable({
  providedIn: 'root',
})
export class AgentContactService {
  constructor(
    private agentContactApi: AgentContactApi,
    private snackBar: MatSnackBar,
    private agentContactState: AgentContactState,
    private propertyState: PropertyState,
    private matDialog: MatDialog,
    private paymentCalculatorState: PaymentCalculatorState,
    private appState: AppState,
    private _ngZone: NgZone
  ) { }

  getPropertyClient(params): Observable<any> {
    return new Observable((observer) => {
      if (
        this.agentContactState.propertyClientDataValue &&
        this.agentContactState.propertyClientDataValue['input'] &&
        JSON.stringify(this.agentContactState.propertyClientDataValue['input']) === JSON.stringify(params)
      ) {
        this.agentContactState.propertyClientDataValue = this.agentContactState.propertyClientDataValue;
      } else {
        this.agentContactState.propertyClientDataValue = {};
        observer.next({ isLoading: true });
        this.agentContactApi
          .getPropertyClient(params)
          .pipe(
            finalize(() => {
              observer.next({ isLoading: false });
              observer.complete();
            })
          )
          .subscribe(
            (res) => {
              if (res && res.status && res.status == 'success' && res.data) {
                this.agentContactState.propertyClientDataValue = { ...res.data, input: params };
              } else {
                this.agentContactState.propertyClientDataValue = {};
              }
            },
            (err) => {
              this.agentContactState.propertyClientDataValue = {};
            }
          );
      }
    });
  }

  schedulePropertyVisit(params) {
    return new Observable((observer) => {
      this.agentContactApi.schedulePropertyVisit(params).subscribe(
        (res) => {
          if (res.status && res.status == 'success' && res.message) {
            observer.next(res);
          }
        },
        (err) => {
          this.openSnackBar(err.error.message || 'Failed to schedule property visit', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }

  requestSellingAgent(params) {
    return new Observable((observer) => {
      this.agentContactApi.requestSellingAgent(params).subscribe(
        (res) => {
          if (res.status && res.status == 'success' && res.message) {
            observer.next(res);
          }
        },
        (err) => {
          this.openSnackBar(err.error.message || 'Failed to request selling agent', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }

  makeAnOfferForAProperty(params) {
    return new Observable((observer) => {
      this.agentContactApi.makeAnOfferForAProperty(params).subscribe(
        (res) => {
          if (res.status && res.status == 'success' && res.message) {
            observer.next(res);
          }
        },
        (err) => {
          this.openSnackBar(err.error.message || 'Failed to sent an offer request', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }

  homeValueReportRequest(params) {
    return new Observable((observer) => {
      this.agentContactApi.homeValueReportRequest(params).subscribe(
        (res) => {
          if (res.status && res.status == 'success' && res.message) {
            observer.next(res);
          }
        },
        (err) => {
          this.openSnackBar(err.error.message || 'Failed to sent home value report request', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }

  preApprovedUrlRequest(params) {
    return new Observable((observer) => {
      this.agentContactApi.getPreApprovedUrl(params).subscribe(
        (res) => {
          if (res.status && res.status == 'success' && res.data.redirectUrl) {
            this.agentContactState.loanApplicationUrlValue = {
              redirectUrl: res.data && res.data.redirectUrl,
              input: params.data.lenderId.toString(),
            };
            observer.next(true);
            observer.complete();
          } else if (res.status && res.status == 'success') {
            this.openSnackBar(res.status.message || 'Loan Officer notified', 'snackbar-success');
            observer.next();
            observer.complete();
          } else {
            this.agentContactState.loanApplicationUrlValue = {
              input: params.data.lenderId.toString(),
            };
          }
        },
        (err) => {
          this.agentContactState.loanApplicationUrlRequestStatusValue = false;
          this.openSnackBar(err.error.message || 'Loan application url is not provided', 'snackbar-error');
          observer.error(err.error);
        }
      );
    });
  }

  preApprovedAction() {
    let params = {
      data: {
        lenderId:
          this.agentContactState &&
            this.agentContactState.propertyClientDataValue &&
            this.agentContactState.propertyClientDataValue['loanOfficer'] &&
            this.agentContactState.propertyClientDataValue['loanOfficer'].id
            ? this.agentContactState.propertyClientDataValue['loanOfficer'].id
            : null,
        PMXPropertyId: this.propertyState.activePropertyDetailsValue['basic'].PMXPropertyId,
        listPrice: this.paymentCalculatorState.homePrice,
        mlsName: this.propertyState.activePropertyDetailsValue['listing'].MlsBoard,
        mlsNumber: this.propertyState.activePropertyDetailsValue['listing'].MlsListingNumber,
        fullAddress: this.propertyState.activePropertyDetailsValue['basic']['Address'],
        address: {
          street: this.propertyState.activePropertyDetailsValue['basic'].StreetAddress,
          city: this.propertyState.activePropertyDetailsValue['basic'].City,
          state: this.propertyState.activePropertyDetailsValue['basic'].StateOrProvince,
          zip: this.propertyState.activePropertyDetailsValue['basic'].PostalCode,
        },
      },
    };
    // if (this.appState.authTokenValue?.idToken) {
    //   this.agentContactState.loanApplicationUrlRequestStatusValue = true;
    //   this.preApprovedUrlRequest(params).subscribe(
    //     (res: any) => {
    //       this.openSnackBar(
    //         'Thank you for showing interest. Our Loan Officer will connect with you shortly',
    //         'snackbar-success'
    //       );
    //       // if (res) window.open(this.agentContactState.loanApplicationUrlValue['redirectUrl']);
    //       this.agentContactState.loanApplicationUrlRequestStatusValue = false;
    //     },
    //     (err) => {
    //       this.agentContactState.loanApplicationUrlRequestStatusValue = false;
    //     }
    //   );
    // } else {
    this._ngZone.run(() => {
      const matDialogRef = this.matDialog.open(LoanApplicationUrlRequestComponent, {
        panelClass: 'Contact-Schedule',
        width: '500px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          proceedAction: (loanApplicationUrlRequestData) => {
            this.agentContactState.loanApplicationUrlRequestFormStatusValue = true;
            this.preApprovedUrlRequest(params).subscribe(
              (res: any) => {
                this.openSnackBar(
                  'Thank you for showing interest. Our Loan Officer will connect with you shortly',
                  'snackbar-success'
                );
                // if (res) window.open(this.agentContactState.loanApplicationUrlValue['redirectUrl']);
                this.agentContactState.loanApplicationUrlRequestFormStatusValue = false;
                matDialogRef.close();
              },
              (err) => {
                this.agentContactState.loanApplicationUrlRequestFormStatusValue = false;
              }
            );
          },
        },
      });
    });
    // }
  }

  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

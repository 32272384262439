import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapConfig {
  mapTheme: Array<any> = [
    {
      elementType: 'geometry',
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
    },
    {
      elementType: 'labels.text.stroke',
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
    },
    {
      featureType: 'road',
      elementType: 'geometry',
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
    },
    {
      featureType: 'water',
      elementType: 'geometry',
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
    },
  ];

  detailedInfoBox = `\
  <div class="map-info-box mapcard_base" id="pmx#PMXPropertyId#" data-pmx="#PMXPropertyId#"\
    style="background: #787878 url('#imageUrl#') no-repeat right top; background-size: cover;"> \
    <span id="detail#PMXPropertyId#"> \
      <button class="favorite" id="fav#PMXPropertyId#">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" \
          width="24px" height="24px" class="float-left">\
          <g>\
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" \
                data-original="#0000FF" class="watch-icon active-path" data-old_color="#0000FF" fill="#favoriteFill#" />\
          </g>\
        </svg>\
      </button> \
      <div class="prop_info"> \
        <h3 id="price#PMXPropertyId#">$ #price#</h3> \
        <ul> \
          <li><p id="bed#PMXPropertyId#">#bedroomCount#</p> <span>Bed</span></li> \
          <li><p id="bath#PMXPropertyId#">#bathroomCount#</p> <span>Bath</span></li> \
          <li><p id="living#PMXPropertyId#">#livingArea#</p> <span>Sqft</span></li> \
          <li><p id="lot#PMXPropertyId#">#lotSizeSquareFeet#</p> <span>Sqft.lot</span></li> \
        </ul> \
        <span><p class="propertyData" id="address#PMXPropertyId#">#fullAddress#</p></span> \
      </div> \
    </span> \
  </div> `;

  detailedAlternateInfoBox = `\
  <div class="map-info-box mapcard_base" id="pmx#PMXPropertyId#" data-pmx="#PMXPropertyId#"\
    style="background: #787878 url('#imageUrl#') no-repeat right top; background-size: cover;"> \
    <span id="detail#PMXPropertyId#"> \
      <button class="favorite" id="fav#PMXPropertyId#">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" xml:space="preserve" \
          width="24px" height="24px" class="float-left">\
          <g>\
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" \
                data-original="#0000FF" class="watch-icon active-path" data-old_color="#0000FF" fill="#favoriteFill#" />\
          </g>\
        </svg>\
      </button> \
      <div class="prop_info"> \
        <h3 id="price#PMXPropertyId#">$ #price#</h3> \
        <ul> \
          <li><p id="bed#PMXPropertyId#"><img src="assets/images/spinner.gif" width="28%"></p> <span>Bed</span></li> \
          <li><p id="bath#PMXPropertyId#"><img src="assets/images/spinner.gif" width="28%"></p> <span>Bath</span></li> \
          <li><p id="living#PMXPropertyId#"><img src="assets/images/spinner.gif" width="28%"></p> <span>Sqft</span></li> \
          <li><p id="lot#PMXPropertyId#"><img src="assets/images/spinner.gif" width="28%"></p> <span>Sqft.lot</span></li> \
        </ul> \
        <span><p class="propertyData" id="address#PMXPropertyId#">#fullAddress#</p></span> \
      </div> \
    </span> \
  </div> `;

  // favorited Icon = #007BFF;
  // nonFavorited Icon = #D1D1D1;

  // #PMXPropertyId#, #address1#, #address2#, #propertyType#, #filteredCount#, #propertyCount#
  basicInfoBox = `\
  <div id="pmx#PMXPropertyId#" class="map-info-box propinfo_base text-center multipropinfo_base" data-pmx="#PMXPropertyId#">\
    <div class="row">\
      <div class="col-lg-12">\
        <h1><b id="addressPart1">#address1#</b></h1>\
        <p class="mt-0" id="addressPart2">#address2#</p>\
      </div>\
      <div class="col-lg-12">\
        <p class="mt-2"><span class="badge badge-info" id="propertyType">#propertyType#</span></p>\
        <div class="mt-2 propinfo_value">\
          <p>Properties Found</p>\
          <p class="mt-0"><span class="font-weight-bold lead">#propertyCount#</span></p>\
        </div>\
      </div>\
    </div>\
    <hr>\
    <div class="row text-center propinfo_call">\
      <div class="col-lg-12">\
        <button class="btn save_btn bg-light text-dark border  border-dark w-100" id="detailMultiple#PMXPropertyId#">\
          View Properties\
        </button>\
      </div>\
    </div>\
  </div>`;

  defaultCenter = {
    lat: 41.7948402,
    lng: -87.9262366,
  };

  defaultZoom = 13; //  Farther from Earth
  streetLevelZoom = 17; //  Closer to Earth
  maxExtendedPropertyList = 70000;
  maxPropertyListToShow = 20000;
}

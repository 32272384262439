import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export interface DialogData {
  content: string;
  title: string;
  address: string;
  showPrimaryButton: boolean;
  primaryButtonText: string;
  proceedAction?: any;
}
@Component({
  selector: 'app-saved-search-dialog',
  templateUrl: './saved-search-dialog.component.html',
  styleUrls: ['./saved-search-dialog.component.scss'],
})
export class SavedSearchDialogComponent implements OnInit {
  @ViewChild('templateName') el: ElementRef;
  title: string = '';
  content: string;
  address: string;
  filters: any = [];
  showPrimaryButton: boolean = true;
  primaryButtonText: string = 'Apply';
  constructor(
    public dialogRef: MatDialogRef<SavedSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.renderer.setStyle(this.el.nativeElement, 'background', '.wrapper-xs {\n    padding: 20\npx\n 0;\n}');
    this.filters = this.data?.content;
    this.title = this.data?.title;
    this.address = this.data?.address || '';
    this.primaryButtonText = this.data?.primaryButtonText || this.primaryButtonText;
    this.showPrimaryButton = this.data?.showPrimaryButton;
    this.cdr.detectChanges();
  }
  primaryAction() {
    if (this.data.proceedAction) {
      this.data.proceedAction(true, (endResult) => {
        if (endResult && endResult.status) {
        }
      });
    }
  }
}

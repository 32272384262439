import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorizeService } from 'src/app/_core/access-control/authorize.service';
import { UserState } from 'src/app/_shared/state/user.state';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../../shared/modules/alerts/confirm-dialog/confirm-dialog.component';
import { DealAnalysisService } from '../service/deal-analysis.service';

export interface sharedDealDetailsList {
  email: string;
  firstName: string;
  _id: string;
}

@Component({
  selector: 'app-shared-deal-details',
  templateUrl: './shared-deal-details.component.html',
  styleUrls: ['./shared-deal-details.component.scss'],
})
export class SharedDealDetailsComponent implements OnInit {
  onDestroyNotifier$ = new Subject();
  errorMsg = '';
  sharedDealDetails: any;
  displayedColumns: string[];
  dataSource;
  selection;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isDataLoading = true;
  tableDataSource = new MatTableDataSource<sharedDealDetailsList>([]);
  sharedDealDetailsCount: number;
  tableColumnList: string[] = ['name', 'view'];
  @Input() dealData;

  constructor(
    private userState: UserState,
    private authorizeService: AuthorizeService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private dealAnalysisService: DealAnalysisService
  ) {}
  ngOnInit(): void {}

  ngOnChanges(): void {
    this.userState.userDetail$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((res) => {
      if (res) {
        this.sharedDealDetailsCount = this.dealData.sharedUserList.length;
        this.sharedDealDetails = this.dealData.sharedUserList;
        this.tableDataSource = new MatTableDataSource(this.sharedDealDetails);
      }
    });
  }

  deleteSharedDeal(data) {
    let sentData = { ...data, dealId: this.dealData._id };
    if (!this.authorizeService.canAct('savedFilter')) {
      return false;
    }
    if (!data._id) {
      this.openSnackBar('Something went wrong', 'snackbar-error');
      return false;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '385px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        type: 'deleteSharedDeal',
        message: 'The user will not be able to view this deal anymore',
        title: 'Do you want to continue?',
        proceedAction: (status, callBack) => {
          if (status) {
            this.dealAnalysisService
              .deleteSharedDeal(sentData)
              .pipe(takeUntil(this.onDestroyNotifier$))
              .subscribe(
                (res) => {
                  this.openSnackBar(res['message'], 'snackbar-success');
                  callBack({ status: true });
                },
                (err) => {
                  this.openSnackBar(err['message'], 'snackbar-error');
                  dialogRef.close();
                }
              );
          } else {
            return false;
          }
        },
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((result) => {
        if (result) {
          this.sharedDealDetails?.splice([this.sharedDealDetails.findIndex((el) => el._id === data._id)], 1);
          this.sharedDealDetailsCount = this.sharedDealDetails.length;
          this.tableDataSource = new MatTableDataSource<sharedDealDetailsList>(this.sharedDealDetails);
        }
      });
  }
  openSnackBar(message, style) {
    if (message) {
      this.snackBar.open(message, '', {
        duration: 3000,
        panelClass: [style ? style : ''],
        verticalPosition: 'top',
      });
    }
  }
}

<div class="body_wrap dashboard_body">
  <div class="dash_base">
    <div class="container-fluid mt-40">
      <div class="container">
        <div class="paymentWrap">
          <div class="w-80 whiteWrap">
            <div class="text-center mb-4 badgeWrap">
              <img src="../assets/images/smileBox.svg" width="110" />
              <!-- <h4 class="mt-2">A new investor <br> has requested access</h4> -->
              <h4 class="mt-2">{{ requestStatus }}</h4>
            </div>
            <!-- <div class="row w-100 mt-40">
              <div class="col-md-12">
                <ul>
                    <h4 class="mb-4"> A new investor has requested access <br> to Prospektr in your business area. </h4>
                    <li> <img src="../../../assets/images/right-arrow.svg" width="20"> Name: <"Investor Name"> 
                    </li>
                    <li> <img src="../../../assets/images/right-arrow.svg" width="20">  Ph: <"Investor Phone">
                    </li>
                    <li> <img src="../../../assets/images/right-arrow.svg" width="20"> Email: <"Investor Email"> </li>
                    <li> <img src="../../../assets/images/right-arrow.svg" width="20">  Interested Markets: <"Investor’s Interested Markets">
                    </li>
                   <li> <hr> <h6> <img src="../../../assets/images/right-arrow.svg" width="20"> <small> status </small> : <span class="text-success"> Approved </span> </h6> </li> -->
            <!--for reject use class = text-danger -->
            <!-- </ul>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
